import React, { Component } from "react";
import "./Ordini.css";
import api from "../../config";
import AsyncSelect from "react-select/async";
import { Link, Redirect } from "react-router-dom";
import Utility from "../../Utility";
import Load from "../../components/Load";
var _ = require("lodash");

export default class Ordini extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      fetching:false,
      orders: [],
      count: 0,
      page: 0,
      max: 1,
      ordered_get: "",
    };
  }
  componentDidMount() {
    this.setState({ loading: true });
    api.fetchSaleOrder(this.props.prev, 0, 50,this.state.ordered_get).then((value) => {
      if (value)
        this.setState({
          orders: value.rows,
          count: value.total,
          max: Math.ceil(value.total / 50) - 1,
          loading: false,
        });
      else
        this.setState({
          loading: false,
        });
    });
  }
  loadOptions = (inputValue, callback) => {
    this.setState({ searching: true });
    if (inputValue.length > 2) {
      api.searchAsyncSaleOrders(inputValue, this.props.prev).then((value) => {
        if (value) callback(value.rows);
        this.setState({ searching: false });
      });
    }
  };
  fetchmore = (page) => {
    var offset = 50;
    this.setState({fetching:true})
    if (this.state.page * offset < this.state.count) offset = 50;
    else {
      offset = this.state.count - 50;
    }
    api.fetchSaleOrder(this.props.prev, page * offset, 50,this.state.ordered_get).then((value) => {
      this.setState({ orders: value.rows, count: value.total, fetching: false });
    });
  };
  renderTablesLoader() {
    var res = [];
    for (var i = 0; i < 10; i++) {
      res.push(
        <tr key={i} className="loader">
          <td data-th="Ordine n." className="col id">
            <span style={{ animationDelay: i * 700 + "ms" }} />
          </td>
          <td data-th="Data" className="col date">
            <span style={{ animationDelay: i * 700 + "ms" }} />
          </td>
          <td data-th="Spedisci A" className="col shipping ">
            <span style={{ animationDelay: i * 700 + "ms" }} />
          </td>
          <td data-th="Totale ordine" className="col total ">
            <span style={{ animationDelay: i * 700 + "ms" }} />
          </td>
          <td data-th="Status" className="col status ">
            <span style={{ animationDelay: i * 700 + "ms" }} />
          </td>
          <td data-th="Azioni" className="col actions">
            <span style={{ animationDelay: i * 700 + "ms" }} />
          </td>
        </tr>
      );
    }
    return res;
  }
  
  renderTablesOrder() {
    const formatDate = function (input) {
      if (input) {
        var datePart = input.match(/\d+/g),
          year = datePart[0].substring(2), // get only two digits
          month = datePart[1],
          day = datePart[2];

        return day + "/" + month + "/" + year;
      }
      else return "Non Specificata"
    };
    var res = [];
    if (this.state.orders.length > 0) {
      this.state.orders.forEach((element) => {
        res.push(
          <tr key={element.id}>
            <td data-th="Ordine n." className="col id">
              {element.name}
            </td>
            <td data-th="Data" className="col date">
              {formatDate(element.customer_confirmation_date)}
            </td>
            <td data-th="Spedisci A" className="col shipping">
              {element.ship_to.name}
            </td>
            <td data-th="Totale ordine" className="col total">
              <span className="price">
                {element.amount_untaxed.toLocaleString("it-IT", {
                  style: "currency",
                  currency: "EUR",
                })}
              </span>
            </td>
            <td data-th="Totale ordine" className="col total">
              <span className="price">
                {element.amount_total.toLocaleString("it-IT", {
                  style: "currency",
                  currency: "EUR",
                })}
              </span>
            </td>
            <td data-th="Status" className="col status">
            <Utility choose = {element.state}/>
            </td>
            <td data-th="Azioni" className="col actions rightalign">
              <Link
                to={
                  this.props.prev
                    ? "/vendite/preventivi/" + element.id
                    : "/vendite/ordini/" + element.id
                }
                className="action view"
              >
                <button className="btn_outlined">Visualizza</button>
              </Link>
            </td>
            {this.props.prev == true ? (
              <td data-th="Azioni" className="col actions rightalign">
                <button
                  className="btn_outlined"
                  onClick={() => {
                    api.putInDraft(element.id).then((value) => {
                      if (value)
                        window.location = "/preventivatore/new/" + element.id;
                    });
                  }}
                >
                  Preventivatore
                </button>
              </td>
            ) : null}
          </tr>
        );
      });
    }
    return res;
  }
  handleOrder = (attributo) => {
    this.setState(
      {
        fetching:true,
        ordered_get:
          attributo +
          " " +
          (this.state.ordered_get.includes("desc") ? "asc" : "desc"),
      },
      () => {
        api
          .fetchSaleOrder(this.props.prev, 0, 50, this.state.ordered_get)
          .then((value) => {
            if (value)
              this.setState({
                orders: value.rows,
                count: value.total,
                max: Math.ceil(value.total / 50) - 1,
                fetching: false,
              });
            else
              this.setState({

                fetching: false,
              });
          });
      }
    );
  };
  render() {
    return (
      <div className="page_container">
        <h1 className="page_title">
          {this.props.prev === 0 ? "I miei Ordini" :  "I miei Preventivi" 
          +( this.props.prev === 2 ? " accettati" : "")}
        </h1>
        <div className="prev_card searchable" style={{ marginBottom: "25px" }}>
          <div className="table_div">
            <div className="row_div">
              <div className="cell_div">
                <p className="subtitle">{
                    this.props.prev === 0
                      ? "Cerca un ordine"
                      : "Cerca un preventivo"
                  }</p>
                <AsyncSelect
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                }}
                menuPortalTarget={document.body}
                  cacheOptions
                  isLoading={this.state.searching}
                  // isClearable={false}
                  //isSearchable={true}
                  onChange={(e) => {
                    this.props.history.push(
                      window.location.pathname + "/" + e.value
                    );
                  }}
                  placeholder={
                    this.props.prev === 0
                      ? "Cerca un ordine"
                      : "Cerca un preventivo"
                  }
                  loadOptions={_.debounce(this.loadOptions, 300)}
                />
              </div>
            </div>
          </div>
        </div>
    
        <div className="prev_card">
        {this.state.orders.length > 0 && !this.state.loading && (
            <div className="page_right">
              {/*  pagina {this.state.page + 1} max: {this.state.max + 1} {"  "} */}
              {this.state.page === 0 ? 1 : this.state.page * 50}-
              {this.state.page === 0
                ? this.state.count<49 ?  this.state.count : 50
                : this.state.page == this.state.max
                ? this.state.count
                : this.state.page * 50 + 50}{" "}
              &#8725; {this.state.count}{" "}
              <span
                onClick={() => {
                  var newpage =
                    this.state.page > 0 ? this.state.page - 1 : this.state.max;

                  this.setState({ page: newpage });
                  this.fetchmore(newpage);
                }}
              >
                &#8249;
              </span>{" "}
              <span
                onClick={() => {
                  var newpage =
                    this.state.page == this.state.max ? 0 : this.state.page + 1;
                  this.setState({ page: newpage });
                  this.fetchmore(newpage);
                }}
              >
                &#8250;
              </span>{" "}
            </div>
        )}
          {!this.state.loading ? (
            this.state.orders.length > 0 ? (
              <table
                className="data table table-order-items history"
                id="my-orders-table-ord"
              >
                <caption className="table-caption">Ordini</caption>
                <thead>
                  <tr>
                  <Utility
                    choose="header"
                    head_array={[
                    {  label:"Ordine n.", value:"name", col:'id' },
                    { label:"Data", value:"customer_confirmation_date", col:'date'},
                    { label:"Spedisci A", value:"link",col:'shipping'},
                    { label:"Totale netto", value:"amount_untaxed",col:'total'},
                    { label:"Totale ivato", value:"amount_total",col:'total'},
                    { label:"Status", value:"state",col:'status'},
                    { label:"", value:"link",col:'actions rightalign'}
                    ]}
                    orderstate={this.state.ordered_get}
                    handleState={this.handleOrder}
                    />
                    {this.props.prev ?  <th scope="col" className="col actions"></th> : null}
                  </tr>
                </thead>

                <tbody>{this.renderTablesOrder()}</tbody>
              </table>
            ) : (
              <div className="message info empty">
                <span>
                  Non è stato inserito nessun{" "}
                  {!this.props.prev ? "ordine" : "preventivo"}.
                </span>
              </div>
            )
          ) : (
            <table
              className="data table table-order-items history"
              id="my-orders-table"
            >
              <caption className="table-caption">Ordini</caption>

              <thead>
              <tr>
                    <th scope="col" className="col id">
                      Ordine n.
                    </th>
                    <th scope="col" className="col date">
                      Data
                    </th>
                    <th scope="col" className="col shipping">
                      Spedisci A
                    </th>
                    <th scope="col" className="col total">
                      Totale netto
                    </th>
                    <th scope="col" className="col total">
                      Totale ivato
                    </th>
                    <th scope="col" className="col status">
                      Status
                    </th>
                    <th scope="col" className="col actions rightalign"></th>
                    {this.props.prev ?  <th scope="col" className="col actions"></th> : null}
                  </tr>
              </thead>

              <tbody>{this.renderTablesLoader()}</tbody>
            </table>
          )}
        </div>
        {this.state.fetching && (
          <div className="obscure">
            <Load text="Carico i nuovi dati" />
          </div>
        )}
      </div>
    );
  }
}
