import React from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import "./AccordionApi.css";
class AccordionApi extends React.Component {
  constructor(props) {
    super(props);
    this.state = { datas: this.props.datas ? this.props.datas : [] };
  }
  renderHeader() {
    var append = [];
    if (this.state.datas) {
      for (var attr in this.state.datas[0]) {
        append.push(<th>{attr}</th>);
      }
    }
    return append;
  }
  colorStatus(string) {
    var res = "";
    switch (string) {
      case "Preventivo":
        res = <b style={{ color: "blue" }}>{string}</b>;
        break;
      case "Ordinato":
        res = <b style={{ color: "orange" }}>{string}</b>;
        break;
      case "Preventivo Inviato":
        res = <b style={{ color: "#2699F" }}>{string}</b>;
        break;
      case "done":
        res = <b style={{ color: "green" }}>Consegnato</b>;
        break;
        case "paid":
        res = <b style={{ color: "green" }}>Pagato</b>;
        break;
      case "out_invoice":
        res = <b >Fattura emessa</b>;
        break;
        case "in_invoice":
        res = <b >Fattura ricevuta</b>;
        break;
      case "out_refund":
        res = <b >Nota di credito emessa</b>;
        break;
        case "in_refund":
          res = <b >Nota di credito ricevuta</b>;
          break;
      default:
        res = <b>{string}</b>;
    }
    return res;
  }
  render() {
    const formatDate = function (input) {
      if (input)
        var datePart = input.match(/\d+/g),
          year = datePart[0].substring(2), // get only two digits
          month = datePart[1],
          day = datePart[2];
      if (!day) {
        return "//";
      }
      return day + "/" + month + "/" + year;
    };
    return (
      <Accordion>
        <AccordionSummary
          expandIcon={<i className="fa fa-arrow-down"></i>}
          className="accordion_component"
          onClick={(e) => {
            if (this.state.datas === [] && this.props.apiCall)
              this.props.apiCall().then((value) => {
                this.setState({ datas: value });
              });
          }}
        >
          <div className="icon_accordion ">
            <i className={this.props.iconclass} />
          </div>{" "}
          {this.props.title}{" "}
          <span style={{ color: "rgba(0,0,0,0.4)", marginLeft: "5px" }}>
            {" "}
            ({this.props.count})
          </span>
        </AccordionSummary>
        <AccordionDetails>
         {this.props.count>0 && <table>
            <thead>
              <tr>
                {/* {this.state.datas && this.renderHeader()} */}
                {this.props.headers &&
                  this.props.headers.map((single) => {
                    return (
                      <th className="col" key={single} >
                        {single}
                      </th>
                    );
                  })}
              </tr>
            </thead>
            <tbody>
              {this.state.datas.map((single) => {
                var append = [];
                var i = 1;
                single.forEach((element) => {
                  if (element === false)
                    append.push(
                      <td key={element || i + 1} className="col"></td>
                    );
                  else if (
                    typeof element === typeof "string" &&
                    element.indexOf("-") === 4
                  )
                    append.push(
                      <td key={element || i + 1} className="col">
                        {formatDate(element.substring(0, 10))}
                      </td>
                    );
                  else if (
                    (typeof element === typeof 23 ||
                      typeof element === typeof 23,
                    3)
                  )
                    append.push(
                      <td key={element || i + 1} className="col">
                        {element.toLocaleString("it-IT", {
                          style: "currency",
                          currency: "EUR",
                        })}
                      </td>
                    );
                  else
                    append.push(
                      <td key={element || i + 1} className="col">
                        {this.colorStatus(element)}
                      </td>
                    );
                
                  i++;
                });
                return <tr key={Math.floor(Math.random() * 1300)}>{append}</tr>;
              })}
            </tbody>
          </table>}
        </AccordionDetails>
      </Accordion>
    );
  }
}

export default AccordionApi;
