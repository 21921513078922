import React, { Component } from "react";
import "./App.css";
import "../node_modules/react-grid-layout/css/styles.css";
import "../node_modules/react-resizable/css/styles.css";
import { Route, Switch, Redirect } from "react-router-dom";
import Home from "./page/Home";
import api from "./config";
import { default as HeaderEmployee } from "./employee/components/Header";
import Header from "./components/Header";
import Ordini from "./page/Ordini";
import Footer from "./components/Footer";
import Container from "./components/Container";
import Sidebar from "./components/Sidebar";
import InnerOrder from "./page/InnerOrder";
import NewCliente from "./employee/page/crm/NewCliente";
import Bacheca from "./employee/page/Bacheca";
import { default as Dashboard } from "./employee/page/Old_dashboard";
import NewPreventivo from "./employee/page/NewPreventivo";
import { default as OrdiniEmployee } from "./employee/page/Ordini";
import { default as InnerOrderEmployee } from "./employee/page/InnerOrder";
import { default as FooterEmployee } from "./employee/components/Footer";
import ListPreventivatori from "./employee/page/ListPreventivatori";
import Catalogo from "./employee/page/Catalogo";
import Tessuti from "./employee/page/Tessuti";
import Anagrafy from "./employee/page/crm/Anagrafy.js";
import logomohd from "./assets/mohd.png";
import NotFound from "./page/NotFound";
import logo from "./assets/logo-mohd.png";
import Load from "./components/Load";
import Login from "./employee/page/Login";
import Logout from "./components/Logout";
import VisPagamento from "./employee/page/pagamenti/VisPagamento";
import RegVisita from "./employee/page/crm/RegVisita";
import VisVista from "./employee/page/crm/VisVista";
import RicPagamento from "./employee/page/pagamenti/RicPagamento";
import PurchaseOrder from "./employee/page/PurchaseOrder";
import InnerPurchaseOrder from "./employee/page/InnerPurchaseOrder";
import InnerTokenOrder from "./page/InnerTokenOrder";
import Picking from "./employee/page/picking/Picking";
import InnerPicking from "./employee/page/picking/InnerPicking";
import Fatture from "./employee/page/fatture/Fatture";
import InnerFollowUp from "./employee/page/FollowUp/InnerFollowUp";
import InnerFatture from "./employee/page/fatture/InnerFatture";
import Corrieri from "./deliver/page/Corrieri";
import FollowUp from "./employee/page/FollowUp/FollowUp";
import InnerVisVisita from "./employee/page/crm/InnerVisVisita";
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      first_login: false,
      actual: window.location.pathname,
      width: window.innerWidth,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateWindowDimensions);
    this.setState({ loading: true });

    api.login().then((data) => {
      if (data === undefined) {
        this.setState({ loading: false });
      } else {
        this.setState({ user: data, loading: false, first_login: true });
      }
    });
  }
  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }
  setNav = (path) => {
    this.setState({ actual: path });
  };
  headerChooser() {
    if (this.state.user) {
      if (
        this.state.user.frontend_user_type === "employee" ||
        this.state.user.frontend_user_type === "carrier"
      ) {
        return (
          <HeaderEmployee
            actual={this.state.actual}
            setNav={this.setNav}
            user_type={this.state.user.frontend_user_type}
            username={
              this.state.user.firstname + " " + this.state.user.lastname
            }
            permission={
              this.state.user ? this.state.user.frontend_user_permissions : null
            }
          />
        );
      } else {
        return (
          <Header
            username={
              this.state.user.firstname + " " + this.state.user.lastname
            }
            permission={
              this.state.user ? this.state.user.frontend_user_permissions : null
            }
          />
        );
      }
    } else return <Header />;
  }
  footerChooser() {
    if (this.state.user) {
      if (
        this.state.user.frontend_user_type === "employee" ||
        this.state.user.frontend_user_type === "carrier"
      ) {
        return <FooterEmployee />;
      } else {
        return <Footer />;
      }
    } else return null;
  }
  renderloader() {
    var res = [];
    for (var i = 0; i < 15; i++) {
      res.push(
        <li key={i}>
          <span style={{ animationDelay: i * 500 + "ms" }} />
        </li>
      );
    }
    return res;
  }
  manageuser = () => {
    this.setState({ loading: true });
    api.login().then((data) => {
      if (data === undefined) {
        this.setState({ loading: false });
      } else this.setState({ user: data, loading: false, first_login: true });
    });
  };
  routeAuth = (type) => {
    var res = [];
    switch (type) {
      case "dashboard": {
        res.push(
          <Route
            key="/dashboard/vendite"
            path="/dashboard/vendite"
            exact
            render={(props) => (
              <Container
                {...props}
                activeContainer={Dashboard}
                onEnter={this.handleEnter()}
              />
            )}
          />
        );
        break;
      }
      case "preventivatore":
        res.push(
          <Route
            key="/preventivatore/new"
            path="/preventivatore/new"
            exact
            render={(props) => (
              <Container
                permission={
                  this.state.user
                    ? this.state.user.frontend_user_permissions
                    : null
                }
                {...props}
                activeContainer={NewPreventivo}
                onEnter={this.handleEnter()}
              />
            )}
          />
        );
        res.push(
          <Route
            key="/preventivatore/new/:id"
            path="/preventivatore/new/:id"
            exact
            render={(props) => (
              <Container
                permission={
                  this.state.user
                    ? this.state.user.frontend_user_permissions
                    : null
                }
                {...props}
                activeContainer={NewPreventivo}
                onEnter={this.handleEnter()}
              />
            )}
          />
        );
        res.push(
          <Route
            key="/preventivatore/drafts"
            path="/preventivatore/drafts"
            exact
            render={(props) => (
              <Container
                {...props}
                setNav={this.setNav}
                activeContainer={ListPreventivatori}
                onEnter={this.handleEnter()}
              />
            )}
          />
        );
        break;
      case "acquisti":
        res.push(
          <Route
            key="/acquisti/ordini"
            path="/acquisti/ordini"
            exact
            render={(props) => (
              <Container
                {...props}
                prev={0}
                permission={
                  this.state.user
                    ? this.state.user.frontend_user_permissions
                    : null
                }
                activeContainer={PurchaseOrder}
                onEnter={this.handleEnter()}
              />
            )}
          />
        );
        res.push(
          <Route
            key="/acquisti/preventivi"
            path="/acquisti/preventivi"
            exact
            render={(props) => (
              <Container
                prev={1}
                {...props}
                permission={
                  this.state.user
                    ? this.state.user.frontend_user_permissions
                    : null
                }
                activeContainer={PurchaseOrder}
                onEnter={this.handleEnter()}
              />
            )}
          />
        );
        res.push(
          <Route
            key="/acquisti/ordini/:idorder"
            path="/acquisti/ordini/:idorder"
            exact
            render={(props) => (
              <Container
                {...props}
                prev={0}
                permission={
                  this.state.user
                    ? this.state.user.frontend_user_permissions
                    : null
                }
                activeContainer={InnerPurchaseOrder}
                onEnter={this.handleEnter()}
              />
            )}
          />
        );
        res.push(
          <Route
            key="/acquisti/preventivi/:idorder"
            path="/acquisti/preventivi/:idorder"
            exact
            render={(props) => (
              <Container
                {...props}
                prev={1}
                permission={
                  this.state.user
                    ? this.state.user.frontend_user_permissions
                    : null
                }
                activeContainer={InnerPurchaseOrder}
                onEnter={this.handleEnter()}
              />
            )}
          />
        );
        break;
        case "followup":
          res.push(
            <Route
              key="/followup/dainviare"
              path="/followup/dainviare"
              exact
              render={(props) => (
                <Container
                  {...props}
                  prev={"followup"}
                  activeContainer={FollowUp}
                  sales
                  onEnter={this.handleEnter()}
                />
              )}
            />
          );
          res.push(
            <Route
              key="/followup/inviati"
              path="/followup/inviati"
              exact
              render={(props) => (
                <Container
                  {...props}
                  prev={"sent,received"}
                  activeContainer={FollowUp}
                  sales
                  onEnter={this.handleEnter()}
                />
              )}
            />
          );
          res.push(
            <Route
              key="/followup/:idorder"
              path="/followup/:idorder"
              exact
              render={(props) => (
                <Container
                  {...props}
                  prev={"sent,received,followup"}
                  activeContainer={InnerFollowUp}
                  sales
                  onEnter={this.handleEnter()}
                />
              )}
            />
          );
          break;
      case "vendite":
        res.push(
          <Route
            key="/vendite/ordini"
            path="/vendite/ordini"
            exact
            render={(props) => (
              <Container
                {...props}
                prev={0}
                activeContainer={OrdiniEmployee}
                sales
                onEnter={this.handleEnter()}
              />
            )}
          />
        );

        res.push(
          <Route
            key="/vendite/preventivi"
            path="/vendite/preventivi"
            exact
            render={(props) => (
              <Container
                prev={1}
                {...props}
                activeContainer={OrdiniEmployee}
                onEnter={this.handleEnter()}
              />
            )}
          />
        );
        res.push(
          <Route
            key="/vendite/ordini/:idorder"
            path="/vendite/ordini/:idorder"
            exact
            render={(props) => (
              <Container
                {...props}
                prev={0}
                permission={
                  this.state.user
                    ? this.state.user.frontend_user_permissions
                    : null
                }
                activeContainer={InnerOrderEmployee}
                onEnter={this.handleEnter()}
              />
            )}
          />
        );
        res.push(
          <Route
            key="/vendite/preventivi/:idorder"
            path="/vendite/preventivi/:idorder"
            exact
            render={(props) => (
              <Container
                {...props}
                permission={
                  this.state.user
                    ? this.state.user.frontend_user_permissions
                    : null
                }
                prev={1}
                activeContainer={InnerOrderEmployee}
                onEnter={this.handleEnter()}
              />
            )}
          />
        );
        res.push(
          <Route
            key="/vendite/preventivi-accettati/:idorder"
            path="/vendite/preventivi-accettati/:idorder"
            exact
            render={(props) => (
              <Container
                {...props}
                permission={
                  this.state.user
                    ? this.state.user.frontend_user_permissions
                    : null
                }
                prev={2}
                activeContainer={InnerOrderEmployee}
                onEnter={this.handleEnter()}
              />
            )}
          />
        );
        res.push(
          <Route
            key="/vendite/preventivi-accettati/"
            path="/vendite/preventivi-accettati/"
            exact
            render={(props) => (
              <Container
                {...props}
                permission={
                  this.state.user
                    ? this.state.user.frontend_user_permissions
                    : null
                }
                prev={2}
                activeContainer={OrdiniEmployee}
                onEnter={this.handleEnter()}
              />
            )}
          />
        );
        break;
      case "fatture":
        res.push(
          <Route
            key="/vendite/fatture"
            path="/vendite/fatture"
            exact
            render={(props) => (
              <Container
                {...props}
                activeContainer={Fatture}
                onEnter={this.handleEnter()}
              />
            )}
          />
        );
        res.push(
          <Route
            key="/vendite/fatture/:idorder"
            path="/vendite/fatture/:idorder"
            exact
            render={(props) => (
              <Container
                {...props}
                activeContainer={InnerFatture}
                onEnter={this.handleEnter()}
              />
            )}
          />
        );
        break;
      case "picking":
        res.push(
          <Route
            key="/vendite/picking"
            path="/vendite/picking"
            exact
            render={(props) => (
              <Container
                {...props}
                activeContainer={Picking}
                onEnter={this.handleEnter()}
              />
            )}
          />
        );
        res.push(
          <Route
            key="/vendite/picking/:idorder"
            path="/vendite/picking/:idorder"
            exact
            render={(props) => (
              <Container
                {...props}
                activeContainer={InnerPicking}
                onEnter={this.handleEnter()}
              />
            )}
          />
        );
        break;
      case "crm":
        res.push(
          <Route
            key="/crm/consulta-anagrafiche"
            path="/crm/consulta-anagrafiche"
            exact
            render={(props) => (
              <Container
                {...props}
                user={this.state.user}
                activeContainer={Anagrafy}
                onEnter={this.handleEnter()}
              />
            )}
          />
        );
        res.push(
          <Route
            key="/crm/create-customer"
            path="/crm/create-customer"
            exact
            render={(props) => (
              <Container
                {...props}
                team_id={this.state.user.team_id}
                permission={this.state.user.frontend_user_permissions}
                activeContainer={NewCliente}
                onEnter={this.handleEnter()}
              />
            )}
          />
        );
        res.push(
          <Route
            key="/crm/registra-visita"
            path="/crm/registra-visita"
            exact
            render={(props) => (
              <Container
                {...props}
                team_id={this.state.user.team_id}
                permission={this.state.user.frontend_user_permissions}
                activeContainer={RegVisita}
                onEnter={this.handleEnter()}
              />
            )}
          />);
          res.push(
            <Route
              key="/crm/visualizza-opportunita"
              path="/crm/visualizza-opportunita"
              exact
              render={(props) => (
                <Container
                  {...props}
                  team_id={this.state.user.team_id}
                  permission={this.state.user.frontend_user_permissions}
                  activeContainer={VisVista} 
                  onEnter={this.handleEnter()}
                />
              )}
            />   
        );
        res.push(
          <Route
            key="/crm/visualizza-opportunita/:idorder"
            path="/crm/visualizza-opportunita/:idorder"
            exact
            render={(props) => (
              <Container
                {...props}
                activeContainer={InnerVisVisita}
                onEnter={this.handleEnter()}
              />
            )}
          />
        );
        break;
      case "pagamenti":
        res.push(
          <Route
            key="/pagamenti/richiedi-pagamenti"
            path="/pagamenti/richiedi-pagamenti"
            exact
            render={(props) => (
              <Container
                {...props}
                prev={0}
                sales
                permission={
                  this.state.user
                    ? this.state.user.frontend_user_permissions
                    : null
                }
                activeContainer={RicPagamento}
                onEnter={this.handleEnter()}
              />
            )}
          />
        );
        res.push(
          <Route
            key="/pagamenti/visualizza-pagamenti"
            path="/pagamenti/visualizza-pagamenti"
            exact
            render={(props) => (
              <Container
                {...props}
                activeContainer={VisPagamento}
                onEnter={this.handleEnter()}
              />
            )}
          />
        );
       
        break;
      case "catalogo":
        res.push(
          <Route
            key="/catalogo"
            path="/catalogo"
            exact
            render={(props) => (
              <Container
                {...props}
                activeContainer={Catalogo}
                onEnter={this.handleEnter()}
              />
            )}
          />
        );
        break;
    }
    return res;
  };
  handleEnter = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  mainSwitch = () => {
    var res;
    switch (this.state.user.frontend_user_type) {
      case "carrier":
        res = (
          <Route
            render={({ location }) => (
              <Switch key={location.pathname} location={location}>
                {this.state.user.frontend_user_permissions.vendite_picking && (
                  <Route
                    key="/"
                    path="/"
                    exact
                    render={(props) => (
                      <Container
                        {...props}
                        permission={this.state.user.frontend_user_permissions}
                        activeContainer={Corrieri}
                        onEnter={this.handleEnter()}
                      />
                    )}
                  />
                )}
                <Route
                  path="/logout"
                  exact
                  render={(props) => (
                    <Container
                      {...props}
                      activeContainer={Logout}
                      onEnter={this.handleEnter()}
                    />
                  )}
                />
                {this.routeAuth("picking")}
                <Route component={NotFound} onEnter={this.handleEnter()} />
              </Switch>
            )}
          ></Route>
        );
        break;
      case "employee":
        res = (
          <Route
            render={({ location }) => (
              <Switch key={location.pathname} location={location}>
                <Route
                  path="/"
                  exact
                  render={(props) => (
                    <Container
                      {...props}
                      permission={this.state.user.frontend_user_permissions}
                      activeContainer={Bacheca}
                      onEnter={this.handleEnter()}
                    />
                  )}
                />
                <Route
                  path="/logout"
                  exact
                  render={(props) => (
                    <Container
                      {...props}
                      activeContainer={Logout}
                      onEnter={this.handleEnter()}
                    />
                  )}
                />
                {this.state.user.frontend_user_permissions.dashboard &&
                  this.routeAuth("dashboard")}
                {this.state.user.frontend_user_permissions.preventivatore &&
                  this.routeAuth("preventivatore")}
                {this.state.user.frontend_user_permissions.crm &&
                  this.routeAuth("crm")}
                {this.state.user.frontend_user_permissions.vendite_picking &&
                  this.routeAuth("picking")}
                {this.state.user.frontend_user_permissions.vendite_fatture &&
                  this.routeAuth("fatture")}
                {this.state.user.frontend_user_permissions.vendite &&
                  this.routeAuth("vendite")}
                {this.state.user.frontend_user_permissions.acquisti &&
                  this.routeAuth("acquisti")}
                {this.state.user.frontend_user_permissions.pagamenti &&
                  this.routeAuth("pagamenti")}
                {this.state.user.frontend_user_permissions.catalogo &&
                  this.routeAuth("catalogo")}
                {this.state.user.frontend_user_permissions.followup && 
                 this.routeAuth("followup")}
                  
                <Route component={NotFound} onEnter={this.handleEnter()} />
              </Switch>
            )}
          ></Route>
        );
        break;
      case "customer":
        res = (
          <main id="maincontent" className="page-main">
            <div className="columns">
              <div className="column main">
                {/*  {!this.state.loading ? ( */}

                <Route
                  render={({ location }) => (
                    <Switch key={location.pathname} location={location}>
                      <Route
                        path="/customer"
                        exact
                        render={(props) => (
                          <Container
                            {...props}
                            ship_
                            to={this.state.user.ship_to}
                            invoice_
                            to={this.state.user.invoice_to}
                            activeContainer={Home}
                            onEnter={this.handleEnter()}
                          />
                        )}
                      />
                      <Route
                        path="/"
                        exact
                        render={(props) => <Redirect to="/customer" />}
                      />
                      <Route
                        path="/customer/orders/:idorder"
                        exact
                        render={(props) => (
                          <Container
                            {...props}
                            activeContainer={InnerOrder}
                            onEnter={this.handleEnter()}
                          />
                        )}
                      />
                      <Route
                        path="/customer/orders"
                        exact
                        render={(props) => (
                          <Container
                            {...props}
                            prev={0}
                            width={this.state.width}
                            activeContainer={Ordini}
                            onEnter={this.handleEnter()}
                          />
                        )}
                      />

                      <Route
                        path="/customer/quotations"
                        exact
                        render={(props) => (
                          <Container
                            prev={1}
                            width={this.state.width}
                            {...props}
                            activeContainer={Ordini}
                          />
                        )}
                      />
                      <Route
                        path="/customer/quotations/:idorder"
                        exact
                        render={(props) => (
                          <Container
                            {...props}
                            prev={1}
                            activeContainer={InnerOrder}
                          />
                        )}
                      />
                      <Route
                        path="/logout"
                        exact
                        render={(props) => (
                          <Container
                            {...props}
                            activeContainer={Logout}
                            onEnter={this.handleEnter()}
                          />
                        )}
                      />
                      <Route
                        component={NotFound}
                        onEnter={this.handleEnter()}
                      />
                    </Switch>
                  )}
                ></Route>
                {/*  ) : null} */}
              </div>
              <Sidebar onEnter={this.handleEnter()} />
            </div>
          </main>
        );
        break;
      default:
        res = (
          <React.Fragment>
            <Header logged />
            <Route
              render={({ location }) => (
                <Switch key={location.pathname} location={location}>
                  <Route
                    path="/customer/quotations/:idorder/:token"
                    exact
                    render={(props) => (
                      <Container
                        {...props}
                        activeContainer={InnerTokenOrder}
                        onEnter={this.handleEnter()}
                      />
                    )}
                  />
                  <Route
                    path="/customer/orders/:idorder/:token"
                    exact
                    render={(props) => (
                      <Container
                        {...props}
                        activeContainer={InnerTokenOrder}
                        onEnter={this.handleEnter()}
                      />
                    )}
                  />
                  <Route
                    path="/"
                    exact
                    render={(props) => (
                      <Container
                        {...props}
                        logged
                        manageuser={this.manageuser}
                        activeContainer={Login}
                        onEnter={this.handleEnter()}
                      />
                    )}
                  />
                </Switch>
              )}
            />
            <Footer />
          </React.Fragment>
        );
        break;
    }
    return res;
  };
  render() {
    if (this.state.loading) {
      return (
        <React.Fragment>
          <header className={"head_employee loading"}>
            {" "}
            <div className="logo_container">
              <img alt="Mohd" src={logo} />
            </div>
          </header>
          <div className="page_containerloader">
            {/* <ul className="nav items loader">{this.renderloader()}</ul> */}
            <Load text="Procedo all'autenticazione" />
          </div>
          <footer className="footer_employee loading">
            <img alt="Mohd" src={logomohd} />
          </footer>
        </React.Fragment>
      );
    } else
      return (
        <div>
          {this.headerChooser()}
          {this.state.first_login ? (
            this.mainSwitch()
          ) : (
            <React.Fragment>
              <Header logged />
              <Route
                render={({ location }) => (
                  <Switch key={location.pathname} location={location}>
                    <Route
                      path="/customer/quotations/:idorder/:token"
                      exact
                      render={(props) => (
                        <Container
                          {...props}
                          activeContainer={InnerTokenOrder}
                          onEnter={this.handleEnter()}
                        />
                      )}
                    />
                    <Route
                      path="/customer/orders/:idorder/:token"
                      exact
                      render={(props) => (
                        <Container
                          {...props}
                          activeContainer={InnerTokenOrder}
                          onEnter={this.handleEnter()}
                        />
                      )}
                    />
                    <Route
                      path="/"
                      exact
                      render={(props) => (
                        <Container
                          {...props}
                          logged
                          manageuser={this.manageuser}
                          activeContainer={Login}
                          onEnter={this.handleEnter()}
                        />
                      )}
                    />
                  </Switch>
                )}
              />
              <Footer />
            </React.Fragment>
          )}

          {this.footerChooser()}
        </div>
      );
  }
}

export default App;
