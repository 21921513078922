import React, { Component } from "react";
import "./Header.css";
import logo from "../../assets/logo-mohd.png";
import { Link } from "react-router-dom";
import SwipableSidebar from "../components/SwipableSidebar";
//import css module per reactflag
export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subcategory: "null",
      sub_toolbar: "super_sub_menu",
      submenu: false,
      actual: window.location.pathname,
      width: window.innerWidth,
      height: 0,
      hamb: false,
      toolbar: "head_employee",
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }
  handleScroll = () => {
    const currentScrollPos = window.pageYOffset;

    if (currentScrollPos < 70 || this.state.prevScrollpos > currentScrollPos)
      this.setState({
        toolbar: "head_employee",
        sub_toolbar: "super_sub_menu",
        prevScrollpos: currentScrollPos,
      });
    else
      this.setState({
        toolbar: "head_employee reduct",
        sub_toolbar: "super_sub_menu reduct",
        prevScrollpos: currentScrollPos,
      });
  };
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", this.handleScroll, { passive: true });
  }
  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }
  //Funzione per acquisire la città scelta
  manageSubmenu(category) {
    if (this.state.submenu) {
      this.setState({ submenu: false });
      setTimeout(() => {
        this.setState({ submenu: true, subcategory: category });
      }, 300);
    } else this.setState({ submenu: true, subcategory: category });
  }
  renderSubcategory() {
    const last_li = (
      <li onClick={() => this.setState({ submenu: false })}>X</li>
    );
    const main_li = <li>Seleziona</li>;
    var res = [];
    switch (this.state.subcategory) {
      case "Dashboard":
        if (this.props.permission.dashboard)
          res.push(
            <ul key="dashboard">
              {main_li}
              <li
                className={
                  this.props.actual.includes("/dashboard/vendite") > 0
                    ? "current"
                    : ""
                }
              >
                <Link
                  onClick={() => this.props.setNav("/dashboard/vendite")}
                  to="/dashboard/vendite"
                >
                  Vendite
                </Link>
              </li>
              {last_li}
            </ul>
          );
        break;
      case "Vendite":
        return (
          <ul key="Vendite">
            {main_li}
            {this.props.permission.vendite && (
              <>
                <li
                  className={
                    this.props.actual.includes("/vendite/preventivi") &&
                    !this.props.actual.includes("/vendite/preventivi-") > 0
                      ? "current"
                      : ""
                  }
                >
                  <Link
                    onClick={() => this.props.setNav("/vendite/preventivi")}
                    to="/vendite/preventivi"
                  >
                    Preventivi
                  </Link>
                </li>
                <li
                  className={
                    this.props.actual.includes(
                      "/vendite/preventivi-accettati"
                    ) > 0
                      ? "current"
                      : ""
                  }
                >
                  <Link
                    onClick={() =>
                      this.props.setNav("/vendite/preventivi-accettati")
                    }
                    to="/vendite/preventivi-accettati"
                  >
                    Preventivi Accettati
                  </Link>
                </li>
                <li
                  className={
                    this.props.actual.includes("/vendite/ordini") > 0
                      ? "current"
                      : ""
                  }
                >
                  <Link
                    onClick={() => this.props.setNav("/vendite/ordini")}
                    to="/vendite/ordini"
                  >
                    Ordini
                  </Link>
                </li>{" "}
              </>
            )}
            {this.props.permission.vendite_picking && (
              <li
                className={
                  this.props.actual.includes("/vendite/picking") > 0
                    ? "current"
                    : ""
                }
              >
                <Link
                  onClick={() => this.props.setNav("/vendite/picking")}
                  to="/vendite/picking"
                >
                  Picking
                </Link>
              </li>
            )}
            {this.props.permission.vendite_fatture && (
              <li
                className={
                  this.props.actual.includes("/vendite/fatture") > 0
                    ? "current"
                    : ""
                }
              >
                <Link
                  onClick={() => this.props.setNav("/vendite/fatture")}
                  to="/vendite/fatture"
                >
                  Fatture
                </Link>
              </li>
            )}

            {last_li}
          </ul>
        );
      case "Acquisti":
        if (this.props.permission.acquisti)
          return (
            <ul key="acquisti">
              {main_li}
              <li
                className={
                  this.props.actual.includes("/acquisti/preventivi") > 0
                    ? "current"
                    : ""
                }
              >
                <Link
                  onClick={() => this.props.setNav("/acquisti/preventivi")}
                  to="/acquisti/preventivi"
                >
                  RFQ
                </Link>
              </li>

              <li
                className={
                  this.props.actual.includes("/acquisti/ordini") > 0
                    ? "current"
                    : ""
                }
              >
                <Link
                  onClick={() => this.props.setNav("/acquisti/ordini")}
                  to="/acquisti/ordini"
                >
                  Ordini
                </Link>
              </li>

              {last_li}
            </ul>
          );
      case "CRM":
        if (this.props.permission.crm)
          return (
            <ul key="CRM">
              {main_li}
              <li
                className={
                  this.props.actual.includes("/crm/visualizza-opportunita") > 0
                    ? "current"
                    : ""
                }
              >
                <Link
                  onClick={() => this.props.setNav("/crm/visualizza-opportunita")}
                  to="/crm/visualizza-opportunita"
                >
                  Visualizza Opportunità
                </Link>
              </li>
              <li
                className={
                  this.props.actual.includes("/crm/registra-visita") > 0
                    ? "current"
                    : ""
                }
              >
                <Link
                  onClick={() => this.props.setNav("/crm/registra-visita")}
                  to="/crm/registra-visita"
                >
                  Registra Opportunità
                </Link>
              </li>
              <li
                className={
                  this.props.actual.includes("/crm/create-customer") > 0
                    ? "current"
                    : ""
                }
              >
                <Link
                  onClick={() => this.props.setNav("/crm/create-customer")}
                  to="/crm/create-customer"
                >
                  Registra Cliente
                </Link>
              </li>

              <li
                className={
                  this.props.actual.includes("/crm/consulta-anagrafiche") > 0
                    ? "current"
                    : ""
                }
              >
                <Link
                  onClick={() => this.props.setNav("/crm/consulta-anagrafiche")}
                  to="/crm/consulta-anagrafiche"
                >
                  {this.props.permission.opt_contact_adv_search
                    ? "Consulta Anagrafiche"
                    : "Consulta il Network"}
                </Link>
              </li>
              {last_li}
            </ul>
          );
      case "Preventivatore":
        if (this.props.permission.preventivatore)
          return (
            <ul key="Preventivatore">
              {main_li}
              <li
                className={
                  this.props.actual.includes("/preventivatore/new") > 0
                    ? "current"
                    : ""
                }
              >
                <Link
                  onClick={() => this.props.setNav("/preventivatore/new")}
                  to="/preventivatore/new"
                >
                  Crea
                </Link>
              </li>
              <li
                className={
                  this.props.actual.includes("/preventivatore/drafts") > 0
                    ? "current"
                    : ""
                }
              >
                <Link
                  onClick={() => this.props.setNav("/preventivatore/drafts")}
                  to="/preventivatore/drafts"
                >
                  Bozze
                </Link>
              </li>
              {last_li}
            </ul>
          );
      case "Pagamenti":
        if (this.props.permission.pagamenti)
          return (
            <ul key="Pagamenti">
              {main_li}

              <li
                className={
                  this.props.actual.includes("/pagamenti/richiedi-pagamenti") >
                  0
                    ? "current"
                    : ""
                }
              >
                <Link
                  onClick={() =>
                    this.props.setNav("/pagamenti/richiedi-pagamenti")
                  }
                  to="/pagamenti/richiedi-pagamenti"
                >
                  Crea Richiesta
                </Link>
              </li>
              <li
                className={
                  this.props.actual.includes(
                    "/pagamenti/visualizza-pagamenti"
                  ) > 0
                    ? "current"
                    : ""
                }
              >
                <Link
                  onClick={() =>
                    this.props.setNav("/pagamenti/visualizza-pagamenti")
                  }
                  to="/pagamenti/visualizza-pagamenti"
                >
                  Visualizza
                </Link>
              </li>
              {last_li}
            </ul>
          );
      case "Catalogo":
        if (this.props.permission.catalogo)
          return (
            <ul key="Catalogo">
              {main_li}
              <li
                className={
                  this.props.actual.includes("/catalogo") > 0 ? "current" : ""
                }
              >
                <Link
                  onClick={() => this.props.setNav("/catalogo")}
                  to="/catalogo"
                >
                  Catalogo
                </Link>
              </li>
              {last_li}
            </ul>
          );
      case "FollowUp":
        if (this.props.permission.followup)
          return (
            <ul key="FollowUp">
              {main_li}
              <li>
                <Link
                  onClick={() => this.props.setNav("/followup/dainviare")}
                  to="/followup/dainviare"
                >
                  Da Inviare
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => this.props.setNav("/followup/inviati")}
                  to="/followup/inviati"
                >
                  Inviati
                </Link>
              </li>
              {last_li}
            </ul>
          );

      // case "Tessuti" && this.props.permission.tessuti:
      //   return (
      //     <ul key="Tessuti">
      //       {main_li}
      //       <li
      //         className={
      //           this.props.actual.includes("/tessuti") > 0 ? "current" : ""
      //         }
      //       >
      //         <Link
      //           onClick={() => this.props.setNav( "/tessuti" )}
      //           to="/tessuti"
      //         >
      //           Tessuti
      //         </Link>
      //       </li>
      //       {last_li}
      //     </ul>
      //   );
    }
    return res;
  }
  handleSide() {
    this.setState({ hamb: !this.state.hamb });
  }
  render() {
    return this.state.width > 800 ? (
      <React.Fragment>
        <header
          className={
            this.state.width > 1100
              ? this.state.toolbar
              : "head_employee noprint"
          }
        >
          <div className="logo_container">
            <Link to="/">
              <img src={logo} />
            </Link>
          </div>
          <div className="menu_container">
            <div className="sup_menu">
              <ul>
                <li>
                  <Link to="/">
                    {this.state.width < 426 ? (
                      <i className="fa fa-user" aria-hidden="true" />
                    ) : (
                      <strong>{this.props.username}</strong>
                    )}
                  </Link>
                </li>
                <li>
                  <Link to="/logout">
                    {" "}
                    {this.state.width < 426 ? (
                      <i className="fa fa-power-off" aria-hidden="true" />
                    ) : (
                      "Log out"
                    )}
                  </Link>
                </li>
              </ul>
            </div>
            <hr />
            <div className="sub_menu">
              {this.props.user_type === "employee" ? (
                <ul>
                  <li
                    className={
                      this.props.actual === "/"
                        ? "current direct_link"
                        : "direct_link"
                    }
                  >
                    <Link
                      onClick={() => {
                        this.setState({ submenu: false });
                        this.props.setNav("/");
                      }}
                      to="/"
                    >
                      Bacheca
                    </Link>
                  </li>

                  {this.props.permission.crm && (
                    <li onClick={() => this.manageSubmenu("CRM")}>CRM</li>
                  )}
                  {this.props.permission.vendite && (
                    <li onClick={() => this.manageSubmenu("Vendite")}>
                      Vendite
                    </li>
                  )}
                  {this.props.permission.acquisti && (
                    <li onClick={() => this.manageSubmenu("Acquisti")}>
                      Acquisti
                    </li>
                  )}
                  {this.props.permission.preventivatore && (
                    <li onClick={() => this.manageSubmenu("Preventivatore")}>
                      Preventivatore
                    </li>
                  )}
                  {this.props.permission.pagamenti && (
                    <li onClick={() => this.manageSubmenu("Pagamenti")}>
                      Pagamenti
                    </li>
                  )}

                  {this.props.permission.catalogo && (
                    <li onClick={() => this.manageSubmenu("Catalogo")}>
                      Catalogo
                    </li>
                  )}
                  {this.props.permission.followup && (
                    <li onClick={() => this.manageSubmenu("FollowUp")}>
                      FollowUp
                    </li>
                  )}

                  {/* {this.props.permission.tessuti &&  <li onClick={() => this.manageSubmenu("Tessuti")}>Tessuti</li>}*/}
                </ul>
              ) : (
                <ul>
                  {" "}
                  <li
                    className={
                      this.props.actual === "/"
                        ? "current direct_link"
                        : "direct_link"
                    }
                  >
                    <Link
                      onClick={() => {
                        this.setState({ submenu: false });
                        this.props.setNav("/");
                      }}
                      to="/"
                    >
                      Home
                    </Link>
                  </li>
                  {(this.props.permission.vendite || this.props.permission.vendite_picking) && (
                    <li onClick={() => this.manageSubmenu("Vendite")}>
                     Magazzino
                    </li>
                  )}
                </ul>
              )}
            </div>
          </div>
        </header>
        <div
          className={
            this.state.submenu
              ? this.state.sub_toolbar
              : this.state.sub_toolbar + " close"
          }
        >
          {this.renderSubcategory()}
        </div>
      </React.Fragment>
    ) : (
      <div className="header">
        <div className="head_inner">
          <React.Fragment>
            <SwipableSidebar
              renderSub={this.renderSubcategory}
              handleOpen={() => this.handleSide()}
              shouldOpen={this.state.hamb}
              permission={this.props.permission}
            />
            <nav
              onClick={() => this.handleSide()}
              className={this.state.hamb ? "hamburger open" : "hamburger"}
            >
              <ul>
                <li></li>
                <li></li>
                <li></li>
              </ul>
            </nav>
            <nav>
              <ul>
                <li>
                  <Link to="/">
                    {this.state.width < 426 ? (
                      <i className="fa fa-user" aria-hidden="true" />
                    ) : (
                      <strong>{this.props.username}</strong>
                    )}
                  </Link>
                </li>
                <li>
                  {" "}
                  {this.state.width < 426 ? (
                    <i className="fa fa-power-off" aria-hidden="true" />
                  ) : (
                    "Log out"
                  )}
                </li>
              </ul>
            </nav>
          </React.Fragment>
          <div className="logomohd">
            <Link to="/">
              <img src={logo} alt="Mohd" />
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
