import React, { Component } from "react";
import { Formik } from "formik";
import api from "../../../config";

import Swal from "sweetalert2";
import Select from "react-select";
import Creatable from "react-select/creatable";
import SearchAnagrafy from "../../../components/search_nation/SearchAnagrafy";
import $ from "jquery";
export default class RegVisita extends Component {
  constructor(props) {
    super(props);
    this.state = {
      done: false,
      customTxt: "",
      loading: false,
      countries: [],
      disableAll: false,
      tags: [],
      team_id: {value:this.props.team_id.id,label:this.props.team_id.name},
      errorNazione: "",
      company_type: "person",
      order_id: "",
      partner_id: "",
      firstname: "",
      lastname: "",
      email: "",
      search_user: "",
      phone: "",
      origineopt:[],
      origine:{},
      cellphone: "",
      iva: "",
      fatt_state: { label: "Scegli Nazione...", value: -11 },
      fatt_country: {
        label: "Scegli Regione/Provincia...",
        value: -11,
      },
      fatt_city: "",
      pec: "",
      sdi: "",
      fatt_street_1: "",
      fatt_street_2: "",
      sped_street_1: "",
      sped_street_2: "",
      fatt_cap: "",
      sped_partner_id: "",
      fatt_partner_id: "",
      sped_cap: "",
      sped_state: { label: "Scegli Nazione...", value: -11 },
      sped_country: {
        label: "Scegli Regione/Provincia...",
        value: -11,
      },
      sped_city: "",
      errors: [],
      tagsopt:[],
      phase: 0,
      same_address: true,
      spedstates: [],
      opportunita:"",
      fattstates: [],
      loadingState: false,
      loadingCountry: false,
    };
  }
  componentDidMount() {
    api.fetchMsg().then((body) => {
      if (body) {
        body = body.replace(/(onclick)+=+("+|'+).*("+|'+)/gm, "onclick=''");
        body = body.replace(/<a(.*)"/gm, "<a href='#'");
      }
      this.setState({ customTxt: body });
    });
    this.setState({ loadingCountry: true, loadingTeams: true });
    api.getOrigins().then((value)=>this.setState({origineopt:value}))
    api.getTags().then((value)=>this.setState({tagsopt:value}))
    api.fetchCountries().then((values) => {
      this.setState({ countries: values, loadingCountry: false });
    });

    api.fetchTeams().then((values) => {
      this.setState({ teams: values, loadingTeams: false });
    });
  }
  setSearch = (e) => {
    if (e)
      this.setState({
        disableAll: true,
        search_user: e.label,
        company_type: e.value.company_type,
        partner_id: e.value.partner_id,
        firstname: e.value.firstname ? e.value.firstname : "",
        lastname: e.value.lastname ? e.value.lastname : "",
        email: e.value.email ? e.value.email : "",
        phone: e.value.phone ? e.value.phone : "",
        origine: e.value.origine,
        cellphone: e.value.cellphone ? e.value.cellphone : "",
        sped_partner_id: e.value.ship_to.partner_id,
        fatt_partner_id: e.value.invoice_to.partner_id,
        fatt_country: e.value.invoice_to
          ? e.value.invoice_to.country !== null
            ? e.value.invoice_to.country
            : { label: "Scegli Nazione...", value: -11 }
          : { label: "Scegli Nazione...", value: -11 },
        fatt_state: e.value.invoice_to
          ? e.value.invoice_to.state !== null
            ? e.value.invoice_to.state
            : {
                label: "Scegli Regione/Provincia...",
                value: -11,
              }
          : {
              label: "Scegli Regione/Provincia...",
              value: -11,
            },
        fatt_city: e.value.invoice_to ? e.value.invoice_to.city : "",
        sped_country: e.value.ship_to
          ? e.value.ship_to.country !== null
            ? e.value.ship_to.country
            : { label: "Scegli Nazione...", value: -11 }
          : { label: "Scegli Nazione...", value: -11 },
        sped_state: e.value.ship_to
          ? e.value.ship_to.state !== null
            ? e.value.ship_to.state
            : {
                label: "Scegli Regione/Provincia...",
                value: -11,
              }
          : {
              label: "Scegli Regione/Provincia...",
              value: -11,
            },
        sped_city: e.value.ship_to ? e.value.ship_to.city : "",
        fatt_street_1: e.value.invoice_to ? e.value.invoice_to.street : "",
        fatt_street_2: e.value.invoice_to ? e.value.invoice_to.street2 : "",
        fatt_cap: e.value.invoice_to ? e.value.invoice_to.zip : "",
        sped_street_1: e.value.ship_to ? e.value.ship_to.street : "",
        sped_street_2: e.value.ship_to ? e.value.ship_to.street2 : "",
        sped_cap: e.value.ship_to ? e.value.ship_to.zip : "",
        same_address:
          e.value.ship_to && e.value.invoice_to
            ? e.value.invoice_to.street === e.value.ship_to.street &&
              e.value.invoice_to.street2 === e.value.ship_to.street2 &&
              e.value.invoice_to.country === e.value.ship_to.country &&
              e.value.invoice_to.city === e.value.ship_to.city &&
              e.value.invoice_to.state === e.value.ship_to.state &&
              e.value.invoice_to.zip === e.value.ship_to.zip
            : e.value.ship_to
            ? false
            : true,
        pec: e.value.pec,
        sdi: e.value.sdi,
        iva: e.value.iva,
      });
    if (e.value.invoice_to)
      if (e.value.invoice_to.country)
        this.fetchFattState(e.value.invoice_to.country.value);
    if (e.value.ship_to)
      if (e.value.ship_to.country)
        this.fetchSpedState(e.value.ship_to.country.value);
  };
  resetAll(){
    this.setState( {
      done: false,
      customTxt: "",
      loading: false,
      countries: [],
      disableAll: false,
      origine:{},
      tags: [],
      team_id: {value:this.props.team_id.id,label:this.props.team_id.name},
      errorNazione: "",
      company_type: "person",
      order_id: "",
      partner_id: "",
      firstname: "",
      lastname: "",
      email: "",
      search_user: "",
      phone: "",

      cellphone: "",
      iva: "",
      fatt_state: { label: "Scegli Nazione...", value: -11 },
      fatt_country: {
        label: "Scegli Regione/Provincia...",
        value: -11,
      },
      fatt_city: "",
      pec: "",
      sdi: "",
      fatt_street_1: "",
      fatt_street_2: "",
      sped_street_1: "",
      sped_street_2: "",
      fatt_cap: "",
      sped_partner_id: "",
      fatt_partner_id: "",
      sped_cap: "",
      sped_state: { label: "Scegli Nazione...", value: -11 },
      sped_country: {
        label: "Scegli Regione/Provincia...",
        value: -11,
      },
      sped_city: "",
      errors: [],
      phase: 0,
      same_address: true,
      spedstates: [],
      fattstates: [],
      loadingState: false,
      loadingCountry: false,
    })
  }
  fetchState(country_id) {
    this.setState({ loadingState: true });
    if (country_id) {
      api.fetchStates(country_id).then((values) => {
        this.setState({ states: values, loadingState: false });
      });
    }
  }
  fetchFattState(country_id) {
    this.setState({ loadingState: true });
    if (country_id) {
      api.fetchStates(country_id).then((values) => {
        this.setState({ fattstates: values, loadingState: false });
      });
    }
  }
  fetchSpedState(country_id) {
    this.setState({ loadingState: true });
    if (country_id) {
      api.fetchStates(country_id).then((values) => {
        this.setState({ spedstates: values, loadingState: false });
      });
    }
  }
  render() {
    return (
      <div className="page_container left_align">
        <h1 className="page_title">Registra Opportunità</h1>
        <p
          className="uppercase subtitle"
          dangerouslySetInnerHTML={{
            __html: this.state.customTxt,
          }}
        ></p>
        <Formik
          enableReinitialize={true}
          initialValues={{
            nome: this.state.firstname,
            cognome: this.state.lastname,
            email: this.state.email,
            privacy: false,
            telefono: this.state.phone,
            newsletter: false,
            street1: this.state.fatt_street_1,
            team_id: this.state.team_id,
            prevista: "",
            pz_opportunita: "",
            street2: this.state.fatt_street_2,
            cap: this.state.fatt_cap,
            origine:this.state.origine,
            citta: this.state.fatt_city,
            opportunita: this.state.opportunita,
          }}
          validate={(values, actions) => {
            const errors = {};
            if (!this.state.disableAll) {
              if (values.nome.length < 3) {
                errors.nome = "*Nome non valido";
              }
              if (values.cognome.length < 3) {
                errors.cognome = "*Cognome non valido";
              }
              if (values.telefono.length < 6) {
                errors.telefono = "*Inserisci un numero di telefono valido";
              }
              if (values.opportunita.length<3) {
                errors.opportunita = "*Inserisci il nome dell'opportunita";
              }
              if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = "*Email non valida";
              }
              if (!values.team_id) {
                errors.team_id = "*Team id non presente";
              }
            }

            if (errors !== {} && this.state.errorNazione !== "") {
              actions.setSubmitting(true);
            }
            return errors;
          }}
          onSubmit={(values, actions) => {
            actions.setSubmitting(false);
            this.setState({ loading: true });
            values.tags = this.state.tags;
            values.team_id = this.state.team_id;
            values.partner_id = this.state.partner_id;
            values.origine=this.state.origine;
            console.log(values)
            if (this.state.fatt_country.value < 0) {
              this.setState({
                errorNazione: "Devi selezionare una Nazione",
                loading: false,
              });
              Swal.fire({
                position: "top-end",
                icon: "error",
                html:
                  "<div><h1>Errore</h1> <p>Non è stato possibile elaborare la richiesta, inserisci la nazione</p> </div>",
                showConfirmButton: false,
                timerProgressBar: true,
                timer: 2000,
                customClass: { container: "swal_container" },
              });
              var button = $("#nazione");
              if (button) {
                var offset = button.offset().top;
                window.scrollTo({ top: offset, behavior: "smooth" });
              }
              return;
            }
            api
              .newOpportunita(values)
              .then((data) => {
                if (data) {
                  if (data.code === "200" || data.code === 200) {
                    Swal.fire({
                      position: "top-end",
                      icon: "success",
                      html:
                        "<h1>Opportunità creata</h1> <p>L'opportunità <b>" +
                        values.opportunita +
                        " </b>è stata creata con successo!<br/></p>",
                      showConfirmButton: false,
                      timerProgressBar: true,
                      timer: 3000,
                      customClass: { container: "swal_container" },
                      onClose: ()  =>  {
                       
                        this.resetAll();
                        actions.resetForm({});
                      ;
                      },
                    });
                  }
                } else {
                  Swal.fire({
                    position: "top-end",
                    icon: "error",
                    html:
                      "<div><h1>Errore</h1> <p>Non è stato possibile elaborare la richiesta, riprova o controlla la tua connessione</p> </div>",
                    showConfirmButton: false,
                    timerProgressBar: true,
                    timer: 2000,
                    customClass: { container: "swal_container" },
                  });
                }
                this.setState({ loading: false });
              })
              .catch((error) => {
                console.log(error);
                this.setState({ loading: false });
              });

            this.setState({ loading: false });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <div className="newClient_container">
              <SearchAnagrafy
                customStyle={{ width: "100%" }}
                permission={
                  this.props.permission
                    ? this.props.permission.opt_contact_adv_search
                    : false
                }
                label={"Cerca Contatto"}
                setSearch={this.setSearch}
                apiCall={api.searchAsyncCustomer}
                async
              />
              <form
                onSubmit={handleSubmit}
                className="form-customer-create"
                id="social-form-create"
                noValidate="novalidate"
              >
                <div
                  id="social-login-popup"
                  className="prev_card "
                  style={{ width: "100%" }}
                >
                  <div style={{ padding: " 0px 21.5px" }}>
                    <p className="subtitle" htmlFor="opportunita">
                      <span>Nome opportunità*</span>
                    </p>
                    <div className="control">
                      <input
                        type="text"
                        name="opportunita"
                        id="opportunita"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.opportunita}
                        className="input-text required-entry"
                      />
                      {errors.opportunita && touched.opportunita && (
                        <div className="invalid-feedback">
                          {errors.opportunita}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="table_div">
                    <div className="row_div">
                      <div className="cell_div">
                        <p className="subtitle" htmlFor="prevista">
                          <span>Entrata Prevista &euro;</span>
                        </p>
                        <div className="control">
                          {/*  <input
                                type="text"
                                id="firstname"
                                name="firstname"
                                defaultValue
                                title="Nome"
                                className="input-text required-entry"
                                data-validate="{required:true}"
                                aria-required="true"
                              /> */}
                          <input
                            type="number"
                            name="prevista"
                            id="prevista"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.prevista}
                            className="input-text required-entry"
                          />
                          {errors.prevista && touched.prevista && (
                            <div className="invalid-feedback">
                              {errors.prevista}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="cell_div">
                        <p className="subtitle" htmlFor="pz_opportunita">
                          <span>Opportunità %</span>
                        </p>
                        <div className="control">
                          <input
                            type="number"
                            min={0}
                            max={100}
                            name="pz_opportunita"
                            id="pz_opportunita"
                            onChange={(e) => {
                              if (
                                Number(e.target.value) < 101 &&
                                Number(e.target.value) > -1
                              )
                                handleChange(e);
                            }}
                            onBlur={handleBlur}
                            value={values.pz_opportunita}
                            className="input-text required-entry"
                          />
                          {errors.pz_opportunita && touched.pz_opportunita && (
                            <div className="invalid-feedback">
                              {errors.pz_opportunita}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div> </div>

                <div
                  id="social-login-popup"
                  className="prev_card searchable"
                  style={{ width: "100%" }}
                >
                  <div className="table_div">
                    <div className="row_div">
                      <div className="cell_div">
                        <p className="subtitle" htmlFor="firstname">
                          <span>Nome </span>
                        </p>
                        <div className="control">
                          {/*  <input
                                type="text"
                                id="firstname"
                                name="firstname"
                                defaultValue
                                title="Nome"
                                className="input-text required-entry"
                                data-validate="{required:true}"
                                aria-required="true"
                              /> */}
                          <input
                            type="text"
                            name="nome"
                            disabled={this.state.disableAll}
                            id="nome"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.nome}
                            className="input-text required-entry"
                          />
                          {errors.nome && touched.nome && (
                            <div className="invalid-feedback">
                              {errors.nome}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="cell_div">
                        <p className="subtitle" htmlFor="lastname">
                          <span>Cognome</span>
                        </p>
                        <div className="control">
                          {/*  <input
                                type="text"
                                id="lastname"
                                name="lastname"
                                defaultValue
                                title="Cognome"
                                className="input-text required-entry"
                                data-validate="{required:true}"
                                aria-required="true"
                              /> */}
                          <input
                            type="text"
                            name="cognome"
                            disabled={this.state.disableAll}
                            id="cognome"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.cognome}
                            className="input-text required-entry"
                          />

                          {errors.cognome && touched.cognome && (
                            <div className="invalid-feedback">
                              {errors.cognome}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row_div">
                      <div className="cell_div">
                        <p className="subtitle">
                          <span>Email </span>
                        </p>
                        <div className="control">
                          <input
                            type="email"
                            disabled={this.state.disableAll}
                            name="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            className="input-text"
                          />

                          {errors.email && touched.email && (
                            <div className="invalid-feedback">
                              {errors.email}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="cell_div">
                        <p className="subtitle">
                          <span>Telefono </span>
                        </p>
                        <div className="control">
                          <input
                            type="text"
                            disabled={this.state.disableAll}
                            name="telefono"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.telefono}
                            className="input-text"
                          />

                          {errors.telefono && touched.telefono && (
                            <div className="invalid-feedback">
                              {errors.telefono}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="table_div">
                    <div className="row_div">
                      <div className="cell_div longone">
                        <p className="subtitle">
                          <span>Indirizzo 1</span>
                        </p>
                        <div className="control">
                          <input
                            type="text"
                            disabled={this.state.disableAll}
                            name="street1"
                            id="street1"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.street1}
                            className="input-text required-entry"
                          />

                          {errors.street1 && touched.street1 && (
                            <div className="invalid-feedback">
                              {errors.street1}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="cell_div">
                        <p className="subtitle" htmlFor="cap">
                          <span>Cap</span>
                        </p>
                        <div className="control">
                          <input
                            type="text"
                            name="cap"
                            disabled={this.state.disableAll}
                            id="cap"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.cap}
                            className="input-text required-entry"
                          />

                          {errors.cap && touched.cap && (
                            <div className="invalid-feedback">{errors.cap}</div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row_div">
                      <div className="cell_div longone">
                        <p className="subtitle">
                          <span>Indirizzo 2</span>
                        </p>
                        <div className="control">
                          <input
                            type="text"
                            disabled={this.state.disableAll}
                            name="street2"
                            id="street2"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.street2}
                            className="input-text required-entry"
                          />

                          {errors.street2 && touched.street2 && (
                            <div className="invalid-feedback">
                              {errors.street2}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row_div">
                      <div className="cell_div">
                        <p className="subtitle">
                          <span>Città</span>
                        </p>
                        <div className="control">
                          <input
                            type="text"
                            disabled={this.state.disableAll}
                            name="citta"
                            id="citta"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.citta}
                            className="input-text required-entry"
                          />

                          {errors.citta && touched.citta && (
                            <div className="invalid-feedback">
                              {errors.citta}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="table_div" style={{ marginTop: "0px" }}>
                    <div className="row_div">
                      <div className="cell_div fixedLength">
                        <p id="nazione" className="subtitle">
                          Nazione *
                        </p>
                        <Select
                           styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                          menuPortalTarget={document.body}
                          isClearable
                          isDisabled={this.state.disableAll}
                          placeholder={this.state.fatt_country.label}
                          options={this.state.countries}
                          isLoading={this.state.loadingCountry}
                          value={this.state.fatt_country.value}
                          onChange={(e) => {
                            if (e) {
                              values.country_id = e.value;
                              this.setState({
                                errorNazione: "",
                                fatt_country: e,
                                fatt_state: {
                                  value: -1,
                                  label: "Provincia.....",
                                },
                              });
                              this.fetchState(e.value);
                            } else {
                              this.fetchState(-99);
                              this.setState({
                                fatt_country: {
                                  value: -11,
                                  label: "Seleziona Nazione...",
                                },
                                fatt_state: {
                                  value: -1,
                                  label: "Provincia.....",
                                },
                              });
                            }
                          }}
                        />
                        {this.state.errorNazione && (
                          <div className="invalid-feedback">
                            {this.state.errorNazione}
                          </div>
                        )}
                      </div>
                      <div className="cell_div">
                        <p className="subtitle">Provincia/Regione</p>
                        <Select
                           styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                          menuPortalTarget={document.body}
                          isClearable
                          isDisabled={this.state.disableAll}
                          placeholder={this.state.fatt_state.label}
                          options={this.state.states}
                          value={this.state.fatt_state.value}
                          onChange={(e) => {
                            if (e) {
                              values.state_id = e.value;

                              this.setState({
                                fatt_state: e,
                              });
                            }
                          }}
                          isLoading={this.state.loadingState}
                        />
                      </div>
                    </div>
                    <div className="row_div">
                      <div className="cell_div">
                        <p className="subtitle">Team*</p>
                        <Select
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                          menuPortalTarget={document.body}
                          value={
                            this.state.teams &&
                            this.state.teams.filter(
                              (option) =>
                                option.value === this.state.team_id.value
                            )
                          }
                          placeholder={values.team_id.name}
                          options={this.state.teams}
                          onChange={(e) => {
                            if (e) {
                              values.team_id = e;
                            }
                            this.setState({ team_id: e });
                          }}
                          isLoading={this.state.loadingState}
                        />
                      </div>
                      <div className="cell_div">
                        <p className="subtitle">Tags</p>
                        <Creatable
                           styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                          menuPortalTarget={document.body}
                          isMulti
                          options={this.state.tagsopt}
                          placeholder="Aggiungi Tags.."
                          onChange={(newValue, actionMeta) => {
                            console.log("action",actionMeta)
                            this.setState({ tags: newValue });
                          }}
                        />
                      </div>
                    </div>
                    <div className="row_div">
                      <div className="cell_div">
                        <p className="subtitle">Origine*</p>
                        <Select
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                          menuPortalTarget={document.body}
                          isSearchable={true}
                          value={
                            this.state.origine
                          }
                          options={this.state.origineopt}
                          onChange={(e) => {
                              values.origine = e;
                            this.setState({ origine: e });
                          }}
                          isLoading={this.state.loadingState}
                        />
                      </div>
                      </div>
                  </div>
                  <div className="table_div">
                    <div className="row_div">
                      <div className="cell_div">
                        <div className="actions-toolbar">
                          <div className="primary">
                            <button
                              className=" create button btn-standard btn-green"
                              type="submit"
                              disabled={isSubmitting || this.state.loading}
                            >
                              {this.state.loading ? (
                                <div className="loginloader"></div>
                              ) : (
                                <span>Invia Anagrafica</span>
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          )}
        </Formik>
        <div
          className="secondary"
          style={{
            transition: "all 0.3s ease-in-out",
            opacity: this.state.done === false ? "0" : "1",
          }}
        >
          {this.state.response}
          <button
            className=" create button btn-standard btn-green"
            onClick={() => this.setState({ done: false })}
          >
            OK
          </button>
        </div>
      </div>
    );
  }
}
