import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./Sidebar.css";
export default class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      actual: window.location.pathname,
    };
  }
  renderloader() {
    var res = [];
    for (var i = 0; i < 5; i++) {
      res.push(
        <li key={i}>
          <span style={{ animationDelay: i * 500 + "ms" }} />
        </li>
      );
    }
    return res;
  }
  render() {
    return (
      <div className="sidebar sidebar-main">
        <div className="block account-nav">
          <div className="title account-nav-title">
            <strong />
          </div>
          <div className="content account-nav-content" id="account-nav">
            <ul className="nav items">
              <li
                className={
                  this.state.actual === "/customer"
                    ? "nav-item current"
                    : "nav-item"
                }
              >
                <Link
                  onClick={() => this.setState({ actual: "/customer" })}
                  to="/customer"
                >
                  <strong>La mia bacheca</strong>
                </Link>
              </li>
              <li
                className={
                  this.state.actual.includes("customer/quotations") > 0
                    ? "nav-item current"
                    : "nav-item"
                }
              >
                <Link
                  onClick={() =>
                    this.setState({ actual: "customer/quotations" })
                  }
                  to="/customer/quotations"
                >
                  <strong>I miei preventivi</strong>
                </Link>
              </li>
              <li
                className={
                  this.state.actual.includes("customer/orders") > 0
                    ? "nav-item current"
                    : "nav-item"
                }
              >
                <Link
                  onClick={() => this.setState({ actual: "customer/orders" })}
                  to="/customer/orders"
                >
                  <strong>I miei ordini</strong>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
