import React, { Component } from 'react';
class Check extends Component {
    constructor(props){
        super(props)
    }
    
    render() { 
        return (  <div className="field choice newsletter">
                     
        <label htmlFor="check_same" className="label">
          <span>{this.props.label} </span>
        </label>
        <span
          className={
            this.props.checkstate
              ? "jcf-checkbox jcf-checked jcf-focus"
              : "jcf-checkbox jcf-unchecked"
          }
          id="check_same"
        >
          <span />

          <input
            type="checkbox"
            className="checkbox"
            name="check_same"
            value={this.props.checkstate}
            onChange={()=>this.props.toggler()
            }
            style={{
              position: "absolute",
              height: "100%",
              width: "100%",
              opacity: 0,
              margin: "0px",
            }}
          />
        </span>
      </div> );
    }
}
 
export default Check;