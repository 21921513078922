import React, { Component } from "react";
import "./Ordini.css";
import api from "../../config";
import AsyncSelect from "react-select/async";
import { Link, Redirect } from "react-router-dom";
import Utility from "../../Utility";
import axios from "axios";
import Load from "../../components/Load";
var _ = api._;
export default class PurchaseOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      orders: [],
      count: 0,
      page: 0,
      max: 1,
      ordered_get: "",
    };
  }
  componentDidMount() {
    this.setState({ loading: true });
    api
      .fetchPurchaseOrder(this.props.prev, 0, 50, this.state.ordered_get)
      .then((value) => {
        if (value)
          this.setState({
            orders: value.rows,
            count: value.total,
            max: Math.ceil(value.total / 50) - 1,
            loading: false,
          });
        else this.setState({ loading: false });
      });
  }
  handleOrder = (attributo) => {
    this.setState(
      {
        fetching:true,
        ordered_get:
          attributo +
          " " +
          (this.state.ordered_get.includes("desc") ? "asc" : "desc"),
      },
      () => {
        api
          .fetchPurchaseOrder(this.props.prev, 0, 50, this.state.ordered_get)
          .then((value) => {
            if (value)
              this.setState({
                orders: value.rows,
                count: value.total,
                max: Math.ceil(value.total / 50) - 1,
                fetching: false,
              });
            else this.setState({ fetching: false });
          });
      }
    );
  };
  loadOptions = (inputValue, callback) => {
    this.setState({ searching: true });
    if (inputValue.length > 4) {
      api
        .searchAsyncPurchaseOrders(inputValue, this.props.prev)
        .then((value) => {
          if (value) callback(value.rows);
          this.setState({ searching: false });
        });
    }
  };
  fetchmore = (page) => {
    var offset = 50;
    this.setState({ fetching: true });
    if (this.state.page * offset < this.state.count) offset = 50;
    else {
      offset = this.state.count - 50;
    }
    api.fetchPurchaseOrder(this.props.prev, page * offset, 50, this.state.ordered_get).then((value) => {
      if (value)
        this.setState({
          orders: value.rows,
          count: value.total,
          fetching: false,
        });
    });
  };
  renderTablesLoader() {
    var res = [];
    for (var i = 0; i < 30; i++) {
      res.push(
        <tr key={i} className="loader">
          <td data-th="Ordine n." className="col id">
            <span style={{ animationDelay: i * 700 + "ms" }} />
          </td>
          <td data-th="Data" className="col date">
            <span style={{ animationDelay: i * 700 + "ms" }} />
          </td>
          <td data-th="Spedisci A" className="col shipping ">
            <span style={{ animationDelay: i * 700 + "ms" }} />
          </td>
          <td data-th="Totale ordine" className="col total ">
            <span style={{ animationDelay: i * 700 + "ms" }} />
          </td>
          <td data-th="Status" className="col status ">
            <span style={{ animationDelay: i * 700 + "ms" }} />
          </td>
          <td data-th="Azioni" className="col actions">
            <span style={{ animationDelay: i * 700 + "ms" }} />
          </td>
          <td data-th="Azioni" className="col actions">
            <span style={{ animationDelay: i * 700 + "ms" }} />
          </td>
     
        </tr>
      );
    }
    return res;
  }
  renderTablesOrder() {
    var res = [];
    if (this.state.orders.length > 0) {
      this.state.orders.forEach((element) => {
        res.push(
          <tr key={element.id}>
            <td data-th="Ordine n." className="col id">
              {element.name}
            </td>
            <td data-th="Data" className="col date">
              {element.partner_id.name}
            </td>
            <td data-th="Spedisci A" className="col shipping">
              {element.purchasing_manager_id ? element.purchasing_manager_id.name : ""}
            </td>
            <td data-th="Totale ordine" className="col total">
              <span className="price">
                {element.date_supplierdoc
                  ? new Date(element.date_supplierdoc).toLocaleDateString()
                  : null}
              </span>
            </td>
            <td data-th="Totale ordine" className="col shipping">
              <span className="price">{element.partner_ref}</span>
            </td>
            <td data-th="Status" className="col shipping">
              {element.order_ref}
            </td>
            {this.props.permission.opt_purchase_view_total && (
              <td data-th="Status" className="col total">
                {element.amount_total.toLocaleString("it-IT", {
                  style: "currency",
                  currency: "eur",
                })}
              </td>
            )}
            <td data-th="Azioni" className="col actions rightalign">
              <Link
                to={
                  this.props.prev
                    ? "/acquisti/preventivi/" + element.id
                    : "/acquisti/ordini/" + element.id
                }
                className="action view"
              >
                <button className="btn_outlined">Visualizza</button>
              </Link>
            </td>
          </tr>
        );
      });
    }
    return res;
  }

  render() {
    return (
      <div className="page_container">
        <h1 className="page_title">
          {this.props.prev === 0 ? "Ordini d'acquisto" : "RFQ "}
        </h1>
        <div className="prev_card searchable" style={{ marginBottom: "25px" }}>
          <div className="table_div">
            <div className="row_div">
              <div className="cell_div">
                <p className="subtitle">Cerca Contatto</p>
                <AsyncSelect
                  cacheOptions
                  isLoading={this.state.searching}
                  // isClearable={false}
                  //isSearchable={true}
                  onChange={(e) => {
                    this.props.history.push(
                      window.location.pathname + "/" + e.value
                    );
                  }}
                  placeholder={
                    this.props.prev === 0
                      ? "Cerca un ordine"
                      : "Cerca un preventivo"
                  }
                  loadOptions={_.debounce(this.loadOptions, 300)}
                />
              </div>
            </div>
          </div>
        </div>
        {this.state.orders.length > 0 && !this.state.loading && (
          <div className="pagination_container">
            <div className="page_right">
              {/*  pagina {this.state.page + 1} max: {this.state.max + 1} {"  "} */}
              {this.state.page === 0 ? 1 : this.state.page * 50}-
              {this.state.page === 0
                ? 50
                : this.state.page == this.state.max
                ? this.state.count
                : this.state.page * 50 + 50}{" "}
              &#8725; {this.state.count}{" "}
              <span
                onClick={() => {
                  var newpage =
                    this.state.page > 0 ? this.state.page - 1 : this.state.max;

                  this.setState({ page: newpage });
                  this.fetchmore(newpage);
                }}
              >
                &#8249;
              </span>{" "}
              <span
                onClick={() => {
                  var newpage =
                    this.state.page == this.state.max ? 0 : this.state.page + 1;
                  this.setState({ page: newpage });
                  this.fetchmore(newpage);
                }}
              >
                &#8250;
              </span>{" "}
            </div>
          </div>
        )}
        <div className="prev_card searchable">
          {!this.state.loading ? (
            this.state.orders.length > 0 ? (
              <table
                className="data table table-order-items history"
                id="my-orders-table-ord"
              >
                <caption className="table-caption">Ordini</caption>
                <thead>
                
                  <tr>
                  <Utility
                    choose="header"
                    head_array={[
                    {  label:"Ordine N.", value:"name", col:"id" },
                    { label:"Fornitore", value:"partner_id", col:'desc'},
                    { label:"Responsabile", value:"purchasing_manager_id",col:'desc'},
                    { label:"Data Documento", value:"date_approve",col:'id'},
                    { label:"Rif. Fornitore", value:"partner_ref",col:'desc'},
                    { label:"Rif. Ordine", value:"order_ref",col:'desc'},
                    { label:"", value:"link",col:'actions rightalign'}
                    ]}
                    orderstate={this.state.ordered_get}
                    handleState={this.handleOrder}
                    /> 
                  </tr>
                </thead>
                <tbody>{this.renderTablesOrder()}</tbody>
              </table>
            ) : (
              <div className="message info empty">
                <span>Non è stato inserito nessun ordine.</span>
              </div>
            )
          ) : (
            <table
              className="data table table-order-items history"
              id="my-orders-table"
            >
              <caption className="table-caption">Ordini</caption>

              <thead>
                <tr>
                  <th scope="col" className="col id">
                    Ordine n.
                  </th>
                  <th scope="col" className="col date">
                    Fornitore
                  </th>
                  <th scope="col" className="col date">
                    Responsabile
                  </th>
                  <th scope="col" className="col date">
                    Data Documento
                  </th>
                  <th scope="col" className="col shipping">
                    Rif. Cliente
                  </th>
                  <th scope="col" className="col total">
                    Rif. Ordine
                  </th>
                  <th scope="col" className="col total">
                    Totale ivato
                  </th>
                  <th scope="col" className="col actions rightalign"></th>
                </tr>
              </thead>

              <tbody>{this.renderTablesLoader()}</tbody>
            </table>
          )}
        </div>
        {this.state.fetching && (
          <div className="obscure">
            <Load text="Carico i nuovi dati" />
          </div>
        )}
      </div>
    );
  }
}
