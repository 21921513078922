import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class NotFound extends Component {
  render() {
    return (
      <div className="page_container home">
        <h1 className="page_title">ERROR 404</h1>
        <p>
          {" "}
          La pagina che hai richiesto non è stata trovata oppure non hai i
          permessi per visualizzarla. torna alla{" "}
          <Link to="/">Torna alla Home</Link>
        </p>
      </div>
    );
  }
}
