import axios from "axios";
import CreatableSelect from "react-select/creatable";
import Swal from "sweetalert2";
axios.defaults.withCredentials = true;
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const url_api = BACKEND_URL + "/rest/data/private";
const DB_NAME = process.env.REACT_APP_DB_NAME;
const api = {
  _: require("lodash"),
  authenticate: function (user, pwd) {
    return axios
      .post(
        this.auth_api + "/rws/session/authenticate",
        {
          params: {
            db: this.body.db,
            login: user,
            password: pwd,
          },
        },
        {
          timeout: this.timeout,
        }
      )
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch(function (error) {
        console.log("error", JSON.stringify(error));
      });
  },
  login: function () {
    return axios
      .post(
        this.auth_api + "/rws/session/get_session_info",
        { params: {} },
        {
          timeout: this.timeout,
        }
      )
      .then((res) => {
        const data = res.data;
        if ("error" in data) {
        } else {
          sessionStorage.setItem("username", data.result.partner_display_name);
          return axios
            .get(url_api + "/user/" + data.result.uid, {
              timeout: this.timeout,
            })
            .then((res) => {
              const data = res.data;
              return data;
            });
        }
      })
      .catch((error) => {
        Swal.fire({
          position: "center",
          title: "Errore!",
          icon: "error",
          text:
            "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
          timer: 3000,
          timerProgressBar: true,
          showConfirmButton: true,
          confirmButtonText: "Aggiorna",
          customClass: {
            container: "swal_container",
            confirmButton: "btn_modifica",
          },
        }).then((value) => {
          if (value.isConfirmed) window.location.reload();
        });
      });
  },

  fetchPurchaseOrder: function (prev, offset, limit, order, settings) {
    return axios
      .get(
        url_api +
          "/purchase-order?p=" +
          prev +
          "&offset=" +
          offset +
          "&limit=" +
          limit +
          "&order_by=" +
          (order ? order : ""),
        settings
          ? settings
          : {
              timeout: this.timeout,
            }
      )
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch(function (error) {
        if (error.code === "ECONNABORTED") {
          Swal.fire({
            position: "center",
            title: "Errore!",
            icon: "error",
            text:
              "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: true,
            confirmButtonText: "Aggiorna",
            customClass: {
              container: "swal_container",
              confirmButton: "btn_modifica",
            },
          }).then((value) => {
            if (value.isConfirmed) window.location.reload();
          });
        }
        console.log(error);
        if (error.response)
          if (error.response.status == "401" || error.response.status == 401) {
            Swal.fire({
              position: "center",
              title: "Errore!",
              icon: "error",
              text:
                "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
              timer: 3000,
              timerProgressBar: true,
              showConfirmButton: true,
              confirmButtonText: "Aggiorna",
              customClass: {
                container: "swal_container",
                confirmButton: "btn_modifica",
              },
            }).then((value) => {
              if (value.isConfirmed) window.location.reload();
            });
          }
      });
  },
  azzeraDiscount: function (id){
      return axios
        .post(url_api + "/sale-order/preventivatore_save_null_discount", {
          order_id: id,
        })
        .then((res) => {
          const data = res.data;
          return data;
        })
        .catch(function (error) {
          if (error.response) {
            if (
              error.response.status == "400" ||
              error.response.status == 400 ||
              error.response.status == 404
            ) {
              Swal.fire({
                position: "center",
                icon: "attention",
                html:
                  "<h1>Attenzione</h1><p>Il Preventivo non è stato trovato, chiudi questo popup per crearne uno nuovo o vai alla pagina delle bozze</p>",
                timerProgressBar: true,
                showConfirmButton: true,
                confirmButtonText: "Vai alle Bozze",
                customClass: {
                  container: "swal_container",
                  confirmButton: "btn_modifica",
                },
                timer: 5000,
                onClose: () => {
                  window.location.replace("/preventivatore/new");
                },
              }).then((value) => {
                if (value.isConfirmed)
                  window.location.replace("/preventivatore/drafts");
              });
              if (
                error.response.status == "401" ||
                error.response.status == 401
              ) {
                Swal.fire({
                  position: "center",
                  title: "Errore!",
                  icon: "error",
                  text:
                    "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
                  timer: 3000,
                  timerProgressBar: true,
                  showConfirmButton: true,
                  confirmButtonText: "Aggiorna",
                  customClass: {
                    container: "swal_container",
                    confirmButton: "btn_modifica",
                  },
                }).then((value) => {
                  if (value.isConfirmed) window.location.reload();
                });
              }
            }
          }
        });
  },
  fetchInnerPurchaseOrder: function (id) {
    return axios
      .get(url_api + "/purchase-order/" + id, {
        timeout: this.timeout,
      })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch(function (error) {
        if (error.code === "ECONNABORTED") {
          Swal.fire({
            position: "center",
            title: "Errore!",
            icon: "error",
            text:
              "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: true,
            confirmButtonText: "Aggiorna",
            customClass: {
              container: "swal_container",
              confirmButton: "btn_modifica",
            },
          }).then((value) => {
            if (value.isConfirmed) window.location.reload();
          });
        }
        console.log(JSON.stringify(error));
        if (error.response)
          if (error.response.status == "401" || error.response.status == 401) {
            Swal.fire({
              position: "center",
              title: "Errore!",
              icon: "error",
              text:
                "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
              timer: 3000,
              timerProgressBar: true,
              showConfirmButton: true,
              confirmButtonText: "Aggiorna",
              customClass: {
                container: "swal_container",
                confirmButton: "btn_modifica",
              },
            }).then((value) => {
              if (value.isConfirmed) window.location.reload();
            });
          }
      });
  },
  fetchSaleOrder: function (prev, offset, limit, order, settings) {
    return axios
      .get(
        url_api +
          "/sale-order?p=" +
          prev +
          "&offset=" +
          offset +
          "&limit=" +
          limit +
          "&order_by=" +
          (order ? order : ""),
        settings
          ? settings
          : {
              timeout: this.timeout,
            }
      )
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch(function (error) {
        if (error.code === "ECONNABORTED") {
          Swal.fire({
            position: "center",
            title: "Errore!",
            icon: "error",
            text:
              "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: true,
            confirmButtonText: "Aggiorna",
            customClass: {
              container: "swal_container",
              confirmButton: "btn_modifica",
            },
          }).then((value) => {
            if (value.isConfirmed) window.location.reload();
          });
        }
        console.log("error", JSON.stringify(error));
        if (error.response)
          if (
            error.response.status == "Network Error" ||
            error.response.status > 399
          ) {
            Swal.fire({
              position: "center",
              title: "Errore!",
              icon: "error",
              text:
                "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
              timer: 3000,
              timerProgressBar: true,
              showConfirmButton: true,
              confirmButtonText: "Aggiorna",
              customClass: {
                container: "swal_container",
                confirmButton: "btn_modifica",
              },
            }).then((value) => {
              if (value.isConfirmed) window.location.reload();
            });
          }
      });
  },
  fetchPicking: function (offset, limit, order, state, settings) {
    return axios
      .get(
        url_api +
          "/picking?offset=" +
          offset +
          "&limit=" +
          limit +
          (state ? "&s=" + state : "") +
          (order ? "&order_by=" + order : ""),
        settings
          ? settings
          : {
              timeout: this.timeout,
            }
      )
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch(function (error) {
        if (error.code === "ECONNABORTED") {
          Swal.fire({
            position: "center",
            title: "Errore!",
            icon: "error",
            text:
              "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: true,
            confirmButtonText: "Aggiorna",
            customClass: {
              container: "swal_container",
              confirmButton: "btn_modifica",
            },
          }).then((value) => {
            if (value.isConfirmed) window.location.reload();
          });
        }
        console.log("error", JSON.stringify(error));
        if (error.response)
          if (
            error.response.status == "Network Error" ||
            error.response.status > 399
          ) {
            Swal.fire({
              position: "center",
              title: "Errore!",
              icon: "error",
              text:
                "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
              timer: 3000,
              timerProgressBar: true,
              showConfirmButton: true,
              confirmButtonText: "Aggiorna",
              customClass: {
                container: "swal_container",
                confirmButton: "btn_modifica",
              },
            }).then((value) => {
              if (value.isConfirmed) window.location.reload();
            });
          }
      });
  },
  getFollowup: function (state, offset, limit, order, settings) {
    return axios
      .get(
        url_api +
          "/mail?offset=" +
          offset +
          "&limit=" +
          limit +
          "&s=" +
          state +
          (order ? "&order_by=" + order : ""),
        settings
          ? settings
          : {
              timeout: this.timeout,
            }
      )
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch(function (error) {
        if (error.code === "ECONNABORTED") {
          Swal.fire({
            position: "center",
            title: "Errore!",
            icon: "error",
            text:
              "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: true,
            confirmButtonText: "Aggiorna",
            customClass: {
              container: "swal_container",
              confirmButton: "btn_modifica",
            },
          }).then((value) => {
            if (value.isConfirmed) window.location.reload();
          });
        }
        console.log("error", JSON.stringify(error));
        if (error.response)
          if (
            error.response.status == "Network Error" ||
            error.response.status > 399
          ) {
            Swal.fire({
              position: "center",
              title: "Errore!",
              icon: "error",
              text:
                "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
              timer: 3000,
              timerProgressBar: true,
              showConfirmButton: true,
              confirmButtonText: "Aggiorna",
              customClass: {
                container: "swal_container",
                confirmButton: "btn_modifica",
              },
            }).then((value) => {
              if (value.isConfirmed) window.location.reload();
            });
          }
      });
  },
  getInnerFollowup: function (id) {
    return axios
      .get(url_api + "/mail/" + id, {
        timeout: this.timeout,
      })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch(function (error) {
        if (error.code === "ECONNABORTED") {
          Swal.fire({
            position: "center",
            title: "Errore!",
            icon: "error",
            text:
              "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: true,
            confirmButtonText: "Aggiorna",
            customClass: {
              container: "swal_container",
              confirmButton: "btn_modifica",
            },
          }).then((value) => {
            if (value.isConfirmed) window.location.reload();
          });
        }
        console.log(error);
        if (error.response)
          if (error.response.status == "401" || error.response.status == 401) {
            Swal.fire({
              position: "center",
              title: "Errore!",
              icon: "error",
              text:
                "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
              timer: 3000,
              timerProgressBar: true,
              showConfirmButton: true,
              confirmButtonText: "Aggiorna",
              customClass: {
                container: "swal_container",
                confirmButton: "btn_modifica",
              },
            }).then((value) => {
              if (value.isConfirmed) window.location.reload();
            });
          }
      });
  },
  fetchFatture: function (offset, limit,order, settings) {
    return axios
      .get(
        url_api + "/invoice?offset=" + offset + "&limit=" + limit+  (order ? "&order_by=" + order : ""),
        settings
          ? settings
          : {
              timeout: this.timeout,
            }
      )
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch(function (error) {
        if (error.code === "ECONNABORTED") {
          Swal.fire({
            position: "center",
            title: "Errore!",
            icon: "error",
            text:
              "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: true,
            confirmButtonText: "Aggiorna",
            customClass: {
              container: "swal_container",
              confirmButton: "btn_modifica",
            },
          }).then((value) => {
            if (value.isConfirmed) window.location.reload();
          });
        }
        console.log("error", JSON.stringify(error));
        if (error.response)
          if (
            error.response.status == "Network Error" ||
            error.response.status > 399
          ) {
            Swal.fire({
              position: "center",
              title: "Errore!",
              icon: "error",
              text:
                "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
              timer: 3000,
              timerProgressBar: true,
              showConfirmButton: true,
              confirmButtonText: "Aggiorna",
              customClass: {
                container: "swal_container",
                confirmButton: "btn_modifica",
              },
            }).then((value) => {
              if (value.isConfirmed) window.location.reload();
            });
          }
      });
  },
  fetchInnerFatture: function (id) {
    return axios
      .get(url_api + "/invoice/" + id, {
        timeout: this.timeout,
      })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch(function (error) {
        if (error.code === "ECONNABORTED") {
          Swal.fire({
            position: "center",
            title: "Errore!",
            icon: "error",
            text:
              "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: true,
            confirmButtonText: "Aggiorna",
            customClass: {
              container: "swal_container",
              confirmButton: "btn_modifica",
            },
          }).then((value) => {
            if (value.isConfirmed) window.location.reload();
          });
        }
        console.log(error);
        if (error.response)
          if (error.response.status == "401" || error.response.status == 401) {
            Swal.fire({
              position: "center",
              title: "Errore!",
              icon: "error",
              text:
                "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
              timer: 3000,
              timerProgressBar: true,
              showConfirmButton: true,
              confirmButtonText: "Aggiorna",
              customClass: {
                container: "swal_container",
                confirmButton: "btn_modifica",
              },
            }).then((value) => {
              if (value.isConfirmed) window.location.reload();
            });
          }
      });
  },
  fetchInnerPicking: function (id) {
    return axios
      .get(url_api + "/picking/" + id, {
        timeout: this.timeout,
      })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch(function (error) {
        if (error.code === "ECONNABORTED") {
          Swal.fire({
            position: "center",
            title: "Errore!",
            icon: "error",
            text:
              "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: true,
            confirmButtonText: "Aggiorna",
            customClass: {
              container: "swal_container",
              confirmButton: "btn_modifica",
            },
          }).then((value) => {
            if (value.isConfirmed) window.location.reload();
          });
        }
        console.log(error);
        if (error.response)
          if (error.response.status == "401" || error.response.status == 401) {
            Swal.fire({
              position: "center",
              title: "Errore!",
              icon: "error",
              text:
                "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
              timer: 3000,
              timerProgressBar: true,
              showConfirmButton: true,
              confirmButtonText: "Aggiorna",
              customClass: {
                container: "swal_container",
                confirmButton: "btn_modifica",
              },
            }).then((value) => {
              if (value.isConfirmed) window.location.reload();
            });
          }
      });
  },
  fetchInnerSaleOrder: function (id) {
    return axios
      .get(url_api + "/sale-order/" + id, {
        timeout: this.timeout,
      })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch(function (error) {
        if (error.code === "ECONNABORTED") {
          Swal.fire({
            position: "center",
            title: "Errore!",
            icon: "error",
            text:
              "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: true,
            confirmButtonText: "Aggiorna",
            customClass: {
              container: "swal_container",
              confirmButton: "btn_modifica",
            },
          }).then((value) => {
            if (value.isConfirmed) window.location.reload();
          });
        }
        console.log(error);
        if (error.response)
          if (error.response.status == "401" || error.response.status == 401) {
            Swal.fire({
              position: "center",
              title: "Errore!",
              icon: "error",
              text:
                "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
              timer: 3000,
              timerProgressBar: true,
              showConfirmButton: true,
              confirmButtonText: "Aggiorna",
              customClass: {
                container: "swal_container",
                confirmButton: "btn_modifica",
              },
            }).then((value) => {
              if (value.isConfirmed) window.location.reload();
            });
          }
      });
  },
  searchCustomer: function (query) {
    return axios
      .get(url_api + "/customer?query=" + query, {
        timeout: this.timeout,
      })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch(function (error) {
        if (error.code === "ECONNABORTED") {
          Swal.fire({
            position: "center",
            title: "Errore!",
            icon: "error",
            text:
              "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: true,
            confirmButtonText: "Aggiorna",
            customClass: {
              container: "swal_container",
              confirmButton: "btn_modifica",
            },
          }).then((value) => {
            if (value.isConfirmed) window.location.reload();
          });
        }
        console.log(error);
        if (error.response)
          if (error.response.status == "401" || error.response.status == 401) {
            Swal.fire({
              position: "center",
              title: "Errore!",
              icon: "error",
              text:
                "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
              timer: 3000,
              timerProgressBar: true,
              showConfirmButton: true,
              confirmButtonText: "Aggiorna",
              customClass: {
                container: "swal_container",
                confirmButton: "btn_modifica",
              },
            }).then((value) => {
              if (value.isConfirmed) window.location.reload();
            });
          }
      });
  },
  searchAsyncPurchaseOrders: function (query, prev) {
    return axios
      .get(url_api + "/purchase-order?p=" + prev + "&async=1&query=" + query, {
        timeout: this.timeout,
      })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch(function (error) {
        if (error.code === "ECONNABORTED") {
          Swal.fire({
            position: "center",
            title: "Errore!",
            icon: "error",
            text:
              "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: true,
            confirmButtonText: "Aggiorna",
            customClass: {
              container: "swal_container",
              confirmButton: "btn_modifica",
            },
          }).then((value) => {
            if (value.isConfirmed) window.location.reload();
          });
        }
        console.log(error);
        if (error.response)
          if (error.response.status == "401" || error.response.status == 401) {
            Swal.fire({
              position: "center",
              title: "Errore!",
              icon: "error",
              text:
                "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
              timer: 3000,
              timerProgressBar: true,
              showConfirmButton: true,
              confirmButtonText: "Aggiorna",
              customClass: {
                container: "swal_container",
                confirmButton: "btn_modifica",
              },
            }).then((value) => {
              if (value.isConfirmed) window.location.reload();
            });
          }
      });
  },
  searchAsyncFollowup: function (query, prev) {
    return axios
      .get(url_api + "/mail?s=" + prev + "&async=1&query=" + query, {
        timeout: this.timeout,
      })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch(function (error) {
        if (error.code === "ECONNABORTED") {
          Swal.fire({
            position: "center",
            title: "Errore!",
            icon: "error",
            text:
              "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: true,
            confirmButtonText: "Aggiorna",
            customClass: {
              container: "swal_container",
              confirmButton: "btn_modifica",
            },
          }).then((value) => {
            if (value.isConfirmed) window.location.reload();
          });
        }
        console.log(error);
        if (error.response)
          if (error.response.status == "401" || error.response.status == 401) {
            Swal.fire({
              position: "center",
              title: "Errore!",
              icon: "error",
              text:
                "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
              timer: 3000,
              timerProgressBar: true,
              showConfirmButton: true,
              confirmButtonText: "Aggiorna",
              customClass: {
                container: "swal_container",
                confirmButton: "btn_modifica",
              },
            }).then((value) => {
              if (value.isConfirmed) window.location.reload();
            });
          }
      });
  },
  
  getInnerLead: function (id) {
    return axios
      .get(url_api + "/lead/" + id, {
        timeout: this.timeout,
      })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch(function (error) {
        if (error.code === "ECONNABORTED") {
          Swal.fire({
            position: "center",
            title: "Errore!",
            icon: "error",
            text:
              "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: true,
            confirmButtonText: "Aggiorna",
            customClass: {
              container: "swal_container",
              confirmButton: "btn_modifica",
            },
          }).then((value) => {
            if (value.isConfirmed) window.location.reload();
          });
        }
        console.log(JSON.stringify(error));
        if (error.response)
          if (error.response.status == "401" || error.response.status == 401) {
            Swal.fire({
              position: "center",
              title: "Errore!",
              icon: "error",
              text:
                "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
              timer: 3000,
              timerProgressBar: true,
              showConfirmButton: true,
              confirmButtonText: "Aggiorna",
              customClass: {
                container: "swal_container",
                confirmButton: "btn_modifica",
              },
            }).then((value) => {
              if (value.isConfirmed) window.location.reload();
            });
          }
      });
  },
  searchAsyncLead: function (query) {
    return axios
      .get(url_api + "/lead?async=1&query=" + query, {
        timeout: this.timeout,
      })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch(function (error) {
        if (error.code === "ECONNABORTED") {
          Swal.fire({
            position: "center",
            title: "Errore!",
            icon: "error",
            text:
              "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: true,
            confirmButtonText: "Aggiorna",
            customClass: {
              container: "swal_container",
              confirmButton: "btn_modifica",
            },
          }).then((value) => {
            if (value.isConfirmed) window.location.reload();
          });
        }
        console.log(error);
        if (error.response)
          if (error.response.status == "401" || error.response.status == 401) {
            Swal.fire({
              position: "center",
              title: "Errore!",
              icon: "error",
              text:
                "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
              timer: 3000,
              timerProgressBar: true,
              showConfirmButton: true,
              confirmButtonText: "Aggiorna",
              customClass: {
                container: "swal_container",
                confirmButton: "btn_modifica",
              },
            }).then((value) => {
              if (value.isConfirmed) window.location.reload();
            });
          }
      });
  },
  searchAsyncSaleOrders: function (query, prev) {
    return axios
      .get(url_api + "/sale-order?p=" + prev + "&async=1&query=" + query, {
        timeout: this.timeout,
      })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch(function (error) {
        if (error.code === "ECONNABORTED") {
          Swal.fire({
            position: "center",
            title: "Errore!",
            icon: "error",
            text:
              "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: true,
            confirmButtonText: "Aggiorna",
            customClass: {
              container: "swal_container",
              confirmButton: "btn_modifica",
            },
          }).then((value) => {
            if (value.isConfirmed) window.location.reload();
          });
        }
        console.log(error);
        if (error.response)
          if (error.response.status == "401" || error.response.status == 401) {
            Swal.fire({
              position: "center",
              title: "Errore!",
              icon: "error",
              text:
                "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
              timer: 3000,
              timerProgressBar: true,
              showConfirmButton: true,
              confirmButtonText: "Aggiorna",
              customClass: {
                container: "swal_container",
                confirmButton: "btn_modifica",
              },
            }).then((value) => {
              if (value.isConfirmed) window.location.reload();
            });
          }
      });
  },
  searchAsyncPicking: function (query) {
    return axios
      .get(url_api + "/picking?async=1&query=" + query, {
        timeout: this.timeout,
      })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch(function (error) {
        if (error.code === "ECONNABORTED") {
          Swal.fire({
            position: "center",
            title: "Errore!",
            icon: "error",
            text:
              "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: true,
            confirmButtonText: "Aggiorna",
            customClass: {
              container: "swal_container",
              confirmButton: "btn_modifica",
            },
          }).then((value) => {
            if (value.isConfirmed) window.location.reload();
          });
        }
        console.log(error);
        if (error.response)
          if (error.response.status == "401" || error.response.status == 401) {
            Swal.fire({
              position: "center",
              title: "Errore!",
              icon: "error",
              text:
                "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
              timer: 3000,
              timerProgressBar: true,
              showConfirmButton: true,
              confirmButtonText: "Aggiorna",
              customClass: {
                container: "swal_container",
                confirmButton: "btn_modifica",
              },
            }).then((value) => {
              if (value.isConfirmed) window.location.reload();
            });
          }
      });
  },
  searchAsyncFatture: function (query) {
    return axios
      .get(url_api + "/invoice?async=1&query=" + query, {
        timeout: this.timeout,
      })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch(function (error) {
        if (error.code === "ECONNABORTED") {
          Swal.fire({
            position: "center",
            title: "Errore!",
            icon: "error",
            text:
              "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: true,
            confirmButtonText: "Aggiorna",
            customClass: {
              container: "swal_container",
              confirmButton: "btn_modifica",
            },
          }).then((value) => {
            if (value.isConfirmed) window.location.reload();
          });
        }
        console.log(error);
        if (error.response)
          if (error.response.status == "401" || error.response.status == 401) {
            Swal.fire({
              position: "center",
              title: "Errore!",
              icon: "error",
              text:
                "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
              timer: 3000,
              timerProgressBar: true,
              showConfirmButton: true,
              confirmButtonText: "Aggiorna",
              customClass: {
                container: "swal_container",
                confirmButton: "btn_modifica",
              },
            }).then((value) => {
              if (value.isConfirmed) window.location.reload();
            });
          }
      });
  },
  searchAsyncSaleOrdersAll: function (query, prev) {
    return axios
      .get(url_api + "/sale-order?p=-1&async=1&query=" + query, {
        timeout: this.timeout,
      })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch(function (error) {
        if (error.code === "ECONNABORTED") {
          Swal.fire({
            position: "center",
            title: "Errore!",
            icon: "error",
            text:
              "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: true,
            confirmButtonText: "Aggiorna",
            customClass: {
              container: "swal_container",
              confirmButton: "btn_modifica",
            },
          }).then((value) => {
            if (value.isConfirmed) window.location.reload();
          });
        }
        console.log(error);
        if (error.response)
          if (error.response.status == "401" || error.response.status == 401) {
            Swal.fire({
              position: "center",
              title: "Errore!",
              icon: "error",
              text:
                "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
              timer: 3000,
              timerProgressBar: true,
              showConfirmButton: true,
              confirmButtonText: "Aggiorna",
              customClass: {
                container: "swal_container",
                confirmButton: "btn_modifica",
              },
            }).then((value) => {
              if (value.isConfirmed) window.location.reload();
            });
          }
      });
  },
  shareOrder: function (id) {
    return axios
      .post(url_api + "/sale-order/" + id + "/share", {})
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch(function (error) {
        if (error.code === "ECONNABORTED") {
          Swal.fire({
            position: "center",
            title: "Errore!",
            icon: "error",
            text:
              "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: true,
            confirmButtonText: "Aggiorna",
            customClass: {
              container: "swal_container",
              confirmButton: "btn_modifica",
            },
          }).then((value) => {
            if (value.isConfirmed) window.location.reload();
          });
        }
        console.log(error);
        if (error.response)
          if (error.response.status == "401" || error.response.status == 401) {
            Swal.fire({
              position: "center",
              title: "Errore!",
              icon: "error",
              text:
                "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
              timer: 3000,
              timerProgressBar: true,
              showConfirmButton: true,
              confirmButtonText: "Aggiorna",
              customClass: {
                container: "swal_container",
                confirmButton: "btn_modifica",
              },
            }).then((value) => {
              if (value.isConfirmed) window.location.reload();
            });
          }
      });
  },
  portalSaleOrder: function (id, token) {
    return axios
      .post(url_api + "/portal/sale-order/sale.order/" + id, {
        t: token,
      })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch(function (error) {
        if (error.code === "ECONNABORTED") {
          Swal.fire({
            position: "center",
            title: "Errore!",
            icon: "error",
            text:
              "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: true,
            confirmButtonText: "Aggiorna",
            customClass: {
              container: "swal_container",
              confirmButton: "btn_modifica",
            },
          }).then((value) => {
            if (value.isConfirmed) window.location.reload();
          });
        }
        console.log(error);
        if (error.response)
          if (error.response.status == "401" || error.response.status == 401) {
            Swal.fire({
              position: "center",
              title: "Errore!",
              icon: "error",
              text:
                "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
              timer: 3000,
              timerProgressBar: true,
              showConfirmButton: true,
              confirmButtonText: "Aggiorna",
              customClass: {
                container: "swal_container",
                confirmButton: "btn_modifica",
              },
            }).then((value) => {
              if (value.isConfirmed) window.location.reload();
            });
          }
      });
  },
  portalpostChatter: function (body, id, token) {
    return axios
      .post(url_api + "/portal/sale-order/sale.order/" + id + "/chatter", {
        t: token,
        msg: body,
      })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch(function (error) {
        if (error.code === "ECONNABORTED") {
          Swal.fire({
            position: "center",
            title: "Errore!",
            icon: "error",
            text:
              "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: true,
            confirmButtonText: "Aggiorna",
            customClass: {
              container: "swal_container",
              confirmButton: "btn_modifica",
            },
          }).then((value) => {
            if (value.isConfirmed) window.location.reload();
          });
        }
        console.log(error);
        if (error.response)
          if (error.response.status == "401" || error.response.status == 401) {
            Swal.fire({
              position: "center",
              title: "Errore!",
              icon: "error",
              text:
                "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
              timer: 3000,
              timerProgressBar: true,
              showConfirmButton: true,
              confirmButtonText: "Aggiorna",
              customClass: {
                container: "swal_container",
                confirmButton: "btn_modifica",
              },
            }).then((value) => {
              if (value.isConfirmed) window.location.reload();
            });
          }
      });
  },
  getPayTerms: function () {
    return axios.post(url_api + "/toolbox/get_pay_terms/", {}).then((res) => {
      const data = res.data;
      return data.rows;
    });
  },
  postChatter: function (body, id) {
    return axios
      .post(url_api + "/sale-order/" + id + "/chatter", {
        msg: body,
      })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch(function (error) {
        if (error.code === "ECONNABORTED") {
          Swal.fire({
            position: "center",
            title: "Errore!",
            icon: "error",
            text:
              "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: true,
            confirmButtonText: "Aggiorna",
            customClass: {
              container: "swal_container",
              confirmButton: "btn_modifica",
            },
          }).then((value) => {
            if (value.isConfirmed) window.location.reload();
          });
        }
        console.log(error);
        if (error.response)
          if (error.response.status == "401" || error.response.status == 401) {
            Swal.fire({
              position: "center",
              title: "Errore!",
              icon: "error",
              text:
                "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
              timer: 3000,
              timerProgressBar: true,
              showConfirmButton: true,
              confirmButtonText: "Aggiorna",
              customClass: {
                container: "swal_container",
                confirmButton: "btn_modifica",
              },
            }).then((value) => {
              if (value.isConfirmed) window.location.reload();
            });
          }
      });
  },
  postChatterFatture: function (body, id) {
    return axios
      .post(url_api + "/invoice/" + id + "/chatter", {
        msg: body,
      })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch(function (error) {
        if (error.code === "ECONNABORTED") {
          Swal.fire({
            position: "center",
            title: "Errore!",
            icon: "error",
            text:
              "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: true,
            confirmButtonText: "Aggiorna",
            customClass: {
              container: "swal_container",
              confirmButton: "btn_modifica",
            },
          }).then((value) => {
            if (value.isConfirmed) window.location.reload();
          });
        }
        console.log(error);
        if (error.response)
          if (error.response.status == "401" || error.response.status == 401) {
            Swal.fire({
              position: "center",
              title: "Errore!",
              icon: "error",
              text:
                "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
              timer: 3000,
              timerProgressBar: true,
              showConfirmButton: true,
              confirmButtonText: "Aggiorna",
              customClass: {
                container: "swal_container",
                confirmButton: "btn_modifica",
              },
            }).then((value) => {
              if (value.isConfirmed) window.location.reload();
            });
          }
      });
  },
  postChatterPicking: function (body, id) {
    return axios
      .post(url_api + "/picking/" + id + "/chatter", {
        msg: body,
      })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch(function (error) {
        if (error.code === "ECONNABORTED") {
          Swal.fire({
            position: "center",
            title: "Errore!",
            icon: "error",
            text:
              "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: true,
            confirmButtonText: "Aggiorna",
            customClass: {
              container: "swal_container",
              confirmButton: "btn_modifica",
            },
          }).then((value) => {
            if (value.isConfirmed) window.location.reload();
          });
        }
        console.log(error);
        if (error.response)
          if (error.response.status == "401" || error.response.status == 401) {
            Swal.fire({
              position: "center",
              title: "Errore!",
              icon: "error",
              text:
                "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
              timer: 3000,
              timerProgressBar: true,
              showConfirmButton: true,
              confirmButtonText: "Aggiorna",
              customClass: {
                container: "swal_container",
                confirmButton: "btn_modifica",
              },
            }).then((value) => {
              if (value.isConfirmed) window.location.reload();
            });
          }
      });
  },
  getOrigins: function () {
    return axios.post(url_api + "/toolbox/get_utm_sources/", {}).then((res) => {
      const data = res.data;
      return data.rows;
    });
  },
  getMailforCompose: function (template_id, res_id, res_model) {
    return axios
      .post(url_api + "/toolbox/get_mail_for_compose", {
        template_id,
        res_id,
        res_model,
      })
      .then((res) => {
        const data = res.data;
        return data;
      });
  },
  getTaxes: function () {
    return axios
      .post(url_api + "/toolbox/get_taxes/", {})
      .then((res) => {
        const data = res.data;
        return data.rows;
      })
      .catch(function (error) {
        if (error.code === "ECONNABORTED") {
          Swal.fire({
            position: "center",
            title: "Errore!",
            icon: "error",
            text:
              "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: true,
            confirmButtonText: "Aggiorna",
            customClass: {
              container: "swal_container",
              confirmButton: "btn_modifica",
            },
          }).then((value) => {
            if (value.isConfirmed) window.location.reload();
          });
        }
        console.log(error);
        if (error.response)
          if (error.response.status == "401" || error.response.status == 401) {
            Swal.fire({
              position: "center",
              title: "Errore!",
              icon: "error",
              text:
                "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
              timer: 3000,
              timerProgressBar: true,
              showConfirmButton: true,
              confirmButtonText: "Aggiorna",
              customClass: {
                container: "swal_container",
                confirmButton: "btn_modifica",
              },
            }).then((value) => {
              if (value.isConfirmed) window.location.reload();
            });
          }
      });
  },
  searchAsyncCustomer: function (query) {
    return axios
      .get(url_api + "/customer?async=1&limit=50&query=" + query, {
        timeout: this.timeout,
      })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch(function (error) {
        if (error.code === "ECONNABORTED") {
          Swal.fire({
            position: "center",
            title: "Errore!",
            icon: "error",
            text:
              "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: true,
            confirmButtonText: "Aggiorna",
            customClass: {
              container: "swal_container",
              confirmButton: "btn_modifica",
            },
          }).then((value) => {
            if (value.isConfirmed) window.location.reload();
          });
        }
        console.log(error);
        if (error.response)
          if (error.response.status == "401" || error.response.status == 401) {
            Swal.fire({
              position: "center",
              title: "Errore!",
              icon: "error",
              text:
                "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
              timer: 3000,
              timerProgressBar: true,
              showConfirmButton: true,
              confirmButtonText: "Aggiorna",
              customClass: {
                container: "swal_container",
                confirmButton: "btn_modifica",
              },
            }).then((value) => {
              if (value.isConfirmed) window.location.reload();
            });
          }
      });
  },
  searchAsyncPayments: function (query) {
    return axios
      .get(url_api + "/payment?async=1&limit=50&query=" + query, {
        timeout: this.timeout,
      })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch(function (error) {
        if (error.code === "ECONNABORTED") {
          Swal.fire({
            position: "center",
            title: "Errore!",
            icon: "error",
            text:
              "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: true,
            confirmButtonText: "Aggiorna",
            customClass: {
              container: "swal_container",
              confirmButton: "btn_modifica",
            },
          }).then((value) => {
            if (value.isConfirmed) window.location.reload();
          });
        }
        console.log(error);
        if (error.response)
          if (error.response.status == "401" || error.response.status == 401) {
            Swal.fire({
              position: "center",
              title: "Errore!",
              icon: "error",
              text:
                "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
              timer: 3000,
              timerProgressBar: true,
              showConfirmButton: true,
              confirmButtonText: "Aggiorna",
              customClass: {
                container: "swal_container",
                confirmButton: "btn_modifica",
              },
            }).then((value) => {
              if (value.isConfirmed) window.location.reload();
            });
          }
      });
  },
  searchAsyncProduct: (query, stock, filters) => {
    return axios
      .get(
        url_api +
          "/product?catalog=0&async=1&instock=" +
          stock +
          "&limit=50&offset=0&query=" +
          query +
          filters,
        {}
      )
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch(function (error) {
        if (
          error.code === "ECONNABORTED" ||
          error.message === "Network Error"
        ) {
          Swal.fire({
            position: "center",
            title: "Errore!",
            icon: "error",
            text:
              "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: true,
            confirmButtonText: "Aggiorna",
            customClass: {
              container: "swal_container",
              confirmButton: "btn_modifica",
            },
          }).then((value) => {
            if (value.isConfirmed) window.location.reload();
          });
        }
        console.log(JSON.stringify(error));
        if (error.response)
          if (error.response.status == "401" || error.response.status == 401) {
            Swal.fire({
              position: "center",
              title: "Errore!",
              icon: "error",
              text:
                "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
              timer: 3000,
              timerProgressBar: true,
              showConfirmButton: true,
              confirmButtonText: "Aggiorna",
              customClass: {
                container: "swal_container",
                confirmButton: "btn_modifica",
              },
            }).then((value) => {
              if (value.isConfirmed) window.location.reload();
            });
          }
      });
  },
  updateSalePrice: (id, price) => {
    return axios
      .post(url_api + "/product/" + id + "/update_sale_price/", {
        vendita: Number(price),
      })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch(function (error) {
        if (error.code === "ECONNABORTED") {
          Swal.fire({
            position: "center",
            title: "Errore!",
            icon: "error",
            text:
              "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: true,
            confirmButtonText: "Aggiorna",
            customClass: {
              container: "swal_container",
              confirmButton: "btn_modifica",
            },
          }).then((value) => {
            if (value.isConfirmed) window.location.reload();
          });
        }
        console.log(error);
        if (error.response)
          if (error.response.status == "401" || error.response.status == 401) {
            Swal.fire({
              position: "center",
              title: "Errore!",
              icon: "error",
              text:
                "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
              timer: 3000,
              timerProgressBar: true,
              showConfirmButton: true,
              confirmButtonText: "Aggiorna",
              customClass: {
                container: "swal_container",
                confirmButton: "btn_modifica",
              },
            }).then((value) => {
              if (value.isConfirmed) window.location.reload();
            });
          }
      });
  },
  newOpportunita: function (values) {
    return axios
      .post(url_api + "/lead/new_opportunity", {
        firstname: values.nome,
        lastname: values.cognome,
        email: values.email,
        street: values.street1,
        street2: values.street2,
        city: values.citta,
        phone: values.telefono,
        zip: values.cap,
        tags: values.tags,
        state_id: values.state_id,
        country_id: values.country_id,
        name: values.opportunita,
        probability: Number(values.pz_opportunita),
        planned_revenue: Number(values.prevista),
        origin_id: values.origine.value,
        team_id: values.team_id.value,
        partner_id: values.partner_id,
      })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch(function (error) {
        if (error.code === "ECONNABORTED") {
          Swal.fire({
            position: "center",
            title: "Errore!",
            icon: "error",
            text:
              "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: true,
            confirmButtonText: "Aggiorna",
            customClass: {
              container: "swal_container",
              confirmButton: "btn_modifica",
            },
          }).then((value) => {
            if (value.isConfirmed) window.location.reload();
          });
        }
        if (error.code === "ECONNABORTED") {
          Swal.fire({
            position: "center",
            title: "Errore!",
            icon: "error",
            text:
              "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: true,
            confirmButtonText: "Aggiorna",
            customClass: {
              container: "swal_container",
              confirmButton: "btn_modifica",
            },
          }).then((value) => {
            if (value.isConfirmed) window.location.reload();
          });
        }
        console.log(error);
        if (error.response)
          if (error.response.status == "401" || error.response.status == 401) {
            Swal.fire({
              position: "center",
              title: "Errore!",
              icon: "error",
              text:
                "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
              timer: 3000,
              timerProgressBar: true,
              showConfirmButton: true,
              confirmButtonText: "Aggiorna",
              customClass: {
                container: "swal_container",
                confirmButton: "btn_modifica",
              },
            }).then((value) => {
              if (value.isConfirmed) window.location.reload();
            });
          }
      });
  },
  newCustomer: function (values) {
    return axios
      .post(url_api + "/lead/new_customer", {
        firstname: values.nome,
        lastname: values.cognome,
        email: values.email,
        newsletter_subscribed: values.newsletter,
        street: values.street1,
        street2: values.street2,
        city: values.citta,
        phone: values.telefono,
        zip: values.cap,
        team_id: values.team_id,
        state_id: values.state_id,
        country_id: values.country_id,
      })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch(function (error) {
        if (error.code === "ECONNABORTED") {
          Swal.fire({
            position: "center",
            title: "Errore!",
            icon: "error",
            text:
              "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: true,
            confirmButtonText: "Aggiorna",
            customClass: {
              container: "swal_container",
              confirmButton: "btn_modifica",
            },
          }).then((value) => {
            if (value.isConfirmed) window.location.reload();
          });
        }
      });
  },
  fetchCountries: function (settings) {
    return axios
      .post(url_api + "/toolbox/get_countries", {}, settings && settings)
      .then((res) => {
        const data = res.data;
        return data.rows;
      })
      .catch(function (error) {
        if (error.code === "ECONNABORTED") {
          Swal.fire({
            position: "center",
            title: "Errore!",
            icon: "error",
            text:
              "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: true,
            confirmButtonText: "Aggiorna",
            customClass: {
              container: "swal_container",
              confirmButton: "btn_modifica",
            },
          }).then((value) => {
            if (value.isConfirmed) window.location.reload();
          });
        }
      });
  },
  fetchCurrency: function () {
    return axios
      .post(url_api + "/toolbox/get_currencies", {})
      .then((res) => {
        const data = res.data;
        return data.rows;
      })
      .catch(function (error) {
        if (error.code === "ECONNABORTED") {
          Swal.fire({
            position: "center",
            title: "Errore!",
            icon: "error",
            text:
              "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: true,
            confirmButtonText: "Aggiorna",
            customClass: {
              container: "swal_container",
              confirmButton: "btn_modifica",
            },
          }).then((value) => {
            if (value.isConfirmed) window.location.reload();
          });
        }
      });
  },
  putInDraft: function (id) {
    return axios
      .post(url_api + "/sale-order/draft_quotation_load", {
        order_id: id,
      })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch(function (error) {
        if (error.code === "ECONNABORTED") {
          Swal.fire({
            position: "center",
            title: "Errore!",
            icon: "error",
            text:
              "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: true,
            confirmButtonText: "Aggiorna",
            customClass: {
              container: "swal_container",
              confirmButton: "btn_modifica",
            },
          }).then((value) => {
            if (value.isConfirmed) window.location.reload();
          });
        }
        console.log(error);
        if (error.response)
          if (error.response.status == "401" || error.response.status == 401) {
            Swal.fire({
              position: "center",
              title: "Errore!",
              icon: "error",
              text:
                "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
              timer: 3000,
              timerProgressBar: true,
              showConfirmButton: true,
              confirmButtonText: "Aggiorna",
              customClass: {
                container: "swal_container",
                confirmButton: "btn_modifica",
              },
            }).then((value) => {
              if (value.isConfirmed) window.location.reload();
            });
          }
      });
  },
  prevUnlink: function (order_id, settings) {
    return axios
      .post(
        url_api + "/sale-order/draft_quotation_unlink",
        {
          order_id: order_id,
        },
        settings && settings
      )
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((error) => {
        if (error.code === "ECONNABORTED") {
          Swal.fire({
            position: "center",
            title: "Errore!",
            icon: "error",
            text:
              "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: true,
            confirmButtonText: "Aggiorna",
            customClass: {
              container: "swal_container",
              confirmButton: "btn_modifica",
            },
          }).then((value) => {
            if (value.isConfirmed) window.location.reload();
          });
        }
        console.log(error);
        if (error.response)
          if (error.response.status == "401" || error.response.status == 401) {
            Swal.fire({
              position: "center",
              title: "Errore!",
              icon: "error",
              text:
                "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
              timer: 3000,
              timerProgressBar: true,
              showConfirmButton: true,
              confirmButtonText: "Aggiorna",
              customClass: {
                container: "swal_container",
                confirmButton: "btn_modifica",
              },
            }).then((value) => {
              if (value.isConfirmed) window.location.reload();
            });
          }
      });
  },
  checkoutPay: function (order_id) {
    return axios
      .post(url_api + "/sale-order/draft_quotation_confirm", {
        order_id: order_id,
      })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((error) => {
        if (error.code === "ECONNABORTED") {
          Swal.fire({
            position: "center",
            title: "Errore!",
            icon: "error",
            text:
              "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: true,
            confirmButtonText: "Aggiorna",
            customClass: {
              container: "swal_container",
              confirmButton: "btn_modifica",
            },
          }).then((value) => {
            if (value.isConfirmed) window.location.reload();
          });
        }
        console.log(error);
        if (error.response)
          if (error.response.status == "401" || error.response.status == 401) {
            Swal.fire({
              position: "center",
              title: "Errore!",
              icon: "error",
              text:
                "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
              timer: 3000,
              timerProgressBar: true,
              showConfirmButton: true,
              confirmButtonText: "Aggiorna",
              customClass: {
                container: "swal_container",
                confirmButton: "btn_modifica",
              },
            }).then((value) => {
              if (value.isConfirmed) window.location.reload();
            });
          }
      });
  },
  putPayment: function (value, order_id) {
    return axios
      .post(
        url_api + "/payment",
        order_id
          ? {
              order_id: order_id,
              acquirer_id: value.acquirer_id,
              amount: parseFloat(value.amount),
              currency_id: value.currency_id,
              reference: value.reference,
              partner_id: value.partner_id,
              axerve_payment_type: value.axerve_payment_type,
            }
          : {
              acquirer_id: value.acquirer_id,
              amount: parseFloat(value.amount),
              currency_id: value.currency_id,
              reference: value.reference,
              partner_id: value.partner_id,
              axerve_payment_type: value.axerve_payment_type,
            }
      )
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch(function (error) {
        return error;
      });
  },
  fetchPaymentType: function () {
    return axios
      .post(url_api + "/toolbox/get_payment_types_axerve", {})
      .then((res) => {
        const data = res.data;
        return data.rows;
      })
      .catch(function (error) {
        if (error.code === "ECONNABORTED") {
          Swal.fire({
            position: "center",
            title: "Errore!",
            icon: "error",
            text:
              "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: true,
            confirmButtonText: "Aggiorna",
            customClass: {
              container: "swal_container",
              confirmButton: "btn_modifica",
            },
          }).then((value) => {
            if (value.isConfirmed) window.location.reload();
          });
        }
        console.log(error);
        if (error.response)
          if (error.response.status == "401" || error.response.status == 401) {
            Swal.fire({
              position: "center",
              title: "Errore!",
              icon: "error",
              text:
                "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
              timer: 3000,
              timerProgressBar: true,
              showConfirmButton: true,
              confirmButtonText: "Aggiorna",
              customClass: {
                container: "swal_container",
                confirmButton: "btn_modifica",
              },
            }).then((value) => {
              if (value.isConfirmed) window.location.reload();
            });
          }
      });
  },
  fetchPaymentGateway: function (settings) {
    return axios
      .post(
        url_api + "/toolbox/get_payment_acquirers",
        {},
        settings && settings
      )
      .then((res) => {
        const data = res.data;
        return data.rows;
      })
      .catch(function (error) {
        if (error.code === "ECONNABORTED") {
          Swal.fire({
            position: "center",
            title: "Errore!",
            icon: "error",
            text:
              "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: true,
            confirmButtonText: "Aggiorna",
            customClass: {
              container: "swal_container",
              confirmButton: "btn_modifica",
            },
          }).then((value) => {
            if (value.isConfirmed) window.location.reload();
          });
        }
        console.log(error);
        if (error.response)
          if (error.response.status == "401" || error.response.status == 401) {
            Swal.fire({
              position: "center",
              title: "Errore!",
              icon: "error",
              text:
                "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
              timer: 3000,
              timerProgressBar: true,
              showConfirmButton: true,
              confirmButtonText: "Aggiorna",
              customClass: {
                container: "swal_container",
                confirmButton: "btn_modifica",
              },
            }).then((value) => {
              if (value.isConfirmed) window.location.reload();
            });
          }
      });
  },
  fetchPayments: function (offset, limit, order, settings) {
    return axios
      .get(
        url_api +
          "/payment?offset=" +
          offset +
          "&limit=" +
          limit +
          "&order_by=" +
          (order ? order : ""),
        settings && settings
      )
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch(function (error) {
        if (error.code === "ECONNABORTED") {
          Swal.fire({
            position: "center",
            title: "Errore!",
            icon: "error",
            text:
              "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: true,
            confirmButtonText: "Aggiorna",
            customClass: {
              container: "swal_container",
              confirmButton: "btn_modifica",
            },
          }).then((value) => {
            if (value.isConfirmed) window.location.reload();
          });
        }
        console.log(error);
        if (error.response)
          if (error.response.status == "401" || error.response.status == 401) {
            Swal.fire({
              position: "center",
              title: "Errore!",
              icon: "error",
              text:
                "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
              timer: 3000,
              timerProgressBar: true,
              showConfirmButton: true,
              confirmButtonText: "Aggiorna",
              customClass: {
                container: "swal_container",
                confirmButton: "btn_modifica",
              },
            }).then((value) => {
              if (value.isConfirmed) window.location.reload();
            });
          }
      });
  },
  getLead: function (offset, limit, order, settings) {
    return axios
      .get(
        url_api +
          "/lead?offset=" +
          offset +
          "&limit=" +
          limit +
          (order ? "&order_by=" + order : ""),
        settings && settings
      )
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch(function (error) {
        if (error.code === "ECONNABORTED") {
          Swal.fire({
            position: "center",
            title: "Errore!",
            icon: "error",
            text:
              "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: true,
            confirmButtonText: "Aggiorna",
            customClass: {
              container: "swal_container",
              confirmButton: "btn_modifica",
            },
          }).then((value) => {
            if (value.isConfirmed) window.location.reload();
          });
        }
        console.log(error);
        if (error.response)
          if (error.response.status == "401" || error.response.status == 401) {
            Swal.fire({
              position: "center",
              title: "Errore!",
              icon: "error",
              text:
                "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
              timer: 3000,
              timerProgressBar: true,
              showConfirmButton: true,
              confirmButtonText: "Aggiorna",
              customClass: {
                container: "swal_container",
                confirmButton: "btn_modifica",
              },
            }).then((value) => {
              if (value.isConfirmed) window.location.reload();
            });
          }
      });
  },
  getTemplates: function (model, settings) {
    return axios
      .post(
        url_api + "/toolbox/get_mail_templates",
        { res_model: model },
        settings && settings
      )
      .then((res) => {
        const data = res.data;
        return data;
      });
  },
  sendEmail: function (
    body_html,
    partner_ids,
    res_id,
    subject,
    attachment_ids,
    res_model,
    settings
  ) {
    return axios
      .post(
        url_api + "/toolbox/send_mail",
        {
          res_model,
          res_id,
          partner_ids,
          attachment_ids,
          subject,
          body_html,
        },
        settings && settings
      )
      .then((res) => {
        const data = res.data;
        return data;
      });
  },
  fetchStates: function (id, settings) {
    return axios
      .post(
        url_api + "/toolbox/get_states",
        { country_id: id },
        settings && settings
      )
      .then((res) => {
        const data = res.data;
        return data.rows;
      })
      .catch(function (error) {
        if (error.code === "ECONNABORTED") {
          Swal.fire({
            position: "center",
            title: "Errore!",
            icon: "error",
            text:
              "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: true,
            confirmButtonText: "Aggiorna",
            customClass: {
              container: "swal_container",
              confirmButton: "btn_modifica",
            },
          }).then((value) => {
            if (value.isConfirmed) window.location.reload();
          });
        }
        console.log(error);
        if (error.response)
          if (error.response.status == "401" || error.response.status == 401) {
            Swal.fire({
              position: "center",
              title: "Errore!",
              icon: "error",
              text:
                "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
              timer: 3000,
              timerProgressBar: true,
              showConfirmButton: true,
              confirmButtonText: "Aggiorna",
              customClass: {
                container: "swal_container",
                confirmButton: "btn_modifica",
              },
            }).then((value) => {
              if (value.isConfirmed) window.location.reload();
            });
          }
      });
  },
  getTags: function () {
    return axios
      .post(url_api + "/toolbox/get_crm_lead_tags", {})
      .then((res) => {
        const data = res.data;
        return data.rows;
      });
  },
  fetchTeams: function () {
    return axios
      .post(url_api + "/toolbox/get_teams", {})
      .then((res) => {
        const data = res.data;
        return data.rows;
      })
      .catch(function (error) {
        if (error.code === "ECONNABORTED") {
          Swal.fire({
            position: "center",
            title: "Errore!",
            icon: "error",
            text:
              "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: true,
            confirmButtonText: "Aggiorna",
            customClass: {
              container: "swal_container",
              confirmButton: "btn_modifica",
            },
          }).then((value) => {
            if (value.isConfirmed) window.location.reload();
          });
        }
        console.log(error);
        if (error.response)
          if (error.response.status == "401" || error.response.status == 401) {
            Swal.fire({
              position: "center",
              title: "Errore!",
              icon: "error",
              text:
                "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
              timer: 3000,
              timerProgressBar: true,
              showConfirmButton: true,
              confirmButtonText: "Aggiorna",
              customClass: {
                container: "swal_container",
                confirmButton: "btn_modifica",
              },
            }).then((value) => {
              if (value.isConfirmed) window.location.reload();
            });
          }
      });
  },

  fetchMsg: function () {
    return axios
      .get(url_api + "/lead")
      .then((res) => {
        const data = res.data;
        return data.txt;
      })
      .catch(function (error) {
        if (error.code === "ECONNABORTED") {
          Swal.fire({
            position: "center",
            title: "Errore!",
            icon: "error",
            text:
              "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: true,
            confirmButtonText: "Aggiorna",
            customClass: {
              container: "swal_container",
              confirmButton: "btn_modifica",
            },
          }).then((value) => {
            if (value.isConfirmed) window.location.reload();
          });
        }
        console.log(error);
        if (error.response)
          if (error.response.status == "401" || error.response.status == 401) {
            Swal.fire({
              position: "center",
              title: "Errore!",
              icon: "error",
              text:
                "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
              timer: 3000,
              timerProgressBar: true,
              showConfirmButton: true,
              confirmButtonText: "Aggiorna",
              customClass: {
                container: "swal_container",
                confirmButton: "btn_modifica",
              },
            }).then((value) => {
              if (value.isConfirmed) window.location.reload();
            });
          }
      });
  },
  getFolder: function (id) {
    return axios
      .get(url_api + "/folder/" + id)
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch(function (error) {
        if (error.code === "ECONNABORTED") {
          Swal.fire({
            position: "center",
            title: "Errore!",
            icon: "error",
            text:
              "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: true,
            confirmButtonText: "Aggiorna",
            customClass: {
              container: "swal_container",
              confirmButton: "btn_modifica",
            },
          }).then((value) => {
            if (value.isConfirmed) window.location.reload();
          });
        }
        console.log(error);
        if (error.response)
          if (error.response.status == "401" || error.response.status == 401) {
            Swal.fire({
              position: "center",
              title: "Errore!",
              icon: "error",
              text:
                "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
              timer: 3000,
              timerProgressBar: true,
              showConfirmButton: true,
              confirmButtonText: "Aggiorna",
              customClass: {
                container: "swal_container",
                confirmButton: "btn_modifica",
              },
            }).then((value) => {
              if (value.isConfirmed) window.location.reload();
            });
          }
      });
  },
  fetchBrands: function () {
    return axios
      .post(url_api + "/toolbox/get_brands")
      .then((res) => {
        const data = res.data;
        return data.rows;
      })
      .catch(function (error) {
        if (error.code === "ECONNABORTED") {
          Swal.fire({
            position: "center",
            title: "Errore!",
            icon: "error",
            text:
              "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: true,
            confirmButtonText: "Aggiorna",
            customClass: {
              container: "swal_container",
              confirmButton: "btn_modifica",
            },
          }).then((value) => {
            if (value.isConfirmed) window.location.reload();
          });
        }
        console.log(error);
        if (error.response)
          if (error.response.status == "401" || error.response.status == 401) {
            Swal.fire({
              position: "center",
              title: "Errore!",
              icon: "error",
              text:
                "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
              timer: 3000,
              timerProgressBar: true,
              showConfirmButton: true,
              confirmButtonText: "Aggiorna",
              customClass: {
                container: "swal_container",
                confirmButton: "btn_modifica",
              },
            }).then((value) => {
              if (value.isConfirmed) window.location.reload();
            });
          }
      });
  },
  fetchCategories: function () {
    return axios
      .post(url_api + "/toolbox/get_categories")
      .then((res) => {
        const data = res.data;
        return data.rows;
      })
      .catch(function (error) {
        if (error.code === "ECONNABORTED") {
          Swal.fire({
            position: "center",
            title: "Errore!",
            icon: "error",
            text:
              "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: true,
            confirmButtonText: "Aggiorna",
            customClass: {
              container: "swal_container",
              confirmButton: "btn_modifica",
            },
          }).then((value) => {
            if (value.isConfirmed) window.location.reload();
          });
        }
        console.log(error);
        if (error.response)
          if (error.response.status == "401" || error.response.status == 401) {
            Swal.fire({
              position: "center",
              title: "Errore!",
              icon: "error",
              text:
                "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
              timer: 3000,
              timerProgressBar: true,
              showConfirmButton: true,
              confirmButtonText: "Aggiorna",
              customClass: {
                container: "swal_container",
                confirmButton: "btn_modifica",
              },
            }).then((value) => {
              if (value.isConfirmed) window.location.reload();
            });
          }
      });
  },

  fetchDraft: function (id) {
    return axios
      .post(url_api + "/sale-order/preventivatore_read", {
        order_id: id,
      })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch(function (error) {
        if (error.response) {
          if (
            error.response.status == "400" ||
            error.response.status == 400 ||
            error.response.status == 404
          ) {
            Swal.fire({
              position: "center",
              icon: "attention",
              html:
                "<h1>Attenzione</h1><p>Il Preventivo non è stato trovato, chiudi questo popup per crearne uno nuovo o vai alla pagina delle bozze</p>",
              timerProgressBar: true,
              showConfirmButton: true,
              confirmButtonText: "Vai alle Bozze",
              customClass: {
                container: "swal_container",
                confirmButton: "btn_modifica",
              },
              timer: 5000,
              onClose: () => {
                window.location.replace("/preventivatore/new");
              },
            }).then((value) => {
              if (value.isConfirmed)
                window.location.replace("/preventivatore/drafts");
            });
            if (
              error.response.status == "401" ||
              error.response.status == 401
            ) {
              Swal.fire({
                position: "center",
                title: "Errore!",
                icon: "error",
                text:
                  "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
                timer: 3000,
                timerProgressBar: true,
                showConfirmButton: true,
                confirmButtonText: "Aggiorna",
                customClass: {
                  container: "swal_container",
                  confirmButton: "btn_modifica",
                },
              }).then((value) => {
                if (value.isConfirmed) window.location.reload();
              });
            }
          }
        }
      });
  },
  fetchDraftList: function (prev, offset, limit, settings) {
    return axios
      .get(
        url_api +
          "/sale-order?p=" +
          prev +
          "&offset=" +
          offset +
          "&limit=" +
          limit +
          "&draft=1",
        settings
          ? settings
          : {
              timeout: this.timeout,
            }
      )
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch(function (error) {
        if (error.code === "ECONNABORTED") {
          Swal.fire({
            position: "center",
            title: "Errore!",
            icon: "error",
            text:
              "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: true,
            confirmButtonText: "Aggiorna",
            customClass: {
              container: "swal_container",
              confirmButton: "btn_modifica",
            },
          }).then((value) => {
            if (value.isConfirmed) window.location.reload();
          });
        }
        console.log(error);
        if (error.response)
          if (error.response.status == "401" || error.response.status == 401) {
            Swal.fire({
              position: "center",
              title: "Errore!",
              icon: "error",
              text:
                "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
              timer: 3000,
              timerProgressBar: true,
              showConfirmButton: true,
              confirmButtonText: "Aggiorna",
              customClass: {
                container: "swal_container",
                confirmButton: "btn_modifica",
              },
            }).then((value) => {
              if (value.isConfirmed) window.location.reload();
            });
          }
      });
  },

  saveDraft: function (
    floors,
    sections,
    items,
    details,
    anagrafy,
    phase,
    nome_prev,
    order_id,
    settings
  ) {
    anagrafy.sped_state =
      anagrafy.sped_state.value < 0 ? null : anagrafy.sped_state;
    anagrafy.fatt_state =
      anagrafy.fatt_state.value < 0 ? null : anagrafy.fatt_state;
    return axios
      .post(
        url_api + "/sale-order/preventivatore_save",
        {
          floors: floors,
          sections: sections,
          items: items,
          details: details,
          nome_prev: nome_prev,
          anagrafy: anagrafy,
          phase: phase,
          order_id: order_id,
        },
        settings && settings
      )
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch(function (error) {
        console.log("errora", JSON.stringify(error.response.data));
        if (error.code === "ECONNABORTED") {
          Swal.fire({
            position: "center",
            title: "Errore!",
            icon: "error",
            text:
              "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: true,
            confirmButtonText: "Aggiorna",
            customClass: {
              container: "swal_container",
              confirmButton: "btn_modifica",
            },
          }).then((value) => {
            if (value.isConfirmed) window.location.reload();
          });
        }

        if (error.response) {
          if (error.response.status == "401" || error.response.status == 401) {
            Swal.fire({
              position: "center",
              title: "Errore!",
              icon: "error",
              text:
                "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
              timer: 3000,
              timerProgressBar: true,
              showConfirmButton: true,
              confirmButtonText: "Aggiorna",
              customClass: {
                container: "swal_container",
                confirmButton: "btn_modifica",
              },
            }).then((value) => {
              if (value.isConfirmed) window.location.reload();
            });
          } else if (error.response.data.code === 400) {
            Swal.fire({
              position: "center",
              title: "Errore!",
              icon: "error",
              html:
                error.response.data.description +
                " per l'oggetto " +
                items[items.length - 1].sku,
              timer: 3000,
              timerProgressBar: true,
              showConfirmButton: false,
              confirmButtonText: "Aggiorna",
              customClass: {
                container: "swal_container",
                confirmButton: "btn_modifica",
              },
            });
          }
        }
      });
  },
  savePreventivatore: function (floors, sections, items, anagrafy) {
    return axios
      .post(url_api + "/prev/create", {
        floors: floors,
        sections: sections,
        items: items,
        anagrafy: anagrafy,
      })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch(function (error) {
        if (error.code === "ECONNABORTED") {
          Swal.fire({
            position: "center",
            title: "Errore!",
            icon: "error",
            text:
              "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: true,
            confirmButtonText: "Aggiorna",
            customClass: {
              container: "swal_container",
              confirmButton: "btn_modifica",
            },
          }).then((value) => {
            if (value.isConfirmed) window.location.reload();
          });
        }
        console.log(error);
        if (error.response)
          if (error.response.status == "401" || error.response.status == 401) {
            Swal.fire({
              position: "center",
              title: "Errore!",
              icon: "error",
              text:
                "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
              timer: 3000,
              timerProgressBar: true,
              showConfirmButton: true,
              confirmButtonText: "Aggiorna",
              customClass: {
                container: "swal_container",
                confirmButton: "btn_modifica",
              },
            }).then((value) => {
              if (value.isConfirmed) window.location.reload();
            });
          }
      });
  },
  searchFolder: function (query) {
    return axios
      .get(url_api + "/folder?query=" + query)
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch(function (error) {
        if (error.code === "ECONNABORTED") {
          Swal.fire({
            position: "center",
            title: "Errore!",
            icon: "error",
            text:
              "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: true,
            confirmButtonText: "Aggiorna",
            customClass: {
              container: "swal_container",
              confirmButton: "btn_modifica",
            },
          }).then((value) => {
            if (value.isConfirmed) window.location.reload();
          });
        }
        console.log(error);
        if (error.response)
          if (error.response.status == "401" || error.response.status == 401) {
            Swal.fire({
              position: "center",
              title: "Errore!",
              icon: "error",
              text:
                "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
              timer: 3000,
              timerProgressBar: true,
              showConfirmButton: true,
              confirmButtonText: "Aggiorna",
              customClass: {
                container: "swal_container",
                confirmButton: "btn_modifica",
              },
            }).then((value) => {
              if (value.isConfirmed) window.location.reload();
            });
          }
      });
  },
  uploadImg: function (foto) {
    return axios
      .post(url_api + "/toolbox/upload_image", {
        name: foto.name,
        datas: foto.datas,
      })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((error) => {
        console.log("uploadImg", JSON.stringify(error));
      });
  },
  getPrintOpt: function (id, model) {
    return axios
      .post(url_api + "/toolbox/" + id + "/get_reports", { res_model: model })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch(function (error) {
        if (error.code === "ECONNABORTED") {
          Swal.fire({
            position: "center",
            title: "Errore!",
            icon: "error",
            text:
              "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: true,
            confirmButtonText: "Aggiorna",
            customClass: {
              container: "swal_container",
              confirmButton: "btn_modifica",
            },
          }).then((value) => {
            if (value.isConfirmed) window.location.reload();
          });
        }
        console.log(error);
        if (error.response)
          if (error.response.status == "401" || error.response.status == 401) {
            Swal.fire({
              position: "center",
              title: "Errore!",
              icon: "error",
              text:
                "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
              timer: 3000,
              timerProgressBar: true,
              showConfirmButton: true,
              confirmButtonText: "Aggiorna",
              customClass: {
                container: "swal_container",
                confirmButton: "btn_modifica",
              },
            }).then((value) => {
              if (value.isConfirmed) window.location.reload();
            });
          }
      });
  },
  setReportOptions: function (id, model, target) {
    return axios
      .post(url_api + "/toolbox/" + id + "/set_report_options", {
        res_model: model,
        target,
      })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch(function (error) {
        if (error.code === "ECONNABORTED") {
          Swal.fire({
            position: "center",
            title: "Errore!",
            icon: "error",
            text:
              "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: true,
            confirmButtonText: "Aggiorna",
            customClass: {
              container: "swal_container",
              confirmButton: "btn_modifica",
            },
          }).then((value) => {
            if (value.isConfirmed) window.location.reload();
          });
        }
        console.log(error);
        if (error.response)
          if (error.response.status == "401" || error.response.status == 401) {
            Swal.fire({
              position: "center",
              title: "Errore!",
              icon: "error",
              text:
                "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
              timer: 3000,
              timerProgressBar: true,
              showConfirmButton: true,
              confirmButtonText: "Aggiorna",
              customClass: {
                container: "swal_container",
                confirmButton: "btn_modifica",
              },
            }).then((value) => {
              if (value.isConfirmed) window.location.reload();
            });
          }
      });
  },
  logout: function () {
    return axios
      .get(this.auth_api + "/rws/session/logout", {
        timeout: this.timeout,
      })
      .then((res) => {
        const data = res.data;
        console.log(data);
        return data;
      })
      .catch(function (error) {
        if (error.code === "ECONNABORTED") {
          Swal.fire({
            position: "center",
            title: "Errore!",
            icon: "error",
            text:
              "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: true,
            confirmButtonText: "Aggiorna",
            customClass: {
              container: "swal_container",
              confirmButton: "btn_modifica",
            },
          }).then((value) => {
            if (value.isConfirmed) window.location.reload();
          });
        }
        console.log(error);
        if (error.response)
          if (error.response.status == "401" || error.response.status == 401) {
            Swal.fire({
              position: "center",
              title: "Errore!",
              icon: "error",
              text:
                "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
              timer: 3000,
              timerProgressBar: true,
              showConfirmButton: true,
              confirmButtonText: "Aggiorna",
              customClass: {
                container: "swal_container",
                confirmButton: "btn_modifica",
              },
            }).then((value) => {
              if (value.isConfirmed) window.location.reload();
            });
          }
      });
  },
  auth_api: BACKEND_URL,
  timeout: 10000,
  body: {
    db: DB_NAME,
  },
};
export default api;
