import React, { Component } from "react";
import { Animated } from "react-animated-css";
import "./Popup.css";
import PropTypes from "prop-types";
import $ from "jquery";
class Popup extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.chargeWidth.bind(this);
  }
  componentDidMount() {
    this.chargeWidth();
    window.addEventListener("resize", this.chargeWidth);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.isVisible  !== this.props.isVisible ) {
      this.chargeWidth()
    }
  }
  chargeWidth = () => {
    var element = $("#pop" + this.props.id);
    element.css("margin-left", (element.innerWidth() / 2) * -1);
    element.css("margin-top", (element.innerHeight() / 2) * -1);
  };
  render() {
    
    return (
      <div style={{ borderRadius:'5px',transition:'all 0.3s ease-in-out',opacity:this.props.isVisible ? "1" : "0", visibility:this.props.isVisible ? 'visible' : 'hidden'}}>
        <Animated isVisible={this.props.isVisible} animationIn={"fadeIn"}>
          <div
            onClick={() =>this.props.handlePopup() }
            className="obscure"
          ></div>
        </Animated>
        <Animated isVisible={this.props.isVisible} animationIn="fadeIn">
          <div
            style={{ width: this.props.width ? this.props.width : "auto" }}
            id={"pop" + this.props.id}
            className="popup"
          >
            {this.props.children}
          </div>
        </Animated>
      </div>
    );
  }
}

Popup.propTypes = {
  isVisible: PropTypes.bool,
  width: PropTypes.string,
  id: PropTypes.string,
  handlePopup: PropTypes.func,
};

export default Popup;
