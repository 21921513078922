import React, { Component } from "react";
import logo from "../assets/logo-mohd.png";
import { Link } from "react-router-dom";
import "./Header.css";
import SwipableSidebar from "./SwipableSidebar";
export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = { width: 0, height: 0, hamb: false };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }
  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }
  handleSide() {
    this.setState({ hamb: !this.state.hamb });
  }
  render() {
    if (this.props.logged)
      return (
        <div className="header">
          <div className="head_inner">
            <div className="logomohd">
              <Link to="/">
                <img src={logo} alt="Logo Mohd"></img>
              </Link>
            </div>
          </div>
        </div>
      );
    else
      return (
        <div className="header">
          <div className="head_inner">
            {this.state.width > 1020 ? (
              <nav>
                <ul>
                  <Link to="/">
                    {" "}
                    <li>La mia bacheca</li>
                  </Link>
                  <li>
                    <Link to="/logout">
                      {" "}
                      {this.state.width < 426 ? (
                        <i className="fa fa-power-off" aria-hidden="true" />
                      ) : (
                        "Log out"
                      )}
                    </Link>
                  </li>
                </ul>
              </nav>
            ) : (
              <React.Fragment>
                <SwipableSidebar
                  handleOpen={() => this.handleSide()}
                  shouldOpen={this.state.hamb}
                  permission={this.props.permission}
                />
                <nav
                  onClick={() => this.handleSide()}
                  className={this.state.hamb ? "hamburger open" : "hamburger"}
                >
                  <ul>
                    <li></li>
                    <li></li>
                    <li></li>
                  </ul>
                </nav>
                <nav>
                  <ul>
                    <li>
                      <Link to="/">
                        {this.state.width < 426 ? (
                          <i className="fa fa-user" aria-hidden="true" />
                        ) : (
                          "La mia bacheca"
                        )}
                      </Link>
                    </li>
                    <li>
                      <Link to="/logout">
                        {" "}
                        {this.state.width < 426 ? (
                          <i className="fa fa-power-off" aria-hidden="true" />
                        ) : (
                          "Log out"
                        )}
                      </Link>
                    </li>
                  </ul>
                </nav>
              </React.Fragment>
            )}

            <div className="logomohd">
              <Link to="/">
                <img src={logo} alt="Logo Mohd"></img>
              </Link>
            </div>
          </div>
        </div>
      );
  }
}
