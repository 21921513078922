import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import "./SwipableSidebar.css";
import $ from "jquery";
import { Link } from "react-router-dom";
const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
});

export default class SwipableSidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = { top: false, actual: window.location.pathname };
  }
  toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    this.props.handleOpen();
    this.setState({ ...this.state, [anchor]: open });
  };
  followLink(target) {
    this.setState({ actual: target });
    this.toggleDrawer("top", false);
    this.props.handleOpen();
  }
  render() {
    return (
      <React.Fragment>
        {this.props.permission ? (
          <SwipeableDrawer
            anchor={"top"}
            open={this.state["top"] || this.props.shouldOpen}
            onClose={this.toggleDrawer("top", false)}
            onOpen={this.toggleDrawer("top", true)}
          >
            <div
              style={{ width: "100%", height: "100%" }}
              className="sidebar_mobile"
            >
              <ul>
                <li
                  className={
                    this.state.actual.includes("customer/quotations") > 0
                      ? "nav-item current"
                      : "nav-item"
                  }
                >
                  <Link
                    onClick={() => this.followLink("/customer/quotations")}
                    to="/customer/quotations"
                  >
                    <strong>I miei preventivi</strong>
                  </Link>
                </li>
                <li
                  className={
                    this.state.actual.includes("/customer/orders") > 0
                      ? "nav-item current"
                      : "nav-item"
                  }
                >
                  <Link
                    onClick={() => this.followLink("/customer/orders")}
                    to="/customer/orders"
                  >
                    <strong>I miei ordini</strong>
                  </Link>
                </li>
                {this.props.permission.crm_inserimento_veloce && (
                  <li
                    className={
                      this.state.actual.includes("/crm/registra-visita") > 0
                        ? "nav-item current"
                        : "nav-item"
                    }
                  >
                    <Link
                      onClick={() => this.followLink("/crm/registra-visita")}
                      to="/crm/registra-visita"
                    >
                      <strong>Aggiungi cliente</strong>
                    </Link>
                  </li>
                )}
                {this.props.permission.preventivatore && (
                  <li
                    className={
                      this.state.actual.includes("/Preventivatore") > 0
                        ? "nav-item current"
                        : "nav-item"
                    }
                  >
                    <Link
                      onClick={() => this.followLink("/Preventivatore")}
                      to="/Preventivatore"
                    >
                      <strong>Preventivatore</strong>
                    </Link>
                  </li>
                )}
              </ul>
            </div>
          </SwipeableDrawer>
        ) : null}
      </React.Fragment>
    );
  }
}
