import React, { Component } from "react";
import { Formik } from "formik";
import "./NewCliente.css";
import api from "../../../config";

import Swal from "sweetalert2";
import Select from "react-select";
export default class NewCliente extends Component {
  constructor(props) {
    super(props);
    this.state = {
      done: false,
      customTxt: "",
      loading: false,
      team_id:this.props.team_id,
      countries: [],
      sel_country: { value: -11, label: "Seleziona Nazione..." },
      sel_state: { value: -11, label: "Provincia....." },
    };
  }
  componentDidMount() {
    api.fetchMsg().then((body) => {
      if(body)
    {  body = body.replace(/(onclick)+=+("+|'+).*("+|'+)/gm, "onclick=''");
      body = body.replace(/<a(.*)"/gm, "<a href='#'");
      this.setState({ customTxt: body });}
    });
    this.setState({ loadingCountry: true, loadingTeams: true });
    api.fetchCountries().then((values) => {
      this.setState({ countries: values, loadingCountry: false });
    });

    api.fetchTeams().then((values) => {
      this.setState({ teams: values, loadingTeams: false });
    });
  }
  fetchState(country_id) {
    this.setState({ loadingState: true });
    if (country_id) {
      api.fetchStates(country_id).then((values) => {
        this.setState({ states: values, loadingState: false });
      });
    }
  }

  render() {
    return (
      <div className="page_container left_align">
        <h1 className="page_title">Registra Cliente</h1>
        <p
          className="uppercase subtitle"
          dangerouslySetInnerHTML={{
            __html: this.state.customTxt,
          }}
        ></p>
        <Formik
          initialValues={{
            nome: "",
            cognome: "",
            email: "",
            privacy: false,
            telefono: "",
            newsletter: false,
            street1: "",
            street2: "",
            cap: "",
            citta: "",
            team_id:this.state.team_id.id,
          }}
          validate={(values, actions) => {
            const errors = {};
            if (values.nome.length < 3) {
              errors.nome = "*Nome non valido";
            }
            if (
              values.cognome.length < 3
            ) {
              errors.cognome = "*Cognome non valido";
            }
            if (values.telefono.length < 6) {
              errors.telefono = "*Inserisci un numero di telefono valido";
            }
            if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = "*Email non valida";
            }
            if (values.privacy === false) {
              errors.privacy = "Devi accettare i termini e le condizioni";
            }
            if (errors.length > 0) {
              actions.setSubmitting(true);
            }
            return errors;
          }}
          onSubmit={(values, actions) => {
            actions.setSubmitting(false);
            this.setState({ loading: true });
            api.newCustomer(values).then((data) => {
              if (data) {
                if (data.code === "200" || data.code === 200) {
                  Swal.fire({
                    position: "top-end",
                    icon: "success",
                    html:
                      "<h1>Utente registrato</h1> <p>L'utente <b>" +
                      values.nome +
                      " " +
                      values.cognome +
                      " </b>è stato registrato!<br/></p>",
                    showConfirmButton: false,
                    timerProgressBar: true,
                    customClass: { container: "swal_container" },
                    onClose: actions.resetForm({}),
                  });
                }
              } else {
                Swal.fire({
                  position: "top-end",
                  icon: "error",
                  html:
                    "<div><h1>Errore</h1> <p>Non è stato possibile elaborare la richiesta, riprova o controlla la tua connessione</p> </div>",
                  showConfirmButton: false,
                  timerProgressBar: true,
                  timer: 2000,
                  customClass: { container: "swal_container" },
                });
              }
              this.setState({ loading: false });
            });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <div className="newClient_container">
              <div
                id="social-login-popup"
                className="prev_card"
                style={{ width: "100%" }}
              >
                <form
                  onSubmit={handleSubmit}
                  className="form-customer-create"
                  id="social-form-create"
                  noValidate="novalidate"
                >
                  <div className="table_div">
                    <div className="row_div">
                      <div className="cell_div">
                        <p className="subtitle" htmlFor="firstname">
                          <span>Nome *</span>
                        </p>
                        <div className="control">
                          {/*  <input
                                type="text"
                                id="firstname"
                                name="firstname"
                                defaultValue
                                title="Nome"
                                className="input-text required-entry"
                                data-validate="{required:true}"
                                aria-required="true"
                              /> */}
                          <input
                            type="text"
                            name="nome"
                            id="nome"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.nome}
                            className="input-text required-entry"
                          />
                          {errors.nome && touched.nome && (
                            <div className="invalid-feedback">
                              {errors.nome}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="cell_div">
                        <p className="subtitle" htmlFor="lastname">
                          <span>Cognome *</span>
                        </p>
                        <div className="control">
                          {/*  <input
                                type="text"
                                id="lastname"
                                name="lastname"
                                defaultValue
                                title="Cognome"
                                className="input-text required-entry"
                                data-validate="{required:true}"
                                aria-required="true"
                              /> */}
                          <input
                            type="text"
                            name="cognome"
                            id="cognome"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.cognome}
                            className="input-text required-entry"
                          />

                          {errors.cognome && touched.cognome && (
                            <div className="invalid-feedback">
                              {errors.cognome}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row_div">
                      <div className="cell_div">
                        <p className="subtitle">
                          <span>Email *</span>
                        </p>
                        <div className="control">
                          <input
                            type="email"
                            name="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            className="input-text"
                          />

                          {errors.email && touched.email && (
                            <div className="invalid-feedback">
                              {errors.email}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="cell_div">
                        <p className="subtitle">
                          <span>Telefono *</span>
                        </p>
                        <div className="control">
                          <input
                            type="text"
                            name="telefono"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.telefono}
                            className="input-text"
                          />

                          {errors.telefono && touched.telefono && (
                            <div className="invalid-feedback">
                              {errors.telefono}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="table_div">
                    <div className="row_div">
                      <div className="cell_div longone">
                        <p className="subtitle">
                          <span>Indirizzo 1</span>
                        </p>
                        <div className="control">
                          <input
                            type="text"
                            name="street1"
                            id="street1"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.street1}
                            className="input-text required-entry"
                          />

                          {errors.street1 && touched.street1 && (
                            <div className="invalid-feedback">
                              {errors.street1}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="cell_div">
                        <p className="subtitle" htmlFor="cap">
                          <span>Cap</span>
                        </p>
                        <div className="control">
                          <input
                            type="text"
                            name="cap"
                            id="cap"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.cap}
                            className="input-text required-entry"
                          />

                          {errors.cap && touched.cap && (
                            <div className="invalid-feedback">{errors.cap}</div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row_div">
                      <div className="cell_div longone">
                        <p className="subtitle">
                          <span>Indirizzo 2</span>
                        </p>
                        <div className="control">
                          <input
                            type="text"
                            name="street2"
                            id="street2"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.street2}
                            className="input-text required-entry"
                          />

                          {errors.street2 && touched.street2 && (
                            <div className="invalid-feedback">
                              {errors.street2}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row_div">
                      <div className="cell_div">
                        <p className="subtitle">
                          <span>Città</span>
                        </p>
                        <div className="control">
                          <input
                            type="text"
                            name="citta"
                            id="citta"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.citta}
                            className="input-text required-entry"
                          />

                          {errors.citta && touched.citta && (
                            <div className="invalid-feedback">
                              {errors.citta}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="table_div" style={{ marginTop: "0px" }}>
                    <div className="row_div">
                      <div className="cell_div fixedLength">
                        <p className="subtitle">Nazione</p>
                        <Select
                           styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                          menuPortalTarget={document.body}
                          isClearable
                          placeholder={this.state.sel_country.label}
                          options={this.state.countries}
                          isLoading={this.state.loadingCountry}
                          value={this.state.sel_country.value}
                          onChange={(e) => {
                            if (e) {
                              values.country_id = e.value;
                              this.setState({
                                sel_country: e,
                                sel_state: {
                                  value: -1,
                                  label: "Provincia.....",
                                },
                              });
                              this.fetchState(e.value);
                            } else {
                              this.fetchState(-99);
                              this.setState({
                                sel_country: {
                                  value: -11,
                                  label: "Seleziona Nazione...",
                                },
                                sel_state: {
                                  value: -1,
                                  label: "Provincia.....",
                                },
                              });
                            }
                          }}
                        />
                      </div>
                      <div className="cell_div">
                        <p className="subtitle">Provincia/Regione</p>
                        <Select
                           styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                          menuPortalTarget={document.body}
                          isClearable
                          placeholder={this.state.sel_state.label}
                          options={this.state.states}
                          value={this.state.sel_state.value}
                          onChange={(e) => {
                            if (e) {
                              values.state_id = e.value;

                              this.setState({
                                sel_state: e,
                              });
                            }
                          }}
                          isLoading={this.state.loadingState}
                        />
                      </div>
                    </div>
                    <div className="row_div">
                      <div className="cell_div">
                        <p className="subtitle">Team</p>
                        <Select
                           styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                          menuPortalTarget={document.body}
                          placeholder={this.state.team_id ? this.state.team_id.name :"Seleziona il Team..." }
                          options={this.state.teams}
                          onChange={(e) => {
                            if (e) {
                              values.team_id = e.value;
                            }
                            this.setState({team_id:e})
                          }}
                          isLoading={this.state.loadingState}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="table_div">
                    <div className="row_div">
                      <div className="cell_div left_align">
                        <div className="field choice newsletter">
                          <span
                            className={
                              values.newsletter
                                ? "jcf-checkbox jcf-checked jcf-focus"
                                : "jcf-checkbox jcf-unchecked"
                            }
                            onFocus={() =>
                              document
                                .getElementById("privacy")
                                .classList.remove("jcf-focus")
                            }
                            id="newsletter"
                          >
                            <span />

                            <input
                              type="checkbox"
                              className="checkbox"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name="newsletter"
                              value={values.newsletter}
                              style={{
                                position: "absolute",
                                height: "100%",
                                width: "100%",
                                opacity: 0,
                                margin: "0px",
                              }}
                            />
                          </span>
                          <label htmlFor="is_subscribed" className="label">
                            <span>Iscrivi alla Newsletter</span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row_div">
                      <div className="cell_div left_align">
                        <div className="field choice newsletter">
                          <span
                            id="privacy"
                            className={
                              values.privacy
                                ? "jcf-checkbox jcf-checked jcf-focus"
                                : "jcf-checkbox jcf-unchecked"
                            }
                            onFocus={() =>
                              document
                                .getElementById("newsletter")
                                .classList.remove("jcf-focus")
                            }
                          >
                            <span />

                            <input
                              type="checkbox"
                              className="checkbox"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name="privacy"
                              value={values.privacy}
                              style={{
                                position: "absolute",
                                height: "100%",
                                width: "100%",
                                opacity: 0,
                                margin: "0px",
                              }}
                            />
                          </span>
                          <label htmlFor="privacy" className="label">
                            <span>
                              Accetta le{" "}
                              <a
                                href="https://shop.mohd.it/it/privacy-policy"
                                rel="noopener noreferrer"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Privacy Policy
                              </a>
                            </span>
                          </label>
                          {errors.privacy && touched.privacy && (
                            <div className="invalid-feedback">
                              {errors.privacy}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row_div">
                      <div className="cell_div">
                        <div className="actions-toolbar">
                          <div className="primary">
                            <button
                              className=" create button btn-standard btn-green"
                              type="submit"
                              disabled={isSubmitting || this.state.loading}
                            >
                              {this.state.loading ? (
                                <div className="loginloader"></div>
                              ) : (
                                <span>Invia Anagrafica</span>
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          )}
        </Formik>
        <div
          className="secondary"
          style={{
            transition: "all 0.3s ease-in-out",
            opacity: this.state.done === false ? "0" : "1",
          }}
        >
          {this.state.response}
          <button
            className=" create button btn-standard btn-green"
            onClick={() => this.setState({ done: false })}
          >
            OK
          </button>
        </div>
      </div>
    );
  }
}
