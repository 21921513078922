import React, { Component } from "react";
import api from "../../../config";
import Swal from "sweetalert2";
import $ from "jquery";
import { makeStyles } from "@material-ui/core/styles";
import { Tooltip } from "@material-ui/core";
import Printbutton from "../../../components/printButton/Printbutton";
import { Animated } from "react-animated-css";
import Load from "../../../components/Load";
export default class InnerPicking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      stamp_active: false,
      partner_id: "",
      share_active: false,
      communication: {
        rows: 5,
        lines: [],
      },
      loader:false
    };
  }
  componentDidMount() {
    this.setState({loader:true})
    api.fetchInnerPicking(this.props.match.params.idorder).then((value) => {
      var count = 0;
      if (value) {
        value.chatter.messages.forEach((element) => {
          if (element.body.length > 0) count++;
        });
        this.setState({
          summary: value,
          lines: value.order_line,
          communication: value.chatter,
          count: count,
          loader:false
        });
      }
      else
      this.setState({loader:false})
    });
  }
  renderDates(lista) {
    var data_res = [];
    lista.forEach((data) => {
      data_res.push(<li key={data}>{data}</li>);
    });
    return data_res;
  }
  Tooltip(props) {
    const useStylesBootstrap = makeStyles((theme) => ({
      arrow: {
        color: theme.palette.common.black,
      },
      tooltip: {
        backgroundColor: theme.palette.common.black,
        boxShadow: theme.shadows[1],
        fontSize: 14,
      },
    }));
    const classes = useStylesBootstrap();

    return <Tooltip arrow classes={classes} {...props} />;
  }
  renderLines() {
    var res = [];
    if (this.state.lines) {
      this.state.lines.forEach((element) => {
          res.push(
            <tr key={element.id} id="order-item-row-516">
              <td className="col name" data-th="Nome prodotto">
                    <p>
                      {element.name}
                    </p>
              </td>
       
              <td className="col date" data-th="Nome prodotto">
                    <p>
                      {element.scheduled_date}
                    </p>
              </td>
              <td className="col date" data-th="Nome prodotto">
                    <p>
                      {element.date_done}
                    </p>
              </td>
              <td className="col date" data-th="Nome prodotto">
                    <p>
                      {element.origin}
                    </p>
              </td>
              <td className="col state" data-th="Nome prodotto">
                    <p>
                      {element.origin}
                    </p>
              </td>
              <td className="col desc" data-th="Note">
                <p>{element.note || ''}</p>
              </td>
            
            </tr>
          );
       
      });
    }
    return res;
  }

  renderReply() {
    const formatDate = function (input) {
      if (input)
        var datePart = input.match(/\d+/g),
          year = datePart[0].substring(2), // get only two digits
          month = datePart[1],
          day = datePart[2];
      if (!day) {
        return "//";
      }
      return day + "/" + month + "/" + year;
    };
    var communication = this.state.communication.messages;
    var res = [];
    if (communication) {
      communication.forEach((element) => {
        var body = element.body;
        body = body.replace(/(onclick)+=+("+|'+).*("+|'+)/gm, "onclick=''");
        body = body.replace(/<a(.*)"/gm, "<a href='#'");
        if (body.length > 1)
          res.push(
            <div key={element.id} className="reply">
              {element.photo ? (
                <img src={element.photo} alt={element.photo} />
              ) : (
                <img
                  src={
                    "https://imagineacademy.microsoft.com/content/images/microsoft-img.png "
                  }
                  alt="thumb"
                />
              )}
              <div className="data_container">
                <h4>{element.author_id[1]}</h4>
                <p className="subtitle">
                  Pubblicato il{" "}
                  {formatDate(element.date.substring(0, 10)) +
                    " " +
                    element.date.substring(11)}
                </p>
                <p
                  className="content"
                  dangerouslySetInnerHTML={{
                    __html: body,
                  }}
                ></p>
              </div>
            </div>
          );
      });
    }
    return res;
  }
  formatDate = function (input) {
    if (input) {
      var datePart = input.match(/\d+/g),
        year = datePart[0].substring(0), // get only two digits
        month = datePart[1],
        day = datePart[2];

      return day + "/" + month + "/" + year;
    } else return "Non specificata";
  };
  render() {
    if (this.state.summary) {
      const alldata = this.state.summary;
      return (
        <React.Fragment>
          <div className="page_container">
            <h1 className="page_title">{alldata.name}</h1>
            <div className="prev_card">
              <div className="table-wrapper order-items">
                <div className="order-date leftalign">
                  <p>
                    Data Pianificata:{" "}
                    <span style={{ color: "black" }}>
                      {this.formatDate(alldata.scheduled_date)}
                    </span>
                    {/* <this.Tooltip title="Condividi con il cliente">
                      <a
                        style={{ float: "right", fontSize: "18px" }}
                        rel="noopener noreferrer"
                        target="_blank"
                        href=""
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({
                            share_active: !this.state.share_active,
                          });
                        }}
                      >
                        <i
                          className="fa fa-share-alt"
                          aria-hidden="true"
                          style={{ marginLeft: "15px" }}
                        />
                      </a>
                    </this.Tooltip>
                  
                    <a
                      style={{ float: "right", fontSize: "18px" }}
                      rel="noopener noreferrer"
                      target="_blank"
                      href=""
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                          stamp_active: !this.state.stamp_active,
                        });
                      }}
                    >
                      <i className="fa fa-print" aria-hidden="true"></i>
                    </a> */}
                  </p>
                  <p>
                    Agente:{" "}
                    <span style={{ color: "black" }}>
                      {alldata.partner_id.name}
                    </span>
                  </p>
                </div>
                <table
                  className="data table table-order-items"
                  id="my-orders-table"
                  summary="Articoli ordinati"
                >
                  <thead>
                    <tr>
                      <th className="col name">Riferimento</th>
                    </tr>
                  </thead>
                  <tbody>{this.renderLines()}</tbody>
                </table>
                <hr />
             </div></div>
         
            <hr style={{margin:'15px 0px'}}/>
            <div className="textbox-container">
              <h4>Comunicazioni</h4>
              <h5>
                <i className="fas fa-comments"></i> Ci sono {this.state.count}{" "}
                messaggi
              </h5>
              <img
                src={
                  "https://imagineacademy.microsoft.com/content/images/microsoft-img.png"
                }
                alt="thumb"
              />
              <h4>{sessionStorage.getItem("username")}</h4>
              <textarea
                name="message"
                value={this.state.message}
                onChange={(e) =>
                  this.setState({ [e.target.name]: e.target.value })
                }
              />
              <button
                disabled={this.state.message.length < 3}
                onClick={() =>
                  api
                    .postChatterPicking(
                      this.state.message,
                      this.props.match.params.idorder
                    )
                    .then((value) => {
                      api
                        .fetchInnerPicking(this.props.match.params.idorder)
                        .then((value) => {
                          this.setState({
                            communication: value.chatter,
                            message: "",
                            count: this.state.count + 1,
                          });
                        });
                      Swal.fire({
                        position: "top-end",
                        icon: "success",
                        html:
                          "<div><h1>Messaggio inviato</h1> <p>Il messaggio è stato inviato con successo</p> </div>",
                        showConfirmButton: false,
                        timerProgressBar: true,
                        timer: 2000,
                        customClass: { container: "swal_container" },
                      });
                    })
                }
              >
                Invia
              </button>
              {this.state.communication.message_count>0 && <hr />}


              {this.renderReply()}
            </div>
          </div>
          {this.state.loader && (
          <div className="obscure">
            <Load text={"Sto caricando " + this.props.prev===0 ? "l'ordine" :"il preventivo"} />
          </div>
        )}
        </React.Fragment>
      );
    } else return  (
      <div className="obscure">
                    <Load text={"Sto caricando " + (this.props.prev===0 ? "l'ordine" :"il preventivo")} />

      </div>)
  }
}
