import React, { Component } from "react";
import "./Footer.css";
import logomohd from "../../assets/logo_footer.png";
export default class Footer extends Component {
  render() {
    return (
      <footer className="footer_employee">
        <img alt="logo_mohd" src={logomohd} /> <span style={{position:'absolute',top:'10px',right:'5px',fontSize:'9px'}}>v1.7.3b</span>
      </footer>
    );
  }
}
