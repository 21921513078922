import React, { Component } from "react";
import "./Footer.css";
import pay2 from '../assets/pay1.png'
import pay1 from '../assets/pay2.png'
export default class Footer extends Component {
  render() {
    return (
      <footer className="page-footer">
        <div className="footer content">
          <div className="container-fascia-registration">
            {" "}
            <div className="red-border" />
          </div>
          <div className="wrap-footer-bottom">
            <div className="container">
              <div className="column-wrap">
                <small className="copyright">
                  <span>
                    <strong>Mollura &amp; C. SPA</strong> <br /> P.Iva
                    02759750835
                    <br />
                    S.S. 114 Km 6,400 98128 Tremestieri <br />
                    Messina - Italia
                  </span>
                </small>
                <meta name="format-detection" content="telephone=no" />
                <div className="footer-box footer-customer-help">
                  <div className="footer-box-title">Possiamo aiutarti?</div>
                  <div className="footer-box-content">
                    <a href="mailto:hello@mohd.it">hello@mohd.it</a>
                    <div className="phone">
                      <a href="tel:+390906258945">+39 0906258945</a>
                    </div>
                  </div>
                </div>
                <div className="footer-wrap-socials">
                  <ul>
                    <li>
                      <a href="https://it-it.facebook.com/mollurahomedesign/">
                        <i className="fa fa-facebook" />
                      </a>
                    </li>
                    <li>
                      <a href="https://twitter.com/mohd_homedesign">
                        <i className="fa fa-twitter" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.pinterest.it/mohdhomedesign/">
                        <i className="fa fa-pinterest" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/mohd_homedesign/">
                        <i className="fa fa-instagram" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="footer-box footer-menus">
                <div className="footer-menu">
                  <div className="footer-box-title">About</div>
                  <ul>
                    <li>
                      <span className="widget block block-cms-link-inline">
                        <a
                          href="https://shop.mohd.it/it/why-mohd"
                          title="Why Mohd"
                        >
                          <span>Why Mohd</span>
                        </a>
                      </span>
                    </li>
                    <li>
                      <div className="widget block block-cms-link">
                        <a
                          href="https://shop.mohd.it/it/about-mohd"
                          title="About Mohd"
                        >
                          <span>About Mohd</span>
                        </a>
                      </div>
                    </li>
                    <li>
                      <span className="widget block block-cms-link-inline">
                        <a
                          href="https://shop.mohd.it/it/showroom"
                          title="Showrooms"
                        >
                          <span>Showrooms</span>
                        </a>
                      </span>
                    </li>
                    <li>
                      <a
                        title="Interior Design Service"
                        href="https://www.mohd.it/design-service"
                        rel="noopener noreferrer"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Interior Design Service
                      </a>
                    </li>
                    <li>
                      <span className="widget block block-cms-link-inline">
                        <a
                          href="https://shop.mohd.it/it/price-promise"
                          title="Miglior Prezzo Garantito"
                        >
                          <span>Price Promise</span>
                        </a>
                      </span>
                    </li>
                    <li>
                      <span className="widget block block-cms-link-inline">
                        <a
                          href="https://shop.mohd.it/it/cookies"
                          title="Cookies Policy"
                        >
                          <span>Cookies Policy</span>
                        </a>
                      </span>
                    </li>
                    <li>
                      <span className="widget block block-cms-link-inline">
                        <a
                          href="https://shop.mohd.it/it/privacy-policy"
                          title="Privacy Policy"
                        >
                          <span>Privacy Policy</span>
                        </a>
                      </span>
                    </li>
                    <li>
                      <span className="widget block block-cms-link-inline">
                        <a href="https://shop.mohd.it/it/faq" title="FAQ">
                          <span>FAQ</span>
                        </a>
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="footer-menu">
                  <div className="footer-box-title">Assistenza clienti</div>
                  <ul>
                    <li>
                      <span className="widget block block-cms-link-inline">
                        <a
                          href="https://shop.mohd.it/it/contact"
                          title="Contattaci"
                        >
                          <span>Contattaci</span>
                        </a>
                      </span>
                    </li>
                    <li>
                      <span className="widget block block-cms-link-inline">
                        <a
                          href="https://shop.mohd.it/it/come-acquistare-online"
                          title="Come acquistare"
                        >
                          <span>Come acquistare</span>
                        </a>
                      </span>
                    </li>
                    <li>
                      <span className="widget block block-cms-link-inline">
                        <a
                          href="https://shop.mohd.it/it/metodi-pagamento"
                          title="Metodi di pagamento"
                        >
                          <span>Metodi di pagamento</span>
                        </a>
                      </span>
                    </li>
                    <li>
                      <span className="widget block block-cms-link-inline">
                        <a
                          href="https://shop.mohd.it/it/money-back-guarantee"
                          title="Garanzia di rimborso"
                        >
                          <span>Garanzia di rimborso</span>
                        </a>
                      </span>
                    </li>
                    <li>
                      <span className="widget block block-cms-link-inline">
                        <a
                          href="https://shop.mohd.it/it/restituzioni"
                          title="Restituzioni"
                        >
                          <span>Restituzioni</span>
                        </a>
                      </span>
                    </li>
                    <li>
                      <span className="widget block block-cms-link-inline">
                        <a
                          href="https://shop.mohd.it/it/spedizioni"
                          title="Spedizioni"
                        >
                          <span>Spedizioni</span>
                        </a>
                      </span>
                    </li>
                    <li>
                      <span className="widget block block-cms-link-inline">
                        <a
                          href="https://shop.mohd.it/it/termini-e-condizioni"
                          title="Termini e condizioni"
                        >
                          <span>Termini e condizioni</span>
                        </a>
                      </span>
                    </li>
                    <li>
                      <span className="widget block block-cms-link-inline">
                        <a
                          href="https://shop.mohd.it/it/garanzia"
                          title="Garanzia e reclami"
                        >
                          <span>Garanzia e reclami</span>
                        </a>
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="footer-menu">
                  <div className="footer-box-title">Be inspired</div>
                  <ul>
                    <li>
                      <a href="https://shop.mohd.it/it/magazine">
                        Mohd Magazine
                      </a>
                    </li>
                    <li>
                      <a href="https://shop.mohd.it/it/magazine/inspire-me">
                        Inspire Me
                      </a>
                    </li>
                    <li>
                      <a href="https://shop.mohd.it/it/magazine/brand">
                        Brand News
                      </a>
                    </li>
                    <li>
                      <a href="https://shop.mohd.it/it/magazine/design-icons">
                        Design Icons
                      </a>
                    </li>
                    <li>
                      <a href="https://shop.mohd.it/it/magazine/design-news">
                        News
                      </a>
                    </li>
                    <li>
                      <a href="https://shop.mohd.it/it/designers.html">
                        Naviga per Designer
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="footer-secure-payments">
                <div className="footer-box-title">Pagamento sicuro</div>
                <div className="images">
                  <img
                    className="credit-cards b-lazy b-loaded"
                    src={pay1}
                    alt="Pagamento sicuro"
                    width={261}
                  />
                  <img
                    className="trusted-shop b-lazy b-loaded"
                    src={pay2}
                    alt="Negozio garantito"
                    width={126}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom-bar">
            <div className="container">
              <div className="container-row-inner">
                <div className="sprite-icons-logo-footer">&nbsp;</div>
                <div className="payoff">Inspired by Beauty since 1968</div>
                <div className="company">
                  Mollura &amp; C. SPA P.Iva 02759750835
                  <br /> S.S. 114 Km 6,400 98128 Tremestieri Messina - Italy
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
