import React, { Component } from "react";
import api from "../../config";
import Swal from "sweetalert2";
import $ from "jquery";
import "./InnerOrder.css";
import { makeStyles } from "@material-ui/core/styles";
import { Tooltip } from "@material-ui/core";
export default class InnerPurchaseOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      communication: {
        rows: 5,
        lines: [],
      },
    };
  }
  componentDidMount() {
    api
      .fetchInnerPurchaseOrder(this.props.match.params.idorder)
      .then((value) => {
        var count = 0;
        if (value) {
          value.chatter.messages.forEach((element) => {
            if (element.body.length > 0) count++;
          });
          this.setState({
            summary: value,
            lines: value.order_line,
            communication: value.chatter,
            count: count,
          });
        }
      });
  }
  renderDates(lista) {
    var data_res = [];
    if (lista)
      lista.forEach((data) => {
        data_res.push(<li>{data}</li>);
      });
    return data_res;
  }
  Tooltip(props) {
    const useStylesBootstrap = makeStyles((theme) => ({
      arrow: {
        color: theme.palette.common.black,
      },
      tooltip: {
        backgroundColor: theme.palette.common.black,
        boxShadow: theme.shadows[1],
        fontSize: 14,
      },
    }));
    const classes = useStylesBootstrap();

    return <Tooltip arrow classes={classes} {...props} />;
  }
  renderLines() {
    var res = [];
    if (this.state.lines) {
      this.state.lines.forEach((element) => {
        res.push(
          <tr key={element.product_id.sku} id="order-item-row-516">
            <td className="col name">
              <strong className="product name product-item-name">
                <div className="max_height">
                  <this.Tooltip title={element.product_id.name}>
                    <p>{element.product_id.name}</p>
                  </this.Tooltip>
                </div>
              </strong>
            </td>
            <td className="col name">
              <strong className="product name product-item-name">
                <div className="max_height">
                  {" "}
                  <this.Tooltip title={element.description}>
                    <p>{element.description}</p>
                  </this.Tooltip>
                </div>
              </strong>
            </td>
            <td className="col sku">{element.product_id.sku}</td>
            <td className="col qty" data-th="Qtà">
              <span className="content">{element.supplier_sku}</span>
            </td>
            <td className="col sku">
              <span className="content">{element.wh_ref}</span>
            </td>
            <td className="col name">
              <span className="content">
                {element.brand_id ? element.brand_id.name : null}
              </span>
            </td>

            <td className="col qty" data-th="Qtà">
              <span className="content">{element.purchased_qty}</span>
            </td>

            {this.props.permission.opt_purchase_view_total &&     <td className="col price" data-th="Prezzo">
              <span className="price-including-tax" data-label="Tasse incl.">
                <span className="cart-price">
                  <span className="price">
                    {element.price_unit.toLocaleString("it-IT", {
                      style: "currency",
                      currency: "EUR",
                    })}
                  </span>{" "}
                </span>
              </span>
            </td>}
            <td className="col discount" data-th="Sconto">
              <span className="price-including-tax" data-label="Tasse incl.">
                <span className="cart-price">
                  <span className="price">
                    {element.discount
                      ? element.discount.toLocaleString("it-IT")
                      : null}
                  </span>{" "}
                </span>
              </span>
            </td>

            {this.props.permission.opt_purchase_view_total && (
              <td className="col subtotal" data-th="Totale prodotti">
                <span className="price-including-tax" data-label="Tasse incl.">
                  <span className="cart-price">
                    <span className="price">
                      {element.price_subtotal.toLocaleString("it-IT", {
                        style: "currency",
                        currency: "EUR",
                      })}
                    </span>{" "}
                  </span>
                </span>
              </td>
            )}
          </tr>
        );
      });
    }
    return res;
  }
  formatDate = function (input) {
    if (input)
      var datePart = input.match(/\d+/g),
        year = datePart[0].substring(0), // get only two digits
        month = datePart[1],
        day = datePart[2];

    if (!day) return "Non specificata";
    return day + "/" + month + "/" + year;
  };
  renderReply() {
    var communication = this.state.communication.messages;
    var res = [];
    if (communication) {
      communication.forEach((element) => {
        var body = element.body;
        body = body.replace(/(onclick)+=+("+|'+).*("+|'+)/gm, "onclick=''");
        body = body.replace(/<a(.*)"/gm, "<a href='#'");
        if (body.length > 1)
          res.push(
            <div key={element.id} className="reply">
              {element.photo ? (
                <img src={element.photo} alt={element.photo} />
              ) : (
                <img
                  src={
                    "https://imagineacademy.microsoft.com/content/images/microsoft-img.png "
                  }
                  alt="thumb"
                />
              )}
              <div className="data_container">
                <h4>{element.author_id[1]}</h4>
                <p className="subtitle">
                  Pubblicato il{" "}
                  {this.formatDate(element.date.substring(0, 10)) +
                    " " +
                    element.date.substring(11)}
                </p>
                <p
                  className="content"
                  dangerouslySetInnerHTML={{
                    __html: body,
                  }}
                ></p>
              </div>
            </div>
          );
      });
    }
    return res;
  }
  render() {
    if (this.state.summary) {
      const alldata = this.state.summary;
      return (
        <div className="page_container">
          <h1 className="page_title">{alldata.name}</h1>
          <div className="prev_card">
            <div className="table-wrapper order-items">
              <div className="order-date leftalign">
                <p>
                  Data Confermata:{" "}
                  <span style={{ color: "black" }}>
                    {this.formatDate(alldata.date_confirmed)}
                  </span>
                </p>
                {/*   <a
                  style={{ float: "right", fontSize: "18px" }}
                  rel="noopener noreferrer"
                  target="_blank"
                  href={
                    api.auth_api +
                    "/report/pdf/mohd_report.report_sale_order_document/" +
                    this.props.match.params.idorder
                  }
                >
                  <i className="fa fa-print" aria-hidden="true"></i>
                </a> */}
                <p>
                  Responsabile:{" "}
                  <span style={{ color: "black" }}>
                    {alldata.purchasing_manager_id
                      ? alldata.purchasing_manager_id.name
                      : null}{" "}
                  </span>
                </p>
              </div>
              <table
                className="data table table-order-items"
                id="my-orders-table"
                summary="Articoli ordinati"
              >
                <thead>
                  <tr>
                    <th className="col name">Nome</th>
                    <th className="col sku">Descrizione</th>
                    <th className="col sku">SKU</th>
                    <th className="col sku">SKU Fornitore</th>
                    <th className="col sku">Riferimento</th>
                    <th className="col sku">Brand</th>
                    <th className="col qta">Qtà</th>
                    {this.props.permission.opt_purchase_view_total &&   <th className="col price">Prezzo</th> }
                    <th className="col discount">Sconto %</th>

                    {this.props.permission.opt_purchase_view_total && (
                      <th className="col subtotal">Totale prodotti</th>
                    )}
                  </tr>
                </thead>
                <tbody>{this.renderLines()}</tbody>
              </table>
              <hr />
              {this.props.permission.opt_purchase_view_total && (
                <div className="footerTable">
                  <div className="ro">
                    <div className="cel">
                      <strong>Totale imponibile scontato</strong>
                    </div>
                    <div className="cel">
                      {alldata.amount_untaxed.toLocaleString("it-IT", {
                        style: "currency",
                        currency: "EUR",
                      })}
                    </div>
                  </div>
                  <div className="ro">
                    <div className="cel">Spedizione e gestione</div>
                    <div className="cel">
                      {" "}
                      {Number(0).toLocaleString("it-IT", {
                        style: "currency",
                        currency: "EUR",
                      })}
                    </div>
                  </div>
                  <div className="ro">
                    <div className="cel">
                      {" "}
                      <strong>IVA</strong>
                    </div>
                    <div className="cel">
                      {alldata.amount_tax.toLocaleString("it-IT", {
                        style: "currency",
                        currency: "EUR",
                      })}
                    </div>
                  </div>
                  <div className="ro">
                    <div className="cel">
                      {" "}
                      <strong>Totale complessivo</strong>
                    </div>
                    <div className="cel">
                      {alldata.amount_total.toLocaleString("it-IT", {
                        style: "currency",
                        currency: "EUR",
                      })}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="textbox-container">
            <h4>Comunicazioni</h4>
            <h5>
              <i className="fas fa-comments"></i> Ci sono {this.state.count}{" "}
              messaggi
            </h5>
            <img
              src={
                "https://imagineacademy.microsoft.com/content/images/microsoft-img.png "
              }
              alt="thumb"
            />
            <h4>{sessionStorage.getItem("username")}</h4>
            <textarea
              name="message"
              value={this.state.message}
              onChange={(e) =>
                this.setState({ [e.target.name]: e.target.value })
              }
            />
            <button
              disabled={this.state.message.length < 3}
              onClick={() =>
                api
                  .postChatter(
                    this.state.message,
                    this.props.match.params.idorder
                  )
                  .then((value) => {
                    api
                      .fetchInnerPurchaseOrder(this.props.match.params.idorder)
                      .then((value) => {
                        if (value)
                          this.setState({
                            communication: value.chatter,
                            message: "",
                            count: this.state.count + 1,
                          });
                      });
                    Swal.fire({
                      position: "top-end",
                      icon: "success",
                      html:
                        "<div><h1>Messaggio inviato</h1> <p>Il messaggio è stato inviato con successo</p> </div>",
                      showConfirmButton: false,
                      timerProgressBar: true,
                      timer: 2000,
                      customClass: { container: "swal_container" },
                    });
                  })
              }
            >
              Invia
            </button>
            <hr />

            {this.renderReply()}
          </div>
        </div>
      );
    } else return <div>Sto caricando</div>;
  }
}
