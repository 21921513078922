import React, { Component } from "react";
import AnimatedNumber from "react-animated-number";
import { Responsive, WidthProvider } from "react-grid-layout";
import { Line, Doughnut, Pie } from "react-chartjs-2";
import api from "../../config";
import { Link } from "react-router-dom";
import Load from "../../components/Load";
import { InputLabel, MenuItem, Select, Tooltip } from "@material-ui/core";
const GridLayout = WidthProvider(Responsive);
export default class Corrieri extends Component {
  constructor(props) {
    super(props);
    this.state = {
      res: false,
      assigned: [],
      done: [],
      cancel: [],
      draft: [],
      permission: this.props.permission,
      loading1: false,
      loading2: false,
      loading3: false,
      loading4: false,
      ordered_assigned: "",
      ordered_done: "",
      ordered_cancel: "",
      ordered_draft: "",
    };
  }
  componentDidMount() {
    this.setState({ loading1: true,loading2:true,loading3:true,loading4:true });
    var layout = JSON.parse(localStorage.getItem("layouts_carrier"));
    if (!layout) layout = this.baseLayout();
    this.setState({ layout });
    api
      .fetchPicking(0, 50, this.state.ordered_assigned,"assigned")
      .then((value) => {
        if (value) this.setState({ assigned: value.rows, loading1: false });
        else this.setState({ loading1: false });
      });
    api.fetchPicking(0, 50, this.state.ordered_done,"done").then((value) => {
      if (value) this.setState({ done: value.rows, loading2: false });
      else this.setState({ loading2: false });
    });
    api
      .fetchPicking(0, 50, this.state.ordered_draft, "draft")
      .then((value) => {
        if (value) this.setState({ cancel: value.rows, loading3: false });
        else this.setState({ loading3: false });
      });
    api
      .fetchPicking(0, 50, this.state.ordered_cancel, "cancel")
      .then((value) => {
        if (value) this.setState({ draft: value.rows, loading4: false });
        else this.setState({ loading4: false });
      });
  }
  onLayoutChange(layout, layouts) {
    localStorage.setItem("layouts_carrier", JSON.stringify(layouts));
  }
  baseLayout() {
    var layout = {
      lg: [
        { i: "0", x: 0, y: 0, w: 4, h: 2, minW: 2, minH: 2 },
        { i: "1", x: 0, y: 2, w: 4, h: 2, minW: 2, minH: 2 },
        { i: "2", x: 0, y: 4, w: 4, h: 2, minW: 2, minH: 2 },
        { i: "3", x: 0, y: 6, w: 4, h: 2, minW: 2, minH: 2 },
      ],
      md:  [
        { i: "0", x: 0, y: 0, w: 4, h: 2, minW: 2, minH: 2 },
        { i: "1", x: 0, y: 2, w: 4, h: 2, minW: 2, minH: 2 },
        { i: "2", x: 0, y: 4, w: 4, h: 2, minW: 2, minH: 2 },
        { i: "3", x: 0, y: 6, w: 4, h: 2, minW: 2, minH: 2 },
      ],
      sm: [
        { i: "0", x: 0, y: 0, w: 4, h: 2, minW: 2, minH: 2 },
        { i: "1", x: 0, y: 2, w: 4, h: 2, minW: 2, minH: 2 },
        { i: "2", x: 0, y: 4, w: 4, h: 2, minW: 2, minH: 2 },
        { i: "3", x: 0, y: 6, w: 4, h: 2, minW: 2, minH: 2 },
      ],
      xs: [
        { i: "0", x: 0, y: 0, w: 4, h: 2, minW: 2, minH: 2 },
        { i: "1", x: 0, y: 2, w: 4, h: 2, minW: 2, minH: 2 },
        { i: "2", x: 0, y: 4, w: 4, h: 2, minW: 2, minH: 2 },
        { i: "3", x: 0, y: 6,w: 4, h: 2, minW: 2, minH: 2 },
      ],
      xxs:  [
        { i: "0", x: 0, y: 0,w: 4, h: 2, minW: 2, minH: 2 },
        { i: "1", x: 0, y: 2, w: 4, h: 2, minW: 2, minH: 2 },
        { i: "2", x: 0, y: 4, w: 4, h: 2, minW: 2, minH: 2 },
        { i: "3", x: 0, y: 6,w: 4, h: 2, minW: 2, minH: 2 },
      ],
    };
    return layout;
  }
  reset() {
    localStorage.removeItem("layouts_carrier");
    this.setState({
      layout: undefined,
    });
    setTimeout(() => {
      this.setState({
        layout: this.baseLayout(),
      });
    }, 500);
  }

  handleAssigned = (attributo) => {
    this.setState(
      {
        ordered_assigned:
          attributo +
          " " +
          (this.state.ordered_assigned.includes("desc") ? "asc" : "desc"),
      },
      () => {
        api
          .fetchPicking(0, 50, this.state.ordered_assigned,"assigned")
          .then((value) => {
            if (value) this.setState({ assigned: value.rows, loading1: false });
            else this.setState({ loading1: false });
          });
      }
    );
  };
  handleDone = (attributo) => {
    this.setState(
      {
        ordered_done:
          attributo +
          " " +
          (this.state.ordered_done.includes("desc") ? "asc" : "desc"),
      },
      () => {
        api
          .fetchPicking(0, 50, this.state.ordered_done,"done").then((value) => {
            if (value) this.setState({ done: value.rows, loading2: false });
            else this.setState({ loading2: false });
          });
      }
    );
  };
  handleDraft = (attributo) => {
    this.setState(
      {
        ordered_draft:
          attributo +
          " " +
          (this.state.ordered_draft.includes("desc") ? "asc" : "desc"),
      },
      () => {
        api
          .fetchPicking(0, 50, this.state.ordered_draft, "draft")
          .then((value) => {
            if (value) this.setState({ draft: value.rows, loading3: false });
            else this.setState({ loading3: false });
          });
      }
    );
  };
  handleCancel = (attributo) => {
    this.setState(
      {
        ordered_cancel:
          attributo +
          " " +
          (this.state.ordered_cancel.includes("desc") ? "asc" : "desc"),
      },
      () => {
        api
          .fetchPicking(0, 50, this.state.ordered_cancel, "cancel")
          .then((value) => {
            if (value) this.setState({ cancel: value.rows, loading4: false });
            else this.setState({ loading4: false });
          });
      }
    );
  };
  formatDate = function (input) {
    if (input)
      var datePart = input.match(/\d+/g),
        year = datePart[0].substring(2), // get only two digits
        month = datePart[1],
        day = datePart[2];
    if (!day) {
      return "//";
    }
    return day + "/" + month + "/" + year;
  };
  renderElement(
    key,
    title,
    lines,
    headers,
    attribute,
    linking,
    orderfunction,
    orderstate
  ) {
    const renderHeader = (headers) => {
      var res = [];
      headers.forEach((element) => {
        res.push(
          <th
            key={element.label}
            scope="col"
            style={{
              textAlign: element.label === "Totale" || "" ? "right" : "",
              position: "relative",
            }}
            className="col id"
            onClick={() => {
              element.value.includes("date") && orderfunction(element.value);
            }}
          >
            {element.label}
            {element.value.includes("date") && (
              <i
                className="fas fa-arrow-up freccia"
                style={{
                  transform: orderstate.includes("desc")
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                  opacity:
                    orderstate === element.value + " desc" ||
                    orderstate === element.value + " asc"
                      ? "1"
                      : "0",
                  transition: "all 0.3s ease-in-out",
                }}
              ></i>
            )}
          </th>
        );
      });
      return res;
    };
    const renderLines = () => {
      var res = [];
      var super_res = [];
      var attr_array = [];
      var attr_ris = "";
      var appogg;
      for (var i = 0; i < lines.length; i++) {
        for (var j = 0; j < headers.length; j++) {
          if (attribute[j].label.indexOf(".") > 0) {
            attr_array = attribute[j].label.split(".");
            appogg = lines[i][attr_array[0]];
            for (var k = 1; k < attr_array.length; k++) {
              attr_ris = appogg[attr_array[k]];
            }
          } else attr_ris = lines[i][attribute[j].label];
          if (attribute[j].type === "link") {
            res.push(
              <td
                key={i + j + 4}
                style={{ textAlign: "right" }}
                className="col name"
              >
                <Link
                  style={{ marginTop: "0px" }}
                  to={linking + "/" + attr_ris}
                >
                  Visualizza
                </Link>
              </td>
            );
          } else if (attribute[j].type === "date") {
            res.push(
              <td
                key={i + j + 4}
                className="col name"
              >
                {this.formatDate(attr_ris)}
              </td>
            );
          } else if (attribute[j].type !== "value") {
            res.push(
              <td key={i + j + 3} className="col name">
                {attr_ris}
              </td>
            );
          } else {
            res.push(
              <td
                key={i + j + 2}
                style={{ textAlign: "right" }}
                className="col name"
              >
                {attr_ris.toLocaleString("it-IT", {
                  style: "currency",
                  currency: "EUR",
                })}
              </td>
            );
          }
        }

        super_res.push(<tr key={i - j + 1}>{res}</tr>);
        res = [];
      }
      return super_res;
    };
    return (
      <div key={key} className="card_auto">
        <div className="big_button _auto">
          <h2>
            {title}
            <Link className="little_one" to={linking}>
              Visualizza tutti
            </Link>
          </h2>

          <div className="padding_class">
            <table
              className="data table table-order-items history"
              id="my-orders-table-ord"
            >
              <caption className="table-caption">Ordini</caption>
              <thead>
                <tr>{renderHeader(headers)}</tr>
              </thead>

              <tbody>{lines ? renderLines() : null}</tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
  vacant = (key, title, headers) => {
    const renderHeader = (headers) => {
      var res = [];
      headers.forEach((element) => {
        res.push(
          <th
            key={element}
            scope="col"
            style={{ textAlign: element === "Totale" || "" ? "right" : "" }}
            className="col id"
          >
            {element}
          </th>
        );
      });
      return res;
    };
    return (
      <div key={key} className="card_auto">
        <div className="big_button _auto">
          <h2>{title}</h2>
          <div className="padding_class">
            <table
              className="data table table-order-items history"
              id="my-orders-table-ord"
            >
              <caption className="table-caption">Ordini</caption>
              <thead>
                <tr>{renderHeader(headers)}</tr>
              </thead>
            </table>
            <p>Non sono presenti {title}</p>
          </div>
        </div>
      </div>
    );
  };
  render() {
    const permission = this.state.permission;
    return (
      <div className="page_container">
        <div className="title">
          <h1 className="page_title absolute">Corrieri</h1>
          <ul className="phaser">
            <li>
              <button onClick={() => this.setState({ res: !this.state.res })}>
                {!this.state.res ? "Modifica" : "Conferma"}
              </button>
            </li>
            <li>
              <button onClick={() => this.reset()}>Reset</button>
            </li>
          </ul>
        </div>
        {this.state.layout && (!this.state.loading1 && !this.state.loading2 && !this.state.loading3 && !this.state.loading4) && (
          <GridLayout
            margin={[25, 25]}
            breakpoints={{ lg: 1800, md: 1000, sm: 700, xs: 580, xxs: 0 }}
            cols={{ lg: 4, md: 4, sm: 4, xs: 4, xxs: 4 }}
            isResizable={this.state.res}
            isDraggable={this.state.res}
            className="layout"
            layouts={this.state.layout}
            onLayoutChange={(layout, layouts) =>
              this.onLayoutChange(layout, layouts)
            }
            rowHeight={230}
          >
            {this.state.assigned.length > 0 && permission.vendite_picking
              ? this.renderElement(
                  "0",
                  "Assigned",
                  this.state.assigned,
                  [
                    { label: "N. Picking", value: "name" },
                    { label: "Data Prevista", value: "scheduled_date" },
                    {label : "Data Spostamento", value : "date_done"},
                    { label: "Ordine di riferimento", value: "origin" },
                    { label: "", value: "id" },
                  ],
                  [
                    { type: "", label: "name" },
                    { type: "date", label: "scheduled_date" },
                    { type : "date", label : "date_done"},
                    { type: "", label: "location_dest_id.name" },
                    { type: "link", label: "id" },
                    
                  ],
                  "/vendite/picking",
                  this.handleAssigned,
                  this.state.ordered_assigned
                )
              : this.vacant("0", "Assigned", [
                  "N. Picking",
                  "Data Prevista",
                  "Totale",
                ])}
            {this.state.done.length > 0 && permission.vendite_picking
              ? this.renderElement(
                  "1",
                  "Done",
                  this.state.done,
                  [
                    { label: "N. Picking", value: "name" },
                    { label: "Data Prevista", value: "scheduled_date" },
                    {label : "Data Spostamento", value : "date_done"},
                    { label: "Ordine di riferimento", value: "origin" },
                    { label: "", value: "id" },
                  ],
                  [
                    { type: "", label: "name" },
                    { type: "date", label: "scheduled_date" },
                    { type : "date", label : "date_done"},
                    { type: "", label: "location_dest_id.name" },
                    { type: "link", label: "id" },
                    
                  ],
                  "/vendite/picking",
                  this.handleDone,
                  this.state.ordered_done
                )
              : this.vacant("0", "Done", ["N. Picking", "Data Prevista", "Totale"])}
            {this.state.draft.length > 0 && permission.vendite_picking
              ? this.renderElement(
                  "2",
                  "Draft",
                  this.state.draft,
                  [
                    { label: "N. Picking", value: "name" },
                    { label: "Data Prevista", value: "scheduled_date" },
                    {label : "Data Spostamento", value : "date_done"},
                    { label: "Ordine di Riferimento", value: "origin" },
                    { label: "", value: "id" },
                  ],
                  [
                    { type: "", label: "name" },
                    { type: "date", label: "scheduled_date" },
                    { type : "date", label : "date_done"},
                    { type: "", label: "location_dest_id.name" },
                    { type: "link", label: "id" },
                    
                  ],
                  "/vendite/picking",
                  this.handleDraft,
                  this.state.ordered_draft
                )
              : this.vacant("0", "Draft", ["N. Picking", "Data Prevista", "Totale"])}
            {this.state.cancel.length > 0 && permission.vendite_picking
              ? this.renderElement(
                  "3",
                  "Cancel",
                  this.state.cancel,
                  [
                    { label: "N. Picking", value: "name" },
                    { label: "Data Prevista", value: "scheduled_date" },
                    {label : "Data Spostamento", value : "date_done"},
                    { label: "Ordine di Riferimento", value: "origin" },
                    { label: "", value: "id" },
                  ],
                  [
                    { type: "", label: "name" },
                    { type: "date", label: "scheduled_date" },
                    { type : "date", label : "date_done"},
                    { type: "", label: "location_dest_id.name" },
                    { type: "link", label: "id" },
                  ],
                  "/vendite/picking",
                  this.handleCancel,
                  this.state.ordered_cancel
                )
              : this.vacant("0", "Cancel", ["N. Picking", "Data Prevista", "Totale"])}
          </GridLayout>
        )}
        {(this.state.loading1 || this.state.loading2 || this.state.loading3 || this.state.loading4) && (
          <div className="obscure">
            <Load text={"Carico la lista dei picking"} />
          </div>
        )}
      </div>
    );
  }
}
