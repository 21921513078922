import React, { Component } from "react";
import Select from "react-select";
import SearchBar from "../search_bar/SearchBar";
import AsyncSelect from "react-select/async";
import api from "../../config";
import { isObject } from "formik";
var _ = require("lodash");

class SearchAnagrafy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countries: [],
      loadCountry: false,
      country: { label: "Seleziona Nazione", value: -11 },
    };
  }
  componentDidMount() {
    if (!this.props.permission) {
      this.setState({ loadingCountry: true });
      api.fetchCountries().then((values) => {
        this.setState({ countries: values, loadingCountry: false });
      });
    }
  }
  loadOptions = (inputValue, callback) => {
    if (inputValue.length > 2) {
      this.props.apiCall(
          inputValue + "&country=" + this.state.country.value
        )
        .then((value) => {
          if (value) callback(value.rows);
        });
    }
  };
  render() {
    if (this.props.permission)
      return <SearchBar chache={false} {...this.props} />;
    else
      return (
        <div className={this.props.cell ? "cell_div" : "prev_card searchable"} style={this.props.customStyle}>
          <div style={{ margin: "0px" }} className="table_div">
            <div className="row_div">
              <div className="cell_div">
                {" "}
                <p id="fatt_state" className="subtitle">
                  {this.props.label}
                </p>
                <Select
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                  menuPortalTarget={document.body}
                  isClearable={false}
                  escapeClearsValue={false}
                  name="stato"
                  placeholder={
                    this.state.country.label
                      ? this.state.country.label
                      : "Inserisci lo stato"
                  }
                  options={this.state.countries}
                  isLoading={this.state.loadingCountry}
                  value={this.state.country.value}
                  onChange={(e) => {
                    this.setState({ country: e });
                  }}
                />
              </div>
              {this.state.country.value > 0 && (
                <div
                  className={
                    this.state.country.value > 0
                      ? "cell_div widthVis"
                      : " cell_div width0"
                  }
                >
                  <p className="subtitle">Cerca Contatto</p>
                  <AsyncSelect
                    name="query"
                    // isLoading={false}
                    // isClearable={false}
                    // isSearchable={true}
                    onChange={(e) => this.props.setSearch(e)}
                    placeholder="Cerca un utente nel database"
                    // defaultValue={this.state.search_user}
                    loadOptions={_.debounce(this.loadOptions, 300)}
                    classNamePrefix={"anagrafySearch"}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      );
  }
}

export default SearchAnagrafy;
