import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Animated } from "react-animated-css";
import api from "../../config";
import "./Catalogo.css";
import { makeStyles } from "@material-ui/core/styles";
import { Tooltip } from "@material-ui/core";
export default class Catalogo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nome_prev: "",
      search: "",
      lista: [],
      files: [],
      sub: [],
      subvis: false,
      loading: false,
      previous: [],
    };
  }
  componentDidMount() {
    this.setState({ loading: true });
    api.searchFolder("Listini Fornitori").then((data) => {
      if (data) this.setState({ loading: false, lista: data.rows });
    });
  }
  getSub(id) {
    this.setState({ subvis: true, loading: true });
    var previous = this.state.previous;
    if (id === -1) {
      if (previous.length === 1) {
        this.setState({ subvis: false, sub: [], loading: false, previous: [] });
        id = previous.pop();
        return;
      } else {
        previous.pop();
        id = previous[previous.length - 1];
      }
    } else previous.push(id);
    api.getFolder(id).then((data) =>
      this.setState({
        sub: data.children_folder_ids,
        files: data.attachment_ids,
        subvis: true,
        loading: false,
        previous,
      })
    );
  }
  Tooltip(props) {
    const useStylesBootstrap = makeStyles((theme) => ({
      arrow: {
        color: theme.palette.common.black,
      },
      tooltip: {
        backgroundColor: theme.palette.common.black,
        boxShadow: theme.shadows[1],
        fontSize: 16,
      },
    }));
    const classes = useStylesBootstrap();

    return <Tooltip arrow classes={classes} {...props} />;
  }
  renderList() {
    var lista = this.state.lista;
    var res = [];
    var i = 0;
    if (this.state.loading) {
      for (var j = 1; j < 11; j++) {
        res.push(
          <Animated
            key={j}
            isVisible={this.state.loading}
            animationIn="bounceIn"
            animationOut={"fadeOut"}
            animationInDelay={300 * j}
            animationOutDuration={500}
            animationInDuration={600}
            className={this.state.loading ? "inline_block" : "invisible"}
          >
            <div
              style={{ animationDelay: j * 200 + "ms" }}
              className="loadingDiv"
            ></div>
          </Animated>
        );
      }
    } else {
      if (lista !== [])
        lista.forEach((l) => {
          if (l.children_folder_ids !== [])
            l.children_folder_ids.forEach((e) => {
              i += 1;
              if (this.state.search.length > 0) {
                if (
                  e.name.toUpperCase().includes(this.state.search.toUpperCase())
                )
                  res.push(
                    <Animated
                      key={e.name}
                      isVisible={!this.state.subvis}
                      animationIn="bounceIn"
                      animationOut={"bounceOut"}
                      animationInDelay={200 * i}
                      animationOutDuration={500}
                      animationInDuration={600}
                      className={
                        !this.state.subvis ? "inline_block" : "invisible"
                      }
                    >
                      {e.img ? (
                        <div
                          onClick={() => this.getSub(e.id)}
                          className="folder"
                        >
                          <img src={e.img} alt={e.name} />
                          <span>{e.name}</span>
                        </div>
                      ) : (
                        <div
                          onClick={() => this.getSub(e.id)}
                          className="folder"
                        >
                          <span>{e.name}</span>
                        </div>
                      )}
                    </Animated>
                  );
              } else
                res.push(
                  <Animated
                    key={e.name}
                    isVisible={!this.state.subvis}
                    animationIn="bounceIn"
                    animationOut={"bounceOut"}
                    animationInDelay={200 * i}
                    animationOutDuration={500}
                    animationInDuration={600}
                    className={
                      !this.state.subvis ? "inline_block" : "invisible"
                    }
                  >
                    {e.img ? (
                      <div onClick={() => this.getSub(e.id)} className="folder">
                        <img src={e.img} alt={e.name} />

                        <span>{e.name}</span>
                      </div>
                    ) : (
                      <div onClick={() => this.getSub(e.id)} className="folder">
                        <span>{e.name}</span>
                      </div>
                    )}
                  </Animated>
                );
            });
        });
    }

    return res;
  }
  renderSub() {
    var lista = this.state.sub;
    var files = this.state.files;
    var res = [];
    var i = 0;
    if (lista) {
      lista.forEach((e) => {
        i += 1;
        if (this.state.search.length > 0) {
          if (e.name.toUpperCase().includes(this.state.search.toUpperCase()))
            res.push(
              <Animated
                key={e.name}
                isVisible={this.state.subvis}
                animationIn="bounceIn"
                animationOut={"bounceOut"}
                className="inline_block"
                animationInDelay={200 * i}
                animationOutDuration={500}
                animationInDuration={600}
              >
                {e.img ? (
                  <div onClick={() => this.getSub(e.id)} className="folder">
                    <img src={e.img} alt={e.name} />
                    <span>{e.name}</span>
                  </div>
                ) : (
                  <div onClick={() => this.getSub(e.id)} className="folder">
                    <span>{e.name}</span>
                  </div>
                )}
              </Animated>
            );
        } else
          res.push(
            <Animated
              key={e.name}
              isVisible={this.state.subvis}
              animationIn="bounceIn"
              animationOut={"bounceOut"}
              animationInDelay={200 * i}
              className="inline_block"
              animationOutDuration={500}
              animationInDuration={600}
            >
              {e.img ? (
                <div onClick={() => this.getSub(e.id)} className="folder">
                  <img src={e.img} alt={e.name} />
                  <span>{e.name}</span>
                </div>
              ) : (
                <div onClick={() => this.getSub(e.id)} className="folder">
                  <span>{e.name}</span>
                </div>
              )}
            </Animated>
          );
      });
    }
    if (files) {
      files.forEach((e) => {
        i += 1;
        if (this.state.search.length > 0) {
          if (e.name.toUpperCase().includes(this.state.search.toUpperCase()))
            res.push(
              <Animated
                key={e.name}
                isVisible={this.state.subvis}
                animationIn="bounceIn"
                animationOut={"bounceOut"}
                className="inline_block"
                animationInDelay={200 * i}
                animationOutDuration={500}
                animationInDuration={600}
              >
                {e.img ? (
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={
                      api.auth_api + "/web/content/" + e.id + "?download=true"
                    }
                    className="file"
                  >
                    <img src={e.img} alt={e.name} />
                    <this.Tooltip title={e.name}>
                      <span>{e.name}</span>
                    </this.Tooltip>
                  </a>
                ) : (
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={
                      api.auth_api + "/web/content/" + e.id + "?download=true"
                    }
                    className="file"
                  >
                    <this.Tooltip title={e.name}>
                      <span>{e.name}</span>
                    </this.Tooltip>
                  </a>
                )}
              </Animated>
            );
        } else
          res.push(
            <Animated
              key={e.name}
              isVisible={this.state.subvis}
              animationIn="bounceIn"
              animationOut={"bounceOut"}
              animationInDelay={200 * i}
              className="inline_block"
              animationOutDuration={500}
              animationInDuration={600}
            >
              {e.img ? (
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={
                    api.auth_api + "/web/content/" + e.id + "?download=false"
                  }
                  className="file"
                >
                  <img src={e.img} alt={e.name} />
                  <this.Tooltip title={e.name}>
                    <span>{e.name}</span>
                  </this.Tooltip>
                </a>
              ) : (
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={
                    api.auth_api + "/web/content/" + e.id + "?download=false"
                  }
                  className="file"
                >
                  <this.Tooltip title={e.name}>
                    <span>{e.name}</span>
                  </this.Tooltip>
                </a>
              )}
            </Animated>
          );
      });
    }
    return res;
  }
  reset() {
    var list = this.state.lista;
    this.setState({ subvis: false, lista: [], reset: true, sub: [] });
    setTimeout(() => {
      this.setState({ lista: list, reset: false, sub: [] });
    }, 500);
  }
  render() {
    return (
      <div className="page_container catalogo">
        <h1 className="page_title">Catalogo</h1>
        <div className="left_align">
          <div>
            <label htmlFor="search_prev">
              <strong>Cerca per brand</strong>
            </label>
            <br />
            <input
              id="search_prev"
              type="text"
              placeholder="ex. Cassina | Frau..."
              className="left_align"
              value={this.state.search}
              onChange={(e) => this.setState({ search: e.target.value })}
            />
          </div>
        </div>
        {this.state.subvis && (
          <div className="left_align">
            <span
              style={{ cursor: "pointer", fontWeight: "700" }}
              onClick={() => {
                if (this.state.previous === []) {
                  this.setState({ subvis: false, sub: [] });
                } else {
                  this.getSub(-1);
                }
              }}
            >
              <i className="fas fa-arrow-left"></i> Torna indietro
            </span>
          </div>
        )}

        <div
          className="bozze_container leftalign"
          style={{ marginTop: "20px" }}
        >
          {this.state.lista && this.state.sub.length < 1
            ? this.renderList()
            : null}
          {this.state.sub.length > 0 || this.state.files.length > 0
            ? this.renderSub().length > 0
              ? this.renderSub()
              : !this.state.reset && (
                  <p>Non esistono Cataloghi con questo brand</p>
                )
            : null}
        </div>
      </div>
    );
  }
}
