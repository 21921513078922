import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Animated } from "react-animated-css";
import api from "../../config";
export default class Tessuti extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nome_prev: "",
      search: "",
      lista: [],
      sub: [],
      subvis: false,
      loading: false,
    };
  }
  componentDidMount() {
    this.setState({ loading: true });
    api
      .fetchFolder("tessuto")
      .then((data) => this.setState({ loading: false, lista: data.rows }));
  }
  fetchSub(id) {
    this.setState({ subvis: true });
    setTimeout(() => {
      api
        .fetchSubFolder("tessuto", id)
        .then((data) => this.setState({ sub: data.rows, subvis: true }));
    }, 300);
  }
  renderList() {
    var lista = this.state.lista;
    var res = [];
    var i = 0;
    if (this.state.loading) {
      for (var j = 1; j < 11; j++) {
        res.push(
          <Animated
            key={j}
            isVisible={this.state.loading}
            animationIn="bounceIn"
            animationOut={"fadeOut"}
            animationInDelay={400 * j}
            animationOutDuration={500}
            animationInDuration={600}
            className="inline_block"
          >
            <div
              style={{ animationDelay: j * 200 + "ms" }}
              className="loadingDiv"
            ></div>
          </Animated>
        );
      }
    } else {
      if (lista !== [])
        lista.forEach((e) => {
          i += 1;
          if (this.state.search.length > 0) {
            if (e.name.toUpperCase().includes(this.state.search.toUpperCase()))
              res.push(
                <Animated
                  key={e.name}
                  isVisible={!this.state.subvis}
                  animationIn="bounceIn"
                  animationOut={"bounceOut"}
                  animationInDelay={200 * i}
                  animationOutDuration={500}
                  animationInDuration={600}
                  className="inline_block"
                >
                  {e.img ? (
                    <div onClick={() => this.fetchSub(e.id)} className={e.type}>
                      <img src={e.img} alt={e.name} />
                      <span>{e.name}</span>
                    </div>
                  ) : (
                    <div onClick={() => this.fetchSub(e.id)} className={e.type}>
                      <span>{e.name}</span>
                    </div>
                  )}
                </Animated>
              );
          } else
            res.push(
              <Animated
                key={e.name}
                isVisible={!this.state.subvis}
                animationIn="bounceIn"
                animationOut={"bounceOut"}
                className="inline_block"
                animationInDelay={200 * i}
                animationOutDuration={500}
                animationInDuration={600}
              >
                {e.img ? (
                  <div onClick={() => this.fetchSub(e.id)} className={e.type}>
                    <img src={e.img} alt={e.name} />
                    <span>{e.name}</span>
                  </div>
                ) : (
                  <div onClick={() => this.fetchSub(e.id)} className={e.type}>
                    <span>{e.name}</span>
                  </div>
                )}
              </Animated>
            );
        });
    }

    return res;
  }
  renderSub() {
    var lista = this.state.sub;
    var res = [];
    var i = 0;
    if (lista)
      lista.forEach((e) => {
        i += 1;
        if (this.state.search.length > 0) {
          if (e.name.toUpperCase().includes(this.state.search.toUpperCase()))
            res.push(
              <Animated
                key={e.name}
                isVisible={this.state.subvis}
                animationIn="bounceIn"
                animationOut={"bounceOut"}
                className="inline_block"
                animationInDelay={200 * i}
                animationOutDuration={500}
                animationInDuration={600}
              >
                {e.img ? (
                  <div onClick={() => this.fetchSub(e.id)} className={e.type}>
                    <img src={e.img} alt={e.name} />
                    <span>{e.name}</span>
                  </div>
                ) : (
                  <div onClick={() => this.fetchSub(e.id)} className={e.type}>
                    <span>{e.name}</span>
                  </div>
                )}
              </Animated>
            );
        } else
          res.push(
            <Animated
              key={e.name}
              isVisible={this.state.subvis}
              animationIn="bounceIn"
              animationOut={"bounceOut"}
              animationInDelay={200 * i}
              className="inline_block"
              animationOutDuration={500}
              animationInDuration={600}
            >
              {e.img ? (
                <div onClick={() => this.fetchSub(e.id)} className={e.type}>
                  <img src={e.img} alt={e.name} />
                  <span>{e.name}</span>
                </div>
              ) : (
                <div onClick={() => this.fetchSub(e.id)} className={e.type}>
                  <span>{e.name}</span>
                </div>
              )}
            </Animated>
          );
      });

    return res;
  }
  reset() {
    var list = this.state.lista;
    this.setState({ subvis: false, lista: [], reset: true });
    setTimeout(() => {
      this.setState({ lista: list, reset: false, sub: [] });
    }, 500);
  }
  render() {
    return (
      <div className="page_container tessuto">
        <h1 className="page_title">Tessuti</h1>
        <div className="left_align">
          <div>
            <label htmlFor="search_prev">
              <strong>Cerca per paletta</strong>
            </label>
            <br />
            <input
              id="search_prev"
              type="text"
              placeholder="ex. Giallo | blue"
              className="left_align"
              value={this.state.search}
              onChange={(e) => this.setState({ search: e.target.value })}
            />
          </div>
        </div>
        {this.state.subvis && (
          <div className="left_align">
            <span
              style={{ cursor: "pointer", fontWeight: "700" }}
              onClick={() => this.reset()}
            >
              <i className="fas fa-arrow-left"></i> Torna indietro
            </span>
          </div>
        )}

        <div
          className="bozze_container leftalign"
          style={{ marginTop: "20px" }}
        >
          {this.state.lista && this.state.sub.length < 1
            ? this.renderList().length > 0
              ? this.renderList()
              : !this.state.reset && (
                  <p>
                    Ops! Non sono riuscito a trovare cartelle di tessuthi con
                    quel nome
                  </p>
                )
            : null}
          {this.state.sub.length > 0
            ? this.renderSub().length > 0
              ? this.renderSub()
              : !this.state.reset && (
                  <p>Non esistono tessuthi con questo brand</p>
                )
            : null}
        </div>
      </div>
    );
  }
}
