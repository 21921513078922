import React, { Component } from "react";
import Files from "react-files";
import JoditEditor from "jodit-react";
import "./HtmlEditor.css";
import Select from "react-select";
import api from "../../config";
import { Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Swal from "sweetalert2";
class HtmlEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: this.props.body ? this.props.body : "",
      subject: this.props.subject ? this.props.subject : "",
      templates: [],
      attachment_ids: [],
      attachment: "",
      attachment_name: "",
      placeholder: "",
      loading: false,
    };
  }

  componentDidMount() {
    api.getTemplates(this.props.model).then((templates) => {
      this.setState({ templates: templates.rows }, () => {
        if (this.props.template_id) {
          var selected = this.state.templates.filter((template) => {
            return template.xml_id === this.props.template_id;
          });
          if (selected.length > 0) {
            this.fetchTemplate(selected[0].value);
            this.setState({ placeholder: selected[0].label });
          }
        }
      });
    });
  }
  generatefoto = (file, callback) => {
    const reader = new FileReader();
    const url = reader.readAsDataURL(file);
    this.setState({ attachment_name: file.name });
    reader.onloadend = (e) => callback(reader.result);
  };
  fetchTemplate(id) {
    api
      .getMailforCompose(id, this.props.res_id, this.props.model)
      .then((value) => {
        this.setState({
          content: value.response.body_html,
          subject: value.response.subject,
        });
      });
  }
  Tooltip(props) {
    const useStylesBootstrap = makeStyles((theme) => ({
      arrow: {
        color: theme.palette.common.black,
      },
      tooltip: {
        backgroundColor: theme.palette.common.black,
        boxShadow: theme.shadows[1],
        fontSize: 14,
      },
    }));
    const classes = useStylesBootstrap();

    return <Tooltip arrow classes={classes} {...props} />;
  }
  elimina_attachment = (name) => {
    var attachment_ids = this.state.attachment_ids.filter((value) => {
      return value.name !== name;
    });
    this.setState({ attachment_ids });
  };
  performAction() {
    this.setState({ loading: true });
    api
      .sendEmail(
        this.state.content,
        this.props.partner_ids,
        this.props.res_id,
        this.state.subject,
        this.state.attachment_ids,
        this.props.model
      )
      .then((value) => {
        this.setState({ loading: false });
        this.props.closePopUp();
        Swal.fire({
          position: "center",
          title: "Invio Riuscito!",
          icon: "success",

          timer: 3000,
          timerProgressBar: true,
          showConfirmButton: false,
          customClass: {
            container: "swal_container",
          },
        });
      })
      .catch((error) => {
        Swal.fire({
          position: "center",
          title: "Errore!",
          icon: "error",
          text:
            "Non è stato possibile comunicare con il server, prova ad aggiornare la pagina",
          timer: 3000,
          timerProgressBar: true,
          showConfirmButton: true,
          confirmButtonText: "Aggiorna",
          customClass: {
            container: "swal_container",
            confirmButton: "btn_modifica",
          },
        });
        this.setState({ loading: false });

        console.log(error);
      });
  }
  render() {
    return (
      <div className={"html_editor_container " + (this.props.className || "")}>
      {!this.props.notemplate &&  <div className="flex-container">
           <label>Template :</label>{" "}
          <Select
            className={"react-select"}
            options={this.state.templates}
            styles={{
              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            }}
            menuPortalTarget={document.body}
            placeholder={
              this.state.placeholder.length > 0
                ? this.state.placeholder
                : "Seleziona template"
            }
            onChange={(e) =>
              this.setState({ select_temp: e }, () =>
                this.fetchTemplate(e.value)
              )
            }
          />
        </div>}
        <div className="flex-container">
          <label htmlFor="subject">Oggetto :</label>{" "}
          <input
            id="subject"
            placeholder={"Inserisci l'oggetto della mail"}
            type="text"
            className="inline-input"
            onChange={(e)=>{this.setState({subject:e.target.value})}}
            value={this.state.subject || ''}
          />
        </div>
        <JoditEditor
          value={this.state.content}
          tabIndex={1}
          height={200}
         config= {{readonly: false}}
          onBlur={(newContent) =>
            this.setState({ content: newContent.target.innerHTML })
          }
        />{" "}
        <div className="flex-container">
          <label htmlFor="allegato">Allegati :</label>{" "}
          <Files
            className="files-dropzone"
            onChange={(e) => {
              this.generatefoto(e[0], (attachment) => {
                this.setState(
                  {
                    attachment: attachment.substring(
                      attachment.indexOf(",") > 0
                        ? attachment.indexOf(",") + 1
                        : 0
                    ),
                  },
                  () => {
                    var attachment_ids = this.state.attachment_ids;
                    attachment_ids.push({
                      name: this.state.attachment_name,
                      datas: attachment.substring(attachment.indexOf(",") + 1),
                    });
                    this.setState({ attachment_ids });
                  }
                );
              });
            }}
            accepts={["image/*", ".pdf", "audio/*"]}
            multiple
            minFileSize={0}
            clickable
          >
            Clicca o trascina i file che vuoi allegare qui
          </Files>
        </div>
        {this.state.attachment_ids.length > 0 &&
          this.state.attachment_ids.map((value) => {
            return (
              <div className="attachment_div flex-container">
                <p key={value.name}>
                  {value.name}{" "}
                  <this.Tooltip title={"Elimina " + value.name}>
                    <i
                      onClick={() => {
                        this.elimina_attachment(value.name);
                      }}
                      className="fa fa-trash"
                    ></i>
                  </this.Tooltip>
                </p>
              </div>
            );
          })}
        <button
          style={{ float: "right", marginTop: "10px" }}
          className="btn_crea"
          onClick={() => {
            this.performAction();
          }}
          type="submit"
          disabled={this.state.loading}
        >
          {this.state.loading ? (
            <div className="loginloader"></div>
          ) : (
            <span>Invia</span>
          )}
        </button>
      </div>
    );
  }
}

export default HtmlEditor;
