import React, { Component } from "react";
import api from "../config";
import Swal from "sweetalert2";
import { makeStyles } from "@material-ui/core/styles";
import { Tooltip } from "@material-ui/core";
import Load from "../components/Load";
import Popup from "../components/popup/Popup";
import { Trans } from "react-i18next";
import Printbutton from "../components/printButton/Printbutton";
import ScriptTag from 'react-script-tag'
export default class InnerTokenOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      total: 0,
      shipping: 0,
      communication: {},
      bankpay: false,
      stamp_active: false,
    };
  }
  componentDidMount() {
    this.setState({ loading: true });
    if (this.props.match.params.token) {
      api
        .portalSaleOrder(
          this.props.match.params.idorder,
          this.props.match.params.token
        )
        .then((value) => {
          if (value) {
            this.setState({
              summary: value,
              lines: value.order_line,
              loading: false,
              communication: value.chatter,
              payment_id: value.payment_id,
            });
          } else {
            this.setState({loading:false},()=>{
              this.props.history.push('/')
            })
          }
        })
        .catch((error) => {
         /*  console.log(error); */
        });
    }
  }
  Tooltip(props) {
    const useStylesBootstrap = makeStyles((theme) => ({
      arrow: {
        color: theme.palette.common.black,
      },
      tooltip: {
        backgroundColor: theme.palette.common.black,
        boxShadow: theme.shadows[1],
        fontSize: 14,
      },
    }));
    const classes = useStylesBootstrap();

    return <Tooltip arrow classes={classes} {...props} />;
  }
  renderLines() {
    var res = [];
    if (this.state.lines) {
      this.state.lines.forEach((element) => {
        if (element.product_id)
          res.push(
            <tr key={element.id} id="order-item-row-516">
              <td className="col name" data-th="Nome prodotto">
                <strong className="product name product-item-name">
                  <this.Tooltip
                    position="top"
                    title={
                      element.product_id.sku === "GENERIC PRODUCT"
                        ? element.prev_nome.substring(0, 300)
                        : element.product_id.name.substring(0, 300)
                    }
                  >
                    <p>
                      {element.product_id.sku === "GENERIC PRODUCT"
                        ? element.prev_nome.substring(0, 30)
                        : element.product_id.name.substring(0, 30)}
                    </p>
                  </this.Tooltip>
                </strong>
              </td>
              <td className="col brand" data-th="Brand">
                {element.product_id.sku === "GENERIC PRODUCT"
                  ? element.prev_brand.name
                  : element.product_id.product_brand_id
                  ? element.product_id.product_brand_id.name
                  : ""}
              </td>
              <td className="col sku" data-th="SKU">
                {element.product_id.sku === "GENERIC PRODUCT"
                  ? "n.d."
                  : element.product_id.sku}
              </td>
              <td className="col price" data-th="Prezzo">
                <span className="price-including-tax" data-label="Tasse incl.">
                  <span className="cart-price">
                    <span className="price">
                      {element.price_unit.toLocaleString("it-IT", {
                        style: "currency",
                        currency: "EUR",
                      })}
                    </span>{" "}
                  </span>
                </span>
              </td>
              <td className="col qty" data-th="Qtà">
                <ul className="items-qty">
                  <li className="item">
                    <span className="content">{element.ordered_qty}</span>
                  </li>
                </ul>
              </td>
              <td className="col discount" data-th="Sconto">
                <span className="price-including-tax" data-label="Tasse incl.">
                  <span className="cart-price">
                    <span className="price">
                      {element.discount.toLocaleString("it-IT")}
                    </span>{" "}
                  </span>
                </span>
              </td>
              <td className="col subtotal" data-th="Totale prodotti">
                <span className="price-including-tax" data-label="Tasse incl.">
                  <span className="cart-price">
                    <span className="price">
                      {element.price_subtotal.toLocaleString("it-IT", {
                        style: "currency",
                        currency: "EUR",
                      })}
                    </span>{" "}
                  </span>
                </span>
              </td>
            </tr>
          );
        else
          res.push(
            <tr
              key={element.description}
              style={{
                background: "rgba(0,0,0,0.07)",
                borderTop: "1px solid black",
                borderRadius: "5px",
              }}
              id="order-item-row-516"
            >
              <td className="col name" data-th="Nome prodotto">
                <div className="max_height">
                  <span>{element.description}</span>
                </div>
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          );
      });
    }
    return res;
  }
  renderReply() {
    const formatDate = function (input) {
      if (input) {
        var datePart = input.match(/\d+/g),
          year = datePart[0].substring(2), // get only two digits
          month = datePart[1],
          day = datePart[2];

        return day + "/" + month + "/" + year;
      } else return "Non Specificata";
    };
    var communication = this.state.communication.messages;
    var res = [];
    if (communication) {
      communication.forEach((element) => {
        var body = element.body;
        if (body.length > 1)
          res.push(
            <div className="reply">
              {element.photo ? (
                <img src={element.photo} alt={element.photo} />
              ) : (
                <img
                  src={
                    "https://imagineacademy.microsoft.com/content/images/microsoft-img.png "
                  }
                  alt="thumb"
                />
              )}
              <div className="data_container">
                <h4>{element.author_id[1]}</h4>
                <p className="subtitle">
                  {formatDate(element.date.substring(0, 10))}
                </p>
                <p
                  className="content"
                  dangerouslySetInnerHTML={{
                    __html: body,
                  }}
                ></p>
              </div>
            </div>
          );
      });
    }
    return res;
  }
  render() {
    if (this.state.summary) {
      const alldata = this.state.summary;
      const shipping = this.state.summary.ship_to;
      const billing = this.state.summary.invoice_to;
      return (
        <main id="maincontent" className="page-main">
          <ScriptTag src="https://ecomm.sella.it/pagam/javascript/axerve.js"></ScriptTag>
          {/* <ScriptTag src="https://sandbox.gestpay.net/pagam/javascript/axerve.js"></ScriptTag> */}

          <div className="columns">
            <div style={{width:'90%',float:'none'}} className="column main">
          
                <div style={{ marginBottom: "5px" }} className="">
                  <h1 className="page-title">
                    <span className="base" data-ui-id="page-title-wrapper">
                      {this.props.prev ? "Preventivo #" : "Ordine #"}{" "}
                      {alldata.name}
                    </span>{" "}
                  </h1>
                  <span>{alldata.state}</span>
                  <br />
                  <span className="label">
                    {" "}
                    <Trans i18nKey="order_data">Order Data</Trans>:
                  </span>{" "}
                  <span>{alldata.customer_confirmation_date}</span>
                </div>
                <div
                  style={{ textAlign: "left", float: "right" }}
                  className="actions"
                >
                  {this.state.payment_id &&
                    this.state.payment_id.provider === "axerve_lightbox" && (
                      <button
                        className="btn_crea"
                        style={{ marginLeft: "0px" }}
                        onClick={() => {
                          window.axerve.lightBox.shop = this.state.payment_id.axerve_shop_login;
                          window.axerve.lightBox.open(
                            this.state.payment_id.axerve_payment_id,
                            this.state.payment_id.axerve_payment_token,
                          true
                          );
                        }}
                      >
                        <Trans i18nKey="pay_now">Pay Now</Trans>
                      </button>
                    )}
                  {this.state.payment_id &&
                    this.state.payment_id.provider === "bank_transfer" && (
                      <button
                        className="btn_crea"
                        style={{ marginLeft: "0px" }}
                        onClick={() => this.setState({ bankpay: true })}
                      >
                        <Trans i18nKey="pay_now">Pay Now</Trans>
                      </button>
                    )}
                  {/*   <button
              className="btn_crea"
              style={{ marginLeft: "13px" }}
              onClick={() => this.setState({ pay: !this.state.pay })}
            >
              Download
            </button>

            <button
              className="btn_crea"
              style={{ marginLeft: "13px" }}
              onClick={() =>
                this.setState({
                  stamp_active: !this.state.stamp_active,
                })
              }
            >
              Stampa
            </button> */}
                </div>
                <div className="order-details-items ordered">
                  <div className="order-title">
                    <strong>Articoli ordinati</strong>
                  </div>
                  <div className="table-wrapper order-items">
                    <table
                      className="data table table-order-items"
                      id="my-orders-table"
                      summary="Articoli ordinati"
                    >
                      <caption className="table-caption">
                        Articoli ordinati
                      </caption>
                      <thead>
                        <tr>
                          <th className="col name">Nome prodotto</th>
                          <th className="col brand">Brand</th>
                          <th className="col sku">SKU</th>
                          <th className="col price">Prezzo</th>
                          <th className="col qty">Qtà</th>
                          <th className="col qty">Sconto %</th>
                          <th className="col subtotal">Totale prodotti</th>
                        </tr>
                      </thead>
                      <tbody>{this.renderLines()}</tbody>
                      {alldata ? (
                        <tfoot>
                          <tr className="subtotal">
                            <th colSpan="6" className="mark" scope="row">
                              Totale prodotti{" "}
                            </th>
                            <td className="amount" data-th="Totale prodotti">
                              <span className="price">
                                {alldata.amount_untaxed.toLocaleString(
                                  "it-IT",
                                  {
                                    style: "currency",
                                    currency: "EUR",
                                  }
                                )}
                              </span>{" "}
                            </td>
                          </tr>
                          <tr className="shipping">
                            <th colSpan="6" className="mark" scope="row">
                              Spedizione e gestione{" "}
                            </th>
                            <td
                              className="amount"
                              data-th="Spedizione e gestione"
                            >
                              <span className="price">
                                {alldata.amount_shipping.toLocaleString(
                                  "it-IT",
                                  {
                                    style: "currency",
                                    currency: "EUR",
                                  }
                                )}
                              </span>{" "}
                            </td>
                          </tr>

                          <tr className="totals-tax">
                            <th colSpan="6" className="mark" scope="row">
                              IVA{" "}
                            </th>
                            <td className="amount" data-th="IVA">
                              <span className="price">
                                {alldata.amount_tax.toLocaleString("it-IT", {
                                  style: "currency",
                                  currency: "EUR",
                                })}
                              </span>{" "}
                            </td>
                          </tr>
                          <tr className="grand_total">
                            <th colSpan="6" className="mark" scope="row">
                              <strong>Totale complessivo</strong>
                            </th>
                            <td className="amount" data-th="Totale complessivo">
                              <strong>
                                <span className="price">
                                  {Number(alldata.amount_total).toLocaleString(
                                    "it-IT",
                                    {
                                      style: "currency",
                                      currency: "EUR",
                                    }
                                  )}
                                </span>
                              </strong>
                            </td>
                          </tr>
                        </tfoot>
                      ) : (
                        <tbody className="loader">
                          <tr>
                            <td>
                              <span
                                style={{ animationDelay: 1 * 500 + "ms" }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span
                                style={{ animationDelay: 2 * 500 + "ms" }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span
                                style={{ animationDelay: 3 * 500 + "ms" }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span
                                style={{ animationDelay: 3 * 500 + "ms" }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              <span
                                style={{ animationDelay: 4 * 500 + "ms" }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              <span
                                style={{ animationDelay: 4 * 500 + "ms" }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              <span
                                style={{ animationDelay: 4 * 500 + "ms" }}
                              />
                            </td>
                          </tr>
                        </tbody>
                      )}
                    </table>
                  </div>
                  {/*  <div className="actions-toolbar">
            <div className="secondary">
              <a
                className="action back"
                href="https://shop.mohd.it/it/sales/order/history/"
              >
                <span>Torna ai miei ordini</span>
              </a>
            </div>
          </div> */}
                </div>
                <div className="block block-order-details-view">
                  <div className="block-title">
                    <strong>Informazioni ordine</strong>
                  </div>
                  <div className="block-content">
                    <div className="box box-order-shipping-address">
                      <strong className="box-title">
                        <span>Indirizzo di Spedizione</span>
                      </strong>
                      <div className="box-content">
                        <address>
                          {shipping.name}
                          <br />
                          {shipping.street}
                          <br />
                          {shipping.street2}
                          <br />
                          {shipping.city}, {shipping.zip}
                          <br />
                          {shipping.country_id.name}
                          <br />
                          T:{" "}
                          <a href={"tel:" + shipping.phone}>{shipping.phone}</a>
                          <br />
                          C:{" "}
                          <a href={"tel:" + shipping.mobile}>
                            {shipping.mobile}
                          </a>
                        </address>
                      </div>
                    </div>
                    {alldata.payment_method_id ? (
                      <div className="box box-order-shipping-method">
                        <strong className="box-title">
                          <span>
                            <Trans i18nKey="pay_method">
                              Metodo di Pagamento
                            </Trans>
                          </span>
                        </strong>
                        <div className="box-content">
                          {alldata.payment_method_id.name}{" "}
                        </div>
                      </div>
                    ) : null}
                    <div className="box box-order-billing-address">
                      <strong className="box-title">
                        <span>Indirizzo di Fatturazione</span>
                      </strong>
                      <div className="box-content">
                        <address>
                          {billing.name}
                          <br />
                          {billing.street},
                          <br />
                          {billing.street2}
                          <br />
                          {billing.city}, {billing.zip}
                          <br />
                          {billing.country_id.name}
                          <br />
                          T:{" "}
                          <a href={"tel:" + billing.phone}>{billing.phone}</a>
                          <br />
                          C:{" "}
                          <a href={"tel:" + billing.mobile}>{billing.mobile}</a>
                        </address>
                      </div>
                    </div>
                  </div>
                  <div className="textbox-container">
                    <h4>Comunicazioni</h4>
                    <h5>
                      <i className="fas fa-comments"></i> Ci sono{" "}
                      {this.state.communication.message_count} messaggi
                    </h5>
                    <img
                      src={
                        "https://imagineacademy.microsoft.com/content/images/microsoft-img.png "
                      }
                    />
                    <h4>{sessionStorage.getItem("username")}</h4>
                    <textarea
                      name="message"
                      value={this.state.message}
                      onChange={(e) =>
                        this.setState({ [e.target.name]: e.target.value })
                      }
                    />
                    <button
                      disabled={this.state.message.length < 3}
                      onClick={() =>
                        api
                          .portalpostChatter(
                            this.state.message,
                            this.props.match.params.idorder,
                            this.props.match.params.token
                          )
                          .then((value) => {
                            api
                              .portalSaleOrder(
                                this.props.match.params.idorder,
                                this.props.match.params.token
                              )
                              .then((value) => {
                                this.setState({
                                  communication: value.chatter,
                                  message: "",
                                });
                              });
                            Swal.fire({
                              position: "top-end",
                              icon: "success",
                              html:
                                "<div><h1>Messaggio inviato</h1> <p>Il messaggio è stato inviato con successo</p> </div>",
                              showConfirmButton: false,
                              timerProgressBar: true,
                              timer: 2000,
                              customClass: { container: "swal_container" },
                            });
                          })
                      }
                    >
                      Invia
                    </button>
                    {this.state.communication.message_count>0 && <hr />}


                    {this.renderReply()}
                  </div>
                </div>
                {this.state.payment_id &&
                  this.state.payment_id.bank_transfer_bank_recipient && (
                    <Popup
                      id={"1"}
                      handlePopup={() =>
                        this.setState({ bankpay: !this.state.bankpay })
                      }
                      isVisible={this.state.bankpay}
                    >
                      <table>
                        <tbody>
                          <tr>
                            <td>
                              <label className="subtitle">
                                <Trans i18nKey="bank_name">Bank Name</Trans>:{" "}
                              </label>
                            </td>
                            <td>
                              <span
                                style={{
                                  color: "black",
                                  fontWeight: "600",
                                  textAlign: "right",
                                }}
                              >
                                {" "}
                                {this.state.payment_id.bank_transfer_bank_name}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <label className="subtitle">
                                <Trans i18nKey="bank_recipient">
                                  Bank Recipient
                                </Trans>
                                :{" "}
                              </label>
                            </td>
                            <td>
                              {" "}
                              <span
                                style={{
                                  color: "black",
                                  fontWeight: "600",
                                  marginRight: "auto",
                                }}
                              >
                                {" "}
                                {
                                  this.state.payment_id
                                    .bank_transfer_bank_recipient
                                }
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <label className="subtitle">
                                <Trans i18nKey="bank_iban">IBAN</Trans>:{" "}
                              </label>
                            </td>
                            <td>
                              <span
                                style={{
                                  color: "black",
                                  fontWeight: "600",
                                  textAlign: "right",
                                }}
                              >
                                {" "}
                                {this.state.payment_id.bank_transfer_bank_iban}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                borderTop: "1px solid black",
                                paddingTop: "10px",
                              }}
                            >
                              <label className="subtitle">
                                <Trans i18nKey="due_amount">Due Amount</Trans>:{" "}
                              </label>
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid black",
                                paddingTop: "10px",
                              }}
                            >
                              <span
                                style={{
                                  color: "black",
                                  fontWeight: "600",
                                  textAlign: "right",
                                }}
                              >
                                {this.state.payment_id.amount.toLocaleString(
                                  "it-IT",
                                  {
                                    style: "currency",
                                    currency: "EUR",
                                  }
                                )}
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Popup>
                  )}
                {/*     <Popup
            id={"2"}
            handlePopup={() =>
              this.setState({ stamp_active: !this.state.stamp_active })
            }
            isVisible={this.state.stamp_active}
          >
            <Printbutton
              id={ this.props.match.params.idorder}
              model="sale.order"
            />
          </Popup> */}
              </div>
            </div>
            {this.state.loading && <div className="obscure">
            <Load text="Sto Caricando" />
          </div>}
        </main>
      );
    } else
      return (
        <div>
          <div className="obscure">
            <Load text="Sto Caricando" />
          </div>
        </div>
      );
  }
}
