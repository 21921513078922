import React, { Component } from "react";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import "./SwipableSidebar.css";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";

import $ from "jquery";
import { Link } from "react-router-dom";
const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
});

export default class SwipableSidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = { top: false, actual: window.location.pathname };
  }
  toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    this.props.handleOpen();
    this.setState({ ...this.state, [anchor]: open });
  };
  followLink(target) {
    this.setState({ actual: target });
    this.toggleDrawer("top", false);
    this.props.handleOpen();
  }
  authAccordion(permission, title, array) {
    if (permission) {
      return (
        <Accordion>
          <AccordionSummary
            expandIcon={<i className="fa fa-arrow-up" />}
            aria-controls="panel1a-content"
            id="panel1a"
          >
            {title}
          </AccordionSummary>
          <AccordionDetails>
            {array.map((single) => {
              return this.authLink(
                single.permission || permission,
                single.link,
                single.label
              );
            })}
          </AccordionDetails>
        </Accordion>
      );
    }
  }
  authLink(permission, link, label) {
    if (permission)
      return (
        <li className={this.state.actual.includes(link) > 0 ? "current" : ""}>
          <Link onClick={() => this.followLink(link)} to={link}>
            {label}
          </Link>
        </li>
      );
  }
  render() {
    return (
      <React.Fragment>
        {this.props.permission ? (
          <SwipeableDrawer
            anchor={"top"}
            open={this.state["top"] || this.props.shouldOpen}
            onClose={this.toggleDrawer("top", false)}
            onOpen={this.toggleDrawer("top", true)}
          >
            <div
              style={{ width: "100%", height: "100%" }}
              className="sidebar_mobile"
            >
              <ul>
                {this.authLink(true,"/","Bacheca")}
                {this.authAccordion(this.props.permission.vendite, "Vendite", [
                  { label: "ordini", link: "/vendite/ordini" },
                  { label: "preventivi", link: "/vendite/preventivi" },
                  { label: "preventivi accettati", link: "/vendite/preventivi-accettati" },
                  { permission:this.props.permission.vendite_picking, label: "Picking", link:"/vendite/picking"},
                  { permission:this.props.permission.vendite_fatture, label: "Fatture", link:"/vendite/fatture"}
                ])}
                 {this.authAccordion(this.props.permission.acquisti, "Acquisti", [
                  { label: "ordini", link: "/acquisti/ordini" },
                  { label: "RFQ", link: "/acquisti/preventivi" },
                ])}
                 {this.authAccordion(this.props.permission.crm, "CRM", [
                  { label: "registra ", link: "/crm/registra-visita" },
                  { label: "registra cliente", link: "/crm/create-customer" },
                  {label : "Viusalizza Opportunità", link : "/crm/visualizza-opportunita"}
                ])}
                {this.authAccordion(this.props.permission.preventivatore, "Preventivatore", [
                  { label: "crea ", link: "/preventivatore/new" },
                  { label: "bozze", link: "/preventivatore/drafts" },
                ])}
                {this.authAccordion(this.props.permission.pagamenti, "Pagamenti", [
                  { label: "crea richiesta ", link: "/pagamenti/richiedi-pagamenti" },
                  { label: "visualizza pagamenti", link: "/pagamenti/visualizza-pagamenti" },
                ])}
                 {this.authAccordion(this.props.permission.catalogo, "Catalogo", [
                  { label: "Visualizza Catalogo", link: "/catalogo" },
                ])}
                {this.authAccordion(this.props.permission.followup, "FollowUp", [
                  { label: "FollowUp Inviati", link: "/followup/inviati" },
                  { label: "FollowUp Da Inviare", link: "/followup/dainviare" },

                ])}
                
                {/*<li*/}
                {/*  className={*/}
                {/*    this.state.actual.includes("/tessuti") > 0 ? "current" : ""*/}
                {/*  }*/}
                {/*>*/}
                {/*  <Link*/}
                {/*    onClick={() => this.followLink("/tessuti")}*/}
                {/*    to="/tessuti"*/}
                {/*  >*/}
                {/*    Tessuti*/}
                {/*  </Link>*/}
                {/*</li>*/}
              </ul>
            </div>
          </SwipeableDrawer>
        ) : null}
      </React.Fragment>
    );
  }
}
