import React, { Component } from "react";
import api from "../../../config";
import AsyncSelect from "react-select/async";
import { Link, Redirect } from "react-router-dom";
import Load from "../../../components/Load";
import Utility from "../../../Utility";
var _ = api._;
export default class VisPagamento extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      fetching: false,
      orders: [],
      count: 0,
      page: 0,
      max: 1,
      ordered_get: "",
    };
  }
  componentDidMount() {
    this.setState({ loading: true });
    api.fetchPayments(0, 50, this.state.ordered_get).then((value) => {
      this.setState({
        orders: value.rows,
        count: value.total,
        max: Math.ceil(value.total / 50) - 1,
        loading: false,
      });
    });
  }
  handleOrdinePagamento = (attributo) => {
    this.setState(
      {
        fetching:true,
        ordered_get:
          attributo +
          " " +
          (this.state.ordered_get.includes("desc") ? "asc" : "desc"),
      },
      () => {
        api.fetchPayments(0, 50, this.state.ordered_get).then((value) => {
          this.setState({
            orders: value.rows,
            count: value.total,
            max: Math.ceil(value.total / 50) - 1,
            fetching: false,
          });
        });
      }
    );
  };
  loadOptions = (inputValue, callback) => {
    this.setState({ searching: true });
    if (inputValue.length > 2) {
      api.searchAsyncPayments(inputValue).then((value) => {
        if (value) callback(value.rows);
        this.setState({ searching: false });
      });
    }
  };
  fetchmore = (page) => {
    var offset = 50;
    this.setState({ fetching: true });

    if (this.state.page * offset < this.state.count) offset = 50;
    else {
      offset = this.state.count - 50;
    }
    api
      .fetchPayments(Number(page * offset), 50, this.state.ordered_get)
      .then((value) => {
        this.setState({
          orders: value.rows,
          count: value.total,
          fetching: false,
        });
      });
  };
  renderTablesLoader() {
    var res = [];
    for (var i = 0; i < 30; i++) {
      res.push(
        <tr key={i} className="loader">
          <td data-th="Ordine n." className="col id">
            <span style={{ animationDelay: i * 700 + "ms" }} />
          </td>
          <td data-th="Data" className="col date">
            <span style={{ animationDelay: i * 700 + "ms" }} />
          </td>
          <td data-th="Spedisci A" className="col shipping ">
            <span style={{ animationDelay: i * 700 + "ms" }} />
          </td>
          <td data-th="Totale ordine" className="col total ">
            <span style={{ animationDelay: i * 700 + "ms" }} />
          </td>
          <td data-th="Status" className="col status ">
            <span style={{ animationDelay: i * 700 + "ms" }} />
          </td>
          <td data-th="Azioni" className="col actions">
            <span style={{ animationDelay: i * 700 + "ms" }} />
          </td>
          <td data-th="Azioni" className="col actions">
            <span style={{ animationDelay: i * 700 + "ms" }} />
          </td>
          <td data-th="Azioni" className="col actions">
            <span style={{ animationDelay: i * 700 + "ms" }} />
          </td>
          <td data-th="Azioni" className="col actions">
            <span style={{ animationDelay: i * 700 + "ms" }} />
          </td>
          <td data-th="Azioni" className="col actions">
            <span style={{ animationDelay: i * 700 + "ms" }} />
          </td>
        </tr>
      );
    }
    return res;
  }

  renderTablesOrder() {
    var res = [];
    if (this.state.orders.length > 0) {
      this.state.orders.forEach((element) => {
        res.push(
          <tr key={element.id}>
            <td data-th="Ordine n." className="col id">
              {element.name}
            </td>
            <td data-th="Data" className="col date">
              {element.create_uid.name}
            </td>
            <td data-th="Spedisci A" className="col shipping">
              {element.partner_id.name}
            </td>
            <td data-th="Totale ordine" className="col total">
              {element.acquirer_id.name}
            </td>
            <td data-th="Totale ordine" className="col total">
              <span className="price">
                {element.amount.toLocaleString("it-IT", {
                  style: "currency",
                  currency: "EUR",
                })}
              </span>
            </td>
            <td data-th="Data" className="col status">
              <span className="price">
                {element.is_request_ok ? (
                  <span style={{ color: "green" }}>&#10004;</span>
                ) : (
                  <span style={{ color: "red" }}>&#10006;</span>
                )}
              </span>
            </td>
            <td data-th="Status" className="col status">
              {element.is_ok ? (
                <span style={{ color: "green" }}>&#10004;</span>
              ) : (
                <span style={{ color: "red" }}>&#10006;</span>
              )}
            </td>
            <td data-th="Status" className="col status">
              {element.is_ko ? (
                <span style={{ color: "green" }}>&#10004;</span>
              ) : (
                <span style={{ color: "red" }}>&#10006;</span>
              )}
            </td>
            {/*   <td data-th="Status" className="col status">
              <Link
                to={"/vendite/ordini/" + element.id}
                className="action view"
              >
                <span>Visualizza</span>
              </Link>
            </td> */}
          </tr>
        );
      });
    }
    return res;
  }

  render() {
    return (
      <div className="page_container">
        <h1 className="page_title">Richieste di Pagamento</h1>
        {/*   <div className="prev_card searchable" style={{ marginBottom: "25px" }}>
          <div className="table_div">
            <div className="row_div">
              <div className="cell_div">
                <p className="subtitle">Cerca Pagamento</p>
                <AsyncSelect
                  cacheOptions
                  isLoading={this.state.searching}
                  onChange={(e) => {}}
                  placeholder={"Cerca un pagamento..."}
                  loadOptions={_.debounce(this.loadOptions, 300)}
                />
              </div>
            </div>
          </div>
        </div> */}
        {this.state.orders.length > 0 ? (
          <div className="pagination_container">
            <div className="page_right">
              {this.state.page === 0 ? 1 : this.state.page * 50}-
              {this.state.page === 0
                ? this.state.count < 50
                  ? this.state.count
                  : 50
                : this.state.page == this.state.max
                ? this.state.count
                : this.state.page * 50 + 50}{" "}
              &#8725; {this.state.count}{" "}
              <span
                onClick={() => {
                  var newpage =
                    this.state.page > 0 ? this.state.page - 1 : this.state.max;

                  this.setState({ page: newpage });
                  this.fetchmore(newpage);
                }}
              >
                &#8249;
              </span>{" "}
              <span
                onClick={() => {
                  var newpage =
                    this.state.page == this.state.max ? 0 : this.state.page + 1;
                  this.setState({ page: newpage });
                  this.fetchmore(newpage);
                }}
              >
                &#8250;
              </span>{" "}
            </div>
          </div>
        ) : null}
        <div className="prev_card searchable">
          {!this.state.loading ? (
            this.state.orders.length > 0 ? (
              <table
                className="data table table-order-items history"
                id="my-orders-table-ord"
              >
                <caption className="table-caption">Ordini</caption>
                <thead>
                  <tr>
                    <Utility
                      choose="header"
                      head_array={[
                        { label: "Numero Richiesta", value: "name", col: "id" },
                        {
                          label: "Creata da",
                          value: "create_uid",
                          col: "date",
                        },
                        {
                          label: "Cliente",
                          value: "partner_id",
                          col: "shipping",
                        },
                        {
                          label: "Gateway di Pagamento",
                          value: "acquirer_id",
                          col: "total",
                        },
                        { label: "Importo", value: "amount", col: "total" },
                        {
                          label: "Inviata?",
                          value: "is_request_ok",
                          col: "status",
                        },
                        {
                          label: "Pagamento Ok?",
                          value: "is_ok",
                          col: "status",
                        },
                        {
                          label: "Pagamento KO?",
                          value: "is_ko",
                          col: "status",
                        },
                      ]}
                      orderstate={this.state.ordered_get}
                      handleState={this.handleOrdinePagamento}
                    />

                    {/* <th scope="col" className="col status"></th> */}
                  </tr>
                </thead>

                <tbody>{this.renderTablesOrder()}</tbody>
              </table>
            ) : (
              <div className="message info empty">
                <span>Non è stato inserito nessun pagamento.</span>
              </div>
            )
          ) : (
            <table
              className="data table table-order-items history"
              id="my-orders-table-ord"
            >
              <caption className="table-caption">Ordini</caption>

              <thead>
                <tr>
                  <th scope="col" className="col id">
                    Numero richiesta
                  </th>
                  <th scope="col" className="col date">
                    Creata da
                  </th>
                  <th scope="col" className="col shipping">
                    Cliente
                  </th>
                  <th scope="col" className="col total">
                    Gateway di pagamento
                  </th>
                  <th scope="col" className="col total">
                    Totale ivato
                  </th>
                  <th scope="col" className="col status">
                    Importo
                  </th>
                  <th scope="col" className="col status">
                    Richiesta inviata correttamente?
                  </th>
                  <th scope="col" className="col status">
                    Pagamento OK?
                  </th>
                  <th scope="col" className="col status">
                    Pagamento KO?
                  </th>
                </tr>
              </thead>

              <tbody>{this.renderTablesLoader()}</tbody>
            </table>
          )}
        </div>
        {this.state.fetching && (
          <div className="obscure">
            <Load text="Carico i nuovi dati" />
          </div>
        )}
      </div>
    );
  }
}
