import React from "react";
import api from "../config";
import Swal from "sweetalert2";

class Logout extends React.Component {
  componentDidMount() {
    document.cookie =
      "username=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  }

  render() {
    api.logout().then((value) => {
      Swal.fire({
        html: "<h1>Logout effettuato con successo</h1>",
        icon: "success",
        position: "top-end",
        customClass: { container: "swal_container" },
        timer: 1000,
        showConfirmButton: false,
        timerProgressBar: true,
        onClose: 
         ()=> window.location.replace("/")
    
      });
      
    });
    return null;
  }
}

export default Logout;
