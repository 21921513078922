import React, { Component } from "react";
import $ from "jquery";
export default class Iframe extends Component {
  componentDidMount() {
    (function ($) {
      $(function () {
        $("#shrFrm").responsiveIframe({ xdomain: "*" });
      });
    })(window.jQuery);
  }
  componentWillUnmount() {}
  render() {
    return (
      <React.Fragment>
        <iframe
          id="shrFrm"
          className="architonic-iframe"
          src="https://vsr.architonic.com/it/8201775"
        ></iframe>
        {}
      </React.Fragment>
    );
  }
}
