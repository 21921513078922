import React, { Component } from "react";
import AnimatedNumber from "react-animated-number";
import "./Bacheca.css";
import { Responsive, WidthProvider } from "react-grid-layout";
import { Line, Doughnut, Pie } from "react-chartjs-2";
import api from "../../config";
import { Link } from "react-router-dom";
import Load from "../../components/Load";

import { InputLabel, MenuItem, Select, Tooltip } from "@material-ui/core";
const GridLayout = WidthProvider(Responsive);
export default class Bacheca extends Component {
  constructor(props) {
    super(props);
    this.state = {
      res: false,
      preventivi: [],
      purchase_order: [],
      pagamenti: [],
      ordini: [],
      permission: this.props.permission,
      loading: false,
      orderedOrders: "",
      orderedPrev: "",
      orderedPay: "",
      orderedBuy: "",
    };
  }
  componentDidMount() {
    this.setState({ loading: true });
    var layout = JSON.parse(localStorage.getItem("layouts_bacheca"));
    if (!layout) layout = this.baseLayout();
    this.setState({ layout });
    api.fetchSaleOrder(1, 0, 10, this.state.orderedPrev).then((value) => {
      if (value) this.setState({ preventivi: value.rows, loading: false });
      else this.setState({loading:false})
    });
    api.fetchSaleOrder(0, 0, 10, this.state.orderedOrders).then((value) => {
      if (value) this.setState({ ordini: value.rows, loading: false });
      else this.setState({loading:false})
    });
    api.fetchPurchaseOrder(0, 0, 10, this.state.orderedBuy).then((value) => {
      if (value) this.setState({ purchase_order: value.rows, loading: false });
      else this.setState({loading:false})
    });
    api.fetchPayments(0, 10, this.state.orderedPay).then((value) => {
      if (value) this.setState({ pagamenti: value.rows, loading: false });
      else this.setState({loading:false})
    });
  }
  onLayoutChange(layout, layouts) {
    localStorage.setItem("layouts_bacheca", JSON.stringify(layouts));
  }
  baseLayout() {
    var layout = {
      lg: [
        { i: "0", x: 0, y: 0, w: 4, h: 2, minW: 2, minH: 2 },
        { i: "1", x: 0, y: 2, w: 4, h: 2, minW: 2, minH: 2 },
        { i: "2", x: 0, y: 4, w: 4, h: 2, minW: 2, minH: 2 },
        { i: "3", x: 0, y: 6, w: 4, h: 2, minW: 2, minH: 2 },
      ],
      md:  [
        { i: "0", x: 0, y: 0, w: 4, h: 2, minW: 2, minH: 2 },
        { i: "1", x: 0, y: 2, w: 4, h: 2, minW: 2, minH: 2 },
        { i: "2", x: 0, y: 4, w: 4, h: 2, minW: 2, minH: 2 },
        { i: "3", x: 0, y: 6, w: 4, h: 2, minW: 2, minH: 2 },
      ],
      sm: [
        { i: "0", x: 0, y: 0, w: 4, h: 2, minW: 2, minH: 2 },
        { i: "1", x: 0, y: 2, w: 4, h: 2, minW: 2, minH: 2 },
        { i: "2", x: 0, y: 4, w: 4, h: 2, minW: 2, minH: 2 },
        { i: "3", x: 0, y: 6, w: 4, h: 2, minW: 2, minH: 2 },
      ],
      xs: [
        { i: "0", x: 0, y: 0, w: 4, h: 2, minW: 2, minH: 2 },
        { i: "1", x: 0, y: 2, w: 4, h: 2, minW: 2, minH: 2 },
        { i: "2", x: 0, y: 4, w: 4, h: 2, minW: 2, minH: 2 },
        { i: "3", x: 0, y: 6,w: 4, h: 2, minW: 2, minH: 2 },
      ],
      xxs:  [
        { i: "0", x: 0, y: 0,w: 4, h: 2, minW: 2, minH: 2 },
        { i: "1", x: 0, y: 2, w: 4, h: 2, minW: 2, minH: 2 },
        { i: "2", x: 0, y: 4, w: 4, h: 2, minW: 2, minH: 2 },
        { i: "3", x: 0, y: 6,w: 4, h: 2, minW: 2, minH: 2 },
      ],
    };
    return layout;
  }
  reset() {
    localStorage.removeItem("layouts_bacheca");
    this.setState({
      layout: undefined,
    });
    setTimeout(() => {
      this.setState({
        layout: this.baseLayout(),
      });
    }, 500);
  }

  handleOrdineOrdini = (attributo) => {
    this.setState(
      {
        orderedOrders:
          attributo +
          " " +
          (this.state.orderedOrders.includes("desc") ? "asc" : "desc"),
      },
      () => {
        api.fetchSaleOrder(0, 0, 10, this.state.orderedOrders).then((value) => {
          if (value) this.setState({ ordini: value.rows, loading: false });
        });
      }
    );
  };

  handleOrdinePreventivi = (attributo) => {
    this.setState(
      {
        orderedPrev:
          attributo +
          " " +
          (this.state.orderedPrev.includes("desc") ? "asc" : "desc"),
      },
      () => {
        api.fetchSaleOrder(1, 0, 10, this.state.orderedPrev).then((value) => {
          if (value) this.setState({ preventivi: value.rows, loading: false });
        });
      }
    );
  };
  handleOrdinePagamenti = (attributo) => {
    this.setState(
      {
        orderedPay:
          attributo +
          " " +
          (this.state.orderedPay.includes("desc") ? "asc" : "desc"),
      },
      () => {
        api.fetchPayments(0, 10, this.state.orderedPay).then((value) => {
          if (value) this.setState({ pagamenti: value.rows, loading: false });
        });
      }
    );
  };
  handleOrdineAcquisti = (attributo) => {
    this.setState(
      {
        orderedBuy:
          attributo +
          " " +
          (this.state.orderedBuy.includes("desc") ? "asc" : "desc"),
      },
      () => {
        api
          .fetchPurchaseOrder(0, 0, 10, this.state.orderedBuy)
          .then((value) => {
            if (value)
              this.setState({ purchase_order: value.rows, loading: false });
          });
      }
    );
  };

  renderElement(
    key,
    title,
    lines,
    headers,
    attribute,
    linking,
    orderfunction,
    orderstate
  ) {
    const renderHeader = (headers) => {
      var res = [];
      headers.forEach((element) => {
        res.push(
          <th
            key={element.label}
            scope="col"
            style={{
              textAlign: element.label === "Totale" || "" ? "right" : "",
              position: "relative",
            }}
            className="col id"
            onClick={() => {
              if(!element.value.includes("link")){
                orderfunction(element.value);
              }
            }}
          >
            {element.label}
            {!element.value.includes("link") && (
              <i
                className="fas fa-arrow-up freccia"
                style={{
                  transform: orderstate.includes("desc")
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                  opacity:
                    orderstate === element.value + " desc" ||
                    orderstate === element.value + " asc"
                      ? "1"
                      : "0",
                  transition: "all 0.3s ease-in-out",
                }}
              ></i>
            )}
          </th>
        );
      });
      return res;
    };
    const renderLines = () => {
      var res = [];
      var super_res = [];
      var attr_array = [];
      var attr_ris = "";
      var appogg;
      for (var i = 0; i < lines.length; i++) {
        for (var j = 0; j < headers.length; j++) {
          if (attribute[j].label.indexOf(".") > 0) {
            attr_array = attribute[j].label.split(".");
            appogg = lines[i][attr_array[0]];
            for (var k = 1; k < attr_array.length; k++) {
              attr_ris = appogg[attr_array[k]];
            }
          } else attr_ris = lines[i][attribute[j].label];
          if (attribute[j].type === "link") {
            res.push(
              <td
                key={attribute[j].label}
                style={{ textAlign: "right" }}
                className="col name"
              >
                <Link
                  style={{ marginTop: "0px" }}
                  to={linking + "/" + attr_ris}
                >
                  Visualizza
                </Link>
              </td>
            );
          } else {
            if (attribute[j].type !== "value")
              res.push(
                <td key={attribute[j].type+j} className="col name">
                  {attr_ris}
                </td>
              );
            else {
              res.push(
                <td
                  key={attribute[j].type+"_"+attribute[j].label}
                  style={{ textAlign: "right" }}
                  className="col name"
                >
                  {attr_ris.toLocaleString("it-IT", {
                    style: "currency",
                    currency: "EUR",
                  })}
                </td>
              );
            }
          }
        }

        super_res.push(<tr key={i - j + 1}>{res}</tr>);
        res = [];
      }
      return super_res;
    };
    return (
      <div key={key} className="card_auto">
        <div className="big_button _auto">
          <h2>
            {title}
            <Link className="little_one" to={linking}>
              Visualizza tutti
            </Link>
          </h2>

          <div className="padding_class">
            <table
              className="data table table-order-items history"
              id="my-orders-table-ord"
            >
              <caption className="table-caption">Ordini</caption>
              <thead>
                <tr>{renderHeader(headers)}</tr>
              </thead>

              <tbody>{lines ? renderLines() : null}</tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
  vacant = (key, title, headers) => {
    const renderHeader = (headers) => {
      var res = [];
      headers.forEach((element) => {
        res.push(
          <th
            key={element}
            scope="col"
            style={{ textAlign: element === "Totale" || "" ? "right" : "" }}
            className="col id"
          >
            {element}
          </th>
        );
      });
      return res;
    };
    return (
      <div key={key} className="card_auto">
        <div className="big_button _auto">
          <h2>{title}</h2>
          <div className="padding_class">
            <table
              className="data table table-order-items history"
              id="my-orders-table-ord"
            >
              <caption className="table-caption">Ordini</caption>
              <thead>
                <tr>{renderHeader(headers)}</tr>
              </thead>
            </table>
            <p>Non sono presenti {title}</p>
          </div>
        </div>
      </div>
    );
  };
  render() {
    const permission = this.state.permission;
    return (
      <div className="page_container">
        <div className="title">
          <h1 className="page_title absolute">La mia Bacheca</h1>
          <ul className="phaser">
            <li>
              <button onClick={() => this.setState({ res: !this.state.res })}>
                {!this.state.res ? "Modifica" : "Conferma"}
              </button>
            </li>
            <li>
              <button onClick={() => this.reset()}>Reset</button>
            </li>
          </ul>
        </div>
        {this.state.layout && (
          <GridLayout
            margin={[25, 25]}
            breakpoints={{ lg: 1800, md: 1000, sm: 700, xs: 580, xxs: 0 }}
            cols={{ lg: 6, md: 4, sm: 2, xs: 2, xxs: 1 }}
            isResizable={this.state.res}
            isDraggable={this.state.res}
            className="layout"
            layouts={this.state.layout}
            onLayoutChange={(layout, layouts) =>
              this.onLayoutChange(layout, layouts)
            }
            rowHeight={230}
          >
            {this.state.preventivi.length > 0
              && permission.vendite ?
                this.renderElement(
                  "0",
                  "Preventivi",
                  this.state.preventivi,
                  [
                    { label: "N. Preventivo", value: "name" },
                    { label: "Cliente", value: "partner_id" },
                    { label: "Team", value: "team_id" },
                    { label: "Commerciale", value: "user_id" },
                    { label: "Totale", value: "amount_total" },
                    { label: "", value: "link" },
                  ],
                  [
                    { type: "", label: "name" },
                    { type: "", label: "partner_id.name" },
                    { type: "", label: "team_id.name" },
                    { type: "", label: "user_id.name" },
                    { type: "value", label: "amount_total" },
                    { type: "link", label: "id" },
                  ],
                  "/vendite/preventivi",
                  this.handleOrdinePreventivi,
                  this.state.orderedPrev
                )
              : this.vacant("0", "Preventivi", [
                  "N. Preventivo",
                  "Cliente",
                  "Totale",
                ])}

            {this.state.ordini.length > 0
             && permission.vendite ?
                this.renderElement(
                  "1",
                  "Ordini",
                  this.state.ordini,
                  [
                    { label: "N. Ordine", value: "name" },
                    { label: "Cliente", value: "partner_id" },
                    { label: "Team", value: "team_id" },
                    { label: "Commerciale", value: "user_id" },
                    { label: "Totale", value: "amount_total" },
                    { label: "", value: "link" },
                  ],
                  [
                    { type: "", label: "name" },
                    { type: "", label: "partner_id.name" },
                    { type: "", label: "team_id.name" },
                    { type: "", label: "user_id.name" },
                    { type: "value", label: "amount_total" },
                    { type: "link", label: "id" },
                  ],
                  "/vendite/ordini",
                  this.handleOrdineOrdini,
                  this.state.orderedOrders
                )
              : this.vacant("1", "Ordini", [
                  "N. Ordine",
                  "Cliente",
                  "Totale",
                  "",
                ])}

            {this.state.pagamenti.length > 0
              && permission.pagamenti ?
                this.renderElement(
                  "2",
                  "Pagamenti",
                  this.state.pagamenti,
                  [
                    { label: "N. Ordine", value: "name" },
                    { label: "Cliente", value: "partner_id" },
                    { label: "Totale", value: "amount" },
                  ],
                  [
                    { type: "", label: "name" },
                    { type: "", label: "partner_id.name" },
                    { type: "value", label: "amount" },
                  ],
                  "/pagamenti/visualizza-pagamenti",
                  this.handleOrdinePagamenti,
                  this.state.orderedPay
                )
              : this.vacant("2", "Pagamenti", [
                  "N. Ordine",
                  "Cliente",
                  "Totale",
                ])}
            {this.state.purchase_order.length > 0
              && permission.acquisti ?
                this.renderElement(
                  "3",
                  "Ordini d'acquisto",
                  this.state.purchase_order,
                  [
                    { label: "N. Ordine", value: "name" },
                    { label: "Rifornitore", value: "partner_id" },
                    { label: "Totale", value: "amount_total" },
                    { label: "", value: "link" },
                  ],

                  [
                    { type: "", label: "name" },
                    { type: "", label: "partner_id.name" },
                    { type: "value", label: "amount_total" },
                    { type: "link", label: "id" },
                  ],
                  "/acquisti/ordini",
                  this.handleOrdineAcquisti,
                  this.state.orderedBuy
                )
              : this.vacant("3", "Ordini d'acquisto", [
                  "N. Ordine",
                  "Cliente",
                  "Totale",
                ])}
          </GridLayout>
        )}
        {this.state.loading && (
          <div className="obscure">
            <Load text={"Caricamento"} />
          </div>
        )}
      </div>
    );
  }
}
