import React, { Component } from "react";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import api from "../../config";
import Swal from "sweetalert2";
import SearchAnagrafy from "../search_nation/SearchAnagrafy";
import SearchBar from "../search_bar/SearchBar";
import Popup from "../popup/Popup";
import HtmlEditor from "../htmlEditor/HtmlEditor";
var _ = api._;

class PayScreen extends Component {
  constructor(props) {
    super(props);
    this.state = this.initialState();
    this.handleInput = this.handleInput.bind(this);
  }
  initialState = () => {
    return {
      gateway: [],
      paymentOptType: [],
      currency: [],
      pay_code: this.props.nome_prev ? this.props.nome_prev : "",
      firstname: this.props.firstname,
      lastname: this.props.lastname,
      amount: this.props.amount,
      partner_id: this.props.partner_id,
      order_id: this.props.order_id,
      loading: false,
      share_active: false,
      _currency: {
        label: "Seleziona la valuta per il pagamento..",
        value: -11,
      },
      currency: [],
      _gateway: { label: "Seleziona il gateway di pagamento...", value: -11 },
      loadingPayment: false,
      _paymentType: { label: "Seleziona il tipo di pagamento...", value: -11 },
    };
  };
  componentDidMount() {
    api.fetchPaymentType().then((value) => {
      this.setState({ paymentType: value });
    });
    api.fetchPaymentGateway().then((value) => {
      this.setState({ gateway: value });
    });
    api.fetchCurrency().then((value) => {
      this.setState({ currency: value });
    });
  }
  loadOptions = (inputValue, callback) => {
    if (inputValue.length > 2) {
      api.searchAsyncCustomer(inputValue).then((value) => {
        callback(value.rows);
      });
    }
  };
  handleInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  disableButton() {
    var flag = false;
    if (
      (this.state._gateway.provider === "axerve_lightbox" ||
        this.state._gateway.provider === "bank_transfer") &&
      !this.state.order_id > 0
    )
      flag = true;

    if (
      this.state._gateway.value < 0 ||
      (this.state._gateway.provider.indexOf("axerve") > -1 &&
        this.state._paymentType.value < 0) ||
      !this.state.partner_id > 0
    ) {
      flag = true;
    }
    return flag;
  }
  performPayment() {
    this.setState({ loadingPayment: true });
    var flag = true;
    var errors = "";
    var validate = {
      acquirer_id:
        this.state._gateway.value > 0 ? this.state._gateway.value : "",
      amount: this.state.amount,
      reference: this.state.pay_code,
      partner_id: this.state.partner_id,
      axerve_payment_type:
        this.state._paymentType.value !== -11
          ? this.state._paymentType.value
          : "",
      currency_id: this.state._currency.value,
    };
    if (this.props.order_id) validate.order_id = this.props.order_id;
    for (var attr in validate) {
      if (
        (validate[attr] === "" ||
          validate[attr] === null ||
          validate[attr] === undefined) &&
        !attr === "axerve_payment_type" &&
        !this.state._gateway.provider === "bank_transfer"
      ) {
        flag = false;
        errors += attr + " ";
      }
    }
    if (flag === false) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        html:
          "<div><h1>Attenzione!</h1><p> Non hai compilato alcuni campi ! Manca " +
          errors +
          "</p></div>",
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 2000,
        customClass: { container: "swal_container" },
      });
      this.setState({ loadingPayment: false });
    } else {
      this.props
        .putPayment(validate, this.state.order_id)
        .then((value) => {
          this.setState({ loadingPayment: false });
          if (value && (value.code === "200" || value.code === 200))
            Swal.fire({
              position: "top-end",
              icon: "success",
              html:
                "<div><h1>Richiesta inoltrata</h1><p>" +
                value.description +
                "</p></div>",
              showConfirmButton: false,
              timerProgressBar: true,
              timer: 2000,
              customClass: { container: "swal_container" },
              onClose: () => {
                if (this.props.onEndPay) {
                  this.props.onEndPay(
                    this.state._gateway.provider.toUpperCase() ===
                      "axerve_lightbox".toUpperCase() ||
                      this.state._gateway.provider.toUpperCase() ===
                        "bank_transfer".toUpperCase()
                  );
                } else
                  {
                (this.state._gateway.provider.toUpperCase() ===
                    "axerve_lightbox".toUpperCase() ||
                    this.state._gateway.provider.toUpperCase() ===
                      "bank_transfer".toUpperCase()) &&
                    this.setState({ share_active: true });}
              },
            });
          else
            Swal.fire({
              position: "top-end",
              icon: "warning",
              html:
                "<div><h1>Errore</h1><p>" + value.description + "</p></div>",
              showConfirmButton: false,
              timerProgressBar: true,
              timer: 3000,
              customClass: { container: "swal_container" },
            });
        })
        .catch((error) => {
          Swal.fire({
            position: "top-end",
            icon: "error",
            html:
              "<div><h1>Pagamento Fallito!</h1><p> Il pagamento non è stato richiesto correttamente, riprova.<br/> '" +
              errors +
              "'</p></div>",
            showConfirmButton: false,
            timerProgressBar: true,
            timer: 6000,
            customClass: { container: "swal_container" },
            onClose: () => {
              this.setState({ loadingPayment: false });
            },
          });
        });
    }
  }
  setSearchOrder = (e) => {
    if (e) {
      console.log(e);
      this.setState({
        partner_id: e.partner_id.id,
        firstname: e.partner_id.name,
        order_id: e.value,
        amount: e.amount_total - (e.invoice_paid_amount || 0),
        pay_code: e.name,
        _currency: { value: e.currency_id.id, label: e.currency_id.name },
      });
    }
  };
  setSearch = (e) => {
    if (e) {
      this.setState({
        search_user: e.label,
        firstname: e.value.firstname,
        lastname: e.value.lastname,
        partner_id: e.value.partner_id,
      });
    }
  };
  render() {
    return (
      <React.Fragment>
        {this.props.title && (
          <h1 className="page_title">Richieste di Pagamento</h1>
        )}

        <div
          id="ric_pay_card"
          style={this.props.isPopup && { transform: "none", boxShadow: "none" }}
          className="prev_card searchable"
        >
          <div className="table_div">
            <div className="row_div">
              <div className="cell_div">
                <p className="subtitle">Gateway di pagamento</p>{" "}
                <Select
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                  menuPortalTarget={document.body}
                  placeholder="Seleziona Gateway di pagamento..."
                  options={this.state.gateway}
                  value={this.state._gateway}
                  onChange={(e) => {
                    this.setState({ _gateway: e });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="table_div">
            <div className="row_div">
              <div className="cell_div">
                <h3>Dettagli Transazione</h3>
              </div>
            </div>
            <div className="row_div">
              {this.props.order && (
                <SearchBar
                  customStyle={{ padding: "0px" }}
                  cell
                  order
                  label={"Cerca Ordine"}
                  setSearch={this.setSearchOrder}
                  async
                />
              )}
              {this.props.searchable && (
                <SearchAnagrafy
                  cell
                  placeholder={
                    this.state.firstname !== "" &&
                    this.state.firstname !== undefined
                      ? this.state.firstname
                      : "Cerca Contatto"
                  }
                  customStyle={{ padding: "0px" }}
                  permission={this.props.permission.opt_contact_adv_search}
                  label={"Cerca Contatto"}
                  setSearch={this.setSearch}
                  apiCall={api.searchAsyncCustomer}
                  async
                />
              )}
            </div>
            <div className="row_div">
              <div className="cell_div">
                <p className="subtitle">Importo &euro;</p>{" "}
                <input
                  name="amount"
                  type="number"
                  step="any"
                  onChange={(e) => {
                    this.setState({ amount: e.target.value });
                  }}
                  value={this.state.amount}
                />
              </div>
              <div className="cell_div">
                <p className="subtitle">Valuta </p>{" "}
                <Select
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                  menuPortalTarget={document.body}
                  placeholder="Scegli la valuta del pagamento..."
                  options={this.state.currency}
                  value={this.state._currency}
                  onChange={(e) => this.setState({ _currency: e })}
                />
              </div>
            </div>
            <div className="row_div">
              <div className="cell_div">
                <p className="subtitle">Riferimento pagamento </p>{" "}
                <input
                  name="pay_code"
                  type="text"
                  value={this.state.pay_code}
                  onChange={(e) =>
                    this.setState({ [e.target.name]: e.target.value })
                  }
                />
              </div>
              {(this.state._gateway.provider === "axerve_pbl" ||
                this.state._gateway.provider === "axerve_lightbox") && (
                <div className="cell_div">
                  <p className="subtitle">Axerve Payment Type</p>{" "}
                  <Select
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                    menuPortalTarget={document.body}
                    placeholder="Scegli metodo di pagamento..."
                    options={this.state.paymentType}
                    value={this.state._paymentType}
                    onChange={(e) => this.setState({ _paymentType: e })}
                  />
                </div>
              )}
            </div>
            <div className="row_div">
              <div className="cell_div left_align">
                <button
                  className=" create button btn-standard btn-green"
                  type="submit"
                  disabled={this.disableButton()}
                  onClick={() => this.performPayment()}
                >
                  {this.state.loadingPayment ? (
                    <div className="loginloader"></div>
                  ) : (
                    <span>Invia Richiesta</span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
        {this.state.share_active && (
          <Popup
            id={"1"}
            width={"60vw"}
            handlePopup={() =>
              this.setState({ share_active: !this.state.share_active })
            }
            isVisible={this.state.share_active}
          >
            <div style={{ width: "600px" }}>
              {this.state.share_active && (
                <HtmlEditor
                  template_id="mohd_payment_system.email_template_share_order"
                  closePopUp={() => this.setState({ share_active: false })}
                  model="sale.order"
                  partner_ids={[this.state.partner_id]}
                  res_id={this.state.order_id}
                  subject={"Preventivo "}
                />
              )}
            </div>
          </Popup>
        )}
      </React.Fragment>
    );
  }
}

export default PayScreen;
