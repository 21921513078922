import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Animated } from "react-animated-css";
import "./ListPreventivatori.css";
import { InputLabel, MenuItem, Select, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Swal from "sweetalert2";
import api from "../../config";
import prev from "../../assets/price_vector.png";
export default class ListPreventivatori extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nome_prev: "",
      search: "",
      bozze: [],
      loading: false,
      team: 0,
      teams: [],
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    api.fetchDraftList(1, 0, 50).then((value) => {
      if (value) {
        console.log("rows", value.rows);
        var teams = value.rows.map((single) => {
          console.log(single.team_id.name);
          return single.team_id.name;
        });
        teams = teams.filter((v, i) => teams.indexOf(v) === i);
        this.setState({ bozze: value.rows, loading: false, teams: teams });
      } else this.setState({ loading: false });
    });
  }
  freeLocal(key) {
    Swal.fire({
      html:
        "<h1>Attenzione</h1><p>Sei sicuro di voler eliminare il preventivo " +
        key +
        "?</p>",
      icon: "warning",
      showCancelButton: true,
      customClass: {
        confirmButton: "btn_modifica",
        cancelButton: "btn_annulla",
        container: "swal_container",
      },
      confirmButtonText: "Si",
      cancelButtonText: "Annulla",
      buttonsStyling: false,
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          customClass: { container: "swal_container" },
          html:
            "<h1>Preventivo eliminato</h1><p>Hai eliminato correttamente il preventivo</p>",
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
          timerProgressBar: true,
          position: "center",
          onClose: () => {
            /* api.prevUnlink(id).then((e) =>
              api.fetchDraftList(1, 0, 50).then((value) => {
                if (value) this.setState({ bozze: value.rows });
              })
            ); */
          },
        });
      }
    });
  }
  Tooltip(props) {
    const useStylesBootstrap = makeStyles((theme) => ({
      arrow: {
        color: theme.palette.common.black,
      },
      tooltip: {
        backgroundColor: theme.palette.common.black,
        boxShadow: theme.shadows[1],
        fontSize: 14,
      },
    }));
    const classes = useStylesBootstrap();

    return <Tooltip arrow classes={classes} {...props} />;
  }
  deletePrev(id, nome) {
    Swal.fire({
      html:
        "<h1>Attenzione</h1><p>Sei sicuro di voler eliminare il preventivo " +
        nome +
        "?</p>",
      icon: "warning",
      showCancelButton: true,
      customClass: {
        confirmButton: "btn_modifica",
        cancelButton: "btn_annulla",
        container: "swal_container",
      },
      confirmButtonText: "Si",
      cancelButtonText: "Annulla",
      buttonsStyling: false,
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          customClass: { container: "swal_container" },
          html:
            "<h1>Preventivo eliminato</h1><p>Hai eliminato correttamente il preventivo</p>",
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
          timerProgressBar: true,
          position: "center",
          onClose: () => {
            api.prevUnlink(id).then((e) =>
              api.fetchDraftList(1, 0, 50).then((value) => {
                if (value) this.setState({ bozze: value.rows });
              })
            );
          },
        });
      }
    });
  }
  renderList() {
    var bozze = this.state.bozze;
    var res = [];
    var res_wrap = [];
    var i = 1;

    this.state.teams.forEach((team) => {
      res = [];
      bozze.forEach((element) => {
        if (
          (element.team_id.name !== this.state.team && this.state.team !== 0) ||
          (this.state.search.length > 0 &&
            !(
              element.nome_prev
                .toUpperCase()
                .indexOf(this.state.search.toUpperCase()) >= 0 ||
              element.partner_id.name
                .toUpperCase()
                .indexOf(this.state.search.toUpperCase()) >= 0
            ))
        ) {
        } else
        if(element.team_id.name===team)
          res.push(
            <Animated
              key={i++}
              animationIn="bounceIn"
              animationOut="bounceOut"
              animationInDelay={200 * i}
              className="bozza_btn"
              animationInDuration={200}
            >
              <p className="iconafree">
                <this.Tooltip title={"Elimina " + element.nome_prev}>
                  <i
                    onClick={() =>
                      this.deletePrev(element.id, element.nome_prev)
                    }
                    className="fa fa-trash"
                  ></i>
                </this.Tooltip>
              </p>{" "}
              <Link
                onClick={() => this.props.setNav("/preventivatore/new")}
                to={"/preventivatore/new/" + element.id}
              >
                <img alt={element.nome_prev} src={prev} />

                <p
                  style={{
                    fontSize: element.nome_prev
                      ? element.nome_prev.concat(element.partner_id.name)
                          .length > 20
                        ? "13px"
                        : "16px"
                      : element.name.concat(element.partner_id.name).length > 20
                      ? "13px"
                      : "16px",
                  }}
                >
                  {element.nome_prev.toUpperCase()}
                  <br />
                  <b>{element.partner_id.name}</b>
                </p>
              </Link>
            </Animated>
          );
      });
      console.log("res.length",res.length)
      if (res.length > 0) {
        res_wrap.push(
          <div key={"prev_card_" + team} className="draft_team_container">
            <h1>{team}</h1>
            {res}
          </div>
        );
      }
      res = [];
    });

    return res_wrap;
  }
  render() {
    return (
      <div className="page_container">
        <h1 className="page_title">Bozze </h1>

        <div className="left_align">
          <div>
            <label htmlFor="search_prev">
              <strong>Cerca</strong>
            </label>
            <br />
            <input
              id="search_prev"
              type="text"
              placeholder="ex. Preventivo1 oppure Nome Cognome"
              className="left_align"
              value={this.state.search}
              onChange={(e) => this.setState({ search: e.target.value })}
            />
          </div>

          <Select
            labelId="select-label"
            value={this.state.team}
            id="demo-simple-select"
            onChange={(e) => {
              this.setState({ team: e.target.value });
            }}
          >
            <MenuItem value={0}>
              {this.state.team !== 0 ? "Tutti" : "Filtra per team.."}
            </MenuItem>
            {this.state.teams &&
              this.state.teams.map((single) => {
                return <MenuItem value={single}>{single}</MenuItem>;
              })}
          </Select>
        </div>
        {this.state.loading ? (
          <div
            className="bozze_container left_align"
            style={{ marginTop: "20px" }}
          >
            {this.state.bozze.length > 0 ? (
              this.renderList().length > 0 ? (
                this.renderList()
              ) : (
                <p>
                  Non esistono clienti o preventivi corrispondenti alla tua
                  ricerca, crea un nuovo preventivo o ripeti la ricerca
                </p>
              )
            ) : (
              <p>
                Non hai ancora effettuato preventivi, crea un nuovo preventivo
                ora!
              </p>
            )}
          </div>
        ) : (
          <div
            className="bozze_container left_align"
            style={{ marginTop: "20px" }}
          >
            {this.state.bozze.length > 0 ? (
              this.renderList().length > 0 ? (
                this.renderList()
              ) : (
                <p>
                  Non esistono clienti o preventivi corrispondenti alla tua
                  ricerca, crea un nuovo preventivo o ripeti la ricerca
                </p>
              )
            ) : (
              <p>
                Non hai ancora effettuato preventivi, crea un nuovo preventivo
                ora!
              </p>
            )}
          </div>
        )}
      </div>
    );
  }
}
