import React, { Component } from "react";
import "./CheckoutPrev.css";
import { makeStyles } from "@material-ui/core/styles";
import { Tooltip } from "@material-ui/core";
import $ from "jquery";
import api from "../../config";
import Swal from "sweetalert2";
import Creatable from "react-select/creatable";
import Select from "react-select";
import Printbutton from "../../components/printButton/Printbutton";
import { Animated } from "react-animated-css";
import Popup from "../../components/popup/Popup";
import HtmlEditor from "../../components/htmlEditor/HtmlEditor";
import Textfit from "react-textfit/lib/Textfit";
import PayScreen from "../../components/pagamento/PayScreen";
export default class CheckoutPrev extends Component {
  constructor(props) {
    super(props);
    this.state = {
      total: 0,
      mod_sconto: false,
      pay_code: "",
      shipping: 0,
      stamp_active: false,
      details: this.props.details,
      height: [],
      share_active: false,
      modify: "",
      pay: false,
      disable: false,
      cartshow: false,
      payTermsOpt: [],
    };
  }
  componentDidMount() {
    api.getPayTerms().then((value) => {
      this.setState({ payTermsOpt: value });
    });
    var calc =
      this.props.details.discount_rate > 0
        ? 100 -
          (100 * this.props.details.amount_total) /
            (this.props.details.amount_untaxed + this.props.details.amount_tax)
        : 0;
    calc = Math.ceil(calc);
    this.setState({
      fixedprice: this.props.details.discount_rate,
      sconto: calc,
    });
  }
  getFloorSections(id_floor) {
    const sections = this.props.sections;
    const floors = this.props.floors;
    var res = [];
    if (floors !== [{}])
      sections.forEach((element) => {
        if (element.id_floor === id_floor) {
          res.push(element);
        }
      });
    return res;
  }
  getSectionItems(id_section) {
    const items = this.props.items;
    var res = [];
    if (items !== [{}])
      items.forEach((element) => {
        if (element.id_section === id_section) {
          res.push(element);
        }
      });

    res.sort(function (a, b) {
      var keyA = a.sequence,
        keyB = b.sequence;
      // Compare the 2 dates
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });
    return res;
  }

  renderLines(items) {
    var res = [];
    if (items) {
      items.forEach((element) => {
        res.push(
          <tr key={element.id} id="order-item-row-516">
            <td className="col img" style={{ width: "50px" }} data-th="Foto">
              <img
                className="preview_product"
                alt={element.foto0.url ? element.nome : "Foto non trovata"}
                src={api.auth_api + element.foto0.url}
              />
            </td>
            <td className="col name" data-th="Nome">
              <div>
                <strong className="product name product-item-name">
                  {element.nome}
                </strong>
              </div>
            </td>
            <td className="col brand" data-th="brand">
              {element.brand.label}
            </td>
            <td className="col desc" data-th="desc">
              <div>{element.desc}</div>
            </td>
            <td className="col sku" data-th="sku">
              {element.sku}
            </td>
            <td className="col qty" data-th="Qtà">
              <ul className="items-qty">
                <li className="item">
                  <span className="content">
                    <strong>{element.qta}</strong>
                  </span>
                </li>
              </ul>
            </td>

            <td className="col price" data-th="Prezzo">
              {!this.props.show_iva
                ? Number(element.vendita).toLocaleString("it-IT", {
                    style: "currency",
                    currency: "EUR",
                  })
                : Number(element.price_unit_taxed_undiscounted).toLocaleString(
                    "it-IT",
                    {
                      style: "currency",
                      currency: "EUR",
                    }
                  )}
            </td>
            <td className="col iva" data-th="Prezzo">
              {element.taxes_ids.map((element) => {
                return <span key={element.label}>{element.label}</span>;
              })}
            </td>
            <td className="col qty" data-th="Discount">
              <span className="content">
                <strong>{element.discount + " %"}</strong>
              </span>
            </td>
            <td className="col subtotal" data-th="Totale prodotti">
              <span className="price-including-tax" data-label="Tasse incl.">
                <span className="cart-price">
                  <span className="price">
                    {!this.props.show_iva
                      ? Number(element.price_subtotal).toLocaleString("it-IT", {
                          style: "currency",
                          currency: "EUR",
                        })
                      : Number(element.price_total).toLocaleString("it-IT", {
                          style: "currency",
                          currency: "EUR",
                        })}
                  </span>{" "}
                </span>
              </span>
            </td>
          </tr>
        );
      });
    }

    return res;
  }
  collapse(id_section) {
    var span = $("#" + id_section + "span");
    if (span.hasClass("rotate")) {
      span.removeClass("rotate");
    } else {
      span.addClass("rotate");
    }
    var div = $("#" + id_section);
    div.css("height", div.innerHeight() + "px");
    var height = this.state.height;
    var particular_height = 0;
    var index;
    if (height !== []) {
      height.forEach((element) => {
        if (element.id === id_section) {
          particular_height = element.height;
          index = height.indexOf(element);
          index === 0 ? height.shift() : height.splice(index, index);
        }
      });
    }
    if (particular_height === 0) {
      height.push({ id: id_section, height: div.innerHeight() });
      this.setState({ height });
      div.css("overflow", "hidden");
    } else {
      setTimeout(() => div.css("overflow", "visible"), 500);
    }
    div.css("height", particular_height + "px");
  }
  renderAll() {
    const isEmptyCheckFloor = (floor) => {
      var flag = false;
      this.props.items.forEach((element) => {
        if (element.id_floor == floor.id) {
          flag = true;
        }
      });
      return flag;
    };
    const isEmptyCheckSection = (section) => {
      var flag = false;
      this.props.items.forEach((element) => {
        if (element.id_section == section.id) {
          flag = true;
        }
      });
      return flag;
    };
    var res = [];
    var cardRes = [];
    var sections;
    var items;
    this.props.floors.forEach((floor) => {
      if (isEmptyCheckFloor(floor)) {
        if (floor.nome !== "DEFAULT") {
          res.push(<h1 key={floor.nome + floor.id + "_h1"}>{floor.nome}</h1>);
          res.push(<hr key={floor.id + floor.nome + "_hr"} />);
        }
        sections = this.getFloorSections(floor.id);
        sections.forEach((section) => {
          items = this.getSectionItems(section.id);
          if (items.length > 0)
            if (section.nome !== "DEFAULT")
              res.push(
                <thead key={section.nome + "_" + section.id + "_section"}>
                  <tr>
                    <th
                      colSpan="1"
                      style={{ fontSize: "0.8em", cursor: "pointer" }}
                      className="col"
                      onClick={() => this.collapse(section.id)}
                    >
                      <span className="section-title">{section.nome}</span>
                      <span style={{ float: "right", marginLeft: "5px" }}>
                        <i
                          id={section.id + "span"}
                          className="fa fa-arrow-down"
                        />
                      </span>
                    </th>
                  </tr>
                </thead>
              );
          if (items.length > 0)
            res.push(
              <div
                key={section.id + "_section"}
                id={section.id}
                className="_expanded"
              >
                <table key={section.id - floor.id}>
                  <thead key={floor.id + section.id}>
                    <tr>
                      <th className="col img"></th>
                      <th className="col name">Nome</th>
                      <th className="col sku">Brand</th>
                      <th className="col sku">Descrizione</th>
                      <th className="col sku">Sku</th>
                      <th className="col qty">Qtà</th>
                      <th className="col price">Prezzo Unit.</th>
                      <th className="col iva">Iva</th>
                      <th className="col qty">Sconto</th>
                      <th
                        colSpan="1"
                        style={{ textAlign: "right" }}
                        className="col subtotal"
                      >
                        Subtotale
                      </th>
                    </tr>
                  </thead>
                  <tbody>{this.renderLines(items)}</tbody>
                </table>
              </div>
            );
        });
        cardRes.push(
          <div key={"prev_card" + floor.id} className="prev_card">
            {res}
          </div>
        );
        res = [];
      }
    });
    return cardRes;
  }
  renderElement(nome, label, anagrafy) {
    var res = [];
    res.push(
      <label key="Label">
        <strong>{label}:</strong>{" "}
      </label>
    );
    if (nome.indexOf(".label") > 0) {
      var attr = nome.substring(0, nome.indexOf(".label"));
      switch (attr) {
        case "payment_term_id": {
          res.push(
            this.state.modify === attr ? (
              <React.Fragment key={"Input" + attr}>
                <Select
                  styles={{
                    menuPortal: (base) => ({
                      ...base,
                      zIndex: 9999,
                      width: "250px",
                    }),
                  }}
                  menuPortalTarget={document.body}
                  isClearable={true}
                  className="select_modify"
                  classNamePrefix={"select_modify"}
                  placeholder={"____________"}
                  options={this.state.payTermsOpt}
                  onChange={(e) => {
                    this.setState({ payment_term_id: e });
                  }}
                />

                <i
                  className="fa fa-check"
                  onClick={() => {
                    var details = this.props.details;
                    details.payment_term_id = this.state.payment_term_id;
                    this.setState({ modify: "" });
                    this.props.saveDraft(
                      this.props.floors,
                      this.props.sections,
                      this.props.items,
                      this.props.anagrafy,
                      2,
                      details
                    );
                  }}
                />
              </React.Fragment>
            ) : (
              <span
                onClick={(e) => this.setState({ modify: attr })}
                key={"span" + attr}
                className="capitalize cliccable"
              >
                {anagrafy[attr].label || "_________"}
              </span>
            )
          );
          break;
        }
        case "fatt_country": {
          res.push(
            this.state.modify === attr ? (
              <React.Fragment key={"Input" + attr}>
                <Creatable
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                  menuPortalTarget={document.body}
                  isClearable={true}
                  className="select_modify"
                  classNamePrefix={"select_modify"}
                  placeholder={this.props.anagrafy.fatt_country.label}
                  options={this.props.countries}
                  isLoading={this.props.loadingCountry}
                  value={this.props.anagrafy.fatt_country.value}
                  onChange={(newValue, actionMeta) => {
                    this.props.changeFattCountry(newValue);
                  }}
                />

                <i
                  className="fa fa-check"
                  onClick={() => {
                    this.setState({ modify: "" });
                    this.props.saveDraft(
                      this.props.floors,
                      this.props.sections,
                      this.props.items,
                      this.props.anagrafy,
                      2
                    );
                  }}
                />
              </React.Fragment>
            ) : (
              <span
                onClick={(e) => this.setState({ modify: attr })}
                key={"span" + attr}
                className="capitalize cliccable"
              >
                {anagrafy[attr].label}
              </span>
            )
          );
          break;
        }
        case "fatt_state": {
          res.push(
            this.state.modify === attr ? (
              <React.Fragment key={"Input" + attr}>
                <Creatable
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                  menuPortalTarget={document.body}
                  isClearable={true}
                  className="select_modify"
                  classNamePrefix={"select_modify"}
                  placeholder={this.props.anagrafy.fatt_state.label}
                  options={this.props.fattstates}
                  isLoading={this.props.loadingState}
                  value={this.props.anagrafy.fatt_state.value}
                  formatCreateLabel={(e) => 'Crea "' + e + '"'}
                  onChange={(newValue, actionMeta) => {
                    newValue.country_id = this.props.anagrafy.fatt_country.value;

                    this.props.changeFattState(newValue);
                  }}
                />
                <i
                  className="fa fa-check"
                  onClick={() => {
                    this.setState({ modify: "" });
                    this.props.saveDraft(
                      this.props.floors,
                      this.props.sections,
                      this.props.items,
                      this.props.anagrafy,
                      2
                    );
                  }}
                />
              </React.Fragment>
            ) : (
              <span
                onClick={(e) => this.setState({ modify: attr })}
                key={"span" + attr}
                className="capitalize  cliccable"
              >
                {anagrafy[attr].label}
              </span>
            )
          );
          break;
        }
        case "sped_country": {
          res.push(
            this.state.modify === attr ? (
              <React.Fragment key={"Input" + attr}>
                <Select
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                  menuPortalTarget={document.body}
                  isClearable={true}
                  className="select_modify"
                  classNamePrefix={"select_modify"}
                  placeholder={this.props.anagrafy.sped_country.label}
                  options={this.props.countries}
                  isLoading={this.props.loadingCountry}
                  value={this.props.anagrafy.sped_country.value}
                  onChange={(e) => {
                    this.props.changeSpedCountry(e);
                  }}
                />
                <i
                  className="fa fa-check"
                  onClick={() => {
                    this.setState({ modify: "" });
                    this.props.saveDraft(
                      this.props.floors,
                      this.props.sections,
                      this.props.items,
                      this.props.anagrafy,
                      2
                    );
                  }}
                />
              </React.Fragment>
            ) : (
              <span
                onClick={(e) => this.setState({ modify: attr })}
                key={"span" + attr}
                className="capitalize cliccable"
              >
                {anagrafy[attr].label}
              </span>
            )
          );
          break;
        }
        case "sped_state": {
          res.push(
            this.state.modify === attr ? (
              <React.Fragment key={"Input" + attr}>
                <Select
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                  menuPortalTarget={document.body}
                  isClearable={true}
                  className="select_modify"
                  classNamePrefix={"select_modify"}
                  placeholder={this.props.anagrafy.sped_state.label}
                  options={
                    this.props.spedstates
                      ? this.props.spedstates
                      : this.props.fattstates
                  }
                  isLoading={this.props.loadingState}
                  value={this.props.anagrafy.sped_state.value}
                  onChange={(newValue, actionMeta) => {
                    newValue.country_id = this.props.anagrafy.fatt_country.value;

                    this.props.changeSpedState(newValue);
                  }}
                />
                <i
                  className="fa fa-check"
                  onClick={() => {
                    this.setState({ modify: "" });
                    this.props.saveDraft(
                      this.props.floors,
                      this.props.sections,
                      this.props.items,
                      this.props.anagrafy,
                      2
                    );
                  }}
                />
              </React.Fragment>
            ) : (
              <span
                onClick={(e) => this.setState({ modify: attr })}
                key={"span" + attr}
                className="capitalize cliccable"
              >
                {anagrafy[attr].label}
              </span>
            )
          );
          break;
        }
      }
    } else {
      res.push(
        this.state.modify === nome ? (
          <React.Fragment key={"Input" + nome}>
            <input
              type="text"
              name={nome}
              onChange={(e) => this.props.handleChange(e)}
              value={anagrafy[nome]}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  this.setState({ modify: "" });
                  this.props.saveDraft(
                    this.props.floors,
                    this.props.sections,
                    this.props.items,
                    this.props.anagrafy,
                    2
                  );
                }
              }}
            />
            <i
              className="fa fa-check"
              onClick={() => {
                this.setState({ modify: "" });
                this.props.saveDraft(
                  this.props.floors,
                  this.props.sections,
                  this.props.items,
                  this.props.anagrafy,
                  2
                );
              }}
            />
          </React.Fragment>
        ) : (
          <span
            onClick={() => this.setState({ modify: nome })}
            key={"span" + nome}
            className="capitalize cliccable"
          >
            {anagrafy[nome] ? anagrafy[nome] : "__________"}
          </span>
        )
      );
    }
    return res;
  }
  performPayment() {
    this.setState({ loadingPayment: true });
    var flag = true;
    var validate = {
      acquirer_id:
        this.state._gateway.value > 0 ? this.state._gateway.value : "",
      amount: this.props.details.amount_total,
      reference: this.state.pay_code,
      partner_id: this.props.anagrafy.partner_id,
      axerve_payment_type:
        this.state._paymentType.value !== -11
          ? this.state._paymentType.value
          : "",
      currency_id: this.state._currency.value,
    };
    for (var attr in validate) {
      if (validate[attr] === "" || validate[attr] === null) {
        flag = false;
      }
    }
    if (flag === false) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        html:
          "<div><h1>Attenzione!</h1><p> Non hai compilato alcuni campi !</p></div>",
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 2000,
        customClass: { container: "swal_container" },
      });
      this.setState({ loadingPayment: false });
    } else {
      this.props
        .saveDraft(
          this.props.floors,
          this.props.sections,
          this.props.items,
          this.props.anagrafy,
          2
        )
        .then((value) => {
          if (value)
            api.putPayment(validate, this.props.order_id).then((value) => {
              this.setState({ loadingPayment: false });
              if (value.code === "200" || value.code === 200)
                Swal.fire({
                  position: "top-end",
                  icon: "success",
                  html:
                    "<div><h1>Richiesta inoltrata</h1><p>" +
                    value.description +
                    "</p></div>",
                  showConfirmButton: false,
                  timerProgressBar: true,
                  timer: 4000,
                  customClass: { container: "swal_container" },
                  onClose: () => {
                    this.setState({ pay: false });
                    api.checkoutPay(this.props.order_id);
                    localStorage.removeItem("checkpoint" + this.props.order_id);
                    this.props.lock_all();
                  },
                });
              else
                Swal.fire({
                  position: "top-end",
                  icon: "error",
                  html:
                    "<div><h1>Pagamento Fallito!</h1><p>" +
                    value.description +
                    "</p></div>",
                  showConfirmButton: false,
                  timerProgressBar: true,
                  timer: 5000,
                  customClass: { container: "swal_container" },
                  onClose: () => {},
                });
            });
        });
    }
  }
  renderPayments() {
    return (
      <div id="ric_pay_card" className="prev_card">
        <div className="table_div">
          <div className="row_div">
            <div className="cell_div">
              <p className="subtitle">Gateway di pagamento</p>{" "}
              <Select
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                }}
                menuPortalTarget={document.body}
                placeholder="Seleziona Gateway di pagamento..."
                options={this.state.gateway}
                value={this.state._gateway}
                onChange={(e) => {
                  this.setState({ _gateway: e });
                }}
              />
            </div>
          </div>
        </div>
        <div className="table_div">
          <div className="row_div">
            <div className="cell_div">
              <h3>Dettagli Transazione</h3>
            </div>
          </div>
          <div className="row_div">
            <div className="cell_div">
              <p className="subtitle">Importo &euro;</p>{" "}
              <input
                name="amount"
                type="number"
                /*  value= */
                onChange={(e) => this.setState({ fixedprice: e.target.value })}
              />
            </div>
            <div className="cell_div">
              <p className="subtitle">Valuta </p>{" "}
              <Select
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                }}
                menuPortalTarget={document.body}
                placeholder="Scegli la valuta del pagamento..."
                options={this.state.currency}
                value={this.state._currency}
                onChange={(e) => this.setState({ _currency: e })}
              />
            </div>
          </div>
          <div className="row_div">
            <div className="cell_div">
              <p className="subtitle">Riferimento pagamento </p>{" "}
              <input
                name="pay_code"
                type="text"
                value={this.state.pay_code}
                onChange={(e) =>
                  this.setState({ [e.target.name]: e.target.value })
                }
              />
            </div>
            {this.state._gateway.provider === "axerve_pbl" ||
              (this.state._gateway.provider === "axerve_lightbox" && (
                <div className="cell_div">
                  <p className="subtitle">Axerve Payment Type</p>{" "}
                  <Select
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                    menuPortalTarget={document.body}
                    placeholder="Scegli metodo di pagamento..."
                    options={this.state.paymentType}
                    value={this.state._paymentType}
                    onChange={(e) => this.setState({ _paymentType: e })}
                  />
                </div>
              ))}
          </div>
          <div className="row_div">
            <div className="cell_div  left_align">
              <button
                className=" create button btn-standard btn-green"
                type="submit"
                onClick={() => this.performPayment()}
              >
                {this.state.loadingPayment ? (
                  <div className="loginloader"></div>
                ) : (
                  <span>Invia Richiesta</span>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
  calcolaSconto(nome, value) {
    switch (nome) {
      case "fixedprice": {
        var calc = (
          100 -
          (100 * value) /
            (this.props.details.amount_untaxed + this.props.details.amount_tax)
        ).toFixed(2);
        this.setState({
          fixedprice: value,
          sconto: calc,
        });
        break;
      }
      case "sconto": {
        var calc = (
          this.props.details.amount_untaxed +
          this.props.details.amount_tax -
          ((this.props.details.amount_untaxed + this.props.details.amount_tax) *
            value) /
            100
        ).toFixed(2);
        this.setState({
          sconto: value,
          fixedprice: calc,
        });
        break;
      }
    }
  }
  Tooltip(props) {
    const useStylesBootstrap = makeStyles((theme) => ({
      arrow: {
        color: theme.palette.common.black,
      },
      tooltip: {
        backgroundColor: theme.palette.common.black,
        boxShadow: theme.shadows[1],
        fontSize: 14,
      },
    }));
    const classes = useStylesBootstrap();

    return <Tooltip arrow classes={classes} {...props} />;
  }

  performSave() {
    var anagrafy = this.props.anagrafy;
    var sections = this.props.sections;
    var floors = this.props.floors;
    var items = this.props.items;
    var order_id = this.props.order_id;
    var details = this.props.details;
    details.discount_rate =
      this.state.fixedprice > 0 ? this.state.fixedprice : details.discount_rate;
    var nome_prev = this.props.nome_prev;
    global
      .savePreventivatore(
        floors,
        sections,
        items,
        anagrafy,
        details,
        nome_prev,
        order_id
      )
      .then((value) => {
        this.setState({ fixedprice: 0 });
        Swal.fire({
          title: "Salvato",
          icon: "success",
          timer: 2000,
          onClose: function () {},
        });
      });
  }
  applyDiscount() {
    this.props.putDiscount(this.state.fixedprice);
    this.props.saveDraft(
      this.props.floors,
      this.props.sections,
      this.props.items,
      this.props.anagrafy,
      2
    );
  }
  endPay = (share_active) => {
    this.setState({ pay: false, share_active: share_active });
    api.checkoutPay(this.props.order_id);
    localStorage.removeItem("checkpoint" + this.props.order_id);
    this.props.lock_all();
  };
  render() {
    var anagrafy = this.props.anagrafy;
    var details = this.props.details;
    const today = new Date().toLocaleDateString();
    return (
      <React.Fragment>
        <div className="actions-toolbar order-actions-toolbar">
          <div className="actions">
            <button
              className="btn_crea"
              disabled={this.props.lock}
              style={{ marginLeft: "13px" }}
              onClick={() =>
                this.props
                  .saveDraft(
                    this.props.floors,
                    this.props.sections,
                    this.props.items,
                    this.props.anagrafy,
                    2
                  )
                  .then((value) => {
                    if (value) {
                      api.checkoutPay(this.props.order_id).then((value) => {
                        if (value)
                          Swal.fire({
                            position: "top-end",
                            icon: "success",
                            html:
                              "<div><h1>Richiesta inoltrata</h1><p>Il preventivo è stato salvato</p></div>",
                            showConfirmButton: false,
                            timerProgressBar: true,
                            timer: 2000,
                            customClass: { container: "swal_container" },
                            onClose: () => {
                              this.setState({ pay: false });
                              this.props.lock_all();
                              localStorage.removeItem(
                                "checkpoint" + this.props.order_id
                              );
                            },
                          });
                      });
                    }
                  })
              }
            >
              Conferma Preventivo
            </button>
            <button
              disabled={this.props.lock}
              className="btn_crea"
              style={{ marginLeft: "13px" }}
              onClick={() => this.setState({ pay: !this.state.pay })}
            >
              Conferma con Pagamento
            </button>
            <button
              className="btn_crea"
              style={{ marginLeft: "13px" }}
              onClick={() =>
                this.setState({ share_active: !this.state.share_active })
              }
            >
              Condividi
            </button>
            {/*   <button
              disabled={this.props.lock}
              className="btn_crea"
              style={{ marginLeft: "13px" }}
              onClick={() =>
                api.shareOrder(this.props.order_id).then((value) => {
                  if (value) {
                    Swal.fire({
                      position: "top-end",
                      icon: "success",
                      html:
                        "<div><h1>Preventivo inoltrato!</h1><p>Il cliente ha ricevuto l'email con successo</p></div>",
                      showConfirmButton: false,
                      timerProgressBar: true,
                      timer: 2000,
                      customClass: { container: "swal_container" },
                      onClose: () => {
                        this.setState({ pay: false });
                        api.checkoutPay(this.props.order_id);
                        this.props.lock_all();
                      },
                    });
                  }
                })
              }
            >
              Condividi col Cliente
            </button> */}
            <button
              className="btn_crea"
              style={{ marginLeft: "13px" }}
              onClick={() =>
                this.setState({
                  stamp_active: !this.state.stamp_active,
                  pay: false,
                })
              }
            >
              Stampa
            </button>
          </div>
        </div>
        <div className="printme order-details-items ordered">
          <div className="table-wrapper order-items">
            <div style={{ height: 30, marginBottom: 15 }}>
              <span
                style={{ float: "left", marginTop: 0 }}
                className="field choice newsletter"
              >
                <span
                  className={
                    this.props.show_iva
                      ? "jcf-checkbox jcf-checked jcf-focus"
                      : "jcf-checkbox jcf-unchecked"
                  }
                  id="check_stock"
                >
                  <span onClick={() => this.props.swap_show_iva()} />

                  <input
                    type="checkbox"
                    className="checkbox"
                    name="check_stock"
                    onChange={() => this.props.swap_show_iva()}
                    value={this.props.show_iva}
                    style={{
                      position: "absolute",
                      height: "100%",
                      width: "100%",
                      opacity: 0,
                      margin: "0px",
                    }}
                  />
                </span>
                <label htmlFor="check_stock" className="label">
                  <span>Mostra prezzi ivati</span>
                </label>
              </span>
            </div>
            <div
              className="printme data table table-order-items"
              id="my-orders-table"
              summary="Articoli ordinati"
            >
              {this.renderAll()}
            </div>
            <hr />

            <div
              /* onMouseEnter={()=>{this.setState({cartshow:true})}} */ onClick={() => {
                this.setState({ cartshow: !this.state.cartshow });
              }}
              style={{
                borderTop: "3px solid #D90916",
                borderLeft: "3px solid #D90916",
                borderBottom: "3px solid #D90916",
                overflowY: "auto",
                cursor: "pointer",
                opacity:
                  this.state.share_active || this.state.stamp_active
                    ? "0"
                    : "1",
                transition: "all 0.3s ease-in-out",
                visibility:
                  this.state.share_active || this.state.stamp_active
                    ? "hidden"
                    : "visible",
              }}
              className={
                this.state.cartshow || this.state.mod_sconto
                  ? "open absolute_checkout prev_card"
                  : "absolute_checkout prev_card"
              }
            >
              {/*    <h1 className="page_title">Riepilogo</h1>
            <p><strong>Totale imponibile scontato</strong></p>
            <p> {Number(
                      this.props.details.amount_total -
                        this.props.details.amount_tax
                    ).toLocaleString("it-IT", {
                      style: "currency",
                      currency: "EUR",
                    })}</p>
            <p><strong>Imposte</strong></p>
            <p> {this.props.details.amount_tax.toLocaleString("it-IT", {
                      style: "currency",
                      currency: "EUR",
                    })}</p> */}
              <table>
                <tbody>
                  <tr>
                    <td>
                      <h1 className="page_title">Riepilogo</h1>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <strong>Totale imponibile:</strong>{" "}
                    </td>
                    <td className="rightalign">
                      {" "}
                      {Number(this.props.details.amount_untaxed).toLocaleString(
                        "it-IT",
                        {
                          style: "currency",
                          currency: "EUR",
                        }
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Imposte:</strong>
                    </td>
                    <td className="rightalign">
                      {this.props.details.amount_tax.toLocaleString("it-IT", {
                        style: "currency",
                        currency: "EUR",
                      })}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Totale (Imposte incluse):</strong>
                    </td>
                    <td className="rightalign">
                      {Number(
                        this.props.details.amount_untaxed +
                          this.props.details.amount_tax
                      ).toLocaleString("it-IT", {
                        style: "currency",
                        currency: "EUR",
                      })}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Sconto:</strong>
                    </td>
                    <td className="rightalign">
                      {this.props.details.discount_rate > 0 && (
                        <i
                          onClick={() => {
                            this.props.azzeraDiscount();
                            this.setState({ fixedprice: 0 });
                          }}
                          className="link fa fa-times "
                          style={{ marginRight: "6px" }}
                          title="Annulla Sconto"
                        />
                      )}
                      {this.state.mod_sconto ? (
                        <input
                          name="fixedprice"
                          value={
                            this.state.fixedprice > 0
                              ? Number(this.state.fixedprice)
                              : 0
                          }
                          onChange={(e) => {
                            if (e.target.value >= 0)
                            this.calcolaSconto(
                              e.target.name,
                              Number(e.target.value)
                            )
                          }}
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              this.applyDiscount();
                              this.setState({
                                mod_sconto: !this.state.mod_sconto,
                              });
                            }
                          }}
                          type="number"
                          className="price"
                        />
                      ) : (
                        <span
                          onClick={() =>
                            this.setState({
                              mod_sconto: !this.state.mod_sconto,
                            })
                          }
                          style={{ textDecoration: "underline" }}
                        >
                          {Number(
                            this.props.details.discount_rate
                          ).toLocaleString("it-IT", {
                            style: "currency",
                            currency: "EUR",
                          })}
                        </span>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Totale scontato: </strong>
                    </td>
                    <td className="rightalign">
                      {this.props.details.amount_total.toLocaleString("it-IT", {
                        style: "currency",
                        currency: "EUR",
                      })}
                    </td>
                  </tr>

                  {this.state.mod_sconto ? (
                    <React.Fragment>
                      {/*  <tr>
                        <td>
                          {" "}
                          <strong>Sconto %</strong>
                        </td>
                        <td className="rightalign">
                          {this.state.mod_sconto ? (
                            <input
                              type="number"
                              name="sconto"
                              value={
                                this.state.sconto > 0
                                  ? Number(this.state.sconto)
                                  : ""
                              }
                              onChange={(e) => {
                                if (e.target.value < 101 && e.target.value > -1)
                                  this.calcolaSconto(
                                    e.target.name,
                                    Number(e.target.value)
                                  );
                              }}
                              onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                  this.applyDiscount();
                                  this.setState({
                                    mod_sconto: !this.state.mod_sconto,
                                  });
                                }
                              }}
                              type="number"
                              className="price"
                            />
                          ) : (
                            <span className="price">
                              {Number(this.state.sconto).toFixed(0)}
                            </span>
                          )}
                        </td>
                      </tr> */}
                      {/*  <tr>
                        <td>
                          <strong>Sconto da Applicare &euro;</strong>
                        </td>
                        <td className="rightalign" data-th="Totale complessivo">
                          {this.state.mod_sconto ? (
                            <input
                              name="fixedprice"
                              value={
                                this.state.fixedprice > 0
                                  ? Number(this.state.fixedprice)
                                  : 0
                              }
                              onChange={(e) =>
                                this.calcolaSconto(
                                  e.target.name,
                                  Number(e.target.value)
                                )
                              }
                              onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                  this.applyDiscount();
                                  this.setState({
                                    mod_sconto: !this.state.mod_sconto,
                                  });
                                }
                              }}
                              type="number"
                              className="price"
                            />
                          ) : (
                            <span>
                              {Number(this.state.fixedprice).toLocaleString(
                                "it-IT",
                                {
                                  style: "currency",
                                  currency: "EUR",
                                }
                              )}
                            </span>
                          )}{" "}
                        </td>
                      </tr> */}
                      <tr>
                        <td>
                          {" "}
                          <button
                            className="btn_annulla"
                            onClick={() => {
                              this.setState({
                                mod_sconto: !this.state.mod_sconto,
                              });
                            }}
                          >
                            Annulla
                          </button>
                        </td>
                        <td className="rightalign">
                          <button
                            className="btn_crea"
                            onClick={() => {
                              this.applyDiscount();
                              this.setState({
                                mod_sconto: !this.state.mod_sconto,
                              });
                            }}
                          >
                            Applica
                          </button>
                        </td>
                      </tr>
                    </React.Fragment>
                  ) : null}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div id="dati_spedizione_checkout" className="table_div leftalign det ">
          <div className="row_div">
            <div className="cell_div">
              <div className="prev_card">
                <h3>Dettagli</h3>
                <ul>
                  {this.props.anagrafy.company_type !== "company" && (
                    <li>{this.renderElement("firstname", "Nome", anagrafy)}</li>
                  )}
                  <li>
                    {this.renderElement(
                      "lastname",
                      this.props.anagrafy.company_type === "company"
                        ? "Rag. Sociale"
                        : "Cognome",
                      anagrafy
                    )}
                  </li>

                  {this.props.anagrafy.company_type !== "company" && (
                    <li>
                      {this.renderElement("company", "Società", anagrafy)}
                    </li>
                  )}
                  <li>{this.renderElement("email", "email", anagrafy)}</li>
                  <li>{this.renderElement("phone", "Telefono", anagrafy)}</li>
                  <li>
                    {this.renderElement("cellphone", "Cellulare", anagrafy)}
                  </li>
                  <li>
                    {this.renderElement(
                      "payment_term_id.label",
                      "Termini di Pagamento",
                      details
                    )}
                  </li>
                </ul>
              </div>
            </div>
            <div className="cell_div">
              <div className="prev_card">
                <h3>Dati Spedizione</h3>
                <ul>
                  <li>{this.renderElement("sped_name", "Nome", anagrafy)}</li>
                  <li>
                    {this.renderElement(
                      "sped_country.label",
                      "Stato",
                      anagrafy
                    )}
                  </li>
                  <li>
                    {this.renderElement(
                      "sped_state.label",
                      "Provincia",
                      anagrafy
                    )}
                  </li>
                  <li> {this.renderElement("sped_city", "Città", anagrafy)}</li>
                  <li>
                    {this.renderElement(
                      "sped_street_1",
                      "Indirizzo 1",
                      anagrafy
                    )}
                  </li>
                  <li>
                    {" "}
                    {this.renderElement(
                      "sped_street_2",
                      "Indirizzo 2",
                      anagrafy
                    )}
                  </li>
                  <li></li>
                </ul>
              </div>
            </div>
            <div className="cell_div">
              <div className="prev_card">
                <h3>Dati Fatturazione</h3>
                <ul>
                  <li>
                    {this.renderElement(
                      !anagrafy.electronic_invoice_subjected
                        ? "iva"
                        : "codice_fiscale",
                      !anagrafy.electronic_invoice_subjected
                        ? "P. Iva"
                        : "cod. Fiscale",
                      anagrafy
                    )}
                  </li>
                  <li>{this.renderElement("fatt_name", "Nome", anagrafy)}</li>
                  <li>
                    {" "}
                    {this.renderElement(
                      "fatt_country.label",
                      "Stato",
                      anagrafy
                    )}
                  </li>
                  <li>
                    {this.renderElement(
                      "fatt_state.label",
                      "Provincia",
                      anagrafy
                    )}
                  </li>
                  <li>{this.renderElement("fatt_city", "Città", anagrafy)}</li>
                  <li>
                    {" "}
                    {this.renderElement(
                      "fatt_street_1",
                      "Indirizzo 1",
                      anagrafy
                    )}
                  </li>
                  <li>
                    {" "}
                    {this.renderElement(
                      "fatt_street_2",
                      "Indirizzo 2",
                      anagrafy
                    )}
                  </li>
                  <li></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div id="dati_spedizione_checkout" className="table_div leftalign det ">
          <div className="row_div">
            <div className="cell_div">
              <div className="prev_card">
                <h3>Note Interne</h3>
                {this.state.modify === "internal_notes" ? (
                  <>
                    <textarea
                      type="text"
                      style={{ height: "100%" }}
                      name="internal_notes"
                      onChange={(e) => this.props.handleDetails(e)}
                      defaultValue={this.state.details.internal_notes || ""}
                    />
                    <i
                      className="fa fa-check"
                      onClick={() => {
                        this.setState({
                          modify: "",
                          details: this.props.details,
                        });
                        this.props.saveDraft(
                          this.props.floors,
                          this.props.sections,
                          this.props.items,
                          this.props.anagrafy,
                          2,
                          this.props.details
                        );
                      }}
                    />
                  </>
                ) : (
                  <>
                    {this.state.details.internal_notes}{" "}
                    <i
                      onClick={() =>
                        this.setState({ modify: "internal_notes" })
                      }
                      className="fa fa-pencil"
                    ></i>
                  </>
                )}
              </div>
            </div>
            <div className="cell_div">
              <div className="prev_card">
                <h3>Note Ordine</h3>
                {this.state.modify === "note_2" ? (
                  <>
                    <textarea
                      type="text"
                      style={{ height: "100%" }}
                      name="note_2"
                      onChange={(e) => this.props.handleDetails(e)}
                      defaultValue={this.state.details.note_2 || ""}
                    />
                    <i
                      className="fa fa-check"
                      onClick={() => {
                        this.setState({
                          modify: "",
                          details: this.props.details,
                        });
                        this.props.saveDraft(
                          this.props.floors,
                          this.props.sections,
                          this.props.items,
                          this.props.anagrafy,
                          2,
                          this.props.details
                        );
                      }}
                    />
                  </>
                ) : (
                  <>
                    {this.state.details.note_2}
                    <i
                      onClick={() => this.setState({ modify: "note_2" })}
                      className="fa fa-pencil"
                    ></i>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        {this.state.share_active && (
          <Popup
            id={"1"}
            width={"60vw"}
            handlePopup={() =>
              this.setState({ share_active: !this.state.share_active })
            }
            isVisible={this.state.share_active}
          >
            <div style={{ width: "600px" }}>
              {this.state.share_active && (
                <HtmlEditor
                  template_id="mohd_payment_system.email_template_share_order"
                  closePopUp={() => this.setState({ share_active: false })}
                  model="sale.order"
                  partner_ids={[this.props.anagrafy.partner_id]}
                  res_id={this.props.order_id}
                  subject={"Preventivo " + this.props.nome_prev}
                  body={
                    "<h2>" +
                    "Preventivo " +
                    this.props.nome_prev +
                    "</h2><p>Gentile " +
                    this.props.anagrafy.firstname +
                    " " +
                    this.props.anagrafy.lastname +
                    ",<br></p>"
                  }
                />
              )}
            </div>
          </Popup>
        )}
        {this.state.pay && (
          <Popup
            id={"3"}
            width={"60vw"}
            handlePopup={() => this.setState({ pay: !this.state.pay })}
            isVisible={this.state.pay}
          >
            <PayScreen
              isPopup
              putPayment={api.putPayment}
              onEndPay={this.endPay}
              amount={
                (this.state.fixedprice > 0
                  ? this.state.fixedprice
                  : this.props.details.amount_total) -
                (this.props.details.invoice_paid_amount || 0)
              }
              partner_id={this.props.anagrafy.partner_id}
              order_id={this.props.order_id}
              firstname={
                this.props.anagrafy.fatt_name !== ""
                  ? this.props.anagrafy.fatt_name
                  : this.props.anagrafy.firstname !== ""
                  ? this.props.anagrafy.firstname
                  : ""
              }
              lastname={
                this.props.anagrafy.fatt_name !== ""
                  ? undefined
                  : this.props.anagrafy.lastname
              }
              nome_prev={this.props.nome_prev}
            />
          </Popup>
        )}
        <Popup
          id={"2"}
          handlePopup={() =>
            this.setState({ stamp_active: !this.state.stamp_active })
          }
          isVisible={this.state.stamp_active}
        >
          <Printbutton id={this.props.order_id} model="sale.order" />{" "}
        </Popup>
      </React.Fragment>
    );
  }
}
