import React, { Component } from "react";
import "./OrderPreview.css";
export default class OrderPreview extends Component {
  render() {
    const item = this.props.item;
    var color_status;
    var status;
    switch (this.props.item.status) {
      case 1:
        color_status = "#E1B41B";
        status = "In spedizione";
        break;
      case 2:
        color_status = "#1BE148";
        status = "Consegnato";
        break;
      case 3:
        color_status = "#E13C1B";
        status = "Annullato";
        break;
    }
    return (
      <div className="order_element">
        <div className="code">Ordine #{item.id}</div>
        <div className="foto_container">
          <p className="subtitle">Prodotti</p>
          <img src={item.photo} />
        </div>
        <div className="desc_container">
          <div className="meta_container">
            <div className="meta_name">
              <h3>{item.brand}</h3>
              <h1>{item.nome}</h1>
            </div>
            <div className="meta_name">
              <p className="subtitle">Effettuato il</p>
              <p className="subtitle_sequel">{item.data}</p>
            </div>
            <div className="meta_name">
              <p className="subtitle">totale</p>
              <p className="subtitle_sequel">
                {item.total.toLocaleString("it-IT", {
                  style: "currency",
                  currency: "EUR",
                })}{" "}
                &euro;
              </p>
            </div>
            <div className="meta_name">
              <p className="subtitle">Stato dell'ordine</p>
              <p style={{ color: color_status }} className="status">
                {status}
              </p>
            </div>
          </div>
          <div className="meta_container btn">
            <div className="meta_name btn">
              <button>Dettagli dell'ordine</button>
              {this.props.item.status === 1 && <button>Traccia Ordine</button>}

              <button>Stampa Fattura</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
