import React, { Component } from "react";
import "./NewPreventivo.css";
import AsyncSelect from "react-select/async";
import { Animated } from "react-animated-css";
import InnerPreventivatore from "../components/InnerPreventivatore";
import CheckoutPrev from "../components/CheckoutPrev";
import Swal from "sweetalert2";
import $ from "jquery";
import api from "../../config";
import Select from "react-select";
import Creatable from "react-select/creatable";
import Load from "../../components/Load";
import SearchAnagrafy from "../../components/search_nation/SearchAnagrafy";
import { makeStyles, Tooltip } from "@material-ui/core";
import Check from "../../components/checkbox/Check";
// Load the full build.
var _ = require("lodash");
export default class NewPreventivo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lock: false,
      nome_prev: "Nuovo Preventivo",
      company_type: "person",
      show_iva: false,
      order_id: "",
      partner_id: "",
      firstname: "",
      lastname: "",

      email: "",
      electronic_invoice_subjected: false,
      search_user: "",
      loadingSave: false,
      codice_fiscale: "",
      phone: "",
      details: {
        note_2: "",
        internal_notes: "",
        amount_tax: 0,
        amount_total: 0,
        amount_discount: 0,
        amount_untaxed: 0,
      },

      sync: true,
      cellphone: "",
      iva: "",
      fatt_state: { label: "Scegli Nazione...", value: -11 },
      fatt_country: {
        label: "Scegli Regione/Provincia...",
        value: -11,
      },
      fatt_city: "",
      pec: "",
      sdi: "",
      sped_name: "",
      fatt_name: "",
      fatt_street_1: "",
      fatt_street_2: "",
      sped_street_1: "",
      sped_street_2: "",
      fatt_cap: "",
      sped_partner_id: "",
      fatt_partner_id: "",
      sped_cap: "",
      sped_state: { label: "Scegli Nazione...", value: -11 },
      sped_country: {
        label: "Scegli Regione/Provincia...",
        value: -11,
      },
      sped_city: "",
      errors: [],
      phase: 0,
      same_address: true,
      floors: [],
      sections: [],
      spedstates: [],
      fattstates: [],
      items: [],
      loadOld: false,
      loadingState: false,
      loadingCountry: false,
    };
  }
  componentDidMount() {
    /* Questo era per il checkpoint in stile videogame */
    /*  if (localStorage.getItem("checkpoint")) {
      Swal.fire({
        position: "center",
        icon: "question",
        html:
          "<h1>Checkpoint!</h1><p>Stavi costruendo un preventivo l'ultima volta, vuoi continuare da li?</p>",
        timerProgressBar: true,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Ripristina",
        cancelButtonText: "Cancella",
        customClass: {
          container: "swal_container",
          confirmButton: "btn_modifica",
          cancelButton: "btn_annulla",
        },
      }).then((value) => {
        console.log(value);
        if (value.isConfirmed)
          {var newstato=JSON.parse(localStorage.getItem("checkpoint"));
          this.setState(newstato)
        this.forceUpdate()}
        if (value.dismiss === "cancel") localStorage.removeItem("checkpoint");
      });
    } */

    localStorage.getItem("show_iva") === "true" &&
      this.setState({ show_iva: true });
    setInterval(() => {
      this.saveBackground();
    }, 10000);
    if (
      this.props.match.params.id !== "" &&
      this.props.match.params.id !== undefined
    ) {
      var prevState = JSON.parse(
        localStorage.getItem("checkpoint" + this.props.match.params.id)
      );
      if (prevState && !prevState.sync) this.setState(prevState);
      else {
        this.setState({ loadOld: true });
        this.fetchOldPrev(this.props.match.params.id);
      }
    }
    this.setState({ loadingCountry: true });
    api.fetchCountries().then((values) => {
      this.setState({ countries: values, loadingCountry: false });
    });
  }

  async saveBackground() {
    function allStorage() {
      var values = [],
        keys = Object.keys(localStorage),
        i = keys.length;

      while (i--) {
        values.push({ key: keys[i], data: localStorage.getItem(keys[i]) });
      }

      return values;
    }
    if (this.state.order_id !== undefined && this.state.order_id !== "") {
      localStorage.setItem(
        "checkpoint" + this.state.order_id,
        JSON.stringify(this.state)
      );
      var values = allStorage();
      if (values.length > 100) {
        localStorage.removeItem(values[values.length - 1].key);
      }
    }
  }
  fetchFattState(country_id) {
    this.setState({ loadingState: true });
    if (country_id) {
      api.fetchStates(country_id).then((values) => {
        this.setState({ fattstates: values, loadingState: false });
      });
    }
  }
  fetchSpedState(country_id) {
    this.setState({ loadingState: true });
    if (country_id) {
      api.fetchStates(country_id).then((values) => {
        this.setState({ spedstates: values, loadingState: false });
      });
    }
  }
  putDiscount = (newdiscount) => {
    var details = this.state.details;
    details.discount_rate = newdiscount;
    this.setState({ details });
  };
  azzeraDiscount = () => {
    this.setState({ loadingSave: true });

    api.azzeraDiscount(this.state.order_id).then((value) => {
      if (value) {
        this.setState(
          {
            details: value.details,
            nome_prev: value.nome_prev,
            fatt_name: value.anagrafy.fatt_name,
            sped_name: value.anagrafy.sped_name,
            sync: true,
            company_type: value.anagrafy.company_type,
            order_id: value.order_id,
            items: value.items,
            partner_id: value.anagrafy.partner_id,
            sped_partner_id: value.anagrafy.sped_partner_id,
            fatt_partner_id: value.anagrafy.fatt_partner_id,
            floors: value.floors,
            sections: value.sections,
            search_user: "",
            firstname: value.anagrafy.firstname,
            lastname: value.anagrafy.lastname,
            email: value.anagrafy.email,
            phone: value.anagrafy.phone,
            cellphone: value.anagrafy.cellphone,
            iva: value.anagrafy.iva,
            codice_fiscale:
              value.anagrafy.codice_fiscale &&
              value.anagrafy.codice_fiscale.toUpperCase(),
            fatt_state: value.anagrafy.fatt_state,
            fatt_country: value.anagrafy.fatt_country,
            fatt_city: value.anagrafy.fatt_city,
            pec: value.anagrafy.pec,
            sdi: value.anagrafy.sdi,
            fatt_street_1: value.anagrafy.fatt_street_1,
            fatt_street_2: value.anagrafy.fatt_street_2,
            sped_street_1: value.anagrafy.sped_street_1,
            sped_street_2: value.anagrafy.sped_street_2,
            fatt_cap: value.anagrafy.fatt_cap,
            sped_cap: value.anagrafy.sped_cap,
            sped_state: value.anagrafy.sped_state,
            sped_city: value.anagrafy.sped_city,
            sped_country: value.anagrafy.sped_country,
            same_address: value.anagrafy.same_address,
            loadOld: false,
            loadingSave: false,
          },
          () => {
            if (
              this.state.spedstates.length === 0 &&
              this.state.fattstates.length === 0
            ) {
              this.fetchFattState(value.anagrafy.fatt_country.value);
              this.fetchSpedState(value.anagrafy.sped_state.value);
            }
          }
        );
      }
      this.setState({ loadingSave: false });
    });
  };
  saveDraft = (floors, sections, items, anagrafy, phase, details) => {
    this.setState({ loadingSave: true });

    return api
      .saveDraft(
        floors,
        sections,
        items,
        details || this.state.details,
        anagrafy,
        phase,
        this.state.nome_prev,
        this.state.order_id
      )
      .then((value) => {
        if (value) {
          this.fetchOldPrev(value.order_id);
          return value;
        } else this.setState({ loadingSave: false, sync: false });
      })
      .catch((error) => {
        this.setState({ loadingSave: false, sync: false });
        if (error.status)
          if (error.status.message) {
            Swal.fire({
              position: "center",
              title: "Errore!",
              icon: "error",
              text: error.status.message,
              timer: 3000,
              timerProgressBar: true,
              showConfirmButton: true,
              confirmButtonText: "Aggiorna",
              customClass: {
                container: "swal_container",
                confirmButton: "btn_modifica",
              },
            }).then((value) => {
              if (value.isConfirmed) window.location.reload();
            });
          }
      });
  };
  swap_show_iva = () => {
    this.setState(
      {
        show_iva: !this.state.show_iva,
      },
      () => {
        localStorage.setItem("show_iva", this.state.show_iva);
      }
    );
  };
  fetchOldPrev(id) {
    if (id) {
      this.setState({ loadSave: true });
      api
        .fetchDraft(id)
        .then((value) => {
          if (value) {
            var new_items_fix_brand = value.items;
                new_items_fix_brand.forEach(element => {
                  if(element.inner_brand==="brand1")
                  {
                    element.inner_brand="1"
                  }
                });
            this.setState(
              {
                details: value.details,
                nome_prev: value.nome_prev,
                fatt_name: value.anagrafy.fatt_name,
                sped_name: value.anagrafy.sped_name,
                phase: value.phase,
                sync: true,
                company_type: value.anagrafy.company_type,
                order_id: value.order_id,
                phase: value.phase,
                items: new_items_fix_brand,
                partner_id: value.anagrafy.partner_id,
                sped_partner_id: value.anagrafy.sped_partner_id,
                fatt_partner_id: value.anagrafy.fatt_partner_id,
                floors: value.floors,
                sections: value.sections,
                search_user: "",
                firstname: value.anagrafy.firstname,
                lastname: value.anagrafy.lastname,
                email: value.anagrafy.email,
                phone: value.anagrafy.phone,
                cellphone: value.anagrafy.cellphone,
                iva: value.anagrafy.iva,
                codice_fiscale:
                  value.anagrafy.codice_fiscale &&
                  value.anagrafy.codice_fiscale.toUpperCase(),
                fatt_state: value.anagrafy.fatt_state,
                fatt_country: value.anagrafy.fatt_country,
                fatt_city: value.anagrafy.fatt_city,
                pec: value.anagrafy.pec,
                sdi: value.anagrafy.sdi,
                fatt_street_1: value.anagrafy.fatt_street_1,
                fatt_street_2: value.anagrafy.fatt_street_2,
                sped_street_1: value.anagrafy.sped_street_1,
                sped_street_2: value.anagrafy.sped_street_2,
                fatt_cap: value.anagrafy.fatt_cap,
                sped_cap: value.anagrafy.sped_cap,
                sped_state: value.anagrafy.sped_state,
                sped_city: value.anagrafy.sped_city,
                sped_country: value.anagrafy.sped_country,
                same_address: value.anagrafy.same_address,
                loadOld: false,
                loadingSave: false,
              },
              () => {
                if (
                  this.state.spedstates.length === 0 &&
                  this.state.fattstates.length === 0
                ) {
                  this.fetchFattState(value.anagrafy.fatt_country.value);
                  this.fetchSpedState(value.anagrafy.sped_state.value);
                }
              }
            );
          } else
            this.setState({
              loadingSave: false,
            });
        })
        .catch((error) => {});
    }
  }

  changePhase = (fase) => {
    if (!this.state.lock) {
      if (fase === 2 && this.state.floors[0]) {
        if (window.pageYOffset > 400)
          window.scrollTo({ top: 0, behavior: "smooth" });
        this.setState({ phase: fase });
      }
      if (fase === 2 && !this.state.floors[0]) {
        Swal.fire({
          position: "center",
          icon: "warning",
          html:
            '<div><h1 class="page_title">Attenzione</h1> <p>Configura un preventivo e premi "avanti" per continuare</p> </div>',
          showConfirmButton: false,
          timerProgressBar: true,
          timer: 2000,
          customClass: { container: "swal_container" },
          onClose: function () {
            var button = $("#vai_avanti");
            if (button) {
              if (button.hasClass("pulsa")) {
                button.removeClass("pulsa");
              }
              button.addClass("pulsa");
              if (button.offset())
                window.scrollTo({
                  top: button.offset().top - 200,
                  behavior: "smooth",
                });
            }
          },
        });

        return;
      } else if (fase === 1) {
        if (this.state.same_address) {
          this.setState({
            sped_street_1: this.state.fatt_street_1,
            sped_street_2: this.state.fatt_street_2,
            sped_cap: this.state.fatt_cap,
            sped_city: this.state.fatt_city,
            sped_state: this.state.fatt_state,
            sped_country: this.state.fatt_country,
          });
        }
      }

      setTimeout(() => {
        if (!this.validateForm()) {
          Swal.fire({
            position: "top-end",
            icon: "error",
            html:
              '<h1 class="page_title">Attenzione!</h1><p>Devi compilare tutti i campi obbligatori* per proseguire!<br/>Mancano i seguenti campi:<br/><br/></p><div class="innerswal left_align">' +
              this.state.errors +
              "</div>",
            showConfirmButton: false,
            timerProgressBar: true,
            customClass: { container: "swal_container" },
          });
          return;
        } else {
          this.setState({ phase: fase });
          if (window.pageYOffset > 400)
            window.scrollTo({ top: 0, behavior: "smooth" });
        }
      }, 700);
    } else if (fase === 0) {
      if (window.pageYOffset > 400)
        window.scrollTo({ top: 0, behavior: "smooth" });

      this.setState({ phase: fase });
    }
  };
  validateForm() {
    var flag = true;
    var error = [];
    var ignore = [
      "errors",
      "search_user",
      "partner_id",
      "fatt_partner_id",
      "sped_partner_id",
      "fattstate",
      "show_iva",
      "lock",
      "floors",
      "fatt_name",
      "sped_name",
      "spedstates",
      "fattstates",
      "sections",
      "details",
      "items",
      "changeNomePrev",
      "nome_prev",
      "phase",
      "electronic_invoice_subjected",
      "total_amount",
      "same_address",
      "company_type",
      "sync",
      "cellphone",
      "order_id",
      "fatt_street_2",
      "loadingSave",
      "sped_street_1",
      "sped_country",
      "sped_cap",
      "sped_city",
      "sped_street_2",
      "sped_state",
      "fatt_city",
      "loadOld",
      "pec",
      "sdi",
      "iva",
      "loadingState",
      "loadingCountry",
    ];
    if (this.state.company_type === "company") {
      ignore.push("codice_fiscale");
      ignore.push("firstname");
    } else ignore.push("iva");
    for (var attr in this.state) {
      if (ignore.indexOf(attr) >= 0) {
      } else if (!this.state[attr] || this.state[attr].length < 3) {
        switch (attr) {
          case "phone":
            error.push("<span>Numero di telefono</span>");
            break;
          case "firstname":
            error.push("<span>Nome</span>");
            break;
          case "lastname":
            if (this.state.company_type === "company")
              error.push("<span>Rag. Sociale</span>");
            else error.push("<span>Cognome</span>");
            break;
          case "email":
            error.push("<span>Email</span>");
            break;
          case "fatt_street_1":
            error.push("<span>Indirizzo per la fatturazione</span>");
            break;

          case "fatt_cap":
            error.push("<span>CAP per la fatturazione</span>");
            break;

          default:
            error.push("<span>Inserisci " + attr + "</span>");
            break;
        }
        flag = false;
      }
      if (["fatt_state", "fatt_country"].indexOf(attr) > -1) {
        if (this.state[attr].value < 0)
          switch (attr) {
            case "fatt_country":
              error.push("<span>Paese per la fatturazione</span>");
              flag = false;
              break;
            case "fatt_state":
              error.push("<span>Stato per la fatturazione</span>");
              flag = false;
              break;
            default:
              error.push("<span>Inserisci " + attr + "</span>");
              flag = false;
              break;
          }
      }
      if (
        "fatt_name" === attr &&
        this.state[attr] &&
        this.state[attr].length === 0
      ) {
        this.setState({
          fatt_name: this.state.firstname + " " + this.state.lastname,
        });
      } else if (
        "sped_name" === attr &&
        this.state[attr] &&
        this.state[attr].length === 0
      ) {
        this.setState({
          sped_name: this.state.firstname + " " + this.state.lastname,
        });
      }
    }
    if (error.length > 0) {
      this.setState({ errors: error });
    }
    return flag;
  }

  saveFloors = (floors, sections, items) => {
    this.setState({ floors, sections, items }, () => {
      var newDetails = { ...this.state.details, discount_rate: 0 };
      this.saveDraft(
        floors,
        sections,
        items,
        this.composeAnagrafy(),
        this.state.phase,
        newDetails
      );
    });
  };
  handleDetails = (e) => {
    const name = e.target.name;
    var details = this.state.details;
    details[name] = e.target.value;
    this.setState({ details });
  };
  handleChange = (e) => {
    const name = e.target.name;
    var anagrafy = this.state;
    anagrafy[name] = e.target.value;
    this.setState({ [name]: e.target.value });
  };

  changePrevName = (e) => {
    this.setState({ nome_prev: e.target.value });
  };

  loadOptions = (inputValue, callback) => {
    if (inputValue.length > 2) {
      api.searchAsyncCustomer(inputValue).then((value) => {
        if (value) callback(value.rows);
      });
    }
  };
  changeFattCountry = (e) => {
    if (e) {
      this.setState({
        fatt_country: e,
        fatt_state: {
          value: -11,
          label: "Provincia.....",
        },
      });
      this.fetchFattState(e.value);
    } else {
      this.fetchFattState(-99);
      this.setState({
        fatt_country: {
          value: -11,
          label: "Seleziona Nazione...",
        },
        fatt_state: {
          value: -1,
          label: "Provincia.....",
        },
      });
    }
  };
  changeSpedCountry = (e) => {
    if (e) {
      this.setState({
        sped_country: e,
        sped_state: {
          value: -11,
          label: "Provincia.....",
        },
      });
      this.fetchSpedState(e.value);
    } else {
      this.fetchSpedState(-99);
      this.setState({
        sped_country: {
          value: -11,
          label: "Seleziona Nazione...",
        },
        sped_state: {
          value: -1,
          label: "Provincia.....",
        },
      });
    }
  };
  changeFattState = (e) => {
    if (e) {
      this.setState({
        fatt_state: e,
      });
    }
  };
  changeSpedState = (e) => {
    if (e) {
      this.setState({
        sped_state: e,
      });
    }
  };
  lock_all = () => {
    this.setState({ lock: true });
  };
  setSync = () => {
    this.setState({ sync: false });
  };

  setSearch = (e) => {
    this.setState({
      search_user: e.label,
      company_type: e.value.company_type,
      partner_id: e.value.partner_id,
      sped_name: e.value.sped_name,
      fatt_name: e.value.fatt_name,
      firstname: e.value.firstname ? e.value.firstname : "",
      lastname: e.value.lastname ? e.value.lastname : "",
      email: e.value.email ? e.value.email : "",
      phone: e.value.phone ? e.value.phone : "",
      cellphone: e.value.cellphone ? e.value.cellphone : "",
      sped_partner_id: e.value.ship_to.partner_id,
      fatt_partner_id: e.value.invoice_to.partner_id,
      fatt_country: e.value.invoice_to
        ? e.value.invoice_to.country !== null
          ? e.value.invoice_to.country
          : { label: "Scegli Nazione...", value: -11 }
        : { label: "Scegli Nazione...", value: -11 },
      fatt_state: e.value.invoice_to
        ? e.value.invoice_to.state !== null
          ? e.value.invoice_to.state
          : {
              label: "Scegli Regione/Provincia...",
              value: -11,
            }
        : {
            label: "Scegli Regione/Provincia...",
            value: -11,
          },
      fatt_city: e.value.invoice_to ? e.value.invoice_to.city : "",
      sped_country: e.value.ship_to
        ? e.value.ship_to.country !== null
          ? e.value.ship_to.country
          : { label: "Scegli Nazione...", value: -11 }
        : { label: "Scegli Nazione...", value: -11 },
      sped_state: e.value.ship_to
        ? e.value.ship_to.state !== null
          ? e.value.ship_to.state
          : {
              label: "Scegli Regione/Provincia...",
              value: -11,
            }
        : {
            label: "Scegli Regione/Provincia...",
            value: -11,
          },
      sped_city: e.value.ship_to ? e.value.ship_to.city : "",
      fatt_street_1: e.value.invoice_to ? e.value.invoice_to.street : "",
      fatt_street_2: e.value.invoice_to ? e.value.invoice_to.street2 : "",
      fatt_cap: e.value.invoice_to ? e.value.invoice_to.zip : "",
      sped_street_1: e.value.ship_to ? e.value.ship_to.street : "",
      sped_street_2: e.value.ship_to ? e.value.ship_to.street2 : "",
      sped_cap: e.value.ship_to ? e.value.ship_to.zip : "",
      same_address:
        e.value.ship_to && e.value.invoice_to
          ? e.value.invoice_to.street === e.value.ship_to.street &&
            e.value.invoice_to.street2 === e.value.ship_to.street2 &&
            e.value.invoice_to.country === e.value.ship_to.country &&
            e.value.invoice_to.city === e.value.ship_to.city &&
            e.value.invoice_to.state === e.value.ship_to.state &&
            e.value.invoice_to.zip === e.value.ship_to.zip
          : e.value.ship_to
          ? false
          : true,
      pec: e.value.pec,
      sdi: e.value.sdi,
      iva: e.value.iva,
      codice_fiscale:
        e.value.codice_fiscale && e.value.codice_fiscale.toUpperCase(),
      electronic_invoice_subjected: e.value.electronic_invoice_subjected,
    });
    if (e.value.invoice_to)
      if (e.value.invoice_to.country)
        this.fetchFattState(e.value.invoice_to.country.value);
    if (e.value.ship_to)
      if (e.value.ship_to.country)
        this.fetchSpedState(e.value.ship_to.country.value);
  };
  BootstrapTooltip(props) {
    const useStylesBootstrap = makeStyles((theme) => ({
      arrow: {
        color: theme.palette.common.black,
      },
      tooltip: {
        backgroundColor: theme.palette.common.black,
        boxShadow: theme.shadows[1],
        fontSize: 14,
      },
    }));
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
  }
  composeAnagrafy() {
    var anagrafy = {
      fatt_name: this.state.fatt_name,
      sped_name: this.state.sped_name,
      nome_prev: this.state.nome_prev,
      company_type: this.state.company_type,
      partner_id: this.state.partner_id,
      fatt_partner_id: this.state.fatt_partner_id,
      sped_partner_id: this.state.sped_partner_id,
      firstname: this.state.firstname,
      electronic_invoice_subjected: this.state.electronic_invoice_subjected,
      lastname: this.state.lastname,
      email: this.state.email,
      phone: this.state.phone,
      codice_fiscale: this.state.codice_fiscale.toUpperCase(),
      cellphone: this.state.cellphone,
      fatt_state: this.state.fatt_state,
      fatt_country: this.state.fatt_country,
      fatt_city: this.state.fatt_city,
      sped_state: this.state.sped_state,
      sped_country: this.state.sped_country,
      sped_city: this.state.sped_city,
      fatt_street_1: this.state.fatt_street_1,
      fatt_street_2: this.state.fatt_street_2,
      fatt_cap: this.state.fatt_cap,
      sped_cap: this.state.sped_cap,
      sped_street_1: this.state.sped_street_1,
      sped_street_2: this.state.sped_street_2,
      sped_cap: this.state.sped_cap,
      same_address: this.state.same_address,
      pec: this.state.pec,
      sdi: this.state.sdi,
      iva: this.state.iva,
    };
    return anagrafy;
  }
  render() {
    return (
      <React.Fragment>
        <div className="page_container preventivatore">
          <div className="prev_card noborder nobg">
            <div className="title">
              <h1 className="page_title absolute">
                {!this.state.changeNomePrev ? (
                  this.state.nome_prev
                ) : (
                  <input
                    type="text"
                    style={{ width: "60%" }}
                    onKeyPress={(event) => {
                      if (event.key === "Enter") {
                        this.setState({
                          changeNomePrev: !this.state.changeNomePrev,
                        });
                      }
                    }}
                    value={this.props.nome_prev}
                    onChange={(e) => this.changePrevName(e)}
                  />
                )}
                {/*   <i
                className={
                  !this.state.changeNomePrev
                    ? "fa fa-pencil-square-o"
                    : "fa fa-check"
                }
                style={{ fontSize: "0.6em", marginLeft: "8px" }}
                onClick={() =>
                  this.setState({ changeNomePrev: !this.state.changeNomePrev })
                }
              /> */}
              </h1>
              <div className="middle_phaser">
                {this.state.floors.length > 0 && (
                  <button
                    onClick={() => {
                      if (!this.state.sync)
                        this.saveDraft(
                          this.state.floors,
                          this.state.sections,
                          this.state.items,
                          this.composeAnagrafy(),
                          this.state.phase
                        );
                    }}
                  >
                    {this.state.loadingSave ? (
                      <span>
                        <i className="fa fa-refresh fa-spin fa-3x fa-fw" />
                      </span>
                    ) : this.state.sync ? (
                      <span style={{ color: "green" }}>
                        {" "}
                        <i className="fa fa-check" aria-hidden="true"></i>{" "}
                        Sincronizzato
                      </span>
                    ) : (
                      <span style={{ color: "red" }}>
                        {" "}
                        <i className="fas fa-close" aria-hidden="true"></i>{" "}
                        <this.BootstrapTooltip title="Clicca per sincronizzare">
                          <span>Modifiche non salvate</span>
                        </this.BootstrapTooltip>
                      </span>
                    )}{" "}
                  </button>
                )}
              </div>
              <ul className="phaser">
                <li
                  onClick={() => {
                    !this.state.lock && this.changePhase(0);
                  }}
                  className={this.state.phase === 0 ? "current" : ""}
                >
                  1. Intestazione{" "}
                  <i className="fa fa-address-book" aria-hidden="true"></i>
                </li>
                <li
                  onClick={() => {
                    !this.state.lock && this.changePhase(1);
                  }}
                  className={this.state.phase === 1 ? "current" : ""}
                >
                  2. Scelta dei prodotti{" "}
                  <i className="fa fa-cart-plus" aria-hidden="true"></i>{" "}
                </li>
                <li
                  onClick={() => {
                    if (!this.validateForm())
                      Swal.fire({
                        position: "top-end",
                        icon: "error",
                        html:
                          "<div><h1>Attenzione!</h1><p> Non hai compilato alcuni campi !</p></div>",
                        showConfirmButton: false,
                        timerProgressBar: true,
                        timer: 2000,
                        onClose: () => {
                          console.log(this.state.errors);
                        },
                        customClass: { container: "swal_container" },
                      });
                    else if (this.state.lock)
                      Swal.fire({
                        position: "top-end",
                        icon: "error",
                        html:
                          "<div><h1>Attenzione!</h1><p> Hai già inviato questo preventivo !</p></div>",
                        showConfirmButton: false,
                        timerProgressBar: true,
                        timer: 2000,
                        customClass: { container: "swal_container" },
                      });
                    else
                      this.saveDraft(
                        this.state.floors,
                        this.state.sections,
                        this.state.items,
                        this.composeAnagrafy(),
                        2
                      );
                  }}
                  style={{ color: this.state.lock ? "green" : "" }}
                  className={this.state.phase === 2 ? "current" : ""}
                >
                  {" "}
                  3. Riepilogo{" "}
                  {this.state.lock ? (
                    <i className="fa fa-check" style={{ color: "green" }} />
                  ) : (
                    <i className="fa fa-info-circle" aria-hidden="true"></i>
                  )}
                </li>
              </ul>
            </div>
          </div>
          <Animated
            isVisible={this.state.phase === 2}
            animationIn="fadeIn"
            animationInDelay={500}
            animationOut="fadeOut"
          >
            {this.state.phase === 2 && this.state.items ? (
              <CheckoutPrev
                azzeraDiscount={this.azzeraDiscount}
                show_iva={this.state.show_iva}
                swap_show_iva={this.swap_show_iva}
                putDiscount={this.putDiscount}
                setSync={this.setSync}
                lock={this.state.lock}
                sync={this.state.sync}
                lock_all={this.lock_all}
                saveDraft={this.saveDraft}
                order_id={this.state.order_id}
                changeFattState={this.changeFattState}
                changeSpedState={this.changeSpedState}
                changeFattCountry={this.changeFattCountry}
                changeSpedCountry={this.changeSpedCountry}
                loadingCountry={this.state.loadingCountry}
                loadingState={this.state.loadingState}
                countries={this.state.countries}
                fattstates={this.state.fattstates}
                spedstates={this.state.spedstates}
                changePhase={this.changePhase}
                nome_prev={this.state.nome_prev}
                anagrafy={this.composeAnagrafy()}
                handleChange={this.handleChange}
                changePhase={this.changePhase}
                details={this.state.details}
                handleDetails={this.handleDetails}
                floors={this.state.floors}
                sections={this.state.sections}
                items={this.state.items}
              />
            ) : (
              this.state.phase === 2 &&
              !this.state.items && (
                <div>
                  Non hai ancora creato il tuo preventivo{" "}
                  <button onClick={() => this.changePhase(1)}>
                    Crealo ora
                  </button>
                </div>
              )
            )}
          </Animated>
          {this.state.phase === 1 && (
            <Animated
              isVisible={this.state.phase === 1}
              animationIn="fadeIn"
              animationOut="fadeOut"
              animateOnMount
            >
              <InnerPreventivatore
                show_iva={this.state.show_iva}
                swap_show_iva={this.swap_show_iva}
                setSync={this.setSync}
                sync={this.state.sync}
                order_id={this.state.order_id}
                saveDraft={this.saveDraft}
                permission={this.props.permission}
                phase={this.state.phase}
                anagrafy={this.composeAnagrafy()}
                nome_prev={this.state.nome_prev}
                floors={this.state.floors}
                sections={this.state.sections}
                items={this.state.items}
                saveFloor={this.saveFloors}
                changePrevName={this.changePrevName}
                changePhase={this.changePhase}
              />
            </Animated>
          )}

          <Animated
            isVisible={this.state.phase === 0}
            animationIn="fadeIn"
            className="preventivatore"
            animationOut="fadeOutRight"
          >
            <SearchAnagrafy
              placeholder="Cerca Contatto"
              permission={this.props.permission.opt_contact_adv_search}
              label={"Cerca Contatto"}
              setSearch={this.setSearch}
              apiCall={api.searchAsyncCustomer}
              async
            />

            <div className="prev_card">
              <div className="table_div">
                <div className="row_div">
                  <div className="cell_div">
                    <h3>Informazioni di Contatto</h3>
                  </div>
                  <div className="cell_div">
                    <Check
                      label="Azienda?"
                      checkstate={this.state.company_type === "company"}
                      toggler={() =>
                        this.setState({
                          company_type:
                            this.state.company_type === "person"
                              ? "company"
                              : "person",
                        })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="table_div">
                {this.state.company_type === "company" ? (
                  <div className="row_div">
                    <div className="cell_div">
                      <p className="subtitle">Ragione Sociale*</p>
                      <input
                        type="text"
                        onChange={(e) =>
                          this.setState({ lastname: e.target.value })
                        }
                        value={this.state.lastname}
                      />
                    </div>
                    <div className="cell_div">
                      <p className="subtitle">Cellulare</p>
                      <input
                        type="text"
                        onChange={(e) =>
                          this.setState({ cellphone: e.target.value })
                        }
                        value={this.state.cellphone}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="row_div">
                    <div className="cell_div">
                      <p className="subtitle">Nome*</p>
                      <input
                        type="text"
                        onChange={(e) =>
                          this.setState({ firstname: e.target.value })
                        }
                        value={this.state.firstname}
                      />
                    </div>
                    <div className="cell_div">
                      <p className="subtitle">Cognome*</p>
                      <input
                        type="text"
                        onChange={(e) =>
                          this.setState({
                            lastname: e.target.value,
                          })
                        }
                        value={this.state.lastname}
                      />
                    </div>
                  </div>
                )}

                <div className="row_div">
                  <div className="cell_div">
                    <p className="subtitle">Email*</p>
                    <input
                      type="text"
                      onChange={(e) => this.setState({ email: e.target.value })}
                      value={this.state.email}
                    />
                  </div>
                  <div className="cell_div">
                    <p className="subtitle">Numero di telefono*</p>
                    <input
                      type="text"
                      onChange={(e) => this.setState({ phone: e.target.value })}
                      value={this.state.phone}
                    />
                  </div>
                </div>
                {this.state.company_type !== "company" && (
                  <div className="row_div">
                    <div className="cell_div">
                      <p className="subtitle">Cellulare</p>
                      <input
                        type="text"
                        onChange={(e) =>
                          this.setState({ cellphone: e.target.value })
                        }
                        value={this.state.cellphone}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="prev_card">
              <div className="table_div">
                <div className="row_div">
                  <div className="cell_div">
                    <h3>Indirizzo di Fatturazione </h3>
                  </div>
                  <div className="cell_div">
                    <Check
                      label="Stesso indirizzo per la spedizione?"
                      checkstate={this.state.same_address}
                      toggler={() =>
                        this.setState({
                          same_address: !this.state.same_address,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="table_div">
                <div style={{ width: "100%" }} className="row_div">
                  <div className="cell_div address">
                    <p className="subtitle">Indirizzo*</p>
                    <input
                      type="text"
                      onChange={(e) =>
                        this.setState({ fatt_street_1: e.target.value })
                      }
                      value={this.state.fatt_street_1}
                    />
                  </div>
                  <div className="cell_div cap">
                    <p className="subtitle">CAP*</p>
                    <input
                      type="text"
                      onChange={(e) =>
                        this.setState({ fatt_cap: e.target.value })
                      }
                      value={this.state.fatt_cap}
                    />
                  </div>
                </div>
                <div style={{ width: "100%" }} className="row_div">
                  <div className="cell_div address">
                    <input
                      type="text"
                      onChange={(e) =>
                        this.setState({ fatt_street_2: e.target.value })
                      }
                      value={this.state.fatt_street_2}
                    />
                  </div>
                </div>
              </div>
              <hr />
              <div className="table_div">
                {" "}
                <div className="row_div">
                  <div className="cell_div">
                    <p className="subtitle">Nome</p>
                    <input
                      type="text"
                      onChange={(e) =>
                        this.setState({ fatt_name: e.target.value })
                      }
                      value={this.state.fatt_name || ""}
                    />
                  </div>
                </div>
                <div className="row_div">
                  <div className="cell_div">
                    <p id="fatt_state" className="subtitle">
                      Stato
                    </p>
                    <Select
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                      menuPortalTarget={document.body}
                      isClearable={true}
                      placeholder={
                        this.state.fatt_country.label
                          ? this.state.fatt_country.label
                          : "Inserisci lo stato"
                      }
                      options={this.state.countries}
                      isLoading={this.state.loadingCountry}
                      formatCreateLabel={(e) => 'Crea "' + e + '"'}
                      value={this.state.fatt_country.value}
                      onChange={(e) => {
                        if (e.value === 110)
                          this.setState({ electronic_invoice_subjected: true });
                        this.changeFattCountry(e);
                      }}
                    />
                    {/*    <input
                    type="text"
                    onChange={(e) =>
                      this.setState({ fatt_state: e.target.value })
                    }
                    value={this.state.fatt_state}
                  /> */}
                  </div>
                  <div className="cell_div">
                    <p id="fatt_country" className="subtitle">
                      Paese/Provincia*
                    </p>
                    <Creatable
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                      menuPortalTarget={document.body}
                      isClearable
                      placeholder={this.state.fatt_state.label}
                      options={this.state.fattstates}
                      value={this.state.fatt_state.value}
                      onChange={(newValue, actionMeta) => {
                        if (newValue)
                          newValue.country_id = this.state.fatt_country.value;

                        if (actionMeta.action === "select-option")
                          this.changeFattState(newValue);
                        else this.setState({ fatt_state: newValue });
                      }}
                      isLoading={this.state.loadingState}
                      formatCreateLabel={(e) => 'Crea "' + e + '"'}
                      value={this.state.fatt_state.value}
                    />

                    {/*  <input
                    type="text"
                    onChange={(e) =>
                      this.setState({ fatt_country: e.target.value })
                    }
                    value={this.state.fatt_country}
                  /> */}
                  </div>
                </div>
                <div className="row_div">
                  <div className="cell_div">
                    <p className="subtitle">Città*</p>
                    <input
                      type="text"
                      onChange={(e) =>
                        this.setState({ fatt_city: e.target.value })
                      }
                      value={this.state.fatt_city}
                    />
                  </div>
                </div>
              </div>

              <React.Fragment>
                <hr />
                <div className="table_div">
                  <div className="row_div"></div>
                  <div className="row_div">
                    {this.state.company_type === "company" ? (
                      <div className="cell_div">
                        <p className="subtitle">Partita Iva</p>
                        <input
                          type="text"
                          onChange={(e) =>
                            this.setState({ iva: e.target.value })
                          }
                          value={this.state.iva}
                        />
                      </div>
                    ) : (
                      <div className="cell_div">
                        <p className="subtitle">Codice Fiscale</p>
                        <input
                          type="text"
                          onChange={(e) =>
                            this.setState({
                              codice_fiscale: e.target.value.toUpperCase(),
                            })
                          }
                          value={this.state.codice_fiscale}
                        />
                      </div>
                    )}
                    <div className="cell_div">
                      <Check
                        label="Soggetto a fatturazione elettronica?"
                        toggler={() =>
                          this.setState({
                            electronic_invoice_subjected: !this.state
                              .electronic_invoice_subjected,
                          })
                        }
                        checkstate={this.state.electronic_invoice_subjected}
                      />
                    </div>
                  </div>
                  {this.state.electronic_invoice_subjected && (
                    <div className="row_div">
                      <div className="cell_div">
                        <p className="subtitle">Pec</p>
                        <input
                          type="text"
                          onChange={(e) =>
                            this.setState({ pec: e.target.value })
                          }
                          value={this.state.pec}
                        />
                      </div>
                      <div className="cell_div">
                        <p className="subtitle">Codice SDI</p>

                        <input
                          type="text"
                          onChange={(e) =>
                            this.setState({ sdi: e.target.value })
                          }
                          value={this.state.sdi}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </React.Fragment>
            </div>
            <div
              className={
                !this.state.same_address
                  ? "prev_card searchable"
                  : "prev_card height0"
              }
            >
              <div className="table_div">
                <div className="row_div">
                  <div className="cell_div">
                    <h3>Indirizzo di Spedizione</h3>
                  </div>
                </div>
                <div style={{ width: "100%" }} className="row_div">
                  <div className="cell_div address">
                    <p className="subtitle">Indirizzo*</p>
                    <input
                      type="text"
                      onChange={(e) =>
                        this.setState({ sped_street_1: e.target.value })
                      }
                      value={this.state.sped_street_1}
                    />
                  </div>
                  <div className="cell_div cap">
                    <p className="subtitle">CAP*</p>
                    <input
                      type="text"
                      onChange={(e) =>
                        this.setState({ sped_cap: e.target.value })
                      }
                      value={this.state.sped_cap}
                    />
                  </div>
                </div>
                <div style={{ width: "100%" }} className="row_div">
                  <div className="cell_div address">
                    <input
                      type="text"
                      onChange={(e) =>
                        this.setState({ sped_street_2: e.target.value })
                      }
                      value={this.state.sped_street_2}
                    />
                  </div>
                </div>
              </div>
              <hr />
              <div className="table_div">
                <div className="row_div">
                  <div className="cell_div">
                    <p className="subtitle">Nome e Cognome</p>
                    <input
                      type="text"
                      onChange={(e) =>
                        this.setState({ sped_name: e.target.value })
                      }
                      value={this.state.sped_name || ""}
                    />
                  </div>
                </div>
                <div className="row_div">
                  <div className="cell_div">
                    <p id="sped_state" className="subtitle">
                      Stato
                    </p>
                    <Select
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                      menuPortalTarget={document.body}
                      isClearable
                      placeholder={this.state.sped_country.label}
                      options={this.state.countries}
                      value={this.state.sped_country.value}
                      onChange={(e) => {
                        this.changeSpedCountry(e);
                      }}
                      isLoading={this.state.loadingState}
                      formatCreateLabel={(e) => 'Crea "' + e + '"'}
                      value={this.state.sped_country.value}
                    />

                    {/*    <input
                    type="text"
                    onChange={(e) =>
                      this.setState({ sped_state: e.target.value })
                    }
                    value={this.state.sped_state}
                  /> */}
                  </div>
                  <div className="cell_div">
                    <p id="sped_country" className="subtitle">
                      Paese/Provincia*
                    </p>
                    <Creatable
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                      menuPortalTarget={document.body}
                      isClearable
                      placeholder={this.state.sped_state.label}
                      options={this.state.spedstates}
                      value={this.state.sped_state.value}
                      onChange={(newValue, actionMeta) => {
                        if (newValue)
                          newValue.country_id = this.state.sped_country.value;

                        if (actionMeta.action === "select-option")
                          this.changeSpedState(newValue);
                        else this.setState({ sped_state: newValue });
                      }}
                      isLoading={this.state.loadingState}
                      formatCreateLabel={(e) => 'Crea "' + e + '"'}
                      value={this.state.sped_state.value}
                    />

                    {/*  <input
                    type="text"
                    onChange={(e) =>
                      this.setState({ sped_country: e.target.value })
                    }
                    value={this.state.sped_country}
                  /> */}
                  </div>
                </div>
                <div className="row_div">
                  {" "}
                  <div className="cell_div">
                    <p className="subtitle">Città*</p>
                    <input
                      type="text"
                      onChange={(e) =>
                        this.setState({ sped_city: e.target.value })
                      }
                      value={this.state.sped_city}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="prev_card noborder nobg">
              <div style={{ width: "100%" }} className="table_div">
                <div style={{ width: "100%" }} className="row_div">
                  <div style={{ float: "right" }} className="cell_div">
                    <button
                      onClick={() => this.changePhase(1)}
                      className="btn_modifica"
                    >
                      Avanti
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Animated>
        </div>
        {this.state.loadingSave && (
          <div className="obscure">
            <Load text="Sincronizzo il preventivo" />
          </div>
        )}
        {this.state.loadOld && (
          <div className="obscure">
            <Load text="Carico il preventivo" />
          </div>
        )}
      </React.Fragment>
    );
  }
}
