import React, { Component } from "react";
import PropTypes from "prop-types";
import api from "../../config";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import "./SearchBar.css";
var _ = api._;

class SearchBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      instock: false,
      filter_array: {},
      collapse: "height0",
    };
  }
  componentDidMount() {
    let filter = this.props.adv_filter;
    if (filter) {
      let filter_array = [];

      filter.forEach((element) => {
        filter_array[element.frontend] = "";
      });

      this.setState({ filter_array });
    }
  }
  loadOptions = (inputValue, callback) => {
    if (this.props.order) {
      this.setState({ fetching: true });
      if (inputValue.length > 2) {
        api.searchAsyncSaleOrdersAll(inputValue, 0).then((value) => {
          if (value) callback(value.rows);
          this.setState({ fetching: false });
        });
      }
    } else {
      var stock = this.state.instock === true ? 1 : 0;
      let filters = "";
      let query = "";
      if (this.props.adv_filter) {
        for (var i = 0; i < this.props.adv_filter.length; i++) {
          query = this.state.filter_array[this.props.adv_filter[i].frontend];
          if (query.length > 2)
            filters += "&" + this.props.adv_filter[i].odoo + "=" + query;
        }
        if (inputValue.length > 2) {
          this.props.apiCall(inputValue, stock, filters).then((value) => {
            if (value) callback(value.rows);
          });
        }
      } else {
        if (inputValue.length > 2) {
          this.props.apiCall(inputValue, stock).then((value) => {
            if (value) callback(value.rows);
          });
        }
      }
    }
  };
  handleChange = (e) => {
    var filter_array = this.state.filter_array;
    filter_array[e.target.name] = e.target.value;
    this.setState({ filter_array });
  };
  renderFilter = () => {
    var res = [];
    this.props.adv_filter.forEach((element) => {
      res.push(
        <input
          type="text"
          className="filter_input"
          key={element.frontend}
          placeholder={"Filtra per " + element.frontend}
          name={element.frontend}
          onChange={(e) => this.handleChange(e)}
          value={this.state.filter_array[element.frontend]  || ''}
        />
      );
    });
    return res;
  };
  render() {
    if (this.props.order) {
      return (
        <div
          className={this.props.cell ? "cell_div" : "prev_card searchable"}
          style={this.props.customStyle}
        >
          <div className="table_div">
            <div className="row_div">
              <div className="cell_div">
                <p className="subtitle">{this.props.label}</p>

                <AsyncSelect
                 styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                }}
                menuPortalTarget={document.body}
                  cacheOptions={this.props.chache}
                  classNamePrefix={"async-select"}
                  noOptionsMessage={() => {
                    return "Non ci sono ordini disponibili";
                  }}
                  onChange={(e) => {
                    let setter = this.props.innerjson
                      ? e[this.props.innerjson]
                      : e;
                    this.props.setSearch(setter);
                  }}
                  placeholder={this.props.label}
                  loadOptions={_.debounce(this.loadOptions, 300)}
                />
              
                {this.props.adv_filter ? (
                  <React.Fragment>
                    <span
                      onClick={() =>
                        this.setState({ collapse: !this.state.collapse })
                      }
                      className="float_right"
                    >
                      {" "}
                      <i
                        className={
                          this.state.collapse
                            ? "fa fa-search-plus"
                            : "fa fa-search-minus"
                        }
                      />{" "}
                      Filtri Avanzati
                    </span>
                    <div
                      style={{
                        height: !this.state.collapse
                          ? (this.props.adv_filter.length / 3) * 100 + "px"
                          : "0px",
                      }}
                      className={this.state.collapse ? "height0" : "ease"}
                    >
                      {this.renderFilter()}
                    </div>
                  </React.Fragment>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      );
    } else if (this.props.async)
      return (
        <div
          className={this.props.cell ? "cell_div" : "prev_card searchable"}
          style={this.props.customStyle}
        >
          <div className="table_div">
            <div className="row_div">
              <div className="cell_div">
                <p className="subtitle">{this.props.label}</p>
                <AsyncSelect
                 styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                }}
                menuPortalTarget={document.body}
                  cacheOptions={this.props.chache}
                  classNamePrefix={"async-select"}
                  noOptionsMessage={() => {
                    return "Non ci sono opzioni disponibili";
                  }}
                  onChange={(e) => {
                    let setter = this.props.innerjson
                      ? e[this.props.innerjson]
                      : e;
                    this.props.setSearch(setter);
                  }}
                  placeholder={this.props.placeholder || 'Seleziona...'}
                  loadOptions={_.debounce(this.loadOptions, 300)}
                />
                {this.props.checkbox && (
                  <span
                    style={{ float: "left", marginTop: 0 }}
                    className="field choice newsletter"
                  >
                    <span
                      className={
                        this.state.instock
                          ? "jcf-checkbox jcf-checked jcf-focus"
                          : "jcf-checkbox jcf-unchecked"
                      }
                      id="check_stock"
                    >
                      <span
                        onClick={() =>
                          this.setState({
                            instock: !this.state.instock,
                          })
                        }
                      />

                      <input
                        type="checkbox"
                        className="checkbox"
                        name="check_stock"
                        onChange={() =>
                          this.setState({
                            instock: !this.state.instock,
                          })
                        }
                        value={this.state.instock}
                        style={{
                          position: "absolute",
                          height: "100%",
                          width: "100%",
                          opacity: 0,
                          margin: "0px",
                        }}
                      />
                    </span>
                    <label htmlFor="check_stock" className="label">
                      <span>In stock</span>
                    </label>
                  </span>
                )}
                {this.props.adv_filter ? (
                  <React.Fragment>
                    <span
                      onClick={() =>
                        this.setState({ collapse: !this.state.collapse })
                      }
                      className="float_right"
                    >
                      {" "}
                      <i
                        className={
                          this.state.collapse
                            ? "fa fa-search-plus"
                            : "fa fa-search-minus"
                        }
                      />{" "}
                      Filtri Avanzati
                    </span>
                    <div
                      style={{
                        height: !this.state.collapse
                          ? (this.props.adv_filter.length / 3) * 100 + "px"
                          : "0px",
                      }}
                      className={this.state.collapse ? "height0" : "ease"}
                    >
                      {this.renderFilter()}
                    </div>
                  </React.Fragment>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      );
    else
      return (
        <div
          className={this.props.nocard ? "" : "prev_card searchable"}
          style={this.props.Customstyle}
        >
          <div className="table_div">
            {" "}
            <div className="row_div">
              <div className="cell_div">
                <p className="subtitle">{this.props.label}</p>
                <Select
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                  menuPortalTarget={document.body}
                  placeholder={this.props.label}
                  isDisabled
                  onChange={(e) => {
                    this.setState({ _brand: e });
                  }}
                  value={this.state._brand}
                  options={this.state.brands}
                />
              </div>
            </div>
          </div>
        </div>
      );
  }
}

SearchBar.propTypes = {
  label: PropTypes.string,
  cache: PropTypes.bool,
  adv_filter: PropTypes.array,
  optionFunc: PropTypes.func,
  setSearch: PropTypes.func,
  async: PropTypes.bool,
  innerjson: PropTypes.string,
};

export default SearchBar; 
