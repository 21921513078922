import React, { Component } from "react";

import api from "../../../config";
import AsyncSelect from "react-select/async";
import { Link, Redirect } from "react-router-dom";
import Utility from "../../../Utility";
import Load from "../../../components/Load";
var _ = require("lodash");

export default class FollowUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      fetching:false,
      orders: [],
      count: 0,
      page: 0,
      max: 1,
      ordered_get: "",
    };
  }
  componentDidMount() {
    this.setState({ loading: true });
    api
      .getFollowup(this.props.prev, 0, 50, this.state.ordered_get)
      .then((value) => {
        if (value)
          this.setState({
            orders: value.rows,
            count: value.total,
            max: Math.ceil(value.total / 50) - 1,
            loading: false,
          });
        else
          this.setState({
            loading: false,
          });
      });
  }
  handleOrdineFollowUp = (attributo) => {
    this.setState(
      {
        fetching:true,
        ordered_get:
          attributo +
          " " +
          (this.state.ordered_get.includes("desc") ? "asc" : "desc"),
      },
      () => {
        api
          .getFollowup(this.props.prev, 0, 50, this.state.ordered_get)
          .then((value) => {
            if (value)
              this.setState({
                orders: value.rows,
                count: value.total,
                max: Math.ceil(value.total / 50) - 1,
                fetching: false,
              });
            else
              this.setState({
                fetching: false,
              });
          });
      }
    );
  };

  loadOptions = (inputValue, callback) => {
    this.setState({ searching: true });
    if (inputValue.length > 2) {
      api.searchAsyncFollowup(inputValue, this.props.prev).then((value) => {
        if (value) callback(value.rows);
        this.setState({ searching: false });
      });
    }
  };
  fetchmore = (page) => {
    var offset = 50;
    this.setState({fetching:true})

    if (this.state.page * offset < this.state.count) offset = 50;
    else {
      offset = this.state.count - 50;
    }
    api.getFollowup(this.props.prev, page * offset, 50, this.state.ordered_get).then((value) => {
      this.setState({ orders: value.rows, count: value.total, loading: false });
    });
  };
  renderTablesLoader() {
    var res = [];
    for (var i = 0; i < 30; i++) {
      res.push(
        <tr key={i} className="loader">
          <td data-th="Ordine n." className="col id">
            <span style={{ animationDelay: i * 700 + "ms" }} />
          </td>
          <td data-th="Data" className="col date">
            <span style={{ animationDelay: i * 700 + "ms" }} />
          </td>
          <td data-th="Spedisci A" className="col shipping ">
            <span style={{ animationDelay: i * 700 + "ms" }} />
          </td>
          <td data-th="Totale ordine" className="col total ">
            <span style={{ animationDelay: i * 700 + "ms" }} />
          </td>
          <td data-th="Status" className="col status ">
            <span style={{ animationDelay: i * 700 + "ms" }} />
          </td>
          <td data-th="Azioni" className="col actions">
            <span style={{ animationDelay: i * 700 + "ms" }} />
          </td>
          <td data-th="Azioni" className="col actions">
            <span style={{ animationDelay: i * 700 + "ms" }} />
          </td>
          <td data-th="Azioni" className="col actions">
            <span style={{ animationDelay: i * 700 + "ms" }} />
          </td>
          <td data-th="Azioni" className="col actions">
            <span style={{ animationDelay: i * 700 + "ms" }} />
          </td>
          <td data-th="Azioni" className="col actions">
            <span style={{ animationDelay: i * 700 + "ms" }} />
          </td>
        </tr>
      );
    }
    return res;
  }
  renderTablesOrder() {
    const formatDate = function (input) {
      if (input) {
        var datePart = input.match(/\d+/g),
          year = datePart[0].substring(2), // get only two digits
          month = datePart[1],
          day = datePart[2];

        return day + "/" + month + "/" + year;
      } else return "Non Specificata";
    };
    var res = [];
    if (this.state.orders.length > 0) {
      this.state.orders.forEach((element) => {
        res.push(
          <tr key={element.id}>
            <td data-th="Ordine n." className="col id">
              {formatDate(element.date)}
            </td>
            <td className="col date">{element.subject}</td>
            <td className="col shipping">
              {element.recipient_ids &&
                element.recipient_ids.map((single) => {
                  return single.name;
                })}
            </td>
            <td className="col shipping">{element.record_name}</td>
            <td className="col shipping"><Utility choose={element.state}/></td>
            <td className="col actions rightalign">
              <Link to={"./" + element.id}>
                <button className="btn_outlined">Visualizza</button>
              </Link>
            </td>
          </tr>
        );
      });
    }
    return res;
  }

  render() {
    return (
      <div className="page_container">
        <h1 className="page_title">
          {this.props.prev !== "followup"
            ? "FollowUp Inviati"
            : "FollowUp Da Inviare"}
        </h1>
        <div className="prev_card searchable" style={{ marginBottom: "25px" }}>
          <div className="table_div">
            <div className="row_div">
              <div className="cell_div">
                <p className="subtitle">
                  Cerca
                </p>
                <AsyncSelect
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                  menuPortalTarget={document.body}
                  cacheOptions
                  isLoading={this.state.searching}
                  // isClearable={false}
                  //isSearchable={true}
                  onChange={(e) => {
                    this.props.history.push("/followup/" + e.value);
                  }}
                  placeholder="Cerca FollowUp.."
                  loadOptions={_.debounce(this.loadOptions, 300)}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="prev_card">
          {this.state.orders.length > 0 && !this.state.loading && (
            <div className="page_right">
              {/*  pagina {this.state.page + 1} max: {this.state.max + 1} {"  "} */}
              {this.state.page === 0 ? 1 : this.state.page * 50}-
              {this.state.page === 0
                ? this.state.count < 49
                  ? this.state.count
                  : 50
                : this.state.page == this.state.max
                ? this.state.count
                : this.state.page * 50 + 50}{" "}
              &#8725; {this.state.count}{" "}
              <span
                onClick={() => {
                  var newpage =
                    this.state.page > 0 ? this.state.page - 1 : this.state.max;

                  this.setState({ page: newpage });
                  this.fetchmore(newpage);
                }}
              >
                &#8249;
              </span>{" "}
              <span
                onClick={() => {
                  var newpage =
                    this.state.page == this.state.max ? 0 : this.state.page + 1;
                  this.setState({ page: newpage });
                  this.fetchmore(newpage);
                }}
              >
                &#8250;
              </span>{" "}
            </div>
          )}
          {!this.state.loading ? (
            this.state.orders.length > 0 ? (
              <table
                className="data table table-order-items history"
                id="my-orders-table-ord"
              >
                <caption className="table-caption">Ordini</caption>
                <thead>
                  <tr>
                    <Utility
                    choose="header"
                    head_array={[
                    {  label:"Data", value:"date", col:'id' },
                    { label:"Oggetto", value:"subject", col:'desc'},
                    { label:"A(Partner)", value:"recipient_ids",col:'desc'},
                    { label:"Origine", value:"record_name",col:'desc'},
                    { label:"Stato", value:"state",col:'desc'},
                    { label:"", value:"link",col:'actions rightalign'}
                    ]}
                    orderstate={this.state.ordered_get}
                    handleState={this.handleOrdineFollowUp}
                    />
                  
                  </tr>
                </thead>

                <tbody>{this.renderTablesOrder()}</tbody>
              </table>
            ) : (
              <div className="message info empty">
                <span>
                  Non è stato inserito nessun follow up.
                </span>
              </div>
            )
          ) : (
            <table
              className="data table table-order-items history"
              id="my-orders-table"
            >
              <caption className="table-caption">Ordini</caption>

              <thead>
                <tr>
                  <th scope="col" className="col id">
                    Data
                  </th>
                  <th scope="col" className="col date">
                    Oggetto
                  </th>
                  <th scope="col" className="col id">
                    A(Partner)
                  </th>
                  <th scope="col" className="col id">
                    Origine
                  </th>
                  <th scope="col" className="col id">
                    Stato
                  </th>
                  <th scope="col" className="col actions rightalign"></th>
                </tr>
              </thead>

              <tbody>{this.renderTablesLoader()}</tbody>
            </table>
          )}
        </div>
        {this.state.fetching && (
          <div className="obscure">
            <Load text="Carico i nuovi dati" />
          </div>
        )}
      </div>
    );
  }
}
