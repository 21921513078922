import React, { Component } from "react";
import "./Home.css";
import { Animated } from "react-animated-css";
import { Formik } from "formik";
import api from "../config";
import { Link } from "react-router-dom";
import OrderPreview from "../components/OrderPreview";
import $ from "jquery";
export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      preview: [
        {
          id: "120",
          id_order: "#203482",
          nome: "Sedia a sdraio",
          data: "30 settembre 2019",
          total: "500",
          brand: "Poltrone Sofà",
          status: 1,
          photo: "./assets/test.jpg",
        },
        {
          id: "11",
          id_order: "#203482",
          nome: "Letto a baldacchino",
          data: "30 settembre 2019",
          total: "650",
          brand: "Divani forti",
          status: 2,
          photo: "./assets/test.jpg",
        },
        {
          id: "3245",
          id_order: "#2034382",
          nome: "Divano comodo",
          data: "10 Marzo 2019",
          total: "120,231",
          brand: "Divani forti",
          status: 3,
          photo: "./assets/test.jpg",
        },
      ],
      main: 0,
      edit: 0,
      ship_to: this.props.ship_to,
      invoice_to: this.props.invoice_to,
    };
  }
  renderAddress(choose) {
    /* 0 è per lo shipping, 1 per il billing */
    let scelta = choose === 0 ? this.state.ship_to : this.state.invoice_to;
    let res = [];
    if (scelta) {
      res.push(
        <React.Fragment key={scelta.name}>
          <div name="div_bella" className="box-content">
            <address>
              {scelta.name}
              <br />
              {scelta.street}
              <br />
              {scelta.city}, {scelta.zip}
              <br />
              {scelta.country}
              <br />
              T: <a href={"tel:" + scelta.phone}>{scelta.phone}</a>
            </address>
          </div>
          <div className="box-actions">
            {/*  <a
              onClick={
                (e) =>
                  e.preventDefault()  this.setState({ main: 1, edit: 0 })
              }
              className="action edit"
              href="#"
              data-ui-id="default-shipping-edit-link"
            >
              <span>Modifica l'indirizzo</span>
            </a> */}
          </div>
        </React.Fragment>
      );
    } else {
      res.push(
        <React.Fragment>
          {" "}
          <div className="box-content">
            <address>
              Non hai impostato un indirizzo di{" "}
              {scelta === 0 ? "spedizione" : "fatturazione"} predefinito.{" "}
            </address>
          </div>
          {/*   <div className="box-actions">
            <span
              onClick={() => this.setState({ main: 1, edit: 1 })}
              className="action edit"
            >
              <span>Modifica l'indirizzo</span>
            </span>
          </div> */}
        </React.Fragment>
      );
    }
    return res;
  }
  renderEdit() {
    let editchoose =
      this.state.edit === 0 ? this.state.shippingaddr : this.state.billingaddr;
    let res = [];
    res.push(
      <React.Fragment>
        <div className="page-title-wrapper">
          <h1 className="page-title">
            <span className="base" data-ui-id="page-title-wrapper">
              Aggiungi nuovo indirizzo
            </span>{" "}
          </h1>
        </div>

        <Formik
          initialValues={{
            firstname: "",
            lastname: "",
            company: "",
            telephone: "",
            fax: "",
            street_1: "",
            street_2: "",
            zip: "",
            vat_id: "",
            city: "",
            country: "",
            region: 0,
            default_billing: false,
            default_shipping: false,
          }}
          validate={(values, actions) => {
            const errors = {};

            if (errors.length > 0) {
              actions.setSubmitting(true);
            }
            return errors;
          }}
          onSubmit={(values, actions) => {
            actions.setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form className="form-address-edit" onSubmit={handleSubmit}>
              <fieldset className="fieldset">
                <legend className="legend">
                  <span>Informazioni di Contatto</span>
                </legend>
                <br />

                <div className="field field-name-firstname required">
                  <label className="label" htmlFor="firstname">
                    <span>Nome</span>
                  </label>
                  <div className="control">
                    <input
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="firstname"
                      name="firstname"
                      value={values.firstname}
                      title="Nome"
                      className="input-text required-entry"
                    />
                  </div>
                </div>
                <div className="field field-name-lastname required">
                  <label className="label" htmlFor="lastname">
                    <span>Cognome</span>
                  </label>
                  <div className="control">
                    <input
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="lastname"
                      name="lastname"
                      value={values.lastname}
                      title="Cognome"
                      className="input-text required-entry"
                    />
                  </div>
                </div>
                <div className="field company">
                  <label className="label" htmlFor="company">
                    <span>Società</span>
                  </label>
                  <div className="control">
                    <input
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="company"
                      id="company"
                      value={values.company}
                      title="Società"
                      className="input-text "
                    />
                  </div>
                </div>
                <div className="field telephone required">
                  <label className="label" htmlFor="telephone">
                    <span>Numero di telefono</span>
                  </label>
                  <div className="control">
                    <input
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="telephone"
                      value={values.telephone}
                      title="Numero di telefono"
                      className="input-text required-entry"
                      id="telephone"
                    />
                  </div>
                </div>
                <div className="field fax">
                  <label className="label" htmlFor="fax">
                    <span>Fax</span>
                  </label>
                  <div className="control">
                    <input
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="fax"
                      id="fax"
                      title="Fax"
                      value={values.fax}
                      className="input-text "
                    />
                  </div>
                </div>
              </fieldset>
              <fieldset className="fieldset">
                <legend className="legend">
                  <span>Indirizzo</span>
                </legend>
                <br />
                <div className="field street required">
                  <label htmlFor="street_1" className="label">
                    <span>Indirizzo</span>
                  </label>
                  <div className="control">
                    <input
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="street_1"
                      value={values.street_1}
                      title="Indirizzo"
                      id="street_1"
                      className="input-text required-entry"
                    />
                    <div className="nested">
                      <div className="field additional">
                        <label className="label" htmlFor="street_2">
                          <span>Indirizzo 2</span>
                        </label>
                        <div className="control">
                          <input
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="street_2"
                            value={values.street_2}
                            title="Indirizzo 2"
                            id="street_2"
                            className="input-text "
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="field zip required">
                  <label className="label" htmlFor="zip">
                    <span>CAP</span>
                  </label>
                  <div className="control">
                    <input
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="zip"
                      value={values.zip}
                      title="CAP"
                      id="zip"
                      className="input-text validate-zip-international required-entry"
                    />
                  </div>
                </div>
                <div className="field taxvat">
                  <label className="label" htmlFor="vat_id">
                    <span>Partita Iva</span>
                  </label>
                  <div className="control">
                    <input
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="vat_id"
                      value={values.vat_id}
                      title="Partita Iva"
                      className="input-text "
                      id="vat_id"
                    />
                  </div>
                </div>
                <div className="field city required">
                  <label className="label" htmlFor="city">
                    <span>Città</span>
                  </label>
                  <div className="control">
                    <input
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="city"
                      value={values.city}
                      title="Città"
                      className="input-text required-entry"
                      id="city"
                    />
                  </div>
                </div>
                <div className="field region">
                  <label className="label" htmlFor="region">
                    <span>Stato/Provincia</span>
                  </label>
                  <div className="control">
                    <div className="selectric-wrapper selectric-validate-select">
                      <div>
                        <select
                          id="region"
                          name="region"
                          title="Stato/Provincia"
                          value={values.region}
                          style={{ display: "inline-block" }}
                        >
                          <option value>
                            Seleziona la regione, stato o provincia.
                          </option>
                          <option value={515}>Agrigento</option>
                          <option value={516}>Alessandria</option>
                          <option value={517}>Ancona</option>
                          <option value={518}>Aosta</option>
                          <option value={519}>Arezzo</option>
                          <option value={520}>Ascoli Piceno</option>
                          <option value={521}>Asti</option>
                          <option value={522}>Avellino</option>
                          <option value={523}>Bari</option>
                          <option value={524}>Barletta-Andria-Trani</option>
                          <option value={525}>Belluno</option>
                          <option value={526}>Benevento</option>
                          <option value={527}>Bergamo</option>
                          <option value={528}>Biella</option>
                          <option value={529}>Bologna</option>
                          <option value={530}>Bolzano</option>
                          <option value={531}>Brescia</option>
                          <option value={532}>Brindisi</option>
                          <option value={533}>Cagliari</option>
                          <option value={534}>Caltanissetta</option>
                          <option value={535}>Campobasso</option>
                          <option value={536}>Carbonia-Iglesias</option>
                          <option value={537}>Caserta</option>
                          <option value={538}>Catania</option>
                          <option value={539}>Catanzaro</option>
                          <option value={540}>Chieti</option>
                          <option value={541}>Como</option>
                          <option value={542}>Cosenza</option>
                          <option value={543}>Cremona</option>
                          <option value={544}>Crotone</option>
                          <option value={545}>Cuneo</option>
                          <option value={546}>Enna</option>
                          <option value={547}>Fermo</option>
                          <option value={548}>Ferrara</option>
                          <option value={549}>Firenze</option>
                          <option value={550}>Foggia</option>
                          <option value={551}>Forlì-Cesena</option>
                          <option value={552}>Frosinone</option>
                          <option value={553}>Genova</option>
                          <option value={554}>Gorizia</option>
                          <option value={555}>Grosseto</option>
                          <option value={556}>Imperia</option>
                          <option value={557}>Isernia</option>
                          <option value={558}>La Spezia</option>
                          <option value={559}>L'Aquila</option>
                          <option value={560}>Latina</option>
                          <option value={561}>Lecce</option>
                          <option value={562}>Lecco</option>
                          <option value={563}>Livorno</option>
                          <option value={564}>Lodi</option>
                          <option value={565}>Lucca</option>
                          <option value={566}>Macerata</option>
                          <option value={567}>Mantova</option>
                          <option value={568}>Massa-Carrara</option>
                          <option value={569}>Matera</option>
                          <option value={570}>Messina</option>
                          <option value={571}>Milano</option>
                          <option value={572}>Modena</option>
                          <option value={573}>Monza e della Brianza</option>
                          <option value={574}>Napoli</option>
                          <option value={575}>Novara</option>
                          <option value={576}>Nuoro</option>
                          <option value={577}>Olbia-Tempio</option>
                          <option value={578}>Oristano</option>
                          <option value={579}>Padova</option>
                          <option value={580}>Palermo</option>
                          <option value={581}>Parma</option>
                          <option value={582}>Pavia</option>
                          <option value={583}>Perugia</option>
                          <option value={584}>Pesaro e Urbino</option>
                          <option value={585}>Pescara</option>
                          <option value={586}>Piacenza</option>
                          <option value={587}>Pisa</option>
                          <option value={588}>Pistoia</option>
                          <option value={589}>Pordenone</option>
                          <option value={590}>Potenza</option>
                          <option value={591}>Prato</option>
                          <option value={592}>Ragusa</option>
                          <option value={593}>Ravenna</option>
                          <option value={594}>Reggio Calabria</option>
                          <option value={595}>Reggio Emilia</option>
                          <option value={596}>Rieti</option>
                          <option value={597}>Rimini</option>
                          <option value={598}>Roma</option>
                          <option value={599}>Rovigo</option>
                          <option value={600}>Salerno</option>
                          <option value={601}>Medio Campidano</option>
                          <option value={602}>Sassari</option>
                          <option value={603}>Savona</option>
                          <option value={604}>Siena</option>
                          <option value={605}>Siracusa</option>
                          <option value={606}>Sondrio</option>
                          <option value={607}>Taranto</option>
                          <option value={608}>Teramo</option>
                          <option value={609}>Terni</option>
                          <option value={610}>Torino</option>
                          <option value={611}>Ogliastra</option>
                          <option value={612}>Trapani</option>
                          <option value={613}>Trento</option>
                          <option value={614}>Treviso</option>
                          <option value={615}>Trieste</option>
                          <option value={616}>Udine</option>
                          <option value={617}>Varese</option>
                          <option value={618}>Venezia</option>
                          <option value={619}>Verbano-Cusio-Ossola</option>
                          <option value={620}>Vercelli</option>
                          <option value={621}>Verona</option>
                          <option value={622}>Vibo Valentia</option>
                          <option value={623}>Vicenza</option>
                          <option value={624}>Viterbo</option>
                          <option value={625}>Agrigento</option>
                          <option value={626}>Alessandria</option>
                          <option value={627}>Ancona</option>
                          <option value={628}>Aosta</option>
                          <option value={629}>Arezzo</option>
                          <option value={630}>Ascoli Piceno</option>
                          <option value={631}>Asti</option>
                          <option value={632}>Avellino</option>
                          <option value={633}>Bari</option>
                          <option value={634}>Barletta-Andria-Trani</option>
                          <option value={635}>Belluno</option>
                          <option value={636}>Benevento</option>
                          <option value={637}>Bergamo</option>
                          <option value={638}>Biella</option>
                          <option value={639}>Bologna</option>
                          <option value={640}>Bolzano</option>
                          <option value={641}>Brescia</option>
                          <option value={642}>Brindisi</option>
                          <option value={643}>Cagliari</option>
                          <option value={644}>Caltanissetta</option>
                          <option value={645}>Campobasso</option>
                          <option value={646}>Carbonia-Iglesias</option>
                          <option value={647}>Caserta</option>
                          <option value={648}>Catania</option>
                          <option value={649}>Catanzaro</option>
                          <option value={650}>Chieti</option>
                          <option value={651}>Como</option>
                          <option value={652}>Cosenza</option>
                          <option value={653}>Cremona</option>
                          <option value={654}>Crotone</option>
                          <option value={655}>Cuneo</option>
                          <option value={656}>Enna</option>
                          <option value={657}>Fermo</option>
                          <option value={658}>Ferrara</option>
                          <option value={659}>Firenze</option>
                          <option value={660}>Foggia</option>
                          <option value={661}>Forlì-Cesena</option>
                          <option value={662}>Frosinone</option>
                          <option value={663}>Genova</option>
                          <option value={664}>Gorizia</option>
                          <option value={665}>Grosseto</option>
                          <option value={666}>Imperia</option>
                          <option value={667}>Isernia</option>
                          <option value={668}>La Spezia</option>
                          <option value={669}>L'Aquila</option>
                          <option value={670}>Latina</option>
                          <option value={671}>Lecce</option>
                          <option value={672}>Lecco</option>
                          <option value={673}>Livorno</option>
                          <option value={674}>Lodi</option>
                          <option value={675}>Lucca</option>
                          <option value={676}>Macerata</option>
                          <option value={677}>Mantova</option>
                          <option value={678}>Massa-Carrara</option>
                          <option value={679}>Matera</option>
                          <option value={680}>Messina</option>
                          <option value={681}>Milano</option>
                          <option value={682}>Modena</option>
                          <option value={683}>Monza e della Brianza</option>
                          <option value={684}>Napoli</option>
                          <option value={685}>Novara</option>
                          <option value={686}>Nuoro</option>
                          <option value={687}>Olbia-Tempio</option>
                          <option value={688}>Oristano</option>
                          <option value={689}>Padova</option>
                          <option value={690}>Palermo</option>
                          <option value={691}>Parma</option>
                          <option value={692}>Pavia</option>
                          <option value={693}>Perugia</option>
                          <option value={694}>Pesaro e Urbino</option>
                          <option value={695}>Pescara</option>
                          <option value={696}>Piacenza</option>
                          <option value={697}>Pisa</option>
                          <option value={698}>Pistoia</option>
                          <option value={699}>Pordenone</option>
                          <option value={700}>Potenza</option>
                          <option value={701}>Prato</option>
                          <option value={702}>Ragusa</option>
                          <option value={703}>Ravenna</option>
                          <option value={704}>Reggio Calabria</option>
                          <option value={705}>Reggio Emilia</option>
                          <option value={706}>Rieti</option>
                          <option value={707}>Rimini</option>
                          <option value={708}>Roma</option>
                          <option value={709}>Rovigo</option>
                          <option value={710}>Salerno</option>
                          <option value={711}>Medio Campidano</option>
                          <option value={712}>Sassari</option>
                          <option value={713}>Savona</option>
                          <option value={714}>Siena</option>
                          <option value={715}>Siracusa</option>
                          <option value={716}>Sondrio</option>
                          <option value={717}>Taranto</option>
                          <option value={718}>Teramo</option>
                          <option value={719}>Terni</option>
                          <option value={720}>Torino</option>
                          <option value={721}>Ogliastra</option>
                          <option value={722}>Trapani</option>
                          <option value={723}>Trento</option>
                          <option value={724}>Treviso</option>
                          <option value={725}>Trieste</option>
                          <option value={726}>Udine</option>
                          <option value={727}>Varese</option>
                          <option value={728}>Venezia</option>
                          <option value={729}>Verbano-Cusio-Ossola</option>
                          <option value={730}>Vercelli</option>
                          <option value={731}>Verona</option>
                          <option value={732}>Vibo Valentia</option>
                          <option value={733}>Vicenza</option>
                          <option value={734}>Viterbo</option>
                        </select>
                      </div>
                    </div>
                    <input
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="region"
                      name="region"
                      defaultValue
                      title="Stato/Provincia"
                      className="input-text"
                      disabled="disabled"
                      style={{ display: "none" }}
                    />
                  </div>
                </div>
                <div className="field country required">
                  <label className="label" htmlFor="country">
                    <span>Paese</span>
                  </label>
                  <div className="control">
                    <div className="selectric-wrapper">
                      <div className="selectric-select">
                        <select
                          name="country_id"
                          id="country"
                          className="required-entry"
                          title="Paese"
                          tabIndex={0}
                        >
                          <option value />
                          <option value="IT">Italia</option>
                        </select>
                      </div>
                    </div>{" "}
                  </div>
                </div>
                <div className="field choice set billing">
                  <span className="jcf-checkbox jcf-unchecked">
                    <span />
                    <input
                      type="checkbox"
                      className="checkbox"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="default_billing"
                      value={values.default_billing}
                      id="default_billing"
                      style={{
                        position: "absolute",
                        height: "27px",
                        width: "27px",
                        opacity: 1,
                        top: "-1px",
                        left: "-1px",
                        border: "none",
                      }}
                    />
                  </span>
                  <label className="label" htmlFor="primary_billing">
                    <span>Usa come indirizzo di fatturazione predefinito</span>
                  </label>
                </div>
                <div className="field choice set shipping">
                  <span className="jcf-checkbox jcf-unchecked">
                    <span />
                    <input
                      type="checkbox"
                      className="checkbox"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="default_shipping"
                      value={values.default_shipping}
                      id="default_shipping"
                      style={{
                        position: "absolute",
                        height: "27px",
                        width: "27px",
                        opacity: 1,
                        top: "-1px",
                        left: "-1px",
                        border: "none",
                      }}
                    />
                  </span>
                  <label className="label" htmlFor="primary_shipping">
                    <span>Usa come indirizzo di spedizione predefinito</span>
                  </label>
                </div>
              </fieldset>

              <div className="actions-toolbar">
                <div className="primary">
                  <button
                    className=" create button btn-standard btn-green"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    <span>Invia Anagrafica</span>
                  </button>
                </div>
                <div className="secondary">
                  <span
                    style={{ cursor: "pointer" }}
                    className="action back"
                    onClick={() => this.setState({ main: 0 })}
                  >
                    <span>Indietro</span>
                  </span>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </React.Fragment>
    );
    return res;
  }
  renderOrderPreview() {
    var res = [];
    let i = 0;
    this.state.preview.forEach((element) => {
      res.push(
        <OrderPreview
          item={element}
          key={element.id}
          bg={i % 2 == 0 ? "rgba(0,0,0,0.05)" : "white"}
        />
      );
      res.push(<hr key={element.id + 1} />);
      i++;
    });
    return res;
  }
  render() {
    return (
      <React.Fragment>
        {this.state.main === 1 ? (
          <Animated
            isVisible={this.state.main === 1}
            animationIn={"fadeInRight"}
            animationOut={"fadeOutRight"}
          >
            {this.renderEdit()}
          </Animated>
        ) : null}
        {/* Caso di visualizzazione principale */}
        <Animated
          isVisible={this.state.main === 0}
          animationIn={"fadeIn"}
          animationOut={"fadeOut"}
        >
          <div className="page-title-wrapper">
            <h1 className="page-title">
              <span className="base" data-ui-id="page-title-wrapper">
                Il mio Account
              </span>{" "}
            </h1>
          </div>

          <div className="block block-dashboard-addresses">
            {/*  <div className="block-title">
              Ordini Recenti{" "}
              <Link to="I_Miei_Ordini">
                <span className="command">visualizza tutti</span>
              </Link>
            </div>
            <div className="preview_container">{this.renderOrderPreview()}</div> */}
            <div className="block-title">
              <span>Indirizzi</span>
            </div>
            <div className="block-content">
              <div className="box box-billing-address">
                <strong className="box-title">
                  <span>Indirizzo di fatturazione predefinito</span>
                </strong>
                {this.renderAddress(1)}
              </div>
              <div className="box box-shipping-address">
                <strong className="box-title">
                  <span>Indirizzo di spedizione predefinito</span>
                </strong>
                {this.renderAddress(0)}
              </div>
            </div>
          </div>
        </Animated>
      </React.Fragment>
    );
  }
}
