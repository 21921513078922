import React, { Component } from "react";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import "./RicPagamento.css";
import api from "../../../config";
import Swal from "sweetalert2";
import SearchAnagrafy from "../../../components/search_nation/SearchAnagrafy";
import PayScreen from "../../../components/pagamento/PayScreen";
var _ = api._;

class RicPagamento extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gateway: [],
      paymentOptType: [],
      currency: [],
      cliente: "",
      pay_code: "",
      amount: "",
      loading: false,
      _currency: {
        label: "Seleziona la valuta per il pagamento..",
        value: -11,
      },
      currency: [],
      _gateway: { label: "Seleziona il gateway di pagamento...", value: -11 },
      loadingPayment: false,
      _paymentType: { label: "Seleziona il tipo di pagamento...", value: -11 },
    };
  }
  componentDidMount() {


  }

  render() {
    return (
      <div className="page_container">
     <PayScreen 
     {...this.props}
     searchable
     order
      amount=""
     title="Richiesta di pagamento"
      putPayment={api.putPayment}
        />
      </div>
    );
  }
}

export default RicPagamento;
