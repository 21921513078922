import React, { Component } from "react";

export default class Utility extends Component {
  renderHeader = (head_array, handleState, orderstate) => {
    var res = [];
    head_array.forEach((element) => {
      res.push(
        <th
          key={element.label}
          scope="col"
          style={{
            position: "relative",
          }}
          className={"col " + element.col}
          onClick={() => {
            if (!element.value.includes("link")) {
              handleState(element.value);
            }
          }}
        >
          {element.label}
          {!element.value.includes("link") && (
            <i
              className="fas fa-arrow-up freccia"
              style={{
                transform: orderstate.includes("desc")
                  ? "rotate(180deg)"
                  : "rotate(0deg)",
                opacity:
                  orderstate === element.value + " desc" ||
                  orderstate === element.value + " asc"
                    ? "1"
                    : "0",
                transition: "all 0.3s ease-in-out",
              }}
            ></i>
          )}
        </th>
      );
    });
    return res;
  };
  colorStatus(string) {
    var res = "";
    switch (string) {
      case "Preventivo":
        res = <b style={{ color: "blue" }}>{string}</b>;
        break;
      case "Ordinato":
        res = <b style={{ color: "orange" }}>{string}</b>;
        break;
      case "Preventivo Inviato":
        res = <b style={{ color: "#2699F" }}>{string}</b>;
        break;
      case "Preventivo Accettato":
        res = <b style={{ color: "green" }}>{string}</b>;
        break;
      case "Annullato":
        res = <b style={{ color: "red" }}>{string}</b>;
        break;
      case "Ordine Accettato":
        res = <b style={{ color: "purple" }}>{string}</b>;
        break;
      case "Ordine di Vendita":
        res = <b style={{ color: "purple" }}>{string}</b>;
        break;
      case "sent":
        res = <b style={{ color: "green" }}>Inviato</b>;
        break;
      case "received":
        res = <b style={{ color: "red" }}>Ricevuto</b>;
        break;
      case "followup":
        res = <b style={{ color: "purple" }}>Followup</b>;
        break;
      case "open":
        res = <b style={{ color: "orange" }}>Aperto</b>;
        break;
      case "paid":
        res = <b style={{ color: "green" }}>Pagato</b>;
        break;
      case "assigned":
        res = <b style={{ color: "#2699F" }}>Assegnato</b>;
        break;
      case "done":
        res = <b style={{ color: "green" }}>Concluso</b>;
        break;
      case "cancel":
        res = <b style={{ color: "red" }}>Cancellato</b>;
        break;
      case "draft":
        res = <b style={{ color: "purple" }}>Bozza</b>;
        break;
      case "opportunity":
        res = <b style={{ color: "green" }}>Opportunità</b>;
        break;
      case "lead":
        res = <b style={{ color: "orange" }}>Lead</b>;
        break;
      default:
        res = <b>{string}</b>;
    }
    return res;
  }
  render() {
    switch (this.props.choose) {
      case "header":
        return this.renderHeader(
          this.props.head_array,
          this.props.handleState,
          this.props.orderstate
        );
      default:
        return this.colorStatus(this.props.choose);
    }
  }
}
