import React, { Component } from "react";
import "./Load.css";
import loader from '../assets/mohd_loading.gif'
export default class Load extends Component {
  render() {
    return (
      <div className="loader_outer h-100 w-100 d-flex justify-content-center align-items-center">
    {/*     <div className="loaderstr">
          <div className="loader__bar" />
          <div className="loader__bar" />
          <div className="loader__bar" />
          <div className="loader__bar" />
          <div className="loader__bar" />
          <div className="loader__ball" />
        </div>{" "} */}
        <div className="loader_div_container">
        <img src={loader}/>
        <p className="loader_string">
          {this.props.text}
          <span className="dots"></span>
        </p></div>
      </div>
    );
  }
}
