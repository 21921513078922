import React, { Component } from "react";
import ReactDOM from "react-dom";
import "./InnerPreventivatore.css";
import furniture from "../../assets/furniture.jpg";
import brand1 from "../../assets/odoo.png";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import brand2 from "../../assets/book.png";
import brand3 from "../../assets/new.png";
import floor from "../../assets/floor.png";
import { Animated } from "react-animated-css";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { Droppable, DragDropContext, Draggable } from "react-beautiful-dnd";
import Select from "react-select";
import { Tooltip } from "@material-ui/core";
import Swal from "sweetalert2";
import $ from "jquery";
import Iframe from "./Iframe";
import api from "../../config";
import Load from "../../components/Load";
import SearchBar from "../../components/search_bar/SearchBar";
import Textfit from "react-textfit/lib/Textfit";
import Creatable from "react-select/creatable";
var _ = api._;
export default class InnerPreventivatore extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingSave: false,
      instock: false,
      fase: 0,
      brand: "",
      changeNomePrev: false,
      _nome: "",
      _desc: "",
      _sku_fornitore: "",
      product_id: "",
      _brand: { value: -11, label: "Seleziona il brand..." },
      _costo: 0,
      _vendita: "",
      _vendita_iva: "",
      _id: -1,
      _sku: "",
      _taxes_ids: [{ amount: 22, label: "Iva al 22% (debito)", value: 1 }],
      _discount: "",
      brand_text: "",
      _categoria: { value: -11, label: "Seleziona la categoria..." },
      _sequence: -1,
      _qta: 1,
      foto0: { id: 0, url: "" },
      foto1: { id: 0, url: "" },
      foto2: { id: 0, url: "" },
      foto3: { id: 0, url: "" },
      _id_section: -1,
      order_line_id: "",
      _id_floor: -1,
      offset: 0,
      new_floor_section_link: -1,
      new_section_name: "",
      newfloor: false,
      newproduct: false,
      modify_floor: -1,
      modify_section: -1,
      new_floor_name: "",
      prod: { nome: "" },
      temp_floor: { id: -1 },
      temp_section: { id: -1 },
      floors: this.props.floors,
      sections: this.props.sections,
      items: this.props.items,
      scrollX: 0,
      iFrameHeight: 0,
      categories: [],
      brands: [],
      taxes: [],
    };
  }
  componentDidMount() {
    api.fetchCategories().then((value) => {
      this.setState({ categories: value });
    });
    api.fetchBrands().then((value) => {
      this.setState({ brands: value });
    });
    api.getTaxes().then((value) => {
      this.setState({ taxes: value });
    });
  }

  selectImg() {
    switch (this.state.brand) {
      case "1": {
        return <img className="fiximg odoo" src={brand1} />;
      }
      case "2": {
        return <img className="fiximg" src={brand2} />;
      }
      case "3": {
        return <img className="fiximg" src={brand3} />;
      }
      default:
        return <img src={furniture} />;
    }
  }
  reset() {
    if (this.state.offset !== 0)
      window.scrollTo({ top: this.state.offset, behavior: "smooth" });
    this.setState({
      fase: 1,
      _sku_fornitore_flag: undefined,
      newfloor: false,
      newproduct: true,
      brand: "",
      brand_text: "",
      modify_floor: -1,
      modify_section: -1,
      modify: false,
      offset: 0,
      order_line_id: "",
      _nome: "",
      _brand: { label: "Seleziona il brand...", value: -11 },
      _costo: 0,
      product_id: "",
      _sku_fornitore: "",
      _id: -1,
      _sequence: -1,
      _vendita_iva: "",
      _vendita: "",
      _taxes_ids: [{ amount: 22, label: "Iva al 22% (debito)", value: 1 }],
      _desc: "",
      _discount: "",
      _sku: "",
      temp_floor: { id: -1 },
      new_floor_section_link: -1,
      temp_section: { id: -1 },
      new_floor_name: "",
      new_section_name: "",
      addfastSection: -1,
      addfastFloor: false,
      foto0: { id: 0, url: "" },
      foto1: { id: 0, url: "" },
      foto2: { id: 0, url: "" },
      foto3: { id: 0, url: "" },
      _foto: { id: 0, url: "" },
      _categoria: { label: "Seleziona la categoria...", value: -11 },
      _qta: 1,
      _id_section: -1,

      _id_floor: -1,
    });
  }
  createFloor(save) {
    var new_id;
    if (this.props.floors === []) {
      new_id = 0;
    } else {
      new_id = this.props.floors.length;
    }
    var floorLayout = {
      nome:
        this.state.new_floor_name !== ""
          ? this.state.new_floor_name.toUpperCase()
          : "DEFAULT",
      id: new_id,
    };
    if (
      floorLayout.nome.toUpperCase() === "DEFAULT" &&
      this.getFloorbyName("DEFAULT")
    ) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        html:
          "<div><h1>Attenzione!</h1><p> Il piano " +
          floorLayout.nome +
          " esiste già, sei pregato di scegliere un'altro nome! </p></div>",
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 2000,
        customClass: { container: "swal_container" },
        onClose: this.reset(),
      });
      return;
    }
    var arrayfloors = this.props.floors;
    arrayfloors.push(floorLayout);
    this.setState({ floors: arrayfloors });
    this.props.setSync(false);
    save &&
      this.props.saveFloor(arrayfloors, this.props.sections, this.props.items);
    save && this.reset();
    return new_id;
  }
  incrementIds(index, array) {
    var items_sup = array.slice(index);
    var items_sub = array.slice(0, index);
    items_sup.forEach((element) => {
      element.sequence = element.sequence + 1;
      items_sub.push(element);
    });

    return items_sub;
  }

  /* Gestisce in che modo deve cambiare la lista */
  onDragEnd = (result) => {
    const { destination, source, reason } = result;
    if (!destination || reason === "CANCEL") {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    var items = this.props.items;

    var destination_rule = JSON.parse(destination.droppableId);
    var source_rule = JSON.parse(result.draggableId);
    if (
      destination_rule.id_section === source_rule.id_section &&
      destination_rule.id_floor === source_rule.id_floor
    ) {
      var items_particular = this.getSectionItems(source_rule.id_section);
      items_particular.forEach((element) => {
        var indexOf = items.indexOf(element);
        items.splice(indexOf, 1);
      });
      items_particular[source.index].sequence =
        items_particular[destination.index].sequence;
      var current_elem = items_particular[source.index];
      items_particular.splice(source.index, 1);
      items_particular = this.incrementIds(destination.index, items_particular);
      items_particular.push(current_elem);
      items_particular.forEach((element) => {
        items.push(element);
      });
    } else {
      items[
        items.indexOf(this.getItem(source_rule.id, this.props.items))
      ].id_floor = destination_rule.id_floor;
      items[
        items.indexOf(this.getItem(source_rule.id, this.props.items))
      ].id_section = destination_rule.id_section;
    }
    this.props.setSync(false);
    this.setState({ items: items });
  };
  swapfloor(floors, id, direction) {
    const swap = (floors, x, y) => {
      var temp = floors[y];
      floors[y] = floors[x];
      floors[x] = temp;
    };

    var tar_index = floors.findIndex((element) => element.id === id);
    if (floors) {
      if (floors.length > 1) {
        if (direction === "up") {
          if (id === 0) {
            swap(floors, floors.length - 1, tar_index);
          } else {
            swap(floors, tar_index, tar_index - 1);
          }
        } else if (direction === "down")
          if (id === floors.length - 1) {
            swap(floors, 0, tar_index);
          } else {
            swap(floors, tar_index, tar_index + 1);
          }
      }
    }
    this.props.setSync(false);
    this.props.saveFloor(floors, this.props.sections, this.props.items);
    this.setState({ floors: floors });
  }

  active_detail = (item, offset)=> {
    let base_imponibile = Number(item.vendita);
    var tax = 0;
    item.taxes_ids.forEach((element) => {
      tax += base_imponibile * (element.amount / 100);
    });
    this.setState({
      product_id: item.product_id,
      _nome: item.nome,
      _costo: item.costo.toFixed(2),
      _vendita: item.vendita.toFixed(2),
      _brand: item.brand,
      _categoria: item.categoria,
      _vendita_iva: (item.vendita + tax).toFixed(2),
      _sku: item.sku,
      _sku_fornitore: item.sku_fornitore || "",
      _qta: item.qta,
      _taxes_ids: item.taxes_ids,
      _desc: item.desc,
      _discount: item.discount,
      _id: item.id,
      foto0: item.foto0,
      foto1: item.foto1,
      foto2: item.foto2,
      foto3: item.foto3,
      order_line_id: item.order_line_id,
      _id_section: item.id_section,
      _sequence: item.sequence,
      _id_floor: item.id_floor,
      temp_floor: this.props.floors.filter((single) => {
        return single.id === item.id_floor;
      })[0],
      temp_section: this.props.sections.filter((single) => {
        return single.id === item.id_section;
      })[0],
      fase: 3,
      brand: item.inner_brand,
      newproduct: true,
      newfloor: false,
      modify: true,
      offset: offset,
    });
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  editSection(element, save) {
    var sections = this.props.sections;
    sections[sections.indexOf(element)].nome = this.state._nome.toUpperCase();
    this.props.setSync(false);
    this.setState({ sections, modify_section: -1 });
    save && this.props.saveFloor(this.props.floors, sections, this.props.items);
  }
  editFloor(element, save) {
    var floors = this.props.floors;
    floors[floors.indexOf(element)].nome = this.state._nome.toUpperCase();
    this.props.setSync(false);
    this.setState({ floors, modify_floor: -1 });
    save && this.props.saveFloor(floors, this.props.sections, this.props.items);
  }
  eliminateSection(target, save) {
    this.props.setSync(false);
    var items = this.props.items.filter((single) => {
      return single.id_section !== target.id;
    });
    var sections = this.props.sections.filter((single) => {
      return single.id !== target.id;
    });
    this.setState({ items: items, sections: sections });
    save && this.props.saveFloor(this.props.floors, sections, items);
  }
  eliminateFloor(target) {
    this.props.setSync(false);
    var sections = this.props.sections;
    if (sections.length > 0) {
      sections.forEach((element) => {
        if (element.id_floor === target.id) {
          this.eliminateSection(element, false);
        }
      });
    }
    var floors = this.props.floors.filter((single) => {
      return single.id !== target.id;
    });
    this.setState({ floors: floors }, () => {
      this.props.saveFloor(floors, sections, this.state.items);
    });
  }
  deleteItem(target, save) {
    var items = this.props.items.filter((single) => {
      return single.id !== target.id;
    });
    this.setState({ items: items });
    save && this.props.saveFloor(this.props.floors, this.props.sections, items);
  }
  renderSections(id_floor) {
    const sections = this.getFloorSections(id_floor);
    const renderItems = (id_sections) => {
      const items = this.getSectionItems(id_sections);
      var res = [];
      items.forEach((element) => {
        res.push(
          <Draggable
            key={element.nome + items.indexOf(element) + element.id}
            index={items.indexOf(element)}
            draggableId={JSON.stringify({
              id: element.id,
              id_section: element.id_section,
              id_floor: element.id_floor,
            })}
            style={{ border: "1px solid blue" }}
          >
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                {provided.placeholder}

                <div key={element.nome} className="row_div items">
                  <div className="cell_div">
                    <img
                      className="preview_product"
                      alt={element.nome.substring(0, 15)}
                      src={api.auth_api + element.foto0.url}
                    />
                  </div>

                  <div className="cell_div">
                    <p className="brand">
                      {
                        element.brand.label
                        /* ? element.brand.label
                        : element.brand_text */
                      }
                    </p>
                    <this.BootstrapTooltip title={element.nome}>
                      <p className="nome">
                        [{element.sku}]{" "}
                        {/*   {element.nome.length > 20
                          ? element.nome.substring(0, 20) + "..."
                          : element.nome} */}
                        {element.nome}
                      </p>
                    </this.BootstrapTooltip>
                  </div>
                  <div className="cell_div">
                    <p className="quantita">x{element.qta}</p>
                  </div>
                  <div className="cell_div">
                    <span className="specify">p.u.</span>
                    <p>
                      {!this.props.show_iva
                        ? Number(element.vendita).toLocaleString("it-IT", {
                            style: "currency",
                            currency: "EUR",
                          })
                        : Number(
                            element.price_unit_taxed_undiscounted
                          ).toLocaleString("it-IT", {
                            style: "currency",
                            currency: "EUR",
                          })}
                    </p>
                  </div>
                  <div className="cell_div">
                    <span className="specify">tax. </span>
                    <ul className="iva">
                      {element.taxes_ids.map((element) => {
                        return (
                          <li key={element.value}>
                            <Textfit
                              style={{ height: "16px" }}
                              mode="single"
                              min={5}
                              max={9}
                            >
                              {element.label}
                            </Textfit>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  <div className="cell_div">
                    <React.Fragment>
                      {" "}
                      <span className="specify">disc. </span>
                      {element.discount} %{" "}
                    </React.Fragment>
                  </div>
                  <div className="cell_div">
                    <span className="specify">subt. </span>
                    <React.Fragment>
                      {!this.props.show_iva
                        ? Number(element.price_subtotal).toLocaleString(
                            "it-IT",
                            {
                              style: "currency",
                              currency: "EUR",
                            }
                          )
                        : Number(element.price_total).toLocaleString("it-IT", {
                            style: "currency",
                            currency: "EUR",
                          })}
                    </React.Fragment>
                  </div>
                  <div style={{ whiteSpace: "nowrap" }} className="cell_div">
                    <button
                      onClick={() => {
                        this.active_detail(element,window.pageXOffset)
                        
                      }}
                      className="btn_outlined"
                    >
                      Dettagli Prodotto
                    </button>
                    <this.BootstrapTooltip title={"Elimina " + element.nome}>
                      <i
                        onClick={() => this.deleteItem(element, true)}
                        style={{
                          marginLeft: "15px",
                          verticalAlign: "middle",
                        }}
                        className="fa fa-trash"
                      />
                    </this.BootstrapTooltip>
                  </div>
                </div>
                {provided.placeholder}
              </div>
            )}
          </Draggable>
        );
      });
      return res;
    };
    var res = [];
    sections.forEach((element) => {
      res.push(
        <Droppable
          key={element.id + element.nome}
          droppableId={JSON.stringify({
            id_floor: element.id_floor,
            id_section: element.id,
          })}
        >
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <Accordion>
                <AccordionSummary
                  expandIcon={<i className="fa fa-arrow-down"></i>}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  {this.state.modify_section === element.id ? (
                    <input
                      value={this.state._nome}
                      onChange={(e) => this.setState({ _nome: e.target.value })}
                      onKeyPress={(event) => {
                        if (event.key === "Enter") {
                          this.editSection(element);
                        }
                      }}
                    ></input>
                  ) : (
                    <span>{element.nome}</span>
                  )}
                  {this.state.modify_section === element.id ? (
                    <div id="checkk" className="icon_accordion">
                      <this.BootstrapTooltip
                        title="Conferma"
                        aria-label="Conferma"
                      >
                        <i
                          onClick={() => this.editSection(element)}
                          className="fa fa-check"
                        />
                      </this.BootstrapTooltip>
                    </div>
                  ) : (
                    <div className="icon_accordion">
                      <this.BootstrapTooltip
                        title="Modifica il nome della sezione"
                        aria-label="Modifica il nome della sezione"
                      >
                        <i
                          className="fa fa-pencil"
                          onClick={() =>
                            this.setState({
                              modify_section: element.id,
                              _nome: element.nome,
                            })
                          }
                        />
                      </this.BootstrapTooltip>
                      <this.BootstrapTooltip
                        title="Elimina Sezione"
                        aria-label="Elimina Sezione"
                      >
                        <i
                          className="fa fa-trash"
                          onClick={() =>
                            Swal.fire({
                              html:
                                "<h1>Sei sicuro di voler eliminare la sezione '" +
                                element.nome +
                                "'? </h1> <p>L'operazione è irreversibile</p>",
                              icon: "warning",
                              showCancelButton: true,
                              customClass: {
                                confirmButton: "btn_modifica",
                                cancelButton: "btn_annulla",
                                container: "swal_container",
                              },
                              confirmButtonText: "Si",
                              buttonsStyling: false,
                              cancelButtonText: "Annulla",
                            }).then((result) => {
                              if (result.isConfirmed) {
                                Swal.fire({
                                  timer: 1000,
                                  timerProgressBar: true,
                                  showConfirmButton: false,
                                  customClass: { container: "swal_container" },
                                  html:
                                    "<h1>Sezione eliminata!</h1><p>Hai eliminato correttamente " +
                                    element.nome +
                                    "</p>",
                                  icon: "success",
                                });
                                this.eliminateSection(element, true);
                              }
                            })
                          }
                        />
                      </this.BootstrapTooltip>
                    </div>
                  )}
                </AccordionSummary>
                <AccordionDetails>
                  <div className="table_div">
                    {provided.placeholder}
                    {renderItems(element.id)}
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          )}
        </Droppable>
      );
      res.push(<br key={element.id + 1} />);
    });
    return res;
  }
  renderFloorName() {
    var res = [];
    if (this.props.floors !== {})
      this.props.floors.forEach((element) => {
        res.push(
          <option key={element.id} value={element.id}>
            {element.nome}
          </option>
        );
      });
    return res;
  }
  BootstrapTooltip(props) {
    const useStylesBootstrap = makeStyles((theme) => ({
      arrow: {
        color: theme.palette.common.black,
      },
      tooltip: {
        backgroundColor: theme.palette.common.black,
        boxShadow: theme.shadows[1],
        fontSize: 14,
      },
    }));
    const classes = useStylesBootstrap();

    return <Tooltip arrow classes={classes} {...props} />;
  }
  renderFloors() {
    var res = [];
    if (this.props.floors)
      this.props.floors.forEach((element) => {
        res.push(
          <Accordion key={element.id + element.nome}>
            <AccordionSummary
              expandIcon={<i className="fa fa-arrow-down"></i>}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div className="icon_accordion arrows">
                <this.BootstrapTooltip
                  title="Porta in basso"
                  aria-label="Porta in basso"
                >
                  <i
                    onClick={() =>
                      this.swapfloor(this.props.floors, element.id, "down")
                    }
                    className="fa fa-arrow-down"
                  />
                </this.BootstrapTooltip>
                <this.BootstrapTooltip
                  title="Porta in alto"
                  aria-label="Porta in alto"
                >
                  <i
                    onClick={() =>
                      this.swapfloor(this.props.floors, element.id, "up")
                    }
                    className="fa fa-arrow-up"
                  ></i>
                </this.BootstrapTooltip>
              </div>
              {this.state.modify_floor === element.id ? (
                <input
                  value={this.state._nome}
                  onChange={(e) => this.setState({ _nome: e.target.value })}
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      this.editFloor(element, true);
                    }
                  }}
                ></input>
              ) : (
                <span>{element.nome}</span>
              )}
              {this.state.modify_floor === element.id ? (
                <this.BootstrapTooltip title="Conferma" aria-label="Conferma">
                  <div id="checkk" className="icon_accordion">
                    <i
                      onClick={() => this.editFloor(element, true)}
                      className="fa fa-check"
                    />
                  </div>
                </this.BootstrapTooltip>
              ) : (
                <div className="icon_accordion">
                  <this.BootstrapTooltip
                    title="Modifica il nome del piano"
                    aria-label="Modifica il nome del piano"
                  >
                    <i
                      className="fa fa-pencil"
                      onClick={() =>
                        this.setState({
                          modify_floor: element.id,
                          _nome: element.nome,
                        })
                      }
                    />
                  </this.BootstrapTooltip>
                  <this.BootstrapTooltip
                    title="Elimina piano"
                    aria-label="Elimina piano"
                  >
                    <i
                      className="fa fa-trash"
                      onClick={(e) =>
                        Swal.fire({
                          html:
                            "<h1>Attenzione</h1><p>Sei sicuro di voler eliminare il piano " +
                            element.nome +
                            "?</p>",
                          icon: "warning",
                          showCancelButton: true,
                          customClass: {
                            confirmButton: "btn_modifica",
                            cancelButton: "btn_annulla",
                            container: "swal_container",
                          },
                          confirmButtonText: "Si",
                          cancelButtonText: "Annulla",
                          buttonsStyling: false,
                        }).then((result) => {
                          if (result.isConfirmed) {
                            Swal.fire({
                              customClass: { container: "swal_container" },
                              html:
                                "<h1>Piano eliminato</h1><p>Hai eliminato correttamente " +
                                element.nome +
                                "</p>",
                              icon: "success",
                              timer: 1500,
                              showConfirmButton: false,
                              timerProgressBar: true,
                              position: "center",
                            });
                            this.eliminateFloor(element);
                          }
                        })
                      }
                    />
                  </this.BootstrapTooltip>
                </div>
              )}
            </AccordionSummary>
            <AccordionDetails>
              {this.renderSections(element.id)}
              <div className="cont">
                <button
                  onClick={() =>
                    this.state.addfastSection !== element.id
                      ? this.setState({
                          addfastSection: element.id,
                          new_floor_section_link: element.id,
                        })
                      : this.createSection(element.id, true)
                  }
                  className="btn_crea"
                >
                  {this.state.addfastSection !== element.id
                    ? "Aggiungi Sezione"
                    : "Conferma"}
                </button>
                <input
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      this.state.addfastSection !== element.id
                        ? this.setState({
                            addfastSection: element.id,
                            new_floor_section_link: element.id,
                          })
                        : this.createSection(element.id, true);
                    }
                  }}
                  className={
                    this.state.addfastSection !== element.id
                      ? "height0"
                      : "height45"
                  }
                  type="text"
                  value={this.state.new_section_name}
                  onChange={(e) =>
                    this.setState({
                      new_section_name: e.target.value.toUpperCase(),
                    })
                  }
                />
              </div>
            </AccordionDetails>
          </Accordion>
        );
      });
    return res;
  }
  validateObj() {
    var valuable = this.state.prod;
    if (
      !valuable.nome ||
      !valuable.costo ||
      !valuable.brand ||
      !valuable.vendita
    ) {
      return true;
    } else return false;
  }
  getFloorSections(id_floor) {
    const sections = this.props.sections;
    const floors = this.props.floors;
    var res = [];
    if (floors !== [{}])
      sections.forEach((element) => {
        if (element.id_floor === id_floor) {
          res.push(element);
        }
      });
    return res;
  }
  getSectionItems(id_section) {
    const items = this.props.items;
    const sections = this.props.sections;
    var res = [];
    if (items !== [{}])
      items.forEach((element) => {
        if (element.id_section === id_section) {
          res.push(element);
        }
      });

    res.sort(function (a, b) {
      var keyA = a.sequence,
        keyB = b.sequence;
      // Compare the 2 dates
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });
    return res;
  }
  getItemBySku(array, sku) {
    var res = null;
    array.forEach((element) => {
      if (element.sku === sku) res = element;
    });
    return res;
  }
  wrapitemcreator(newproduct, id_floor, id_section) {
    /* inserire/modificare l'oggetto in un piano ed una sezione */
    var items = this.props.items;
    var items_array = [];
    var newid = newproduct.id;
    var index = 0;
    /* Correzioni costi e attributi del prodotto */
    newproduct.costo = newproduct.costo + "".replace(/,/gm, ".");
    newproduct.vendita = newproduct.vendita + "".replace(/,/gm, ".");
    newproduct.costo = parseFloat(newproduct.costo);
    newproduct.vendita = parseFloat(newproduct.vendita);
    newproduct.product_id = this.state.product_id;
    newproduct.foto0 = newproduct.foto0 === null ? {} : newproduct.foto0;
    newproduct.foto1 = newproduct.foto1 === null ? {} : newproduct.foto1;
    newproduct.foto2 = newproduct.foto2 === null ? {} : newproduct.foto2;
    newproduct.foto3 = newproduct.foto3 === null ? {} : newproduct.foto3;
    /* IL PRODOTTO ESISTE */
    if (newid > -1) {
      console.log("newid", newid);
      newproduct.id_floor = id_floor;
      newproduct.id_section = id_section;
      items = items.filter((single) => {
        return single.id !== newproduct.id;
      });
      items.push(newproduct);
      this.props.setSync(false);
      this.setState({ items: items }, () => {
        this.props.saveFloor(this.props.floors, this.props.sections, items);
      });

      this.reset();
      return;
    } else {
      /* IL PRODOTTO NON HA ID DEVE ESSERE CREATO */

      /* ASSEGNAZIONE DEL PIANO */
      if (id_floor > -1) {
        /* Caso Piano Esistente */
        newproduct.id_floor = id_floor;
      } else {
        /* Caso Piano Inesistente */
        /* Cerco il piano DEFAULT */
        var def_floor = this.props.floors.find((single) => {
          return single.nome === "DEFAULT";
        });
        console.log("def_floor", def_floor);
        if (def_floor) {
          console.log("Esiste");
          /* CASO PIANO DEFAULT ESISTENTE */
          newproduct.id_floor = def_floor.id;
        } else {
          /* CASO PIANO DEFAULT NON TROVATO, LO CREO */
          newproduct.id_floor = this.createFloor(false);
        }
      }
      /* FINE ASSEGNAZIONE DEL PIANO */

      /* INIZIO ASSEGNAZIONE DELLA SEZIONE */
      if (id_section > -1) {
        /* Caso sezione esistente */
        newproduct.id_section = id_section;
      } else {
        /* Cerco la sezione default all'interno del piano */
        var section = this.props.sections.find((single) => {
          return (
            single.id_floor === newproduct.id_floor && single.nome === "DEFAULT"
          );
        });
        console.log(section);
        if (section) {
          /* La sezione DEFAULT è presente nel piano */
          newproduct.id_section = section.id;
        } else {
          /* La sezione DEFAULT non è presente nel piano, la creo */
          newproduct.id_section = this.createSection(
            newproduct.id_floor,
            false
          );
        }
      }

      /* FINE ASSEGNAZIONE PIANO E SEZIONE */

      /* Array di supporto per sequence e id */
      items_array = items.filter((single) => {
        return (
          single.id_section === newproduct.id_section &&
          single.id_floor === newproduct.id_floor
        );
      });
      /* ASSEGNAZIONE SEQUENCE */
      if (newproduct.sequence < 0 || !newproduct.sequence) {
        if (items_array.length === 0) newproduct.sequence = 1;
        else {
          newproduct.sequence =
            Math.max.apply(
              Math,
              items_array.map(function (o) {
                return o.sequence;
              })
            ) + 1;
        }
      }
      /* FINE ASSEGNAZIONE SEQUENCE */

      /* ASSEGNAZIONE ID */
      if (newproduct.id < 0) {
        if (items.length === 0) newproduct.id = 0;
        else {
          newproduct.id =
            Math.max.apply(
              Math,
              items.map(function (o) {
                return o.id;
              })
            ) + 1;
        }
        items.push(newproduct);
      } else {
        items = items.map((single) => {
          if (
            single.id === newproduct.id &&
            single.id_floor === newproduct.id_floor &&
            single.id_section === newproduct.id_section
          ) {
            single = newproduct;
          }
        });
      }
      this.props.setSync(false);
      this.setState({ items: items }, () => {
        this.props.saveFloor(this.props.floors, this.props.sections, items);
      });

      this.reset();
    }

    /* INIZIO CONTROLLO PRE-ESISTENZA DEL PRODOTTO */
  }
  /*  wrapitemcreator(newproduct, id_floor, id_section) {
    var items = this.props.items;
    var items_array = [];
    var newid = newproduct.id;
    var index = 0;
    var floor = this.getFloor(id_floor);
    var section = this.getSection(id_section);
    newproduct.costo = newproduct.costo + "".replace(/,/gm, ".");
    newproduct.vendita = newproduct.vendita + "".replace(/,/gm, ".");
    newproduct.costo = parseFloat(newproduct.costo);
    newproduct.vendita = parseFloat(newproduct.vendita);
    newproduct.product_id = this.state.product_id;
    newproduct.foto0=newproduct.foto0===null ? {} :newproduct.foto0
    newproduct.foto1=newproduct.foto1===null ? {} :newproduct.foto1
    newproduct.foto2=newproduct.foto2===null ? {}:newproduct.foto2
    newproduct.foto3=newproduct.foto3===null ? {}:newproduct.foto3
    if (id_floor !== -1) {
      newproduct.id_floor = id_floor;
    } else if (floor === undefined) {
      var new_floor = this.getFloorbyName("DEFAULT");
      if (new_floor !== undefined) newproduct.id_floor = new_floor.id;
      else {
        newproduct.id_floor = this.createFloor(false);
      }
    }
    if (id_section !== -1) {
      newproduct.id_section = id_section;
      items_array = this.getSectionItems(id_section);
    } else if (section === undefined) {
      var new_section = this.getSectionbyName("DEFAULT", newproduct.id_floor);
      if (new_section !== undefined) {
        newproduct.id_section = new_section.id;
        items_array = this.getSectionItems(new_section.id);
      } else {
        newproduct.id_section = this.createSection(newproduct.id_floor, false);
        items_array = this.getSectionItems(newproduct.id_section);
      }
    } else {
      newproduct.id_section = section.id;
      items_array = this.getSectionItems(section.id);
    }

    var same = this.getItemBySku(items_array, newproduct.sku);
    if (same !== null) {
      var index_same = items.indexOf(same);

      if (index_same === 0) items.shift();
      else items.splice(index_same, index_same);
      if (!newproduct.id) newproduct.id = newproduct.order_line_id;
      items.push(newproduct);

      this.setState({ items }, () => {
        this.props.saveFloor(this.props.floors, this.props.sections, items);
      });
      this.props.setSync(false);

      this.reset();
      return;
    }
    if ((newproduct.sequence = -1)) {
      if (items_array.length === 0) newproduct.sequence = 1;
      else {
        newproduct.sequence = Math.max.apply(
          Math,
          items_array.map(function (o) {
            return o.sequence;
          })
        );
      }
    }
    if (newid === -1) {
      newid = items_array === [] ? 0 : items.length;
      newproduct.id = newid;
      items.push(newproduct);
    } else {
      var result = items.find((obj) => {
        return obj.id === newproduct.id;
      });
      index = items.indexOf(result);
      items[index] = newproduct;
    }
    this.setState({ items: items }, () => {
      this.props.saveFloor(this.props.floors, this.props.sections, items);
    });

    this.props.setSync(false);
    this.reset();
  } */
  validateForm() {
    if (
      this.state._nome.length > 0 &&
      this.state._brand &&
      (this.state._brand.value > 0 || this.state._brand.value === "ship") &&
      this.state._categoria &&
      this.state._categoria.value > 0
    )
      return true;
    else {
      Swal.fire({
        position: "center",
        title: "Compila tutti i dati",
        icon: "error",
        timer: 1500,
        timerProgressBar: true,
        showConfirmButton: false,
        customClass: { container: "swal_container" },
      });
      return false;
    }
  }
  saveLocalStorage() {
    this.props
      .saveDraft(
        this.props.floors,
        this.props.sections,
        this.state.items,
        this.props.anagrafy,
        1
      )
      .then((value) => {
        if (value) {
          setTimeout(() => {
            api.fetchDraft(value.order_id).then((final) => {
              if (final) {
                var new_items_fix_brand = final.items;
                new_items_fix_brand.forEach(element => {
                  if(element.inner_brand==="brand1")
                  {
                    element.inner_brand="1"
                  }
                });
                this.setState({
                  items: new_items_fix_brand,
                  sections: final.sections,
                  floors: final.floors,
                });

                Swal.fire({
                  position: "center",
                  title: "Preventivo Salvato",
                  icon: "success",
                  timer: 1500,
                  timerProgressBar: true,
                  showConfirmButton: false,
                  customClass: { container: "swal_container" },
                });
              } else this.setState({ loadingSave: false });
            }, 500);
          });
        }
      });
  }
  getFloor(id) {
    var result = this.props.floors.find((obj) => {
      return obj.id === id;
    });
    return result;
  }
  getFloorbyName(nome) {
    var result = null;
    result = this.props.floors.find((obj) => {
      return obj.nome.toUpperCase() === nome.toUpperCase();
    });
    return result;
  }
  getSectionbyName(nome, id_floor) {
    var result = null;
    result = this.props.sections.find((obj) => {
      return (
        obj.nome.toUpperCase() === nome.toUpperCase() &&
        obj.id_floor === id_floor
      );
    });
    return result;
  }
  getItem(id, array) {
    var result = array.find((obj) => {
      return obj.id === id;
    });
    return result;
  }
  getSection(id_section) {
    var result = this.props.sections.find((obj) => {
      return obj.id === id_section;
    });
    return result;
  }
  setSearchProduct = (item) => {
    this.setState({
      _nome: item.name,
      _vendita_iva: item.taxed_sale_price.toFixed(2),
      _costo: item.standard_price.toFixed(2),
      _vendita: item.lst_price.toFixed(2),
      product_id: item.id,
      order_line_id: item.order_line_id,
      _is_shipping: item.is_shipping,
      _brand: item.is_shipping
        ? {
            label: "Shipping",
            value: "ship",
          }
        : item.brand,
      _sku: item.sku,
      _desc: item.description,
      _sku_fornitore_flag: item.sku_fornitore ? true : false,
      _sku_fornitore: item.sku_fornitore ? item.sku_fornitore : "",
      _taxes_ids: item.taxes_id,
      _categoria: item.category,
      foto0: item.image_medium
        ? { id: "", url: item.image_medium }
        : { id: 0, url: "" },
      _id: item.id,
    });
  };
  generatefoto(file, callback) {
    const reader = new FileReader();
    const url = reader.readAsDataURL(file);
    this.setState({ loadingUpload: true });
    reader.onloadend = (e) => {
      let request = {
        name: file.name,
        datas: reader.result.substring(
          reader.result.indexOf(",") > 0 ? reader.result.indexOf(",") + 1 : 0
        ),
      };
      api.uploadImg(request).then((value) => {
        callback(value);
        this.setState({ loadingUpload: false });
      });
    };
  }

  handePhoto(event, i) {
    const file = event.target.files[0];
    // User cancelled
    // event.persist()
    if (!file) {
      if (event.target.defaultValue) {
        switch (i) {
          case 1:
            this.setState({ foto0: event.target.defaultValue });
            break;
          case 2:
            this.setState({ foto1: event.target.defaultValue });
            break;
          case 3:
            this.setState({ foto2: event.target.defaultValue });
            break;
          case 4:
            this.setState({ foto3: event.target.defaultValue });
            break;
        }
      }
      return;
    }
    switch (i) {
      case 1:
        this.generatefoto(file, (foto0) => {
          this.setState({ foto0 });
        });
        break;
      case 2:
        this.generatefoto(file, (foto1) => {
          this.setState({ foto1 });
        });
        break;
      case 3:
        this.generatefoto(file, (foto2) => {
          this.setState({ foto2 });
        });
        break;
      case 4:
        this.generatefoto(file, (foto3) => {
          this.setState({ foto3 });
        });
        break;
    }
  }

  addProduct() {
    var res = [];
    switch (this.state.brand) {
      case "1":
        res.push(
          <div className="adder" key="1">
            <SearchBar
              checkbox
              placeholder="Cerca un prodotto..."
              label={"Cerca Prodotto"}
              setSearch={this.setSearchProduct}
              apiCall={api.searchAsyncProduct}
              async
              adv_filter={[
                { odoo: "filters.categ_id.name", frontend: "categoria" },
                { odoo: "filters.product_brand_id.name", frontend: "brand" },
              ]}
              innerjson="value"
            />

            <div className="prev_card">
              <div className="table_div">
                <div className="row_div">
                  <div className="cell_div">
                    <p className="subtitle">Brand*</p>

                    <Select
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                      menuPortalTarget={document.body}
                      placeholder="Seleziona Brand..."
                      isDisabled
                      onChange={(e) => {
                        this.setState({ _brand: e });
                      }}
                      value={this.state._brand}
                      options={this.state.brands}
                    />
                  </div>
                  <div className="cell_div">
                    <p className="subtitle">Prodotto*</p>
                    <input
                      type="text"
                      disabled
                      value={this.state._nome}
                      onChange={(e) => {
                        this.setState({ _nome: e.target.value });
                      }}
                    />
                  </div>
                </div>
                <div className="row_div">
                  <div className="cell_div">
                    <p className="subtitle">Categoria*</p>
                    <Select
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                      menuPortalTarget={document.body}
                      placeholder="Seleziona Categoria..."
                      isDisabled
                      onChange={(e) => {
                        this.setState({ _categoria: e });
                      }}
                      value={this.state._categoria}
                      options={this.state.categories}
                    />
                  </div>
                  <div className="cell_div">
                    <p className="subtitle">Sku</p>
                    <input type="text" disabled value={this.state._sku} />
                  </div>
                </div>
              </div>
              <div className="table_div">
                <div className="row_div">
                  <div className="cell_div">
                    <p className="subtitle">Sku Fornitore*</p>
                    <input
                      style={{
                        border:
                          this.state._sku_fornitore_flag === false
                            ? "2px solid black"
                            : "",
                        transition: "all 0.3s ease-in-out",
                      }}
                      type="text"
                      disabled={this.state._sku_fornitore_flag}
                      onChange={(e) =>
                        this.setState({ _sku_fornitore: e.target.value })
                      }
                      value={this.state._sku_fornitore}
                    />
                  </div>
                </div>
                <div className="row_div">
                  <div className="cell_div">
                    <p className="subtitle">Descrizione</p>
                    <textarea
                      type="text"
                      id="_desc"
                      onChange={(e) => this.setState({ _desc: e.target.value })}
                      value={this.state._desc || ""}
                    >
                      {this.state._desc}
                    </textarea>
                  </div>
                </div>
              </div>
            </div>

            <div className="prev_card  searchable">
              <div className="table_div">
                <div className="row_div">
                  <div className="cell_div">
                    <p className="subtitle">Vendita (Iva excl.)* &euro;</p>
                    {this.props.permission.opt_product_upd_sale_price ? (
                      <div className="input_container">
                        <input
                          type="number"
                          className="input_icon"
                          onChange={(e) => {
                            let base_imponibile = Number(e.target.value);
                            var tax = 0;
                            this.state._taxes_ids.forEach((element) => {
                              tax += base_imponibile * (element.amount / 100);
                            });

                            this.setState({
                              _vendita: e.target.value,
                              _vendita_iva: Number(
                                (base_imponibile + tax).toFixed(2)
                              ),
                            });
                          }}
                          value={this.state._vendita}
                        />
                        <this.BootstrapTooltip title={"Aggiorna Prezzo Odoo"}>
                          <i
                            onClick={() => {
                              if (this.state.product_id)
                                api.updateSalePrice(
                                  this.state.product_id,
                                  this.state._vendita
                                );
                            }}
                            className="fa fa-cloud-upload"
                          />
                        </this.BootstrapTooltip>
                      </div>
                    ) : (
                      <input
                        type="number"
                        className="input_icon"
                        onChange={(e) => {
                          let base_imponibile = Number(e.target.value);
                          var tax = 0;
                          this.state._taxes_ids.forEach((element) => {
                            tax += base_imponibile * (element.amount / 100);
                          });

                          this.setState({
                            _vendita: e.target.value,
                            _vendita_iva: Number(
                              (base_imponibile + tax).toFixed(2)
                            ),
                          });
                        }}
                        value={this.state._vendita}
                      />
                    )}
                  </div>
                  <div className="cell_div">
                    <p className="subtitle">Vendita (Iva incl.)</p>
                    <input
                      type="number"
                      min={0}
                      onChange={(e) => {
                        var prezzo_lordo = Number(e.target.value);
                        var tax = 0;
                        var imponibile = 0;
                        this.state._taxes_ids.forEach((element) => {
                          imponibile =
                            (prezzo_lordo * 100) / (element.amount + 100);
                          tax += prezzo_lordo - imponibile;
                        });
                        this.setState({
                          _vendita: Number((prezzo_lordo - tax).toFixed(2)),
                          _vendita_iva: e.target.value,
                        });
                      }}
                      value={this.state._vendita_iva}
                    />
                  </div>
                </div>

                <div className="row_div">
                  <div className="cell_div">
                    <p className="subtitle">Sconto %</p>
                    <input
                      type="number"
                      onChange={(e) =>
                        this.setState({
                          _discount: Number(e.target.value),
                        })
                      }
                      value={this.state._discount}
                    />
                  </div>

                  <div className="cell_div">
                    <p className="subtitle">Quantità*</p>
                    <input
                      type="number"
                      onChange={(e) => {
                        if (Number(e.target.value) > -1)
                          this.setState({ _qta: Number(e.target.value) });
                      }}
                      value={this.state._qta}
                    />
                  </div>
                </div>
                {this.props.permission.opt_product_view_cost_price && (
                  <div className="row_div">
                    <div className="cell_div">
                      <p className="subtitle">Costo &euro;</p>
                      <input
                        type="number"
                        disabled
                        onChange={(e) =>
                          this.setState({ _costo: e.target.value })
                        }
                        value={this.state._costo}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="table_div">
                <div className="row_div">
                  <div className="cell_div">
                    <p className="subtitle">IVA </p>
                    <Select
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                      menuPortalTarget={document.body}
                      isMulti
                      classNamePrefix={"multi-select"}
                      placeholder="Seleziona Iva..."
                      onChange={(e, actiontype) => {
                        if (actiontype.action === "remove-value") {
                          this.setState({
                            _taxes_ids: e ? e : [],
                            _vendita_iva: e
                              ? this.state._vendita_iva +
                                (e[e.length - 1].amount / 100) *
                                  this.state._vendita
                              : this.state._vendita,
                          });
                        } else {
                          this.setState({
                            _taxes_ids: e === 0 ? [] : e,
                            _vendita_iva:
                              this.state._vendita_iva +
                              (e[e.length - 1].amount / 100) *
                                this.state._vendita,
                          });
                        }
                      }}
                      value={this.state._taxes_ids}
                      options={this.state.taxes}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="prev_card  ">
              <div className="table_div">
                <div className="row_div">
                  <div className="cell_div">
                    <div
                      className="img_uploader"
                      style={{
                        backgroundImage:
                          "url(" +
                          api.auth_api +
                          (this.state.foto0 && this.state.foto0.url) +
                          ")",
                      }}
                    >
                      {this.state.foto0 && this.state.foto0.url.length > 1 && (
                        <span className="iconafree">
                          <this.BootstrapTooltip title={"Elimina"}>
                            <i
                              onClick={() => this.setState({ foto0: null })}
                              className="fa fa-trash"
                            ></i>
                          </this.BootstrapTooltip>
                        </span>
                      )}
                      <input
                        accept="image/*"
                        type="file"
                        id="myuniqueid0"
                        onChange={(e) => this.handePhoto(e, 1)}
                      />
                      <label htmlFor="myuniqueid0">
                        <i
                          style={{
                            color:
                              this.state.foto0 &&
                              this.state.foto0.url.length > 1
                                ? "transparent"
                                : "blue",
                          }}
                          className="fa fa-plus"
                          aria-hidden="true"
                        />
                      </label>
                    </div>
                  </div>
                  <div className="cell_div">
                    <div
                      className="img_uploader"
                      style={{
                        backgroundImage:
                          "url(" +
                          api.auth_api +
                          (this.state.foto1 && this.state.foto1.url) +
                          ")",
                      }}
                    >
                      {this.state.foto1 && this.state.foto1.url.length > 1 && (
                        <span className="iconafree">
                          <this.BootstrapTooltip title={"Elimina"}>
                            <i
                              onClick={() => this.setState({ foto1: null })}
                              className="fa fa-trash"
                            ></i>
                          </this.BootstrapTooltip>
                        </span>
                      )}
                      <input
                        accept="image/*"
                        type="file"
                        id="myuniqueid1"
                        onChange={(e) => this.handePhoto(e, 2)}
                      />
                      <label htmlFor="myuniqueid1">
                        {" "}
                        <i
                          style={{
                            color:
                              this.state.foto1 &&
                              this.state.foto1.url.length > 1
                                ? "transparent"
                                : "blue",
                          }}
                          className="fa fa-plus"
                          aria-hidden="true"
                        />
                      </label>
                    </div>
                  </div>
                  <div className="cell_div">
                    <div
                      className="img_uploader"
                      style={{
                        backgroundImage:
                          "url(" +
                          api.auth_api +
                          (this.state.foto2 && this.state.foto2.url) +
                          ")",
                      }}
                    >
                      {this.state.foto2 && this.state.foto2.url.length > 1 && (
                        <span className="iconafree">
                          <this.BootstrapTooltip title={"Elimina"}>
                            <i
                              onClick={() => this.setState({ foto2: null })}
                              className="fa fa-trash"
                            ></i>
                          </this.BootstrapTooltip>
                        </span>
                      )}
                      <input
                        accept="image/*"
                        type="file"
                        id="myuniqueid2"
                        onChange={(e) => this.handePhoto(e, 3)}
                      />
                      <label htmlFor="myuniqueid2">
                        {" "}
                        <i
                          style={{
                            color:
                              this.state.foto2 &&
                              this.state.foto2.url.length > 1
                                ? "transparent"
                                : "blue",
                          }}
                          className="fa fa-plus"
                          aria-hidden="true"
                        />
                      </label>
                    </div>
                  </div>

                  <div className="cell_div">
                    <div
                      className="img_uploader"
                      style={{
                        backgroundImage:
                          "url(" +
                          api.auth_api +
                          (this.state.foto3 && this.state.foto3.url) +
                          ")",
                      }}
                    >
                      {this.state.foto3 && this.state.foto3.url.length > 1 && (
                        <span className="iconafree">
                          <this.BootstrapTooltip title={"Elimina"}>
                            <i
                              onClick={() => this.setState({ foto3: null })}
                              className="fa fa-trash"
                            ></i>
                          </this.BootstrapTooltip>
                        </span>
                      )}
                      <input
                        accept="image/*"
                        type="file"
                        id="myuniqueid3"
                        onChange={(e) => this.handePhoto(e, 4)}
                      />
                      <label htmlFor="myuniqueid3">
                        {" "}
                        <i
                          style={{
                            color:
                              this.state.foto3 &&
                              this.state.foto3.url.length > 1
                                ? "transparent"
                                : "blue",
                          }}
                          className="fa fa-plus"
                          aria-hidden="true"
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="prev_card">
              <div className="table_div">
                <div className="row_div">
                  <div className="cell_div ">
                    <p className="subtitle">Seleziona Piano</p>
                    <Creatable
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                      menuPortalTarget={document.body}
                      isLoading={false}
                      className="selecter"
                      placeholder={
                        this.state.temp_floor.nome
                          ? this.state.temp_floor.nome
                          : "Seleziona/crea piano.."
                      }
                      isClearable={false}
                      defaultValue={this.state.temp_floor.nome}
                      isSearchable={true}
                      onChange={(e, newValue) => {
                        this.setState({
                          temp_section: {
                            id: -1,
                            nome: "Seleziona una Sezione",
                          },
                        });
                        if (e.__isNew__) {
                          var newfloor = this.state.temp_floor;
                          this.setState({ new_floor_name: e.label }, () => {
                            newfloor.id = this.createFloor(false);
                            this.setState({ temp_floor: newfloor });
                          });
                        } else this.setState({ temp_floor: e.value });
                      }}
                      options={this.props.floors.map((x) => {
                        return { value: x, label: x.nome };
                      })}
                    />
                  </div>
                  <div className="cell_div ">
                    <p className="subtitle">Seleziona Sezione</p>

                    <Creatable
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                      menuPortalTarget={document.body}
                      isLoading={false}
                      className="selecter"
                      isDisabled={this.state.temp_floor.id < 0}
                      isClearable={false}
                      placeholder={
                        this.state.temp_section.nome
                          ? this.state.temp_section.nome
                          : "Seleziona/Crea Sezione"
                      }
                      isSearchable={true}
                      onChange={(e, newValue) => {
                        if (e.__isNew__) {
                          var newsec = this.state.temp_section;
                          this.setState({ new_section_name: e.label }, () => {
                            newsec.id = this.createSection(
                              this.state.temp_floor.id,
                              false
                            );
                            this.setState({ temp_section: newsec });
                          });
                        } else this.setState({ temp_section: e.value });
                      }}
                      value={this.state.temp_section.nome}
                      options={this.getFloorSections(
                        this.state.temp_floor.id
                      ).map((x) => {
                        return { value: x, label: x.nome };
                      })}
                    />
                  </div>
                </div>
                <div style={{ height: "50px" }} className="row_div">
                  <div className="cell_div"></div>
                  <div className="cell_div"></div>
                </div>
                <div className="row_div">
                  <div className="cell_div">
                    <button
                      className="btn_annulla"
                      onClick={() => this.reset()}
                    >
                      Indietro
                    </button>
                  </div>
                  <div className="cell_div">
                    {this.state.modify === true ? (
                      <button
                        isdisabled={this.state.loadingUpload}
                        className="btn_crea"
                        onClick={() => {
                          if (this.validateForm())
                            this.wrapitemcreator(
                              {
                                nome: this.state._nome,
                                sku: this.state._sku,
                                sku_fornitore: this.state._sku_fornitore,
                                discount: this.state._discount,

                                taxes_ids: this.state._taxes_ids,
                                brand: this.state._brand,
                                foto0: this.state.foto0,
                                foto1: this.state.foto1,
                                foto2: this.state.foto2,
                                foto3: this.state.foto3,
                                product_id: this.state.product_id,
                                order_line_id: this.state.order_line_id,
                                desc:
                                  this.state._desc === ""
                                    ? $("#_desc").html()
                                    : this.state._desc,

                                inner_brand: "1",
                                costo: this.state._costo,
                                categoria: this.state._categoria,
                                vendita: this.state._vendita,
                                qta: this.state._qta,
                                id: this.state._id,
                                sequence: this.state._sequence,
                              },
                              this.state.temp_floor.id,
                              this.state.temp_section.id
                            );
                        }}
                      >
                        Modifica
                      </button>
                    ) : (
                      <button
                        isdisabled={this.state.loadingUpload}
                        className="btn_crea"
                        onClick={() => {
                          if (this.validateForm())
                            this.wrapitemcreator(
                              {
                                nome: this.state._nome,
                                sku: this.state._sku,
                                sku_fornitore: this.state._sku_fornitore,
                                product_id: this.state._product_id,
                                brand: this.state._brand,
                                order_line_id: this.state.order_line_id,
                                discount: this.state._discount,
                                taxes_ids: this.state._taxes_ids,

                                foto0: this.state.foto0
                                  ? this.state.foto0
                                  : { id: 0, url: "" },
                                foto1: this.state.foto1
                                  ? this.state.foto1
                                  : { id: 0, url: "" },
                                foto2: this.state.foto2
                                  ? this.state.foto2
                                  : { id: 0, url: "" },
                                foto3: this.state.foto3
                                  ? this.state.foto3
                                  : { id: 0, url: "" },
                                inner_brand: "1",
                                desc:
                                  this.state._desc === ""
                                    ? $("#_desc").html()
                                    : this.state._desc,
                                costo: this.state._costo,
                                categoria: this.state._categoria,
                                vendita: this.state._vendita,
                                qta: this.state._qta,

                                id: -1,
                              },
                              this.state.temp_floor.id,
                              this.state.temp_section.id
                            );
                        }}
                      >
                        Aggiungi
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
        break;
      case "2":
        res.push(
          <div className="adder" key="2">
            <div
              style={{ padding: "12px", marginBottom: "55px" }}
              className="prev_card"
            >
              <div className="fullframe">
                {this.state.brand === "2" && <Iframe />}
              </div>
            </div>

            <div className="prev_card">
              <div className="table_div">
                <div className="row_div">
                  <div className="cell_div">
                    <p className="subtitle">Brand*</p>
                    <input
                      style={{ display: "none" }}
                      type="text"
                      value={this.state.brand_text}
                      id="_brand"
                      onChange={(e) => {
                        var soluz = { label: "Seleziona brand", value: -11 };
                        this.state.brands.forEach((element) => {
                          if (
                            element.label
                              .toUpperCase()
                              .indexOf(e.target.value.toUpperCase()) > -1
                          ) {
                            soluz = element;
                          }
                        });
                        this.setState({ _brand: soluz });
                      }}
                    />
                    <Select
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                      menuPortalTarget={document.body}
                      placeholder="Seleziona Brand..."
                      onChange={(e) => {
                        this.setState({ _brand: e });
                      }}
                      value={this.state._brand}
                      options={this.state.brands}
                    />
                  </div>
                  <div className="cell_div">
                    <p className="subtitle">Prodotto*</p>
                    <input
                      type="text"
                      id="_nome"
                      value={this.state._nome}
                      onChange={(e) => this.setState({ _nome: e.target.value })}
                    />
                  </div>
                </div>
                <div className="row_div">
                  <div className="cell_div">
                    <p className="subtitle">Categoria*</p>
                    <Select
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                      menuPortalTarget={document.body}
                      placeholder="Seleziona Categoria..."
                      onChange={(e) => {
                        this.setState({ _categoria: e });
                      }}
                      value={this.state._categoria}
                      options={this.state.categories}
                    />
                  </div>
                  <div className="cell_div">
                    <p className="subtitle">Sku</p>
                    <input type="text" disabled value={this.state._sku} />
                  </div>
                </div>
              </div>
              <div className="table_div">
                <div className="row_div">
                  <div className="cell_div">
                    <p className="subtitle">Sku Fornitore*</p>
                    <input
                      type="text"
                      onChange={(e) =>
                        this.setState({ _sku_fornitore: e.target.value })
                      }
                      value={this.state._sku_fornitore}
                    />
                  </div>
                </div>
                <div className="row_div">
                  <div className="cell_div">
                    <p className="subtitle">Descrizione</p>
                    <textarea
                      type="text"
                      id="_desc"
                      onChange={(e) => this.setState({ _desc: e.target.value })}
                      value={this.state._desc}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>

            <div className="prev_card  searchable">
              <div className="table_div">
                <div className="row_div">
                  <div className="cell_div">
                    <p className="subtitle">Vendita (Iva excl.)* &euro;</p>
                    <input
                      type="number"
                      min={0}
                      onChange={(e) => {
                        let base_imponibile = Number(e.target.value);
                        var tax = 0;
                        this.state._taxes_ids.forEach((element) => {
                          tax += base_imponibile * (element.amount / 100);
                        });

                        this.setState({
                          _vendita: e.target.value,
                          _vendita_iva: Number(
                            (base_imponibile + tax).toFixed(2)
                          ),
                        });
                      }}
                      value={this.state._vendita}
                    />
                  </div>
                  <div className="cell_div">
                    <p className="subtitle">Vendita (Iva incl.)</p>
                    <input
                      type="number"
                      min={0}
                      onChange={(e) => {
                        var prezzo_lordo = Number(e.target.value);
                        var tax = 0;
                        var imponibile = 0;
                        this.state._taxes_ids.forEach((element) => {
                          imponibile =
                            (prezzo_lordo * 100) / (element.amount + 100);
                          tax += prezzo_lordo - imponibile;
                        });
                        this.setState({
                          _vendita: Number((prezzo_lordo - tax).toFixed(2)),
                          _vendita_iva: e.target.value,
                        });
                      }}
                      value={this.state._vendita_iva}
                    />
                  </div>
                </div>

                <div className="row_div">
                  <div className="cell_div">
                    <p className="subtitle">Sconto %</p>
                    <input
                      type="number"
                      onChange={(e) =>
                        this.setState({
                          _discount: Number(e.target.value),
                        })
                      }
                      value={this.state._discount}
                    />
                  </div>

                  <div className="cell_div">
                    <p className="subtitle">Quantità*</p>
                    <input
                      type="number"
                      onChange={(e) => {
                        if (Number(e.target.value) > -1)
                          this.setState({ _qta: e.target.value });
                      }}
                      value={this.state._qta}
                    />
                  </div>
                </div>
                {this.props.permission.opt_product_view_cost_price && (
                  <div className="row_div">
                    <div className="cell_div">
                      <p className="subtitle">Costo &euro;</p>
                      <input
                        type="number"
                        disabled
                        onChange={(e) =>
                          this.setState({ _costo: e.target.value })
                        }
                        value={this.state._costo}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="table_div">
                <div className="row_div">
                  <div className="cell_div">
                    <p className="subtitle">IVA </p>
                    <Select
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                      menuPortalTarget={document.body}
                      isMulti
                      classNamePrefix={"multi-select"}
                      placeholder="Seleziona Iva..."
                      onChange={(e, actiontype) => {
                        if (actiontype.action === "remove-value") {
                          this.setState({
                            _taxes_ids: e ? e : [],
                            _vendita_iva: e
                              ? this.state._vendita_iva +
                                (e[e.length - 1].amount / 100) *
                                  this.state._vendita
                              : this.state._vendita,
                          });
                        } else {
                          this.setState({
                            _taxes_ids: e === 0 ? [] : e,
                            _vendita_iva:
                              this.state._vendita_iva +
                              (e[e.length - 1].amount / 100) *
                                this.state._vendita,
                          });
                        }
                      }}
                      value={this.state._taxes_ids}
                      options={this.state.taxes}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="prev_card  ">
              <div className="table_div">
                <div className="row_div">
                  <div className="cell_div">
                    <div
                      className="img_uploader"
                      style={{
                        backgroundImage:
                          "url(" +
                          api.auth_api +
                          (this.state.foto0 && this.state.foto0.url) +
                          ")",
                      }}
                    >
                      {this.state.foto0 && this.state.foto0.url.length > 1 && (
                        <span className="iconafree">
                          <this.BootstrapTooltip title={"Elimina"}>
                            <i
                              onClick={() => this.setState({ foto0: null })}
                              className="fa fa-trash"
                            ></i>
                          </this.BootstrapTooltip>
                        </span>
                      )}
                      <input
                        accept="image/*"
                        type="file"
                        id="myuniqueid0"
                        onChange={(e) => this.handePhoto(e, 1)}
                      />
                      <label htmlFor="myuniqueid0">
                        <i
                          style={{
                            color:
                              this.state.foto0 &&
                              this.state.foto0.url.length > 1
                                ? "transparent"
                                : "blue",
                          }}
                          className="fa fa-plus"
                          aria-hidden="true"
                        />
                      </label>
                    </div>
                  </div>
                  <div className="cell_div">
                    <div
                      className="img_uploader"
                      style={{
                        backgroundImage:
                          "url(" +
                          api.auth_api +
                          (this.state.foto1 && this.state.foto1.url) +
                          ")",
                      }}
                    >
                      {this.state.foto1 && this.state.foto1.url.length > 1 && (
                        <span className="iconafree">
                          <this.BootstrapTooltip title={"Elimina"}>
                            <i
                              onClick={() => this.setState({ foto1: null })}
                              className="fa fa-trash"
                            ></i>
                          </this.BootstrapTooltip>
                        </span>
                      )}
                      <input
                        accept="image/*"
                        type="file"
                        id="myuniqueid1"
                        onChange={(e) => this.handePhoto(e, 2)}
                      />
                      <label htmlFor="myuniqueid1">
                        {" "}
                        <i
                          style={{
                            color:
                              this.state.foto1 &&
                              this.state.foto1.url.length > 1
                                ? "transparent"
                                : "blue",
                          }}
                          className="fa fa-plus"
                          aria-hidden="true"
                        />
                      </label>
                    </div>
                  </div>
                  <div className="cell_div">
                    <div
                      className="img_uploader"
                      style={{
                        backgroundImage:
                          "url(" +
                          api.auth_api +
                          (this.state.foto2 && this.state.foto2.url) +
                          ")",
                      }}
                    >
                      {this.state.foto2 && this.state.foto2.url.length > 1 && (
                        <span className="iconafree">
                          <this.BootstrapTooltip title={"Elimina"}>
                            <i
                              onClick={() => this.setState({ foto2: null })}
                              className="fa fa-trash"
                            ></i>
                          </this.BootstrapTooltip>
                        </span>
                      )}
                      <input
                        accept="image/*"
                        type="file"
                        id="myuniqueid2"
                        onChange={(e) => this.handePhoto(e, 3)}
                      />
                      <label htmlFor="myuniqueid2">
                        {" "}
                        <i
                          style={{
                            color:
                              this.state.foto2 &&
                              this.state.foto2.url.length > 1
                                ? "transparent"
                                : "blue",
                          }}
                          className="fa fa-plus"
                          aria-hidden="true"
                        />
                      </label>
                    </div>
                  </div>

                  <div className="cell_div">
                    <div
                      className="img_uploader"
                      style={{
                        backgroundImage:
                          "url(" +
                          api.auth_api +
                          (this.state.foto3 && this.state.foto3.url) +
                          ")",
                      }}
                    >
                      {this.state.foto3 && this.state.foto3.url.length > 1 && (
                        <span className="iconafree">
                          <this.BootstrapTooltip title={"Elimina"}>
                            <i
                              onClick={() => this.setState({ foto3: null })}
                              className="fa fa-trash"
                            ></i>
                          </this.BootstrapTooltip>
                        </span>
                      )}
                      <input
                        accept="image/*"
                        type="file"
                        id="myuniqueid3"
                        onChange={(e) => this.handePhoto(e, 4)}
                      />
                      <label htmlFor="myuniqueid3">
                        {" "}
                        <i
                          style={{
                            color:
                              this.state.foto3 &&
                              this.state.foto3.url.length > 1
                                ? "transparent"
                                : "blue",
                          }}
                          className="fa fa-plus"
                          aria-hidden="true"
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="prev_card">
              <div className="table_div">
                <div className="row_div">
                  <div className="cell_div ">
                    <p className="subtitle">Seleziona Piano</p>

                    <Creatable
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                      menuPortalTarget={document.body}
                      isLoading={false}
                      className="selecter"
                      placeholder={
                        this.state.temp_floor.nome
                          ? this.state.temp_floor.nome
                          : "Seleziona/crea piano.."
                      }
                      isClearable={false}
                      defaultValue={this.state.temp_floor.nome}
                      isSearchable={true}
                      onChange={(e, newValue) => {
                        this.setState({
                          temp_section: {
                            id: -1,
                            nome: "Seleziona una Sezione",
                          },
                        });
                        if (e.__isNew__) {
                          var newfloor = this.state.temp_floor;
                          this.setState({ new_floor_name: e.label }, () => {
                            newfloor.id = this.createFloor(false);
                            this.setState({ temp_floor: newfloor });
                          });
                        } else this.setState({ temp_floor: e.value });
                      }}
                      options={this.props.floors.map((x) => {
                        return { value: x, label: x.nome };
                      })}
                    />
                  </div>
                  <div className="cell_div ">
                    <p className="subtitle">Seleziona Sezione</p>
                    <Creatable
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                      menuPortalTarget={document.body}
                      isLoading={false}
                      className="selecter"
                      isDisabled={this.state.temp_floor.id < 0}
                      isClearable={false}
                      placeholder={
                        this.state.temp_floor.nome
                          ? this.state.temp_floor.nome
                          : "Seleziona/crea piano.."
                      }
                      isSearchable={true}
                      onChange={(e, newValue) => {
                        console.log("event", e);
                        if (e.__isNew__) {
                          var newsec = this.state.temp_section;
                          this.setState({ new_section_name: e.label }, () => {
                            newsec.id = this.createSection(
                              this.state.temp_floor.id,
                              false
                            );
                            this.setState({ temp_section: newsec });
                          });
                        } else this.setState({ temp_section: e.value });
                      }}
                      defaultValue={this.state.temp_section.nome}
                      options={this.getFloorSections(
                        this.state.temp_floor.id
                      ).map((x) => {
                        return { value: x, label: x.nome };
                      })}
                    />
                  </div>
                </div>
                <div style={{ height: "50px" }} className="row_div">
                  <div className="cell_div"></div>
                  <div className="cell_div"></div>
                </div>
                <div className="row_div">
                  <div className="cell_div">
                    <button
                      className="btn_annulla"
                      onClick={() => this.reset()}
                    >
                      Indietro
                    </button>
                  </div>
                  <div className="cell_div">
                    {this.state.modify === true ? (
                      <button
                        isdisabled={this.state.loadingUpload}
                        className="btn_crea"
                        onClick={() => {
                          if (this.validateForm())
                            this.wrapitemcreator(
                              {
                                nome: this.state._nome,
                                sku: this.state._sku,
                                sku_fornitore: this.state._sku_fornitore,
                                brand: this.state._brand,
                                order_line_id: this.state.order_line_id,
                                discount: this.state._discount,
                                taxes_ids: this.state._taxes_ids,

                                product_id: this.state.product_id,
                                foto0: this.state.foto0,
                                foto1: this.state.foto1,
                                foto2: this.state.foto2,
                                foto3: this.state.foto3,
                                desc:
                                  this.state._desc === ""
                                    ? $("#_desc").html()
                                    : this.state._desc,

                                inner_brand: "2",
                                costo: this.state._costo,
                                categoria: this.state._categoria,
                                vendita: this.state._vendita,
                                qta: this.state._qta,
                                id: this.state._id,
                                sequence: this.state._sequence,
                              },
                              this.state.temp_floor.id,
                              this.state.temp_section.id
                            );
                        }}
                      >
                        Modifica
                      </button>
                    ) : (
                      <button
                        className="btn_crea"
                        isdisabled={this.state.loadingUpload}
                        onClick={() => {
                          if (this.validateForm())
                            this.wrapitemcreator(
                              {
                                nome:
                                  this.state._nome === ""
                                    ? $("#_nome").attr("value")
                                    : this.state._nome,
                                discount: this.state._discount,

                                taxes_ids: this.state._taxes_ids,
                                sku: this.state._sku,
                                sku_fornitore: this.state._sku_fornitore,

                                brand: this.state._brand,
                                foto0: this.state.foto0,
                                foto1: this.state.foto1,
                                foto2: this.state.foto2,
                                foto3: this.state.foto3,
                                inner_brand: "2",
                                costo: this.state._costo,
                                order_line_id: this.state.order_line_id,
                                product_id: this.state.product_id,
                                categoria: this.state._categoria,
                                vendita: this.state._vendita,
                                desc:
                                  this.state._desc === ""
                                    ? $("#_desc").attr("value")
                                    : this.state._desc,
                                qta: this.state._qta,
                                id: -1,
                              },
                              this.state.temp_floor.id,
                              this.state.temp_section.id
                            );
                        }}
                      >
                        Aggiungi
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
        break;
      case "3":
        res.push(
          <div className="adder" key="3">
            <div className="prev_card">
              <div className="table_div">
                <div className="row_div">
                  <div className="cell_div">
                    <p className="subtitle">Brand*</p>

                    <Select
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                      menuPortalTarget={document.body}
                      placeholder="Seleziona Brand..."
                      onChange={(e) => {
                        this.setState({ _brand: e });
                      }}
                      value={this.state._brand}
                      options={this.state.brands}
                    />
                  </div>
                  <div className="cell_div">
                    <p className="subtitle">Prodotto*</p>
                    <input
                      type="text"
                      value={this.state._nome}
                      onChange={(e) => {
                        this.setState({ _nome: e.target.value });
                      }}
                    />
                  </div>
                </div>
                <div className="row_div">
                  <div className="cell_div">
                    <p className="subtitle">Categoria*</p>
                    <Select
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                      menuPortalTarget={document.body}
                      placeholder="Seleziona la categoria..."
                      onChange={(e) => {
                        this.setState({ _categoria: e });
                      }}
                      value={this.state._categoria}
                      options={this.state.categories}
                    />
                  </div>
                  <div className="cell_div">
                    <p className="subtitle">Sku</p>
                    <input type="text" disabled value={this.state._sku} />
                  </div>
                </div>
              </div>
              <div className="table_div">
                <div className="row_div">
                  <div className="cell_div">
                    <p className="subtitle">Sku Fornitore*</p>
                    <input
                      type="text"
                      onChange={(e) =>
                        this.setState({ _sku_fornitore: e.target.value })
                      }
                      value={this.state._sku_fornitore}
                    />
                  </div>
                </div>
                <div className="row_div">
                  <div className="cell_div">
                    <p className="subtitle">Descrizione</p>
                    <textarea
                      type="text"
                      id="_desc"
                      onChange={(e) => this.setState({ _desc: e.target.value })}
                      value={this.state._desc}
                    >
                      {this.state._desc}
                    </textarea>
                  </div>
                </div>
              </div>
            </div>

            <div className="prev_card  searchable">
              <div className="table_div">
                <div className="row_div">
                  <div className="cell_div">
                    <p className="subtitle">Vendita (Iva excl.)* &euro;</p>
                    <input
                      type="number"
                      min={0}
                      onChange={(e) => {
                        let base_imponibile = Number(e.target.value);
                        var tax = 0;
                        this.state._taxes_ids.forEach((element) => {
                          tax += base_imponibile * (element.amount / 100);
                        });

                        this.setState({
                          _vendita: e.target.value,
                          _vendita_iva: Number(
                            (base_imponibile + tax).toFixed(2)
                          ),
                        });
                      }}
                      value={this.state._vendita}
                    />
                  </div>
                  <div className="cell_div">
                    <p className="subtitle">Vendita (Iva incl.)</p>
                    <input
                      type="number"
                      min={0}
                      onChange={(e) => {
                        var prezzo_lordo = Number(e.target.value);
                        var tax = 0;
                        var imponibile = 0;
                        this.state._taxes_ids.forEach((element) => {
                          imponibile =
                            (prezzo_lordo * 100) / (element.amount + 100);
                          tax += prezzo_lordo - imponibile;
                        });
                        this.setState({
                          _vendita: Number((prezzo_lordo - tax).toFixed(2)),
                          _vendita_iva: e.target.value,
                        });
                      }}
                      value={this.state._vendita_iva}
                    />
                  </div>
                </div>

                <div className="row_div">
                  <div className="cell_div">
                    <p className="subtitle">Sconto %</p>
                    <input
                      type="number"
                      onChange={(e) =>
                        this.setState({
                          _discount: Number(e.target.value),
                        })
                      }
                      value={this.state._discount}
                    />
                  </div>

                  <div className="cell_div">
                    <p className="subtitle">Quantità*</p>
                    <input
                      type="number"
                      onChange={(e) => {
                        if (Number(e.target.value) > -1)
                          this.setState({ _qta: e.target.value });
                      }}
                      value={this.state._qta}
                    />
                  </div>
                </div>
                {this.props.permission.opt_product_view_cost_price && (
                  <div className="row_div">
                    <div className="cell_div">
                      <p className="subtitle">Costo &euro;</p>
                      <input
                        type="number"
                        disabled
                        onChange={(e) =>
                          this.setState({ _costo: e.target.value })
                        }
                        value={this.state._costo}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="table_div">
                <div className="row_div">
                  <div className="cell_div">
                    <p className="subtitle">IVA </p>
                    <Select
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                      menuPortalTarget={document.body}
                      isMulti
                      classNamePrefix={"multi-select"}
                      placeholder="Seleziona Iva..."
                      onChange={(e, actiontype) => {
                        if (actiontype.action === "remove-value") {
                          this.setState({
                            _taxes_ids: e ? e : [],
                            _vendita_iva: e
                              ? this.state._vendita_iva +
                                (e[e.length - 1].amount / 100) *
                                  this.state._vendita
                              : this.state._vendita,
                          });
                        } else {
                          this.setState({
                            _taxes_ids: e === 0 ? [] : e,
                            _vendita_iva:
                              this.state._vendita_iva +
                              (e[e.length - 1].amount / 100) *
                                this.state._vendita,
                          });
                        }
                      }}
                      value={this.state._taxes_ids}
                      options={this.state.taxes}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="prev_card  ">
              <div className="table_div">
                <div className="row_div">
                  <div className="cell_div">
                    <div
                      className="img_uploader"
                      style={{
                        backgroundImage:
                          "url(" +
                          api.auth_api +
                          (this.state.foto0 && this.state.foto0.url) +
                          ")",
                      }}
                    >
                      {this.state.foto0 && this.state.foto0.url.length > 1 && (
                        <span className="iconafree">
                          <this.BootstrapTooltip title={"Elimina"}>
                            <i
                              onClick={() => this.setState({ foto0: null })}
                              className="fa fa-trash"
                            ></i>
                          </this.BootstrapTooltip>
                        </span>
                      )}
                      <input
                        accept="image/*"
                        type="file"
                        id="myuniqueid0"
                        onChange={(e) => this.handePhoto(e, 1)}
                      />
                      <label htmlFor="myuniqueid0">
                        <i
                          style={{
                            color:
                              this.state.foto0 &&
                              this.state.foto0.url.length > 1
                                ? "transparent"
                                : "blue",
                          }}
                          className="fa fa-plus"
                          aria-hidden="true"
                        />
                      </label>
                    </div>
                  </div>
                  <div className="cell_div">
                    <div
                      className="img_uploader"
                      style={{
                        backgroundImage:
                          "url(" +
                          api.auth_api +
                          (this.state.foto1 && this.state.foto1.url) +
                          ")",
                      }}
                    >
                      {this.state.foto1 && this.state.foto1.url.length > 1 && (
                        <span className="iconafree">
                          <this.BootstrapTooltip title={"Elimina"}>
                            <i
                              onClick={() => this.setState({ foto1: null })}
                              className="fa fa-trash"
                            ></i>
                          </this.BootstrapTooltip>
                        </span>
                      )}
                      <input
                        accept="image/*"
                        type="file"
                        id="myuniqueid1"
                        onChange={(e) => this.handePhoto(e, 2)}
                      />
                      <label htmlFor="myuniqueid1">
                        {" "}
                        <i
                          style={{
                            color:
                              this.state.foto1 &&
                              this.state.foto1.url.length > 1
                                ? "transparent"
                                : "blue",
                          }}
                          className="fa fa-plus"
                          aria-hidden="true"
                        />
                      </label>
                    </div>
                  </div>
                  <div className="cell_div">
                    <div
                      className="img_uploader"
                      style={{
                        backgroundImage:
                          "url(" +
                          api.auth_api +
                          (this.state.foto2 && this.state.foto2.url) +
                          ")",
                      }}
                    >
                      {this.state.foto2 && this.state.foto2.url.length > 1 && (
                        <span className="iconafree">
                          <this.BootstrapTooltip title={"Elimina"}>
                            <i
                              onClick={() => this.setState({ foto2: null })}
                              className="fa fa-trash"
                            ></i>
                          </this.BootstrapTooltip>
                        </span>
                      )}
                      <input
                        accept="image/*"
                        type="file"
                        id="myuniqueid2"
                        onChange={(e) => this.handePhoto(e, 3)}
                      />
                      <label htmlFor="myuniqueid2">
                        {" "}
                        <i
                          style={{
                            color:
                              this.state.foto2 &&
                              this.state.foto2.url.length > 1
                                ? "transparent"
                                : "blue",
                          }}
                          className="fa fa-plus"
                          aria-hidden="true"
                        />
                      </label>
                    </div>
                  </div>

                  <div className="cell_div">
                    <div
                      className="img_uploader"
                      style={{
                        backgroundImage:
                          "url(" +
                          api.auth_api +
                          (this.state.foto3 && this.state.foto3.url) +
                          ")",
                      }}
                    >
                      {this.state.foto3 && this.state.foto3.url.length > 1 && (
                        <span className="iconafree">
                          <this.BootstrapTooltip title={"Elimina"}>
                            <i
                              onClick={() => this.setState({ foto3: null })}
                              className="fa fa-trash"
                            ></i>
                          </this.BootstrapTooltip>
                        </span>
                      )}
                      <input
                        accept="image/*"
                        type="file"
                        id="myuniqueid3"
                        onChange={(e) => this.handePhoto(e, 4)}
                      />
                      <label htmlFor="myuniqueid3">
                        {" "}
                        <i
                          style={{
                            color:
                              this.state.foto3 &&
                              this.state.foto3.url.length > 1
                                ? "transparent"
                                : "blue",
                          }}
                          className="fa fa-plus"
                          aria-hidden="true"
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="prev_card">
              <div className="table_div">
                <div className="row_div">
                  <div className="cell_div ">
                    <p className="subtitle">Seleziona Piano</p>
                    <Creatable
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                      menuPortalTarget={document.body}
                      isLoading={false}
                      className="selecter"
                      placeholder={
                        this.state.temp_floor.nome
                          ? this.state.temp_floor.nome
                          : "Seleziona/crea piano.."
                      }
                      isClearable={false}
                      defaultValue={this.state.temp_floor.nome}
                      isSearchable={true}
                      onChange={(e, newValue) => {
                        this.setState({
                          temp_section: {
                            id: -1,
                            nome: "Seleziona una Sezione",
                          },
                        });
                        if (e.__isNew__) {
                          var newfloor = this.state.temp_floor;
                          this.setState({ new_floor_name: e.label }, () => {
                            newfloor.id = this.createFloor(false);
                            this.setState({ temp_floor: newfloor });
                          });
                        } else this.setState({ temp_floor: e.value });
                      }}
                      options={this.props.floors.map((x) => {
                        return { value: x, label: x.nome };
                      })}
                    />
                  </div>
                  <div className="cell_div ">
                    <p className="subtitle">Seleziona Sezione</p>
                    <Creatable
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                      menuPortalTarget={document.body}
                      isLoading={false}
                      className="selecter"
                      isDisabled={this.state.temp_floor.id < 0}
                      isClearable={false}
                      placeholder={
                        this.state.temp_floor.nome
                          ? this.state.temp_floor.nome
                          : "Seleziona/crea piano.."
                      }
                      isSearchable={true}
                      onChange={(e, newValue) => {
                        console.log("event", e);
                        if (e.__isNew__) {
                          var newsec = this.state.temp_section;
                          this.setState({ new_section_name: e.label }, () => {
                            newsec.id = this.createSection(
                              this.state.temp_floor.id,
                              false
                            );
                            this.setState({ temp_section: newsec });
                          });
                        } else this.setState({ temp_section: e.value });
                      }}
                      defaultValue={this.state.temp_section.nome}
                      options={this.getFloorSections(
                        this.state.temp_floor.id
                      ).map((x) => {
                        return { value: x, label: x.nome };
                      })}
                    />
                  </div>
                </div>
                <div style={{ height: "50px" }} className="row_div">
                  <div className="cell_div"></div>
                  <div className="cell_div"></div>
                </div>
                <div className="row_div">
                  <div className="cell_div">
                    <button
                      className="btn_annulla"
                      onClick={() => this.reset()}
                    >
                      Indietro
                    </button>
                  </div>
                  <div className="cell_div">
                    {this.state.modify === true ? (
                      <button
                        className="btn_crea"
                        onClick={() => {
                          if (this.validateForm())
                            this.wrapitemcreator(
                              {
                                nome: this.state._nome,
                                sku: this.state._sku,
                                sku_fornitore: this.state._sku_fornitore,
                                discount: this.state._discount,
                                taxes_ids: this.state._taxes_ids,
                                brand: this.state._brand,
                                foto0: this.state.foto0,
                                foto1: this.state.foto1,
                                foto2: this.state.foto2,
                                foto3: this.state.foto3,
                                product_id: this.state.product_id,
                                order_line_id: this.state.order_line_id,
                                desc:
                                  this.state._desc === ""
                                    ? $("#_desc").html()
                                    : this.state._desc,

                                inner_brand: "3",
                                costo: this.state._costo,
                                categoria: this.state._categoria,
                                vendita: this.state._vendita,
                                qta: this.state._qta,
                                id: this.state._id,
                                sequence: this.state._sequence,
                              },
                              this.state.temp_floor.id,
                              this.state.temp_section.id
                            );
                        }}
                      >
                        Modifica
                      </button>
                    ) : (
                      <button
                        isdisabled={this.state.loadingUpload}
                        className="btn_crea"
                        onClick={() => {
                          if (this.validateForm())
                            this.wrapitemcreator(
                              {
                                nome: this.state._nome,
                                sku: this.state._sku,
                                sku_fornitore: this.state._sku_fornitore,
                                product_id: this.state._product_id,
                                brand: this.state._brand,
                                order_line_id: this.state.order_line_id,
                                discount: this.state._discount,
                                taxes_ids: this.state._taxes_ids,
                                foto0: this.state.foto0
                                  ? this.state.foto0
                                  : { id: 0, url: "" },
                                foto1: this.state.foto1
                                  ? this.state.foto1
                                  : { id: 0, url: "" },
                                foto2: this.state.foto2
                                  ? this.state.foto2
                                  : { id: 0, url: "" },
                                foto3: this.state.foto3
                                  ? this.state.foto3
                                  : { id: 0, url: "" },
                                inner_brand: "3",
                                desc:
                                  this.state._desc === ""
                                    ? $("#_desc").html()
                                    : this.state._desc,
                                costo: this.state._costo,
                                categoria: this.state._categoria,
                                vendita: this.state._vendita,
                                qta: this.state._qta,

                                id: -1,
                              },
                              this.state.temp_floor.id,
                              this.state.temp_section.id
                            );
                        }}
                      >
                        Aggiungi
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
        break;
    }
    return res;
  }

  createSection(save) {
    var sections = this.props.sections;
    var sec_name;
    var new_section;

    var id_floor =
      this.state.new_floor_section_link === -1
        ? this.state.temp_floor.id
          ? this.state.temp_floor.id
          : -1
        : this.state.new_floor_section_link;

    if (id_floor < 0) {
      var floor = this.getFloorbyName("DEFAULT");
      if (floor === undefined) {
        id_floor = this.createFloor(false);
      } else id_floor = floor.id;
    }
    if (this.state.new_section_name === "") {
      sec_name = "DEFAULT";
    } else sec_name = this.state.new_section_name.toUpperCase();
    if (
      this.getSectionbyName(sec_name.toUpperCase(), id_floor) &&
      sec_name !== "DEFAULT"
    ) {
      Swal.fire({
        position: "center",
        icon: "error",
        text: 'La sezione "' + sec_name + '" esiste già nel piano selezionato',

        timerProgressBar: true,
        timer: 2000,
        showConfirmButton: false,
        customClass: { container: "swal_container" },
      });

      this.reset();
      return;
    }
    const id_section = sections === [] ? 0 : sections.length;

    new_section = {
      nome: sec_name,
      id: id_section,
      id_floor: floor ? floor.id : id_floor,
    };
    sections.push(new_section);
    this.setState({ sections: sections, addfastSection: -1 }, () => {
      save &&
        this.props.saveFloor(this.props.floors, sections, this.props.items);
    });
    this.props.setSync(false);

    /*  this.reset(); */
    return id_section;
  }
  createSection(idfloor, save) {
    var sections = this.props.sections;
    var sec_name;
    var new_section;

    var id_floor = idfloor;

    if (id_floor < 0) {
      var floor = this.getFloorbyName("DEFAULT");
      if (floor === undefined) {
        id_floor = this.createFloor(false);
      } else id_floor = floor.id;
    }
    if (this.state.new_section_name === "") {
      sec_name = "DEFAULT";
    } else sec_name = this.state.new_section_name.toUpperCase();
    if (this.getSectionbyName(sec_name, id_floor)) {
      Swal.fire({
        position: "center",
        icon: "error",
        text: 'La sezione "' + sec_name + '" esiste già nel piano selezionato',
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 2000,
        customClass: { container: "swal_container" },
      });
      this.reset();
      return;
    }
    const id_section = sections === [] ? 0 : sections.length;

    new_section = {
      nome: sec_name,
      id: id_section,
      id_floor: floor ? floor.id : id_floor,
    };
    sections.push(new_section);
    this.setState({ sections: sections, addfastSection: -1 }, () => {
      save &&
        this.props.saveFloor(this.props.floors, sections, this.props.items);
    });
    this.props.setSync(false);
    /* this.reset(); */
    return id_section;
  }
  gotoCheckout() {
    this.props.saveFloor(
      this.props.floors,
      this.props.sections,
      this.props.items
    );
    if (this.props.sync) this.props.changePhase(2);

    Swal.fire({
      position: "center",
      title: "Preventivo Salvato",
      icon: "success",
      timer: 1500,
      timerProgressBar: true,
      showConfirmButton: false,
      customClass: { container: "swal_container" },
      onClose: () => {},
    });
  }

  render() {
    return (
      <div id="page" className="product_wrapper">
        <Animated
          isVisible={this.state.fase < 2}
          animationIn="fadeIn"
          animationOut="fadeOutLeft"
          className={this.state.fase < 2 ? "big_button" : "invisible"}
        >
          <div
            onClick={() =>
              this.setState({
                fase: this.state.fase === 0 ? 1 : 0,
                newproduct: true,
              })
            }
          >
            <i
              className={
                this.state.fase === 0 ? "fa fa-plus" : "fa fa-minus rotate"
              }
              aria-hidden="true"
            ></i>
          </div>
        </Animated>
        <Animated
          isVisible={this.state.fase > 0}
          animationIn="fadeIn"
          animationOut="fadeOut"
          animationInDuration={1000}
          className={
            this.state.fase <= 1 ? "inline_block" : "float " + this.state.brand
          }
        >
          {this.state.brand !== "" && (
            <div
              id="choser"
              className={"big_button"}
              onClick={() => this.reset()}
            >
              {this.selectImg()}
            </div>
          )}

          <div
            className={
              this.state.fase === 1 && this.state.newproduct === true
                ? "big_button  noborder"
                : "big_button invisible"
            }
            onClick={() => this.setState({ fase: 3, brand: "1" })}
          >
            <img className="fiximg odoo" src={brand1} />
          </div>

          <div
            className={
              this.state.fase === 1 && this.state.newproduct === true
                ? "big_button noborder"
                : "big_button invisible"
            }
            onClick={() => this.setState({ fase: 3, brand: "2" })}
          >
            <img className="fiximg" src={brand2} />
          </div>

          <div
            className={
              this.state.fase === 1 && this.state.newproduct === true
                ? "big_button noborder"
                : "big_button invisible"
            }
            onClick={() => this.setState({ fase: 3, brand: "3" })}
          >
            <img className="fiximg" src={brand3} />
          </div>
        </Animated>
        <Animated
          isVisible={this.state.fase > 2 && this.state.brand !== ""}
          animationIn="fadeIn"
          animationOut="fadeOut"
          animationInDuration={800}
          animationInDelay={750}
          className={
            this.state.fase > 2 && this.state.brand !== ""
              ? "inline_block fullwidth"
              : "invisible"
          }
        >
          {this.addProduct()}
        </Animated>

        <DragDropContext onDragEnd={this.onDragEnd}>
          <div
            style={{
              border: this.props.sync
                ? "1px solid rgba(128, 128, 128, 0.39)"
                : "1.5px solid red",
            }}
            className="droppable_wrapper"
          >
            <div style={{ height: 30, marginBottom: 15 }}>
              <span
                style={{ float: "left", marginTop: 0 }}
                className="field choice newsletter"
              >
                <span
                  className={
                    this.props.show_iva
                      ? "jcf-checkbox jcf-checked jcf-focus"
                      : "jcf-checkbox jcf-unchecked"
                  }
                  id="check_stock"
                >
                  <span onClick={() => this.props.swap_show_iva()} />

                  <input
                    type="checkbox"
                    className="checkbox"
                    name="check_stock"
                    onChange={() => this.props.swap_show_iva()}
                    value={this.props.show_iva}
                    style={{
                      position: "absolute",
                      height: "100%",
                      width: "100%",
                      opacity: 0,
                      margin: "0px",
                    }}
                  />
                </span>
                <label htmlFor="check_stock" className="label">
                  <span>Mostra prezzi ivati</span>
                </label>
              </span>
            </div>
            {this.renderFloors()}
            <div className="cont">
              <button
                onClick={() =>
                  !this.state.addfastFloor
                    ? this.setState({
                        addfastFloor: true,
                      })
                    : this.createFloor(true)
                }
                className="btn_crea"
              >
                {!this.state.addfastFloor ? "Aggiungi Piano" : "Conferma"}
              </button>
              <input
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    !this.state.addfastFloor
                      ? this.setState({
                          addfastFloor: true,
                        })
                      : this.createFloor(true);
                  }
                }}
                className={!this.state.addfastFloor ? "height0" : "height45"}
                type="text"
                value={this.state.new_floor_name}
                onChange={(e) =>
                  this.setState({
                    new_floor_name: e.target.value.toUpperCase(),
                  })
                }
              />
            </div>
          </div>
        </DragDropContext>
        {this.state.loadingSave && (
          <div className="obscure">
            <Load text="Salvo il preventivo" />
          </div>
        )}
        <div style={{ marginTop: "25px" }} className="prev_card noborder nobg">
          <div style={{ width: "100%" }} className="table_div">
            <div style={{ width: "100%" }} className="row_div">
              <div style={{ float: "right" }} className="cell_div">
                <button
                  onClick={() => this.gotoCheckout()}
                  className="btn_modifica"
                  id="vai_avanti"
                >
                  {this.props.sync ? (
                    " Avanti"
                  ) : (
                    <span>
                      {" "}
                      Sincronizza{" "}
                      <i className="fa fa-refresh fa-spin fa-3x fa-fw" />{" "}
                    </span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
