import React, { Component } from "react";
import { Formik } from "formik";
import "./Login.css";
import api from "../../config";
import Swal from "sweetalert2";

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = { done: false, customTxt: "", loading: false };
  }
  trylogin(user, pwd) {
    this.setState({ loading: true });
    api
      .authenticate(user, pwd)
      .then((data) => {
        if (data.error) {
          this.setState({ loading: false });

          switch (data.error.message) {
            case "Odoo Server Error": {
              Swal.fire({
                html:
                  "<h1>Errore</h1> <p>Non è stato possibile procedere all'autenticazione, controlla i tuoi dati</p>",
                icon: "error",
                showCancelButton: false,
                timer: 3000,
                timerProgressBar: true,
                customClass: {
                  container: "swal_container",
                },
                position: "center",
                showConfirmButton: false,
              });
              break;
            }
            default: {
              Swal.fire({
                html:
                  "<h1>Errore</h1> <p>Non è stato possibile procedere all'autenticazione, controlla i tuoi dati o prova ad aggiornare la pagina</p>",
                icon: "warning",
                showCancelButton: false,
                customClass: {
                  confirmButton: "btn_modifica",
                  container: "swal_container",
                },
                position: "center",
                showConfirmButton: true,
                confirmButtonText: "Aggiorna",
                buttonsStyling: false,
              }).then((result) => {
                if (result.isConfirmed) {
                  window.location.reload(false);
                }
              });
              break;
            }
          }
        } else {
          this.setState({ loading: false });
          this.props.manageuser();
        }
      })
      .catch((error) => {
        this.setState({ loading: false });

        switch (error.message) {
          case "Odoo Server Error": {
            Swal.fire({
              html:
                "<h1>Errore</h1> <p>Non è stato possibile procedere all'autenticazione, controlla i tuoi dati</p>",
              icon: "error",
              showCancelButton: false,
              timer: 3000,
              timerProgressBar: true,
              customClass: {
                confirmButton: "btn_modifica",
                container: "swal_container",
              },
              position: "center",
              showConfirmButton: false,
            });
            break;
          }
          default: {
            Swal.fire({
              html:
                "<h1>Errore</h1> <p>Non è stato possibile procedere all'autenticazione, controlla i tuoi dati o prova ad aggiornare la pagina</p>",
              icon: "warning",
              showCancelButton: false,
              customClass: {
                confirmButton: "btn_modifica",
                container: "swal_container",
              },
              position: "center",
              showConfirmButton: true,
              confirmButtonText: "Aggiorna",
              buttonsStyling: false,
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.reload(false);
              }
            });
            break;
          }
        }
      });
      
  }
  render() {
    return (
      <div style={{ background: "white" }} className="page_container">
        <div className="login_container">
          <h1 className="page_title">Entra in mohd</h1>
          <p className="uppercase subtitle">{this.state.customTxt}</p>
          <Formik
            initialValues={{
              user: "",
              pwd: "",
            }}
            validate={(values, actions) => {
              const errors = {};
              if (values.user.length < 3) {
                errors.nome = "*Username non valido";
              }
              if (values.pwd.length < 3) {
                errors.pwd = "*Password non valida";
              }

              if (errors.length > 0) {
                actions.setSubmitting(true);
              }
              return errors;
            }}
            onSubmit={(values, actions) => {
              actions.setSubmitting(false);
              this.trylogin(values.user, values.pwd);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form
                onSubmit={handleSubmit}
                className="form-login"
                id="social-form-create"
                noValidate="novalidate"
              >
                <fieldset className="fieldset create info">
                  <div className="field field-name-firstname required">
                    <label className="label" htmlFor="firstname">
                      <span>Username</span>
                    </label>
                    <div className="control">
                      {/*  <input
                                type="text"
                                id="firstname"
                                name="firstname"
                                defaultValue
                                title="Nome"
                                className="input-text required-entry"
                                data-validate="{required:true}"
                                aria-required="true"
                              /> */}
                      <input
                        type="text"
                        name="user"
                        autoComplete="username"
                        id="user"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.user}
                        className="input-text required-entry"
                      />
                      {errors.user && touched.user && (
                        <div className="invalid-feedback">{errors.user}</div>
                      )}
                    </div>
                  </div>
                  <div className="field field-name-lastname required">
                    <label className="label" htmlFor="lastname">
                      <span>Password</span>
                    </label>
                    <div className="control">
                      {/*  <input
                                type="text"
                                id="lastname"
                                name="lastname"
                                defaultValue
                                title="pwd"
                                className="input-text required-entry"
                                data-validate="{required:true}"
                                aria-required="true"
                              /> */}
                      <input
                        type="password"
                        name="pwd"
                        autoComplete="current-password"
                        id="pwd"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.pwd}
                        className="input-text required-entry"
                      />

                      {errors.pwd && touched.pwd && (
                        <div className="invalid-feedback">{errors.pwd}</div>
                      )}
                    </div>
                  </div>
                </fieldset>
                <br />
                <button
                  className="btn_modifica"
                  type="submit"
                  disabled={isSubmitting || this.state.loading}
                >
                  {this.state.loading ? (
                    <div className="loginloader"></div>
                  ) : (
                    <span>Accedi</span>
                  )}
                </button>
              </form>
            )}
          </Formik>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <div
            className="secondary"
            style={{
              transition: "all 0.3s ease-in-out",
              opacity: this.state.done === false ? "0" : "1",
            }}
          >
            Cliente creato con successo{" "}
            <button
              className="btn_modifica"
              disabled={this.state.loading}
              onClick={() => this.setState({ done: false })}
            >
              {this.state.loading ? "Carico.." : "OK"}
            </button>
          </div>
        </div>
      </div>
    );
  }
}
