import React, { Component } from "react";
import api from "../../config";
import Swal from "sweetalert2";
import $ from "jquery";
import "./InnerOrder.css";
import { makeStyles } from "@material-ui/core/styles";
import { Tooltip } from "@material-ui/core";
import Printbutton from "../../components/printButton/Printbutton";
import { Animated } from "react-animated-css";
import Textfit from "react-textfit/lib/Textfit";
import HtmlEditor from "../../components/htmlEditor/HtmlEditor";
import Popup from "../../components/popup/Popup";
import AccordionApi from "../../components/accordionApi/AccordionApi";
import Load from "../../components/Load";
import PayScreen from "../../components/pagamento/PayScreen";
export default class InnerOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      stamp_active: false,
      partner_id: "",
      share_active: false,
      pay: false,
      communication: {
        rows: 5,
        lines: [],
      },
      loader: false,
    };
  }
  componentDidMount() {
    this.setState({ loader: true });
    api.fetchInnerSaleOrder(this.props.match.params.idorder).then((value) => {
      var count = 0;
      if (value) {
        value.chatter.messages.forEach((element) => {
          if (element.body.length > 0) count++;
        });
        this.setState({
          summary: value,
          lines: value.order_line,
          communication: value.chatter,
          count: count,
          loader: false,
        });
      } else this.setState({ loader: false });
    });
  }
  onEndPay = (share_active) =>{
    this.setState({pay:false,share_active:share_active})
  }
  renderDates(lista) {
    var data_res = [];
    lista.forEach((data) => {
      data_res.push(<li key={data}>{data}</li>);
    });
    return data_res;
  }
  Tooltip(props) {
    const useStylesBootstrap = makeStyles((theme) => ({
      arrow: {
        color: theme.palette.common.black,
      },
      tooltip: {
        backgroundColor: theme.palette.common.black,
        boxShadow: theme.shadows[1],
        fontSize: 14,
      },
    }));
    const classes = useStylesBootstrap();

    return <Tooltip arrow classes={classes} {...props} />;
  }
  renderLines() {
    var res = [];
    if (this.state.lines) {
      this.state.lines.forEach((element) => {
        if (element.product_id)
          res.push(
            <tr key={element.product_id.sku} id="order-item-row-516">
              <td className="col name" data-th="Nome prodotto">
                <div>
                  {element.product_id.sku === "GENERIC PRODUCT"
                    ? element.prev_nome
                    : element.product_id.name}
                </div>
              </td>
              <td className="col desc" data-th="Descrizione">
                <div>
                  {element.product_id.sku === "GENERIC PRODUCT"
                    ? element.prev_descrizione
                    : element.description}
                </div>
              </td>
              <td className="col brand" data-th="Brand">
                {element.product_id.sku === "GENERIC PRODUCT"
                  ? element.prev_brand.nome
                  : element.product_id.product_brand_id &&
                    element.product_id.product_brand_id.name}
              </td>
              <td className="col sku" data-th="SKU">
                {element.product_id.sku === "GENERIC PRODUCT"
                  ? "n.d."
                  : element.product_id.sku}
              </td>
              <td className="col price" data-th="Prezzo">
                <span className="price-including-tax" data-label="Tasse incl.">
                  <span className="cart-price">
                    <span className="price">
                      {element.price_unit.toLocaleString("it-IT", {
                        style: "currency",
                        currency: "EUR",
                      })}
                    </span>{" "}
                  </span>
                </span>
              </td>
              <td className="col sku" data-th="Qtà">
                <ul className="items-qty">
                  <li className="item">
                    <span className="content">{element.ordered_qty}</span>
                  </li>
                </ul>
              </td>
              <td className="col sku" data-th="Sconto">
                <span className="price-including-tax" data-label="Tasse incl.">
                  <span className="cart-price">
                    <span className="price">
                      {element.discount.toLocaleString("it-IT")}
                    </span>{" "}
                  </span>
                </span>
              </td>
              {this.props.prev === 0 ? (
                <td className="col sku" data-th="Dates">
                  <ul className="items-qty">
                    <li className="item">
                      <span className="content">
                        <ul>{this.renderDates(element.dates_planned)}</ul>
                      </span>
                    </li>
                  </ul>
                </td>
              ) : null}
              {this.props.prev === 0 ? (
                <td className="col sku" data-th="Dates">
                  <ul className="items-qty">
                    <li className="item">
                      <span className="content">
                        <ul>{this.renderDates(element.po_numbers)}</ul>
                      </span>
                    </li>
                  </ul>
                </td>
              ) : null}
              {this.props.prev === 0 ? (
                <td className="col dates" data-th="Dates">
                  <ul className="items-qty">
                    <li className="item">
                      <span className="content">
                        <ul>{this.renderDates(element.suppliers_name)}</ul>
                      </span>
                    </li>
                  </ul>
                </td>
              ) : null}
              {this.props.prev === 0 ? (
                <td className="col dates" data-th="Dates">
                  <ul className="items-qty">
                    <li className="item">
                      <span className="content">{element.delivery_state}</span>
                    </li>
                  </ul>
                </td>
              ) : null}

              <td className="col subtotal" data-th="Totale prodotti">
                <span className="price-including-tax" data-label="Tasse incl.">
                  <span className="cart-price">
                    <span className="price">
                      {element.price_subtotal.toLocaleString("it-IT", {
                        style: "currency",
                        currency: "EUR",
                      })}
                    </span>{" "}
                  </span>
                </span>
              </td>
            </tr>
          );
        else
          res.push(
            <tr
              key={element.description}
              style={{
                background: "rgba(0,0,0,0.07)",
                borderTop: "1px solid black",
                borderRadius: "5px",
              }}
              id="order-item-row-516"
            >
              <td className="col name" data-th="Nome prodotto">
                <div className="max_height">
                  <span>{element.description}</span>
                </div>
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              {this.props.prev === 0 && (
                <>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </>
              )}
            </tr>
          );
      });
    }
    return res;
  }

  renderReply() {
    const formatDate = function (input) {
      if (input)
        var datePart = input.match(/\d+/g),
          year = datePart[0].substring(2), // get only two digits
          month = datePart[1],
          day = datePart[2];
      if (!day) {
        return "//";
      }
      return day + "/" + month + "/" + year;
    };
    var communication = this.state.communication.messages;
    var res = [];
    if (communication) {
      communication.forEach((element) => {
        var body = element.body;
        body = body.replace(/(onclick)+=+("+|'+).*("+|'+)/gm, "onclick=''");
        body = body.replace(/<a(.*)"/gm, "<a href='#'");
        if (body.length > 1)
          res.push(
            <div key={element.id} className="reply">
              {element.photo ? (
                <img src={element.photo} alt={element.photo} />
              ) : (
                <img
                  src={
                    "https://imagineacademy.microsoft.com/content/images/microsoft-img.png "
                  }
                  alt="thumb"
                />
              )}
              <div className="data_container">
                <h4>{element.author_id[1]}</h4>
                <p className="subtitle">
                  Pubblicato il{" "}
                  {formatDate(element.date.substring(0, 10)) +
                    " " +
                    element.date.substring(11)}
                </p>
                <p
                  className="content"
                  dangerouslySetInnerHTML={{
                    __html: body,
                  }}
                ></p>
              </div>
            </div>
          );
      });
    }
    return res;
  }
  formatDate = function (input) {
    if (input) {
      var datePart = input.match(/\d+/g),
        year = datePart[0].substring(0), // get only two digits
        month = datePart[1],
        day = datePart[2];

      return day + "/" + month + "/" + year;
    } else return "Non specificata";
  };
  render() {
    if (this.state.summary) {
      const alldata = this.state.summary;
      const shipping = this.state.summary.ship_to;
      const billing = this.state.summary.invoice_to;
      return (
        <React.Fragment>
          <div className="page_container">
            <h1 className="page_title">
              {alldata.name} / {alldata.state}
            </h1>
            <div className="prev_card">
              <div className="table-wrapper order-items">
                <div className="order-date leftalign">
                  <p>
                    Data ordine:{" "}
                    <span style={{ color: "black" }}>
                      {this.formatDate(alldata.customer_confirmation_date)}
                    </span>
                    <this.Tooltip title="Condividi con il cliente">
                      <a
                        style={{ float: "right", fontSize: "18px" }}
                        rel="noopener noreferrer"
                        target="_blank"
                        href=""
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({
                            share_active: !this.state.share_active,
                          });
                        }}
                      >
                        <i
                          className="fa fa-share-alt"
                          aria-hidden="true"
                          style={{ marginLeft: "15px" }}
                        />
                      </a>
                    </this.Tooltip>
                    {this.props.prev === 1 && (
                      <this.Tooltip title="Invia al Preventivatore">
                        <a
                          style={{ float: "right", fontSize: "18px" }}
                          rel="noopener noreferrer"
                          target="_blank"
                          href=""
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          <i
                            className="fas fa-edit"
                            style={{ marginLeft: "15px" }}
                            onClick={(e) => {
                              e.preventDefault();
                              api
                                .putInDraft(this.props.match.params.idorder)
                                .then((value) => {
                                  if (value)
                                    window.location =
                                      "/preventivatore/new/" +
                                      this.props.match.params.idorder;
                                });
                            }}
                          />
                        </a>
                      </this.Tooltip>
                    )}
                      {this.props.prev === 1 && (
                      <this.Tooltip title="Vai al Pagamento">
                        <a
                          style={{ float: "right", fontSize: "18px" }}
                          rel="noopener noreferrer"
                          target="_blank"
                          href=""
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          <i
                            className="fas fa-dollar"
                            style={{ marginLeft: "15px" }}
                            onClick={(e) => {
                            this.setState({pay:true})
                            }}
                          />
                        </a>
                      </this.Tooltip>
                    )}
                    <a
                      style={{ float: "right", fontSize: "18px" }}
                      rel="noopener noreferrer"
                      target="_blank"
                      href=""
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                          stamp_active: !this.state.stamp_active,
                        });
                      }}
                    >
                      <i className="fa fa-print" aria-hidden="true"></i>
                    </a>
                  
                  </p>
                  <p>
                    Agente:{" "}
                    <span style={{ color: "black" }}>
                      {alldata.agent_id
                        ? alldata.agent_id.name
                        : "Non specificato"}{" "}
                    </span>
                  </p>
                </div>
                <table
                  className="data table table-order-items"
                  id="my-orders-table"
                  summary="Articoli ordinati"
                >
                  <thead>
                    <tr>
                      <th className="col name">Nome prodotto</th>
                      <th className="col desc">Descrizione</th>
                      <th className="col brand">Brand</th>
                      <th className="col sku">SKU</th>
                      <th className="col price">Prezzo</th>
                      <th className="col sku">Qtà</th>

                      <th className="col sku">Sconto %</th>
                      {this.props.prev === 0 ? (
                        <th className="col sku">Data stimata</th>
                      ) : null}
                      {this.props.prev === 0 ? (
                        <th className="col sku">Rif. PO</th>
                      ) : null}
                      {this.props.prev === 0 ? (
                        <th className="col sku">Fornitore</th>
                      ) : null}
                      {this.props.prev === 0 ? (
                        <th className="col sku">Stato Sped.</th>
                      ) : null}
                      <th className="col subtotal">Totale prodotti</th>
                    </tr>
                  </thead>
                  <tbody>{this.renderLines()}</tbody>
                </table>
                <hr />
                <div className="footerTable">
                  <div className="ro">
                    <div className="cel">
                      <strong>Sconto</strong>{" "}
                    </div>
                    <div className="cel">
                      {alldata.amount_discount.toLocaleString("it-IT", {
                        style: "currency",
                        currency: "EUR",
                      })}
                    </div>
                  </div>

                  <div className="ro">
                    <div className="cel">
                      <strong>Totale imponibile scontato</strong>
                    </div>
                    <div className="cel">
                      {alldata.amount_untaxed.toLocaleString("it-IT", {
                        style: "currency",
                        currency: "EUR",
                      })}
                    </div>
                  </div>
                  <div className="ro">
                    <div className="cel">Spedizione e gestione</div>
                    <div className="cel">
                      {" "}
                      {alldata.amount_shipping.toLocaleString("it-IT", {
                        style: "currency",
                        currency: "EUR",
                      })}
                    </div>
                  </div>
                  <div className="ro">
                    <div className="cel">
                      {" "}
                      <strong>IVA</strong>
                    </div>
                    <div className="cel">
                      {alldata.amount_tax.toLocaleString("it-IT", {
                        style: "currency",
                        currency: "EUR",
                      })}
                    </div>
                  </div>
                  <div className="ro">
                    <div className="cel">
                      {" "}
                      <strong>Totale complessivo</strong>
                    </div>
                    <div className="cel">
                      {alldata.amount_total.toLocaleString("it-IT", {
                        style: "currency",
                        currency: "EUR",
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="table_div leftalign">
              <div className="row_div">
                <div style={{ paddingRight: "12.5px" }} className="cell_div">
                  <div className="prev_card height450">
                    <div className="row_div">
                      <div className="cell_div">
                        <h3>Dati Spedizione</h3>
                      </div>
                    </div>
                    <div className="row_div">
                      <div className="cell_div">
                        <label>
                          <strong>Nome:</strong>{" "}
                        </label>
                        <span className="capitalize">{shipping.name}</span>
                      </div>
                    </div>
                    <div className="row_div">
                      <div className="cell_div">
                        <label>
                          <strong>Telefono:</strong>{" "}
                        </label>
                        <span className="capitalize">{shipping.phone}</span>
                      </div>
                    </div>
                    <div className="row_div">
                      <div className="cell_div">
                        <label>
                          <strong>Cellulare:</strong>{" "}
                        </label>
                        <span className="capitalize">{shipping.mobile}</span>
                      </div>
                    </div>
                    <div className="row_div">
                      <div className="cell_div">
                        <label>
                          <strong>Email:</strong>{" "}
                        </label>
                        <span className="capitalize">{shipping.email}</span>
                      </div>
                    </div>
                    <div className="row_div">
                      {shipping.country_id && (
                        <div className="cell_div">
                          <label>
                            <strong>Stato/Provincia:</strong>{" "}
                          </label>
                          <span className="capitalize">
                            {shipping.country_id.name}
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="row_div">
                      <div className="cell_div">
                        <label>
                          <strong>Città:</strong>{" "}
                        </label>
                        <span className="capitalize">{shipping.city}</span>
                      </div>
                    </div>
                    <div className="row_div">
                      <div className="cell_div">
                        <label>
                          <strong>Indirizzo:</strong>{" "}
                        </label>
                        <span className="capitalize">{shipping.street}</span>
                      </div>
                    </div>

                    <div className="row_div">
                      <div className="cell_div">
                        <label>
                          <strong>Cap:</strong>{" "}
                        </label>
                        <span className="capitalize">{shipping.zip}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ paddingLeft: "12.5px" }} className="cell_div">
                  <div className="prev_card height450">
                    <div className="row_div">
                      <div className="cell_div">
                        <h3>Dati Fatturazione</h3>
                      </div>
                    </div>
                    <div className="row_div">
                      <div className="cell_div">
                        <label>
                          <strong>Nome:</strong>{" "}
                        </label>
                        <span className="capitalize">{billing.name}</span>
                      </div>
                    </div>
                    <div className="row_div">
                      <div className="cell_div">
                        <label>
                          <strong>Telefono:</strong>{" "}
                        </label>
                        <span className="capitalize">{billing.phone}</span>
                      </div>
                    </div>
                    <div className="row_div">
                      <div className="cell_div">
                        <label>
                          <strong>Cellulare:</strong>{" "}
                        </label>
                        <span className="capitalize">{billing.mobile}</span>
                      </div>
                    </div>
                    <div className="row_div">
                      <div className="cell_div">
                        <label>
                          <strong>Email:</strong>{" "}
                        </label>
                        <span className="capitalize">{billing.email}</span>
                      </div>
                    </div>
                    <div className="row_div">
                      {billing.country_id && (
                        <div className="cell_div">
                          <label>
                            <strong>Stato/Provincia:</strong>{" "}
                          </label>
                          <span className="capitalize">
                            {billing.country_id.name}
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="row_div">
                      <div className="cell_div">
                        <label>
                          <strong>Città:</strong>{" "}
                        </label>
                        <span className="capitalize">{billing.city}</span>
                      </div>
                    </div>

                    <div className="row_div">
                      <div className="cell_div">
                        <label>
                          <strong>Indirizzo:</strong>{" "}
                        </label>
                        <span className="capitalize">{billing.street}</span>
                      </div>
                    </div>
                    <div className="row_div">
                      <div className="cell_div">
                        <label>
                          <label>{"           "}</label>
                        </label>
                        <span className="capitalize">{billing.street2}</span>
                      </div>
                    </div>
                    <div className="row_div">
                      <div className="cell_div">
                        <label>
                          <strong>Cap:</strong>{" "}
                        </label>
                        <span className="capitalize">{billing.zip}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="dati_spedizione_checkout"
              className="table_div leftalign det"
              style={{ marginTop: "25px" }}
            >
              <div className="row_div">
                <div className="cell_div">
                  <div className="prev_card">
                    {" "}
                    <h3>Note Interne</h3> {alldata.internal_notes}
                  </div>
                </div>{" "}
                <div className="cell_div">
                  <div className="prev_card">
                    <h3>Note Ordine</h3> {alldata.note_2}
                  </div>
                </div>
              </div>
            </div>
            <hr style={{ margin: "15px 0px" }} />
            {alldata.picking_accordion && (
              <AccordionApi
                iconclass="fa fa-truck"
                title={"Consegne"}
                count={alldata.picking_accordion.picking_count}
                datas={alldata.picking_accordion.picking_ids}
                headers={alldata.picking_accordion.picking_headers}
              />
            )}
            {alldata.purchase_accordion && (
              <AccordionApi
                iconclass="fa fa-exchange"
                title={"Acquisti"}
                count={alldata.purchase_accordion.purchase_count}
                datas={alldata.purchase_accordion.purchase_ids}
                headers={alldata.purchase_accordion.purchase_headers}
              />
            )}
            {/* <AccordionApi
              iconclass="fa fa-eur"
              title="Pagamenti"
              headers={["Nome1", "Nome2", "nome3"]}
            /> */}
            {alldata.invoice_accordion && (
              <AccordionApi
                iconclass="fa fa-edit"
                title={"Fatture"}
                count={alldata.invoice_accordion.invoice_count}
                datas={alldata.invoice_accordion.invoice_ids}
                headers={alldata.invoice_accordion.invoice_headers}
              />
            )}
            <div className="textbox-container">
              <h4>Comunicazioni</h4>
              <h5>
                <i className="fas fa-comments"></i> Ci sono {this.state.count}{" "}
                messaggi
              </h5>
              <img
                src={
                  "https://imagineacademy.microsoft.com/content/images/microsoft-img.png"
                }
                alt="thumb"
              />
              <h4>{sessionStorage.getItem("username")}</h4>
              <textarea
                name="message"
                value={this.state.message}
                onChange={(e) =>
                  this.setState({ [e.target.name]: e.target.value })
                }
              />
              <button
                disabled={this.state.message.length < 3}
                onClick={() =>
                  api
                    .postChatter(
                      this.state.message,
                      this.props.match.params.idorder
                    )
                    .then((value) => {
                      api
                        .fetchInnerSaleOrder(this.props.match.params.idorder)
                        .then((value) => {
                          this.setState({
                            communication: value.chatter,
                            message: "",
                            count: this.state.count + 1,
                          });
                        });
                      Swal.fire({
                        position: "top-end",
                        icon: "success",
                        html:
                          "<div><h1>Messaggio inviato</h1> <p>Il messaggio è stato inviato con successo</p> </div>",
                        showConfirmButton: false,
                        timerProgressBar: true,
                        timer: 2000,
                        customClass: { container: "swal_container" },
                      });
                    })
                }
              >
                Invia
              </button>
              {this.state.communication.message_count > 0 && <hr />}

              {this.renderReply()}
            </div>
          </div>
          <Popup
            id={"1"}
            width={"60vw"}
            handlePopup={() =>
              this.setState({ share_active: !this.state.share_active })
            }
            isVisible={this.state.share_active}
          >
            <HtmlEditor
              template_id="mohd_payment_system.email_template_share_order"
              closePopUp={() => this.setState({ share_active: false })}
              model="sale.order"
              partner_ids={[alldata.partner_id.id]}
              res_id={alldata.id}
              subject={"Preventivo " + alldata.name}
              body={""}
            />
          </Popup>
          <Popup
            id={"2"}
            width={"60vw"}
            handlePopup={() =>
              this.setState({ stamp_active: !this.state.stamp_active })
            }
            isVisible={this.state.stamp_active}
          >
            <Printbutton
              id={this.props.match.params.idorder}
              model="sale.order"
            />
          </Popup>{" "}
          {this.state.pay && (
            <Popup
              id={"3"}
              width={"60vw"}
              handlePopup={() => this.setState({ pay: !this.state.pay })}
              isVisible={this.state.pay}
            >
              <PayScreen
                isPopup
                putPayment={api.putPayment}
                onEndPay={this.onEndPay}
                amount={alldata.amount_total - alldata.invoice_paid_amount}
                partner_id={alldata.partner_id.id}
                order_id={alldata.id}
                firstname={shipping.name || billing.name}
                lastname={""}
                nome_prev={alldata.name}
              />
            </Popup>
          )}
          {this.state.loader && (
            <div className="obscure">
              <Load
                text={
                  "Sto caricando " + this.props.prev === 0
                    ? "l'ordine"
                    : "il preventivo"
                }
              />
            </div>
          )}
        </React.Fragment>
      );
    } else
      return (
        <div className="obscure">
          <Load
            text={
              "Sto caricando " +
              (this.props.prev === 0 ? "l'ordine" : "il preventivo")
            }
          />
        </div>
      );
  }
}
