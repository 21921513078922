import React, { Component } from "react";
import api from "../../../config";
import Swal from "sweetalert2";
import $ from "jquery";
import { makeStyles } from "@material-ui/core/styles";
import { Tooltip } from "@material-ui/core";
import Printbutton from "../../../components/printButton/Printbutton";
import { Animated } from "react-animated-css";
import Load from "../../../components/Load";
import HtmlEditor from "../../../components/htmlEditor/HtmlEditor";
export default class InnerFollowup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      partner_id: "",
      loader: false,
      summary: {},
    };
  }
  componentDidMount() {
    this.setState({ loader: true });
    api.getInnerFollowup(this.props.match.params.idorder).then((value) => {
      if (value) {
        this.setState({
          summary: value,
          loader: false,
        });
      } else this.setState({ loader: false });
    });
  }
  renderDates(lista) {
    var data_res = [];
    lista.forEach((data) => {
      data_res.push(<li key={data}>{data}</li>);
    });
    return data_res;
  }
  Tooltip(props) {
    const useStylesBootstrap = makeStyles((theme) => ({
      arrow: {
        color: theme.palette.common.black,
      },
      tooltip: {
        backgroundColor: theme.palette.common.black,
        boxShadow: theme.shadows[1],
        fontSize: 14,
      },
    }));
    const classes = useStylesBootstrap();

    return <Tooltip arrow classes={classes} {...props} />;
  }
  renderLines() {
    var res = [];
    if (this.state.lines) {
      this.state.lines.forEach((element) => {
        res.push(
          <tr key={element.id} id="order-item-row-516">
            <td className="col name" data-th="Nome prodotto">
              <span>{element.name}</span>
            </td>

            <td className="col date" data-th="Nome prodotto">
              <span>{this.formatDate(element.date)}</span>
            </td>
            <td className="col qta" data-th="Quantità">
              <span>{element.quantity}</span>
            </td>
            <td
              style={{ textAlign: "right" }}
              className="col total"
              data-th="Totale"
            >
              <strong>
                {element.amount_subtotal.toLocaleString("it-IT", {
                  style: "currency",
                  currency: "EUR",
                })}
              </strong>
            </td>
          </tr>
        );
      });
    }
    return res;
  }
  formatDate = function (input) {
    if (input) {
      var datePart = input.match(/\d+/g),
        year = datePart[0].substring(0), // get only two digits
        month = datePart[1],
        day = datePart[2];

      return day + "/" + month + "/" + year;
    } else return "Non specificata";
  };
  render() {
    if (this.state.summary) {
      const alldata = this.state.summary;

      return (
        <React.Fragment>
          <div className="page_container">
            <h1 className="page_title">
              {alldata.record_name + " / " + alldata.state}
            </h1>
         
            <div className="prev_card">
            <div className="order-date leftalign">
              <p>
                Data:{" "}
                <span style={{ color: "black" }}>
                  {this.formatDate(alldata.date)}
                </span>
              </p>
            </div>
              {alldata.body && (
                <HtmlEditor
                  notemplate
                  closePopUp={() => this.setState({ share_active: false })}
                  subject={""}
                  body={alldata.body}
                  model="sale.order"
                  className="full"
                />
              )}
            </div>

            <hr style={{ margin: "15px 0px" }} />
          </div>
          {this.state.loader && (
            <div className="obscure">
              <Load
                text={
                  "Sto caricando " + this.props.prev === 0
                    ? "l'ordine"
                    : "il preventivo"
                }
              />
            </div>
          )}
        </React.Fragment>
      );
    } else
      return (
        <div className="obscure">
          <Load
            text={
              "Sto caricando " +
              (this.props.prev === 0 ? "l'ordine" : "il preventivo")
            }
          />
        </div>
      );
  }
}
