import React, { Component } from "react";
import "./Ordini.css";
import { Link } from "react-router-dom";
import api from "../config";
import AsyncSelect from "react-select/async";

export default class Ordini extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      orders: [],
      count: 0,
      page: 0,
      max: 1,
    };
  }
  componentDidMount() {
    this.setState({ loading: true });
    api.fetchSaleOrder(this.props.prev, 0, 50).then((value) => {
      if (value)
        this.setState({
          orders: value.rows,
          count: value.total,
          max: Math.ceil(value.total / 50) - 1,
          loading: false,
        });
      else this.setState({ loading: false });
    });
  }
  loadOptions = (inputValue, callback) => {
    this.setState({ fetching: true });
    if (inputValue.length > 4) {
      api.searchAsyncSaleOrders(inputValue, this.props.prev).then((value) => {
        if (value) callback(value.rows);
        this.setState({ fetching: false });
      });
    }
  };
  fetchmore = (page) => {
    var offset = 50;

    if (this.state.page * offset < this.state.count) offset = 50;
    else {
      offset = this.state.count - 50;
    }
    api.fetchSaleOrder(this.props.prev, offset, page * offset).then((value) => {
      if (value)
        this.setState({
          orders: value.rows,
          count: value.total,
          loading: false,
        });
    });
  };
  renderTablesLoader() {
    var res = [];
    for (var i = 0; i < 30; i++) {
      res.push(
        <tr key={i} className="loader">
          <td>
            <span style={{ animationDelay: i * 700 + "ms" }} />
          </td>
          <td>
            <span style={{ animationDelay: i * 700 + "ms" }} />
          </td>
          <td>
            <span style={{ animationDelay: i * 700 + "ms" }} />
          </td>
          <td>
            <span style={{ animationDelay: i * 700 + "ms" }} />
          </td>
          <td>
            <span style={{ animationDelay: i * 700 + "ms" }} />
          </td>
          <td>
            <span style={{ animationDelay: i * 700 + "ms" }} />
          </td>
          <td>
            <span style={{ animationDelay: i * 700 + "ms" }} />
          </td>
        </tr>
      );
    }
    return res;
  }
  colorStatus(string) {
    var res = "";
    switch (string) {
      case "Preventivo":
        res = <b style={{ color: "blue" }}>{string}</b>;
        break;
      case "Ordinato":
        res = <b style={{ color: "orange" }}>{string}</b>;
        break;
      case "Preventivo Accettato":
        res = <b style={{ color: "green" }}>{string}</b>;
        break;
      case "Annullato":
        res = <b style={{ color: "red" }}>{string}</b>;
        break;
      case "Ordine di Vendita":
        res = <b style={{ color: "green" }}>{string}</b>;
        break;
      case "Ordine Accettato":
        res = <b style={{ color: "green" }}>{string}</b>;
        break;
      default:
        res = <b>{string}</b>;
    }
    return res;
  }
  renderTablesOrder() {
    var res = [];
    if (this.state.orders.length > 0) {
      this.state.orders.forEach((element) => {
        res.push(
          <tr className = "hideCell" onClick={()=>{this.props.width < 1000 &&
            this.props.history.push(
              this.props.prev
                ? "/customer/quotations/" + element.id
                : "/customer/orders/" + element.id
            );}} key={element.id}>
            <td data-th="Ordine n." className="col id">
              {element.name}
            </td>
            <td data-th="Data" className="col date">
              {element.customer_confirmation_date}
            </td>
            <td data-th="Spedisci A" className="col shipping">
              {element.ship_to.name}
            </td>
            <td data-th="Totale ordine" className="col total">
              <span className="price">
                {element.amount_untaxed.toLocaleString("it-IT", {
                  style: "currency",
                  currency: "EUR",
                })}
              </span>
            </td>
            <td data-th="Totale ordine" className="col total">
              <span className="price">
                {element.amount_total.toLocaleString("it-IT", {
                  style: "currency",
                  currency: "EUR",
                })}
              </span>
            </td>
            <td data-th="Status" className="col status">
              {this.colorStatus(element.shipping_state)}
            </td>
            <td data-th="Azioni" className="col actions hideCell rightalign">
              <Link
                to={
                  this.props.prev
                    ? "/customer/quotations/" + element.id
                    : "/customer/orders/" + element.id
                }
                className="action view"
              >
                Visualizza {this.props.prev ? "preventivo" : "ordine"}
              </Link>
            </td>
          </tr>
        );
      });
    }
    return res;
  }
  render() {
    return (
      <React.Fragment>
        <h1 className="page_title">
          {this.props.prev ? "I miei Preventivi" : "I miei Ordini"}
        </h1>

        <div className="pagination_container">
          <div className="page_right">
            {/*  pagina {this.state.page + 1} max: {this.state.max + 1} {"  "} */}
            {this.state.page === 0 ? 1 : this.state.page * 50}-
            {this.state.page === 0
              ? 50
              : this.state.page == this.state.max
              ? this.state.count
              : this.state.page * 50 + 50}{" "}
            &#8725; {this.state.count}{" "}
            <span
              onClick={() => {
                var newpage =
                  this.state.page > 0 ? this.state.page - 1 : this.state.max;

                this.setState({ page: newpage });
                this.fetchmore(newpage);
              }}
            >
              &#8249;
            </span>{" "}
            <span
              onClick={() => {
                var newpage =
                  this.state.page == this.state.max ? 0 : this.state.page + 1;
                this.setState({ page: newpage });
                this.fetchmore(newpage);
              }}
            >
              &#8250;
            </span>{" "}
          </div>
        </div>
        <div className="orders_wrapper">
          {!this.state.loading ? (
            this.state.orders ? (
              <table
                className="data table table-order-items history"
                id="my-orderss-table"
              >
                <thead>
                  <tr>
                    <th scope="col" className="col id">
                      {this.props.prev ? "Preventivo " : "Ordine "} n.
                    </th>
                    <th scope="col" className="col date">
                      Data
                    </th>
                    <th scope="col" className="col shipping">
                      Spedisci A
                    </th>
                    <th scope="col" className="col total">
                      Totale netto {this.props.prev ? "preventivo" : "ordine"}
                    </th>
                    <th scope="col" className="col total">
                      Totale ivato {this.props.prev ? "preventivo" : "ordine"}
                    </th>
                    <th scope="col" className="col status">
                      Status
                    </th>
                    <th scope="col" className="col actions hideCell"></th>
                  </tr>
                </thead>

                <tbody>{this.renderTablesOrder()}</tbody>
              </table>
            ) : (
              <div className="message info empty">
                <span>Non è stato inserito nessun ordine.</span>
              </div>
            )
          ) : (
            <table
              className="data table table-order-items history"
              id="my-orders-table"
            >
              <caption className="table-caption">Ordini</caption>
              <thead>
                <tr>
                  <th scope="col" className="col id">
                    Ordine n.
                  </th>
                  <th scope="col" className="col date">
                    Data
                  </th>
                  <th scope="col" className="col shipping">
                    Spedisci A
                  </th>
                  <th scope="col" className="col total">
                    Totale netto {this.props.prev ? "preventivo" : "ordine"}
                  </th>
                  <th scope="col" className="col total">
                    Totale ivato {this.props.prev ? "preventivo" : "ordine"}
                  </th>
                  <th scope="col" className="col status">
                    Status
                  </th>
                  <th scope="col" className="col actions"></th>
                </tr>
              </thead>

              <tbody>{this.renderTablesLoader()}</tbody>
            </table>
          )}
        </div>
      </React.Fragment>
    );
  }
}
