import React, { Component } from "react";
import AnimatedNumber from "react-animated-number";
import "./Bacheca.css";
import { Responsive, WidthProvider } from "react-grid-layout";
import { Line, Doughnut, Pie } from "react-chartjs-2";
const GridLayout = WidthProvider(Responsive);
export default class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      res: false,
    };
  }
  componentDidMount() {
    var layout = JSON.parse(localStorage.getItem("layouts"));
    if (!layout)
      layout = {
        lg: [
          { i: "0", x: 0, y: 0, w: 1, h: 1 },
          { i: "1", x: 1, y: 0, w: 1, h: 1 },
          { i: "2", x: 2, y: 0, w: 1, h: 1 },
          { i: "3", x: 3, y: 0, w: 1, h: 1 },
          { i: "4", x: 4, y: 0, w: 1, h: 1 },
        ],
        md: [
          { i: "0", x: 1, y: 0, w: 1, h: 1 },
          { i: "1", x: 2, y: 0, w: 1, h: 1 },
          { i: "2", x: 3, y: 0, w: 1, h: 1 },
          { i: "3", x: 2, y: 1, w: 1, h: 1 },
          { i: "4", x: 3, y: 1, w: 1, h: 1 },
        ],
        sm: [
          { i: "0", x: 0, y: 0, w: 1, h: 1 },
          { i: "1", x: 1, y: 0, w: 1, h: 1 },
          { i: "2", x: 2, y: 0, w: 1, h: 1 },
          { i: "3", x: 0, y: 1, w: 1, h: 1 },
          { i: "4", x: 1, y: 1, w: 1, h: 1 },
        ],
        xs: [
          { i: "0", x: 0, y: 0, w: 1, h: 1 },
          { i: "1", x: 1, y: 0, w: 1, h: 1 },
          { i: "2", x: 0, y: 1, w: 1, h: 1 },
          { i: "3", x: 1, y: 1, w: 1, h: 1 },
          { i: "4", x: 0, y: 2, w: 1, h: 1 },
        ],
        xxs: [
          { i: "0", x: 0, y: 0, w: 1, h: 1 },
          { i: "1", x: 0, y: 1, w: 1, h: 1 },
          { i: "2", x: 2, y: 2, w: 1, h: 1 },
          { i: "3", x: 0, y: 3, w: 1, h: 1 },
          { i: "4", x: 1, y: 4, w: 1, h: 1 },
        ],
      };

    this.setState({ layout });
  }
  renderminiChart(titolo, valore, img, i) {
    var res = [];
    res.push(
      <div key={i} className="card_auto">
        <div className="big_button _auto">
          <img src={img} />
          <h3>{titolo}</h3>
          <AnimatedNumber
            value={valore}
            type="number"
            style={{
              transition: "1s ease-out",
              fontSize: 28,
            }}
            formatValue={(value) => value.toFixed(0)}
            duration={1000}
          />
        </div>
      </div>
    );
    return res;
  }
  onLayoutChange(layout, layouts) {
    localStorage.setItem("layouts", JSON.stringify(layouts));
  }
  reset() {
    localStorage.removeItem("layouts");
    this.setState({
      layout: undefined,
    });
    setTimeout(() => {
      this.setState({
        layout: {
          lg: [
            { i: "0", x: 0, y: 0, w: 1, h: 1 },
            { i: "1", x: 1, y: 0, w: 1, h: 1 },
            { i: "2", x: 2, y: 0, w: 1, h: 1 },
            { i: "3", x: 3, y: 0, w: 1, h: 1 },
            { i: "4", x: 4, y: 0, w: 1, h: 1 },
          ],
          md: [
            { i: "0", x: 0, y: 0, w: 1, h: 1 },
            { i: "1", x: 1, y: 0, w: 1, h: 1 },
            { i: "2", x: 2, y: 0, w: 1, h: 1 },
            { i: "3", x: 3, y: 0, w: 1, h: 1 },
            { i: "4", x: 0, y: 1, w: 1, h: 1 },
          ],
          sm: [
            { i: "0", x: 0, y: 0, w: 1, h: 1 },
            { i: "1", x: 1, y: 0, w: 1, h: 1 },
            { i: "2", x: 2, y: 0, w: 1, h: 1 },
            { i: "3", x: 0, y: 1, w: 1, h: 1 },
            { i: "4", x: 1, y: 1, w: 1, h: 1 },
          ],
          xs: [
            { i: "0", x: 0, y: 0, w: 1, h: 1 },
            { i: "1", x: 1, y: 0, w: 1, h: 1 },
            { i: "2", x: 0, y: 1, w: 1, h: 1 },
            { i: "3", x: 1, y: 1, w: 1, h: 1 },
            { i: "4", x: 0, y: 2, w: 1, h: 1 },
          ],
          xxs: [
            { i: "0", x: 0, y: 0, w: 1, h: 1 },
            { i: "1", x: 0, y: 1, w: 1, h: 1 },
            { i: "2", x: 0, y: 2, w: 1, h: 1 },
            { i: "3", x: 0, y: 3, w: 1, h: 1 },
            { i: "4", x: 0, y: 4, w: 1, h: 1 },
          ],
        },
      });
    }, 500);
  }
  render() {
    const datac = {
      labels: ["January", "February"],
      datasets: [
        {
          label: "My First dataset",
          fill: false,
          lineTension: 0.1,
          backgroundColor: "rgba(75,192,192,0.4)",
          borderColor: "rgba(75,192,192,1)",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "rgba(75,192,192,1)",
          pointBackgroundColor: "#fff",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgba(75,192,192,1)",
          pointHoverBorderColor: "rgba(220,220,220,1)",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: [65, 59],
        },
      ],
    };

    const data2 = {
      labels: ["January", "February", "March", "April", "May", "June", "July"],
      datasets: [
        {
          label: "My First dataset",
          fill: false,
          lineTension: 0.1,
          backgroundColor: [
            "rgba(75, 192, 192, 0.4)",
            "rgba(75, 192, 11, 0.4)",
            " rgba(75, 31, 192, 0.4)",
            "rgba(75, 192, 192, 0.4)",
            "rgba(75, 6, 192, 0.4)",
          ],
          borderColor: "rgba(75,192,192,1)",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "rgba(75,192,192,1)",
          pointBackgroundColor: "#fff",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgba(75,192,192,1)",
          pointHoverBorderColor: "rgba(220,220,220,1)",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: [65, 59, 80, 81, 56, 55, 40],
        },
      ],
    };
    return (
      <div className="page_container">
        <div className="title">
          <h1 className="page_title absolute">Vendite</h1>
          <ul className="phaser">
            <li>
              <button onClick={() => this.setState({ res: !this.state.res })}>
                {!this.state.res ? "Modifica" : "Conferma"}
              </button>
            </li>
            <li>
              <button onClick={() => this.reset()}>Reset</button>
            </li>
          </ul>
        </div>
        {this.state.layout && (
          <GridLayout
            margin={[25, 25]}
            breakpoints={{ lg: 1100, md: 900, sm: 700, xs: 580, xxs: 0 }}
            cols={{ lg: 5, md: 4, sm: 3, xs: 2, xxs: 1 }}
            isResizable={this.state.res}
            isDraggable={this.state.res}
            className="layout"
            layouts={this.state.layout}
            onLayoutChange={(layout, layouts) =>
              this.onLayoutChange(layout, layouts)
            }
            rowHeight={230}
          >
            {this.renderminiChart(
              "Preventivi",
              29,
              "https://img.icons8.com/clouds/2x/sales-performance.png",
              "0"
            )}
            {this.renderminiChart(
              "Ordini di Vendita",
              665,
              "https://img.icons8.com/clouds/2x/sales-performance.png",
              "1"
            )}
            {this.renderminiChart(
              "Bozze Preventivatore",
              5,
              "https://img.icons8.com/clouds/2x/sales-performance.png",
              "2"
            )}
            {this.renderminiChart(
              "Lead",
              244,
              "https://img.icons8.com/clouds/2x/sales-performance.png",
              "3"
            )}
            {this.renderminiChart(
              "Richieste di Pagamento Pendenti",
              25,
              "https://img.icons8.com/clouds/2x/sales-performance.png",
              "4"
            )}
          </GridLayout>
        )}
      </div>
    );
  }
}
