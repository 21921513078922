import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
//Provo senza HashRouter
//import {HashRouter as Router} from "react-router-dom"
import { Router } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import { createBrowserHistory } from "history";
import { I18nextProvider } from "react-i18next";

import i18n from "./locales/i18next";
let History = createBrowserHistory();

ReactDOM.render(
<I18nextProvider i18n={i18n}>
  <Router history={History}>
    <App history={History} />
  </Router>
  </I18nextProvider>
  ,
  document.getElementById("root")
);

serviceWorker.unregister();
