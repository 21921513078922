import React, { Component } from "react";
import Select from "react-select";
import api from "../../config";
import "./Printbutton.css";
class Printbutton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      printOption: [],
      attributes: [],
      checkboxes: {},
    };
  }
  componentDidMount() {
    api.getPrintOpt(this.props.id, this.props.model).then((value) => {
      if (value)
        this.setState({ printOption: value.rows, attributes: value.options });
      var checkboxes = {};
      for (var i = 0; i < value.options.length; i++) {
        checkboxes[value.options[i].label] = value.options[i].selected;
      }
      this.setState({ checkboxes });
    });
  }
  renderChecks() {
    var res = [];
    var i = 0;
    this.state.attributes.forEach((element) => {
      i++;
      res.push(
        <li className="label_stampa" key={element.value}>
          <input
            id={element.value}
            name={element.label}
            onChange={(e) => {
              var val = this.state.checkboxes;
              val[element.label] = !val[element.label];
              api.setReportOptions(this.props.id, this.props.model, {
                [element.value]: val[element.label],
              });
              this.setState({ checkboxes: val });
            }}
            value={this.state.checkboxes[element.label]}
            checked={this.state.checkboxes[element.label]}
            name={element.value}
            type="checkbox"
          ></input>{" "}
          <label htmlFor={element.value}>{element.label}</label>
        </li>
      );
    });
    return res;
  }
  render() {
    return (
      <React.Fragment>
        <Select
          styles={{
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          }}
          menuPortalTarget={document.body}
          className="btn_crea react-select"
          placeholder="Stampa"
          classNamePrefix="react-select"
          options={this.state.printOption}
          onChange={(e) =>
            window.open(
              api.auth_api + "/report/pdf/" + e.value + "/" + this.props.id,
              "_blank"
            )
          }
        />
        <div className="checkbox_container">
          <ul>{this.renderChecks()}</ul>
        </div>
      </React.Fragment>
    );
  }
}

export default Printbutton;
