import React, { Component } from "react";
import api from "../../../config";
import AsyncSelect from "react-select/async";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import Utility from "../../../Utility";
import Load from "../../../components/Load";
export default class VisVista extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      orders: [],
      count: 0,
      page: 0,
      max: 1,
      ordered_get: "",
    };
  }
  componentDidMount() {
    this.setState({ loading: true });
    api.getLead(0, 50).then((value) => {
      if (value)
        this.setState({
          orders: value.rows,
          count: value.total,
          max: Math.ceil(value.total / 50) - 1,
          loading: false,
        });
      else this.setState({ loading: false });
    });
  }
  handleOrdineOpportunita = (attributo) => {
    this.setState(
      {
        fetching:true,
        ordered_get:
          attributo +
          " " +
          (this.state.ordered_get.includes("desc") ? "asc" : "desc"),
      },
      () => {
        api.getLead(0, 50, this.state.ordered_get).then((value) => {
          if (value)
            this.setState({
              orders: value.rows,
              count: value.total,
              max: Math.ceil(value.total / 50) - 1,
              fetching: false,
            });
          else this.setState({ fetching: false });
        });
      }
    );
  };
  formatDate = function (input) {
    if (input) {
      var datePart = input.match(/\d+/g),
        year = datePart[0].substring(2), // get only two digits
        month = datePart[1],
        day = datePart[2];

      return day + "/" + month + "/" + year;
    } else return "Non Specificata";
  };
  loadOptions = (inputValue, callback) => {
    this.setState({ searching: true });
    if (inputValue.length > 2) {
      api.searchAsyncLead(inputValue).then((value) => {
        if (value) callback(value.rows);
        this.setState({ searching: false });
      });
    }
  };
  fetchmore = (page) => {
    var offset = 50;
    this.setState({fetching:true})
    if (this.state.page * offset < this.state.count) offset = 50;
    else {
      offset = this.state.count - 50;
    }
    api.getLead(Number(this.state.page * offset), 50).then((value) => {
      this.setState({ orders: value.rows, count: value.total, fetching: false });
    });
  };
  renderTablesLoader() {
    var res = [];
    for (var i = 0; i < 30; i++) {
      res.push(
        <tr key={i} className="loader">
          <td data-th="Ordine n." className="col id">
            <span style={{ animationDelay: i * 700 + "ms" }} />
          </td>
          <td data-th="Data" className="col date">
            <span style={{ animationDelay: i * 700 + "ms" }} />
          </td>
          <td data-th="Spedisci A" className="col shipping ">
            <span style={{ animationDelay: i * 700 + "ms" }} />
          </td>
          <td data-th="Totale ordine" className="col total ">
            <span style={{ animationDelay: i * 700 + "ms" }} />
          </td>
          <td data-th="Status" className="col status ">
            <span style={{ animationDelay: i * 700 + "ms" }} />
          </td>
          <td data-th="Azioni" className="col actions">
            <span style={{ animationDelay: i * 700 + "ms" }} />
          </td>
          <td data-th="Azioni" className="col actions">
            <span style={{ animationDelay: i * 700 + "ms" }} />
          </td>
          <td data-th="Azioni" className="col actions">
            <span style={{ animationDelay: i * 700 + "ms" }} />
          </td>
          <td data-th="Azioni" className="col actions">
            <span style={{ animationDelay: i * 700 + "ms" }} />
          </td>
          <td data-th="Azioni" className="col actions">
            <span style={{ animationDelay: i * 700 + "ms" }} />
          </td>
        </tr>
      );
    }
    return res;
  }

  renderTablesOrder() {
    var res = [];
    if (this.state.orders.length > 0) {
      this.state.orders.forEach((element) => {
        res.push(
          <tr key={element.id}>
            <td data-th="Ordine n." className="col id">
              {element.name}
            </td>
            <td data-th="Data" className="col id">
              {this.formatDate(element.date)}
            </td>
            <td data-th="Data" className="col id">
              {element.partner_id ? element.partner_id.name : ""}
            </td>
            <td data-th="Spedisci A" className="col shipping">
              {element.phone}
            </td>
            <td data-th="Totale ordine" className="col total">
              {element.planned_revenue.toLocaleString("it-IT", {
                style: "currency",
                currency: "EUR",
              })}
            </td>
            <td data-th="Totale ordine" className="col total">
              <span className="price">{element.probability + "%"}</span>
            </td>
            <td data-th="Data" className="col id">
              {element.team_id ? element.team_id.name : ""}
            </td>
            <td data-th="Data" className="col id">
              {element.user_id ? element.user_id.name : ""}
            </td>
            <td data-th="Status" className="col status">
              <Utility choose={element.type} />
            </td>
            <td data-th="Azioni" className="col actions rightalign">
              <Link
                to={"/crm/visualizza-opportunita/" + element.id}
                className="action view"
              >
                <button className="btn_outlined">Visualizza</button>
              </Link>
            </td>
          </tr>
        );
      });
    }
    return res;
  }

  render() {
    return (
      <div className="page_container">
        <h1 className="page_title">Visualizza Opportunità</h1>
        <div className="prev_card searchable" style={{ marginBottom: "25px" }}>
          <div className="table_div">
            <div className="row_div">
              <div className="cell_div">
                <p className="subtitle">Cerca Opportunità</p>
                <AsyncSelect
                  cacheOptions
                  isLoading={this.state.searching}
                  onChange={(e) => {
                    this.props.history.push(
                      window.location.pathname + "/" + e.value
                    );
                  }}
                  placeholder={"Cerca tra le opportunità..."}
                  loadOptions={api._.debounce(this.loadOptions, 300)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="pagination_container">
          <div className="page_right">
            {this.state.page === 0 ? 1 : this.state.page * 50}-
            {this.state.page === 0
              ? 50
              : this.state.page == this.state.max
              ? this.state.count
              : this.state.page * 50 + 50}{" "}
            &#8725; {this.state.count}{" "}
            <span
              onClick={() => {
                var newpage =
                  this.state.page > 0 ? this.state.page - 1 : this.state.max;

                this.setState({ page: newpage });
                this.fetchmore(newpage);
              }}
            >
              &#8249;
            </span>{" "}
            <span
              onClick={() => {
                var newpage =
                  this.state.page == this.state.max ? 0 : this.state.page + 1;
                this.setState({ page: newpage });
                this.fetchmore(newpage);
              }}
            >
              &#8250;
            </span>{" "}
          </div>
        </div>
        <div className="prev_card searchable">
          {!this.state.loading ? (
            this.state.orders ? (
              <table
                className="data table table-order-items history"
                id="my-orders-table-ord"
              >
                <caption className="table-caption">Opportunità</caption>
                <thead>
                  <tr>
                    <Utility
                      choose="header"
                      head_array={[
                        {
                          label: "Nome",
                          value: "name",
                          col: "id",
                        },
                        {
                          label: "Data",
                          value: "date_open",
                          col: "date",
                        },
                        {
                          label: "Agente",
                          value: "partner_id",
                          col: "shipping",
                        },
                        { label: "Telefono", value: "phone", col: "total" },
                        {
                          label: "Guadagno previsto",
                          value: "planned_revenue",
                          col: "total",
                        },
                        {
                          label: "Probabilità",
                          value: "probability",
                          col: "total",
                        },
                        {
                          label: "Team",
                          value: "team_id",
                          col: "team",
                        },
                        {
                          label: "Cliente",
                          value: "user_id",
                          col: "desc",
                        },
                        {
                          label: "Tipo",
                          value: "type",
                          col: "status",
                        },
                        {
                          label: "",
                          value: "link",
                          col: "col actions rightalign",
                        },
                      ]}
                      orderstate={this.state.ordered_get}
                      handleState={this.handleOrdineOpportunita}
                    />
                  </tr>
                </thead>

                <tbody>{this.renderTablesOrder()}</tbody>
              </table>
            ) : (
              <div className="message info empty">
                <span>Non sono presenti opportunità registrate.</span>
              </div>
            )
          ) : (
            <table
              className="data table table-order-items history"
              id="my-orders-table-ord"
            >
              <caption className="table-caption">Ordini</caption>

              <thead>
                <tr>
                  <th scope="col" className="col id">
                    Nome
                  </th>
                  <th scope="col" className="col date">
                    Data
                  </th>
                  <th scope="col" className="col shipping">
                    Agente
                  </th>
                  <th scope="col" className="col total">
                    Telefono
                  </th>
                  <th scope="col" className="col total">
                    Guadagno Previsto
                  </th>
                  <th scope="col" className="col status">
                    Probabilità
                  </th>
                  <th scope="col" className="col status">
                    Team
                  </th>
                  <th scope="col" className="col desc">
                    Cliente
                  </th>
                  <th scope="col" className="col id">
                    Tipo
                  </th>
                </tr>
              </thead>

              <tbody>{this.renderTablesLoader()}</tbody>
            </table>
          )}
        </div>
        {this.state.fetching && (
          <div className="obscure">
            <Load text="Carico i nuovi dati" />
          </div>
        )}{" "}
      </div>
    );
  }
}
