import React, { Component } from "react";
import api from "../../../config";
import Swal from "sweetalert2";
import { makeStyles } from "@material-ui/core/styles";
import { Tooltip } from "@material-ui/core";
import Load from "../../../components/Load";
export default class InnerVisVisita extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stamp_active: false,
      partner_id: "",
      share_active: false,
      loader: false,
    };
  }
  componentDidMount() {
    this.setState({ loader: true });
    api.getInnerLead(this.props.match.params.idorder).then((value) => {
      var count = 0;
      if (value) {
        this.setState({
          summary: value,
          lines: value.order_line,
          count: count,
          loader: false,
        });
      } else this.setState({ loader: false });
    });
  }
  renderDates(lista) {
    var data_res = [];
    lista.forEach((data) => {
      data_res.push(<li key={data}>{data}</li>);
    });
    return data_res;
  }
  Tooltip(props) {
    const useStylesBootstrap = makeStyles((theme) => ({
      arrow: {
        color: theme.palette.common.black,
      },
      tooltip: {
        backgroundColor: theme.palette.common.black,
        boxShadow: theme.shadows[1],
        fontSize: 14,
      },
    }));
    const classes = useStylesBootstrap();

    return <Tooltip arrow classes={classes} {...props} />;
  }
  colorStatus(string) {
    var res = "";
    switch (string) {
      case "opportunity":
        res = <b style={{ color: "green" }}>Opportunità</b>;
        break;
      case "Ordinato":
        res = <b style={{ color: "orange" }}>{string}</b>;
        break;
      default:
        res = <b>{string}</b>;
    }
    return res;
  }
  formatDate = function (input) {
    if (input) {
      var datePart = input.match(/\d+/g),
        year = datePart[0].substring(0), // get only two digits
        month = datePart[1],
        day = datePart[2];

      return day + "/" + month + "/" + year;
    } else return "Non specificata";
  };
  render() {
    if (this.state.summary) {
      const alldata = this.state.summary;

      return (
        <React.Fragment>
          <div className="page_container">
            <h1 style={{textTransform:'capitalize'}} className="page_title">{alldata.name || "Senza nome"}</h1>
            <div className="prev_card">
              <div className="table-wrapper order-items">
                <div className="order-date leftalign">
                <p>
                    Nome Opportunità :{" "}
                    <span style={{ color: "black" }}>{alldata.name || "Senza nome"}</span>
                  </p>
                  <p>
                    Data Opportunità:{" "}
                    <span style={{ color: "black" }}>
                      {this.formatDate(alldata.date)}
                    </span>
                  </p>
                  <p>
                    Entrata Prevista :{" "}
                    <span style={{ color: "black" }}>{alldata.planned_revenue.toLocaleString("it-IT", {
                          style: "currency",
                          currency: "EUR",
                        })}</span>
                  </p>
                  {alldata.partner_id &&   <p>
                    Agente :{" "}
                    <span style={{ color: "black" }}>
                     {alldata.partner_id.name}
                    </span>
                  </p> }
                  <p>
                    Tipo :{" "}
                    <span style={{ color: "black" }}>{this.colorStatus(alldata.type)}</span>
                  </p>
                 
                </div>
              </div>
            </div>

          </div>
          {this.state.loader && (
            <div className="obscure">
              <Load
                text={
                  "Sto caricando "
                }
              />
            </div>
          )}
        </React.Fragment>
      );
    } else
      return (
        <div className="obscure">
          <Load
            text={
              "Sto caricando"
            }
          />
        </div>
      );
  }
}
