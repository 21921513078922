import React, { Component } from "react";
import AsyncSelect from "react-select/async";
import { Animated } from "react-animated-css";
import api from "../../../config";
import Load from "../../../components/Load";
import Select from "react-select";
import SearchAnagrafy from "../../../components/search_nation/SearchAnagrafy";
var _ = api._;
export default class Anagrafy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      company: "",
      firstname: "",
      lastname: "",
      email: "",
      search_user: "",
      phone: "",
      cellphone: "",
      iva: "",
      fatt_state: { label: "" },
      fatt_country: { label: "" },
      fatt_city: "",
      pec: "",
      sdi: "",
      fatt_street_1: "",
      fatt_street_2: "",
      sped_street_1: "",
      sped_street_2: "",
      fatt_cap: "",
      sped_cap: "",
      sped_state: { label: "" },
      sped_city: { label: "" },
      errors: [],
      sped_country: "",
      country: {},
      search:false,
      same_address: true,
      loadingCountry: false,
      countries: [],
      permission: this.props.user.frontend_user_permissions.anagrafica,
    };
  }
  componentDidMount() {
    this.setState({ loadingCountry: true });
    api.fetchCountries().then((values) => {
      this.setState({ countries: values, loadingCountry: false });
    });
  }
  loadOptions = (inputValue, callback) => {
    if (inputValue.length > 2) {
      api.searchAsyncCustomer(inputValue).then((value) => {
        callback(value.rows);
      });
    }
  };
  fillForm=(e)=>{
    if(e.label.length>2)
      this.setState({
        search:true,
        search_user: e.label,
        firstname: e.value.firstname ? e.value.firstname : "",
        lastname: e.value.lastname ? e.value.lastname : "",
        company: e.value.company ? e.value.company : "",
        email: e.value.email ? e.value.email : "",
        phone: e.value.phone ? e.value.phone : "",
        cellphone: e.value.cellphone ? e.value.cellphone : "",
        fatt_state: e.value.invoice_to
          ? e.value.invoice_to.country !== null
            ? e.value.invoice_to.country
            : { label: "", value: -11 }
          : { label: "", value: -11 },
        fatt_country: e.value.invoice_to
          ? e.value.invoice_to.state !== null
            ? e.value.invoice_to.state
            : {
                label: "",
                value: -11,
              }
          : {
              label: "",
              value: -11,
            },
        fatt_city: e.value.invoice_to ? e.value.invoice_to.city : "",
        sped_state: e.value.ship_to
          ? e.value.ship_to.country !== null
            ? e.value.ship_to.country
            : { label: "", value: -11 }
          : { label: "", value: -11 },
        sped_country: e.value.ship_to
          ? e.value.ship_to.state !== null
            ? e.value.ship_to.state
            : {
                label: "",
                value: -11,
              }
          : {
              label: "",
              value: -11,
            },
        sped_city: e.value.ship_to ? e.value.ship_to.city : "",
        fatt_street_1: e.value.invoice_to
          ? e.value.invoice_to.street
          : "",
        fatt_street_2: e.value.invoice_to
          ? e.value.invoice_to.street2
          : "",
        fatt_cap: e.value.invoice_to ? e.value.invoice_to.zip : "",
        sped_street_1: e.value.ship_to ? e.value.ship_to.street : "",
        sped_street_2: e.value.ship_to ? e.value.ship_to.street2 : "",
        sped_cap: e.value.ship_to ? e.value.ship_to.zip : "",
        same_address:
          e.value.ship_to && e.value.invoice_to
            ? e.value.invoice_to.street === e.value.ship_to.street &&
              e.value.invoice_to.street2 ===
                e.value.ship_to.street2 &&
              e.value.invoice_to.country ===
                e.value.ship_to.country &&
              e.value.invoice_to.city === e.value.ship_to.city &&
              e.value.invoice_to.state === e.value.ship_to.state &&
              e.value.invoice_to.zip === e.value.ship_to.zip
            : e.value.ship_to
            ? false
            : true,
        pec: e.value.pec,
        sdi: e.value.sdi,
        iva: e.value.iva,
      });
  }
  renderSearch = () => {
    var res = null;
    if (this.state.permission) {
      res = (
        <div className="row_div">
          <div className="cell_div">
            <p className="subtitle">Cerca Contatto</p>
            <AsyncSelect
              cacheOptions
              // isLoading={false}
              // isClearable={false}
              // isSearchable={true}
              onChange={(e) => {

               this.fillForm(e)
               window.scrollBy({top:200, behavior: 'smooth'})
              }}
              placeholder="Cerca un utente nel database"
              classNamePrefix="anagrafy-select"
              loadOptions={_.debounce(this.loadOptions, 300)}
          
            />
          </div>
        </div>
      );
    } else {
      res = (
        <div className="row_div">
          
          <div className="cell_div">
            {" "}
            <p id="fatt_state" className="subtitle">
              Stato
            </p>
            <Select
               styles={{
                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              }}
              menuPortalTarget={document.body}
              isClearable={false}
              escapeClearsValue={false}
              
              name="stato"
              placeholder={
                this.state.country.label
                  ? this.state.country.label
                  : "Inserisci lo stato"
              }
              options={this.state.countries}
              isLoading={this.state.loadingCountry}
              formatCreateLabel={(e) => 'Crea "' + e + '"'}
              value={this.state.fatt_country.value}
              onChange={(e) => {
                this.setState({country:e})
              }}
            />
          </div>
          {this.state.country.value >0  &&
          <div className={this.state.country.value >0 ? "cell_div widthVis" : " cell_div width0"}>
            <p className="subtitle">Cerca Contatto</p>
            <AsyncSelect
            name="query"
              cacheOptions
              // isLoading={false}
              // isClearable={false}
              // isSearchable={true}
              onChange={(e)=> this.fillForm(e)
              }
              placeholder="Cerca un utente nel database"
              // defaultValue={this.state.search_user}
              loadOptions={_.debounce(this.loadOptions, 300)}
              classNamePrefix={"anagrafySearch"}
           
            />
          </div>
            }
            </div>
      );
    }
    return res;
  };

  render() {

    if (this.props.user.frontend_user_permissions)
      return (
        <div className="page_container preventivatore">
          <div className="prev_card noborder nobg">
            <h1 className="page_title ">
              {this.props.user.frontend_user_permissions.opt_contact_adv_search
                ? "Consulta Anagrafiche"
                : "Consulta il Network"}
            </h1>
          </div>

          <Animated
            isVisible={true}
            animationIn="fadeIn"
            animationInDelay={500}
            className="preventivatore"
            animationOut="fadeOutRight"
          >
            <SearchAnagrafy
              permission={
                this.props.user.frontend_user_permissions.opt_contact_adv_search
              }
              label={"Cerca Contatto"}
              setSearch={this.fillForm}
              apiCall={api.searchAsyncCustomer}
              async
            />

            <Animated
              isVisible={this.state.search}
              animationIn="fadeIn"
              animationInDelay={500}
              style={{ marginTop: this.state.search ? "inherit" : "250px" }}
              className="preventivatore"
              animationOut="fadeOutRight"
            >
              <div className="prev_card">
                <div className="table_div">
                  <div className="row_div">
                    <div className="cell_div">
                      <h3>Informazioni di Contatto</h3>
                    </div>
                  </div>
                  <div className="row_div">
                    <div className="cell_div">
                      <p className="subtitle">Nome*</p>
                      <input
                        type="text"
                        disabled
                        onChange={(e) =>
                          this.setState({ firstname: e.target.value })
                        }
                        value={this.state.firstname}
                      />
                    </div>
                    <div className="cell_div">
                      <p className="subtitle">Cognome*</p>
                      <input
                        type="text"
                        disabled
                        onChange={(e) =>
                          this.setState({ lastname: e.target.value })
                        }
                        value={this.state.lastname}
                      />
                    </div>
                  </div>
                  <div className="row_div">
                    <div className="cell_div">
                      <p className="subtitle">Email*</p>
                      <input
                        type="text"
                        disabled
                        onChange={(e) =>
                          this.setState({ email: e.target.value })
                        }
                        value={this.state.email}
                      />
                    </div>
                    <div className="cell_div">
                      <p className="subtitle">Numero di telefono*</p>
                      <input
                        type="text"
                        disabled
                        onChange={(e) =>
                          this.setState({ phone: e.target.value })
                        }
                        value={this.state.phone}
                      />
                    </div>
                  </div>
                  <div className="row_div">
                    <div className="cell_div">
                      <p className="subtitle">Cellulare</p>
                      <input
                        type="text"
                        disabled
                        onChange={(e) =>
                          this.setState({ cellphone: e.target.value })
                        }
                        value={this.state.cellphone}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="prev_card">
                <div className="table_div">
                  <div className="row_div">
                    <div className="cell_div">
                      <h3>Indirizzo di Fatturazione </h3>
                    </div>
                    <div className="cell_div">
                      <div className="field choice newsletter">
                        <span
                          className={
                            this.state.same_address
                              ? "jcf-checkbox jcf-checked jcf-focus"
                              : "jcf-checkbox jcf-unchecked"
                          }
                          id="check_same"
                        >
                          <span />

                          <input
                            type="checkbox"
                            className="checkbox"
                            name="check_same"
                            disabled
                            value={this.state.same_address}
                            onChange={() =>
                              this.setState({
                                same_address: !this.state.same_address,
                              })
                            }
                            style={{
                              position: "absolute",
                              height: "100%",
                              width: "100%",
                              opacity: 0,
                              margin: "0px",
                            }}
                          />
                        </span>
                        <label htmlFor="check_same" className="label">
                          <span>Stesso indirizzo per la spedizione</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div style={{ width: "100%" }} className="row_div">
                    <div className="cell_div address">
                      <p className="subtitle">Indirizzo*</p>
                      <input
                        type="text"
                        disabled
                        onChange={(e) =>
                          this.setState({ fatt_street_1: e.target.value })
                        }
                        value={this.state.fatt_street_1}
                      />
                    </div>
                    <div className="cell_div cap">
                      <p className="subtitle">CAP*</p>
                      <input
                        type="text"
                        disabled
                        onChange={(e) =>
                          this.setState({ fatt_cap: e.target.value })
                        }
                        value={this.state.fatt_cap}
                      />
                    </div>
                  </div>
                  <div style={{ width: "100%" }} className="row_div">
                    <div className="cell_div address">
                      <input
                        type="text"
                        disabled
                        onChange={(e) =>
                          this.setState({ fatt_street_2: e.target.value })
                        }
                        value={this.state.fatt_street_2}
                      />
                    </div>
                  </div>
                </div>
                <hr />
                <div className="table_div">
                  {" "}
                  <div className="row_div">
                    <div className="cell_div">
                      <p className="subtitle">Stato/Provincia</p>
                      <input
                        type="text"
                        disabled
                        onChange={(e) =>
                          this.setState({ fatt_state: e.target.value })
                        }
                        value={this.state.fatt_state.label}
                      />
                    </div>
                    <div className="cell_div">
                      <p className="subtitle">Città*</p>
                      <input
                        type="text"
                        disabled
                        onChange={(e) =>
                          this.setState({ fatt_city: e.target.value })
                        }
                        value={this.state.fatt_city}
                      />
                    </div>
                  </div>
                  <div className="row_div">
                    <div className="cell_div">
                      <p className="subtitle">Paese*</p>

                      <input
                        type="text"
                        disabled
                        onChange={(e) =>
                          this.setState({ fatt_country: e.target.value })
                        }
                        value={this.state.fatt_country.label}
                      />
                    </div>
                  </div>
                </div>
                <hr />
                <div className="table_div">
                  <div className="row_div">
                    <div className="cell_div">
                      <p className="subtitle">Società</p>
                      <input
                        type="text"
                        disabled
                        onChange={(e) =>
                          this.setState({ company: e.target.value })
                        }
                        value={this.state.company}
                      />
                    </div>
                    <div className="cell_div">
                      <p className="subtitle">Partita Iva</p>
                      <input
                        type="text"
                        disabled
                        onChange={(e) => this.setState({ iva: e.target.value })}
                        value={this.state.iva}
                      />
                    </div>
                  </div>
                  <div className="row_div">
                    <div className="cell_div">
                      <p className="subtitle">Pec</p>
                      <input
                        type="text"
                        disabled
                        onChange={(e) => this.setState({ pec: e.target.value })}
                        value={this.state.pec}
                      />
                    </div>
                    <div className="cell_div">
                      <p className="subtitle">Codice SDI</p>

                      <input
                        type="text"
                        disabled
                        onChange={(e) => this.setState({ sdi: e.target.value })}
                        value={this.state.sdi}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={
                  !this.state.same_address
                    ? "prev_card height450"
                    : "prev_card height0"
                }
              >
                <div className="table_div">
                  <div className="row_div">
                    <div className="cell_div">
                      <h3>Indirizzo di Spedizione</h3>
                    </div>
                  </div>
                  <div style={{ width: "100%" }} className="row_div">
                    <div className="cell_div address">
                      <p className="subtitle">Indirizzo*</p>
                      <input
                        type="text"
                        disabled
                        onChange={(e) =>
                          this.setState({ sped_street_1: e.target.value })
                        }
                        value={this.state.sped_street_1}
                      />
                    </div>
                    <div className="cell_div cap">
                      <p className="subtitle">CAP*</p>
                      <input
                        type="text"
                        disabled
                        onChange={(e) =>
                          this.setState({ sped_cap: e.target.value })
                        }
                        value={this.state.sped_cap}
                      />
                    </div>
                  </div>
                  <div style={{ width: "100%" }} className="row_div">
                    <div className="cell_div address">
                      <input
                        type="text"
                        disabled
                        onChange={(e) =>
                          this.setState({ sped_street_2: e.target.value })
                        }
                        value={this.state.sped_street_2}
                      />
                    </div>
                  </div>
                </div>
                <hr />
                <div className="table_div">
                  {" "}
                  <div className="row_div">
                    <div className="cell_div">
                      <p className="subtitle">Stato/Provincia</p>
                      <input
                        type="text"
                        disabled
                        onChange={(e) =>
                          this.setState({ sped_state: e.target.value })
                        }
                        value={this.state.sped_state.label}
                      />
                    </div>
                    <div className="cell_div">
                      <p className="subtitle">Città*</p>
                      <input
                        type="text"
                        disabled
                        onChange={(e) =>
                          this.setState({ sped_city: e.target.value })
                        }
                        value={this.state.sped_city}
                      />
                    </div>
                  </div>
                  <div className="row_div">
                    <div className="cell_div">
                      <p className="subtitle">Paese*</p>

                      <input
                        type="text"
                        disabled
                        onChange={(e) =>
                          this.setState({ sped_country: e.target.value })
                        }
                        value={this.state.sped_country.label}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* 
         <div className="prev_card noborder nobg">
            <div style={{ width: "100%" }} className="table_div">
              <div style={{ width: "100%" }} className="row_div">
                <div style={{ float: "right" }} className="cell_div">
                  <button
                    onClick={() => this.changePhase(1)}
                    className="btn_modifica"
                  >
                    Avanti
                  </button>
                </div>
              </div>
            </div>
          </div> */}
            </Animated>
          </Animated>
        </div>
      );
      else return(<div className="page_container preventivatore">
     <Load /></div>);
  }
}
