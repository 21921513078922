import React, { Component } from "react";
import { Animated } from "react-animated-css";

class Container extends Component {
  render() {
    const ContainerName = this.props.activeContainer;

    return (
      <Animated visible={true} animationIn="fadeIn" animationOut="fadeOut">
        <ContainerName {...this.props} />
      </Animated>
    );
  }
}

export default Container;
